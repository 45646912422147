import React from 'react';

const Singapore = () => {
    return (
        <div style={{ height: '700px', backgroundColor: '#1A1A25' }}>
            <h1 style={{ marginTop: '80px' }} className='text-gradient text-center pt-5 text-uppercase'>Singapore</h1>
        </div>
    );
};

export default Singapore;