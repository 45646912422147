import React, { useContext, useEffect, useState } from "react";
import Web3 from "web3";
import { CelebrityContext } from "../../../context/CelebrityContext";

const NftTest = () => {
  const { nftabi } = useContext(CelebrityContext);
  const [nftTokens, setNftTokens] = useState([]);

  useEffect(() => {
    const fetchNFTs = async () => {
      try {
        const walletAddress = "0x626D20125da6a371aA48023bF9dad94BD66588F7";
        const contractAddress = "0x7Eb18A5F7DB1e8b0a321bc563fE0b67dCD86F504";

        if (window.ethereum) {
          const web3 = new Web3(window.ethereum);
          await window.ethereum.enable();

          // Replace ABI with the actual ABI of your NFT contract

          const contract = new web3.eth.Contract(nftabi, contractAddress);

          // Call the contract's function to retrieve the token IDs
          const tokenIds = [1000031];

          setNftTokens(tokenIds);
        }
      } catch (error) {
        console.log("Error:", error);
      }
    };

    fetchNFTs();
  }, []);

  return (
    <div>
      <h1>NFTs in Wallet</h1>
      {nftTokens?.map((tokenId) => (
        <div key={tokenId}>
          <p>Token ID: {tokenId}</p>
          {/* Fetch and display the image using the token ID */}
          <img
            src={`YOUR_IMAGE_BASE_URL/${tokenId}.png`}
            alt={`NFT Image ${tokenId}`}
          />
        </div>
      ))}
    </div>
  );
};

export default NftTest;
