import { Typography } from "@mui/material";
import { useState } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import MetaData from "../../MetaData/MetaData";

function HowItWorks() {
  const [isMore, setIsMore] = useState(false);

  let moreLess = "";
  // `${!isMore ? "More details" : "Less details"}`

  return (
    <div>
        <MetaData pageTitle={"Play Blockchain games | Buy NFT and win prizes"} pageDescription={"Playtoearnblockchain.com NFTs can be sold in marketplaces or used in our PLAY TO EARN Blockchain Games. It is a blockchain-based game that allows players to earn rewards & Purchase NFTs."}></MetaData>
      <div>
        <h1
          className="text-gradient text-center pt-5 text-uppercase"
          style={{ marginTop: "80px", fontSize: "1.75rem" }}
        >
          How it works
        </h1>
        <div className="small-border bg-color-2"></div>
      </div>
      <div>
        <Container>
          <div className="text-white p-2">
            {/* <Typography
              className="pt-1 howItWorksFs"
              variant="subtitle2"
              component="div"
            >
              DS Legends Pte Ltd (DSL) has launched{" "}
              <a
                target="_blank"
                href="https://playtoearnblockchain.com/"
                style={{ textDecoration: "none" }}
                className="text-primary"
              >
                Playtoearnblockchain.com
              </a>{" "}
              in SINGAPORE FINTECH FESTIVAL 2022.
            </Typography> */}
            <Typography
              className="pt-3 howItWorksFs"
              variant="subtitle2"
              component="div"
            >
              The NFTs are created using Artificial Intelligence.
            </Typography>
            <Typography
              className="pt-3 howItWorksFs"
              variant="subtitle2"
              component="div"
            >
              Anyone who purchases these Playtoearnblockchain.com NFTs can be sold in
              marketplaces or used in our PLAY TO EARN Blockchain Games.
            </Typography>

            <Typography
              className="pt-3 howItWorksFs"
              variant="subtitle2"
              component="div"
            >
              Please purchase our NFTs{" "}
              <Link
                to="/nfts"
                style={{ textDecoration: "none" }}
                className="text-primary"
              >
                here.
              </Link>
            </Typography>

            <Typography
              className="pt-3 pb-3 howItWorksFs"
              variant="subtitle2"
              component="div"
            >
              Please test our Play To Earn Blockchain Games{" "}
              <Link
                to="/playtoearn"
                style={{ textDecoration: "none" }}
                className="text-primary"
              >
                here.
              </Link>
            </Typography>

            <Typography
              className="howItWorksFs"
              variant="subtitle2"
              component="div"
            ></Typography>

            <Typography
              className="pt-3 howItWorksFs"
              variant="subtitle2"
              component="div"
            ></Typography>
          </div>
          <div id="forTrans" className="text-white p-2">
            <div className="text-white w-75">
              <Typography
                className=" pb-2 text-primary howItWorksFs"
                variant="subtitle2"
                component="div"
              >
                Why Buy Playtoearnblockchain.com NFTs?
              </Typography>

              <Typography
                className="pt-1 howItWorksFs"
                variant="subtitle2"
                component="div"
              >
                1. Sell your{" "}
                <a
                  target="_blank"
                  href="https://playtoearnblockchain.com/"
                  style={{ textDecoration: "none" }}
                  className="text-primary"
                >
                  Playtoearnblockchain.com
                </a>{" "}
                NFTs in marketplaces for a profit
              </Typography>
              <Typography
                className="pt-1 howItWorksFs"
                variant="subtitle2"
                component="div"
              >
                {/* 3. Earn at Play To Earn Platforms */}
                2. Earn Coins and Tokens at our Play To Earn Blockchain Games.
              </Typography>
            </div>
            {/* <div className="text-white w-75 mt-4">
              <Typography
                className=" pb-2 text-primary howItWorksFs"
                variant="subtitle2"
                component="div"
              >
                Please email at sam@dsl.sg for the following.
              </Typography>

        
              <Typography
                className="pt-1 howItWorksFs"
                variant="subtitle2"
                component="div"
              >
                Develop your own Play to Earn Blockchain Game similar to{" "}
                <a
                  target="_blank"
                  href="https://playtoearnblockchain.com/"
                  style={{ textDecoration: "none" }}
                  className="text-primary"
                >
                  Playtoearnblockchain.com
                </a>
              </Typography>
            </div> */}
            <div className="text-white my-3"></div>
          </div>
          <p
            onClick={() => setIsMore(!isMore)}
            id="expandBtn"
            className="text-primary text-decoration-underline p-2"
            style={{ cursor: "pointer" }}
          >
            {moreLess}
          </p>

          <div
            className="d-flex pt-2 pb-3"
            style={{ justifyContent: "center" }}
          ></div>
          <h4 className="text-center mx-auto d-block mb-3">
            <Link
              to="/playtoearn"
              className="text-decoration-none playtoearn text-center mx-auto"
            >
              Play To Earn
            </Link>
          </h4>
        </Container>
      </div>
    </div>
  );
}

export default HowItWorks;
