import React, { useState, useEffect, useRef, useContext } from 'react';
import Buttons from 'react-bootstrap/Button';
import Box from '@mui/material/Box';
import './Racing.css';
import { Button, CircularProgress } from '@mui/material';
import swal from 'sweetalert';
import Timer from 'react-compound-timer/build';
import { useTimer } from "reactjs-countdown-hook";
import axios from 'axios';
import moment from 'moment';
import Game3D from './Game3D/Game3D';
import { useFullScreenHandle } from "react-full-screen";
import { useUnityContext } from "react-unity-webgl";
import Fullscreen2 from 'react-fullscreen-crossbrowser';
import { CelebrityContext } from '../../../context/CelebrityContext';
import SelectionModal from './SelectionModal/SelectionModal';
import PositionDialog from './PositionDialog';
import Stopwatch from './StopWatch/Stopwatch';

import { useLocation, useNavigate } from 'react-router-dom';

const RacingPage = () => {

    const { unityProvider, isLoaded, loadingProgression, sendMessage, requestFullscreen
    } = useUnityContext({
        loaderUrl: './Build/Celebrity 1.17 - Brotli.loader.js',
        dataUrl: "./Build/Celebrity 1.17 - Brotli.data.unityweb",
        frameworkUrl: "./Build/Celebrity 1.17 - Brotli.framework.js.unityweb",
        codeUrl: "./Build/Celebrity 1.17 - Brotli.wasm.unityweb",
    });

    const navigate = useNavigate()

    const { user } = useContext(CelebrityContext);

    const [animationHandle, setAnimationHandle] = useState(false);
    const [animationRestart, setAnimationRestart] = useState(false);
    const [animationPause, setAnimationPause] = useState(false);
    const [speed, setSpeed] = useState([])
    const [positions, setPositions] = useState([])
    const [racePost, setRacePost] = useState([])
    const [place, setPlace] = useState([])
    const [timeTricker, setTimeTricker] = useState(undefined)
    const [clocktimeTricker, setClockTimeTricker] = useState(undefined)
    const [catAnim, setCatAnim] = useState(undefined)
    const [position, setPosition] = useState(false)
    const [firstInRace, setFirstInRace] = useState('');
    const [lastInRace, setLastInRace] = useState('')
    const [email, setEmail] = useState('')
    const [open, setOpen] = React.useState(false);
    const [focus, setFocus] = React.useState(false);
    const [focusBid, setFocusBid] = React.useState(false);
    const [countdown, setCountdown] = React.useState(false);
    const [checkEmail, setCheckEmail] = React.useState(false);
    const [biddingDog, setBiddingDog] = React.useState('');
    const [myDog, setMyDog] = useState({});
    const [bid, setBid] = useState("");
    const [racePoint, setRacePoints] = React.useState([]);
    const [emailVerify, setEmailVerify] = useState(false);
    const [replay, setReplay] = useState(false);
    const [changeRestart, setChangeRestart] = useState(false);
    const [win40, setWin40] = useState(false);
    const [raceWin, setraceWin] = useState({});
    const [money, setMoney] = useState('');
    const [nftId, setnftId] = useState("");
    const myRef = useRef(null)
    const [isFullscreenEnabled2, setIsFullscreenEnabled2] = useState(false);
    const [time, setTime] = useState(0);
    const [timerOn, setTimerOn] = useState(false);
    const [popUpBtn, setPopUpBtn] = useState(false);
    const [go, setGo] = useState(false);
    const [loading, setLoading] = React.useState(true)
    const [modalShow, setModalShow] = React.useState(false);
    const [resultBtn, setResultBtn] = React.useState(false);
    const [AllData, setAllData] = React.useState(0);
    const [change, setChange] = React.useState(0);
    const title = "busd"
    let dslClaim = 0
    let speedCount = [];
    let willWin = 0;

    // scroll to racing section 
    const executeScroll = () => myRef.current.scrollIntoView()

    // fullscreen 
    const handle = useFullScreenHandle();
    const handle2 = useFullScreenHandle();

    // countdown portion object
    const {
        isActive,
        counter,
        seconds,
        minutes,
        hours,
        days,
        pause,
        resume,
        reset,
    } = useTimer(3, () => { setCountdown(true) });

    // countdown reset function 
    const reset2 = () => {
        reset();

    }

    //  the dog object 
    const dogsImg = [
        { img: "https://i.ibb.co/RNgCqsp/skyblue-male.png", probability: 0, strength: 75, gender: "Male Sky blue", track: '1' },
        { img: "https://i.ibb.co/vv7f4SL/skyblue-female.png", probability: 0, strength: 80, gender: "Female Sky blue", track: '2' },
        { img: "https://i.ibb.co/yR920bL/red-male.png", probability: 0, strength: 82, gender: "Male Red", track: '3' },
        { img: "https://i.ibb.co/VLLvS5f/red-female.png", probability: 0, strength: 75, gender: "Female Red", track: '4' },
        { img: "https://i.ibb.co/4pL7Wp9/orange-male.png", probability: 0, strength: 70, gender: "Male Yellow", track: '5' },
        { img: "https://i.ibb.co/P4fL15q/orange-female.png", probability: 0, strength: 71, gender: "Female Yellow", track: '6' },
        { img: "https://i.ibb.co/Vw0G5N8/navyblue-male.png", probability: 0, strength: 79, gender: "Male Navy", track: '7' },
        { img: "https://i.ibb.co/rZG1V0s/navyblue-female.png", probability: 0, strength: 78, gender: "Female Navy", track: '8' },
        { img: "https://i.ibb.co/wMHtJKy/magenta-male.png", probability: 0, strength: 76, gender: "Male Magenta", track: '9' },
        { img: "https://i.ibb.co/NZ51yD4/magenta-female.png", probability: 0, strength: 71, gender: "Female Magenta", track: '10' },
        { img: "https://i.ibb.co/mTzs5zN/lime-male.png", probability: 0, strength: 83, gender: "Male Lime", track: '11' },
        { img: "https://i.ibb.co/VND8pfj/lime-female.png", probability: 0, strength: 71, gender: "Female Lime", track: '12' },
        { img: "https://i.ibb.co/KyzjLsq/grey-male.png", probability: 0, strength: 74, gender: "Male White", track: '13' },
        { img: "https://i.ibb.co/vBzh7Zt/grey-female.png", probability: 0, strength: 78, gender: "Female White", track: '14' },
        { img: "https://i.ibb.co/D8kxx8v/green-male.png", probability: 0, strength: 77, gender: "Male Green", track: '15' },
        { img: "https://i.ibb.co/g6nJ1W4/green-female.png", probability: 0, strength: 75, gender: "Female Green", track: '16' },
        { img: "https://i.ibb.co/bR9gM7G/brown-male.png", probability: 0, strength: 78, gender: "Male Orange", track: '17' },
        { img: "https://i.ibb.co/jkK6v6z/brown-female.png", probability: 0, strength: 73, gender: "Female Orange", track: '18' },
        { img: "https://i.ibb.co/KVxW5Fy/aquagreen-male.png", probability: 0, strength: 75, gender: "Male Aqua Green", track: '19' },
        { img: "https://i.ibb.co/4mcBhBF/aquagreen-female.png", probability: 0, strength: 76, gender: "Female Aqua Green", track: '20' },

    ]
    const onPageLoad = () => {
        setLoading(false);
    };


    const handleChange = (event) => {
        setBiddingDog(event.target.value);
        const select = dogsImg.find(dog => dog.img === event.target.value);
        setMyDog(select);
    };

    // position dialog modal 
    const handleClickOpen = () => {
        setOpen(true);
    };

    // position dialog modal 
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        console.log("in the page")
        return () => {
            console.log("outside the page")
            if (unityProvider) {
                sendMessage("GameManager", "QuitGame")
            }
        };
    }, [isLoaded, unityProvider]);

    const alreadyVeified = () => {
        swal({
            // title: "S",
            text: "You are already verified your email.",
            icon: "warning",
            button: "OK!",
            className: "modal_class_success",
        });
    }

    useEffect(() => {
        fetch("https://backend.playtoearnblockchain.com/api/v1/raceBUSD/", {
            headers: { "authorization": `Bearer bs2zDM01DMMEgt33CrKZt8x4abs2zDM01DMMEgt33Cr6atzL3CJdExpP4x4abs2zDM01DMMEgt33Cr` }
        })
            .then((res) => res.json())
            .then((data) => {
                setAllData(data?.data.length)
            });
    }, [change]);

    const racePostInfo = () => {
        axios.post('https://backend.playtoearnblockchain.com/api/v1/latestraceBUSD', { race: place, selectedDog: myDog }, {
            headers: { "authorization": `Bearer bs2zDM01DMMEgt33CrKZt8x4abs2zDM01DMMEgt33Cr6atzL3CJdExpP4x4abs2zDM01DMMEgt33Cr` }
        }).then(() => {
        })

        axios.post('https://backend.playtoearnblockchain.com/api/v1/raceBUSD/', { race: place, email: user?.walletAddress, raceNumber: AllData, selectedDog: myDog }, {
            headers: { "authorization": `Bearer bs2zDM01DMMEgt33CrKZt8x4abs2zDM01DMMEgt33Cr6atzL3CJdExpP4x4abs2zDM01DMMEgt33Cr` }
        })

        const dslamount = place.filter(dog => dog.img == myDog.img)
        dslClaim = parseInt(dslamount[0].prize)
        axios.post('https://backend.playtoearnblockchain.com/api/v1/payment-busd-record', {
            nftId: locataion?.state?.nft,
            bid: bid,
            prizeAmount: dslClaim,
            walletAddress: user?.walletAddress
        })
    }

    const timeoutFunc = () => {
        setTimeTricker(setTimeout(() => {
            axios.post('https://backend.playtoearnblockchain.com/api/v1/mint/data', {
                value: 10,
                walletAddress: user?.walletAddress

            })

            axios.put(`https://backend.playtoearnblockchain.com/api/v1/verifymint/${locataion?.state?.nft}`, {
                experiencePoints: 10,
                bid: bid,
                prizeAmount: dslClaim

            }, {
                headers: { "authorization": `Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54` }
            })

            setPosition(true)
            setOpen(true)
            setBiddingDog('')
            setBid('')
            setEmail('')
            setFocus(false)
            setFocusBid(false)
            setCheckEmail(false)
            setChangeRestart(true);
            setPopUpBtn(false)
            setResultBtn(true)
            setWin40(false)
            setraceWin({})
            detas = {}
            willWin = 0
        }, 38000 + 5600))
    }

    let detas = { index: 3, raceWin: [] };
    // [2, 0, 0, 1, 0, 0, 0, 0, 0, 0]

    const winPercentage = () => {
        fetch('https://backend.playtoearnblockchain.com/api/v1/mint/data1')
            .then(res => res.json())
            .then(data => {
                setraceWin(data);
                detas = data?.data;
                willWin = data?.result?.currentValue;
                getStartingInfo()
            })
    }

    const generateSpeed = () => {
        speedCount = []
        let i = 0;
        do {
            let value = Math.floor(Math.random() * (400 - 355 + 1)) + 330;
            if (!speedCount.includes(value)) {
                if (value !== speedCount[speedCount.length - 1]) {
                    speedCount.push(value);
                    i++;
                }
            }

        } while (i < 20);
        let newarray = speedCount;
        let first = Math.min(...speedCount);
        let secondMin = newarray.reduce((pre, cur) => (cur < pre && cur !== first) ? cur : pre
            , Infinity);
        let firstmin = Math.max(...speedCount), secmin = Math.max(...speedCount), thirdmin = Math.max(...speedCount);
        for (let i = 0; i < newarray.length; i++) {
            if (newarray[i] < firstmin) {
                thirdmin = secmin;
                secmin = firstmin;
                firstmin = newarray[i];
            }
            else if (newarray[i] < secmin) {
                thirdmin = secmin;
                secmin = newarray[i];
            }

            else if (newarray[i] < thirdmin)
                thirdmin = newarray[i];
        }
        // if (detas?.raceWin[detas?.index] > 0 && bid <= 1) {
        if (willWin > 10 && bid <= 1) {
            const random = Math.floor(Math.random() * (3 - 1 + 1)) + 1;
            console.log("busd random value 1", random)
            //  Math.floor(Math.random() * (4 - 1 + 1)) + 1;
            if (random == 1) {
                first = Math.min(...speedCount);
            }
            else if (random == 2) {
                first = newarray.reduce((pre, cur) => (cur < pre && cur !== first) ? cur : pre
                    , Infinity)
            }
            else if (random == 3) {
                first = thirdmin;
            }

            const mydogIndex = parseInt(myDog?.track) - 1
            const fromIndex = speedCount.indexOf(first);
            const toIndex = mydogIndex;
            const flag = speedCount[toIndex]
            speedCount[toIndex] = first;
            speedCount[fromIndex] = flag
        }
        else if (bid > 1 || willWin < 10) {
            const mydogIndex = parseInt(myDog?.track) - 1;
            const random = Math.floor(Math.random() * (20 - 4 + 1)) + 4;


            if (speedCount[mydogIndex] == first || speedCount[mydogIndex] == secondMin || speedCount[mydogIndex] == thirdmin) {

                const flag = speedCount[random];
                speedCount[random] = speedCount[mydogIndex];
                speedCount[mydogIndex] = flag;

                return speedCount;
            }
        }
        return speedCount;

    }
    const generateRacePoints = () => {
        let n = [];
        let flag = 100
        for (let i = 0; i < 20; i++) {
            n[i] = flag
            flag = flag - 5;
        }
        setRacePoints(n)
    }

    const generateDogs = (positionSort, speed) => {
        setPlace([]);
        generateRacePoints();
        let pointTotal = 105;
        let sumPoint = [];
        let bidCalculate = 10;
        const date = `${moment(new Date()).format("DD-MM-YYYY hh:mm A")}`
        console.log(firstInRace, 'first in race')
        for (let i = 0; i < 20; i++) {

            pointTotal -= 5;
            sumPoint.push({ pointTotal });
            const prizeAmount = bidCalculate * bid;


            setPlace(place => [...place, { img: dogsImg[positionSort[i]].img, gender: dogsImg[positionSort[i]].gender, probability: racePoint[i], strength: (speed[positionSort[i]] + racePoint[i]) - 350, prize: prizeAmount, time: speed[positionSort[i]], date: date, position: i + 1, track: dogsImg[positionSort[i]].track }])

            if (i == 0) {
                bidCalculate = 6
            }
            else if (i == 1) {
                bidCalculate = 4
            }
            else if (i => 2) {
                bidCalculate = 0
            }

        }
    }

    const generatePosition = (speed) => {
        setFirstInRace(Math.min(...speed))
        setLastInRace(Math.max(...speed))

        const positionSort = Array.from(Array(speed.length).keys()).sort((a, b) => speed[a] < speed[b] ? -1 : (speed[b] < speed[a]) | 0)
        setPositions(positionSort)


        timeoutFunc();
        generateDogs(positionSort, speed);



    }
    const checkInput = () => {
        swal({
            // title: "S",
            text: "Please select a stake amount!",
            icon: "warning",
            button: "OK!",
            className: "modal_class_success",
        });
    }
    const validEmail = () => {
        swal({
            text: "Verify your email!",
            icon: "warning",
            button: "OK!",
            className: "modal_class_success",
        });
    }
    useEffect(() => {
        setSpeed(generateSpeed())
        generateRacePoints()
        setCountdown(true);
        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            return () => window.removeEventListener('load', onPageLoad);
        }

    }, [])

    useEffect(() => {

        if (locataion?.state?.name == "gamenft") {

            setBid(locataion?.state?.stake);
            isLoaded && setModalShow(true)
            setnftId(locataion?.state?.nft)
        }
    }, [isLoaded])


    const [isFullscreen, setIsFullscreen] = React.useState(false);
    const locataion = useLocation()

    // Watch for fullscreenchange
    React.useEffect(() => {

        if (locataion?.state?.name == "gamenft") {
            setBid(locataion?.state?.stake);
            isLoaded && setModalShow(true)

        }
        locataion?.state?.name == "replay" && setResultBtn(true);
        locataion?.state?.name == "replay" && setTimeout(() => {
            handleClickOpen()
        }, 1500);;
        console.log(locataion, 'location')

        function onFullscreenChange() {
            setIsFullscreen(Boolean(document.fullscreenElement));
            requestFullscreen(Boolean(document.fullscreenElement))
        }

        document.addEventListener('fullscreenchange', onFullscreenChange);
        document.addEventListener("fullscreenerror", function () {
            requestFullscreen(true)
        });

        return () => document.removeEventListener('fullscreenchange', onFullscreenChange);


    }, []);


    const getStartingInfo = () => {
        const speedd = generateSpeed()
        setSpeed(speedd)
        generatePosition(speedd)
    }

    // let PauseButtonValue;
    const handleDestrcture = () => {
        setTimeout(() => {
            setGo(false)
        }, 500);
        setAnimationHandle(true);
        setAnimationPause(true)
    }


    const takeBackToStartLine = () => {
        let element = document.getElementById('uniqe');
        let element1 = document.getElementById('uniqe2');
        let element2 = document.getElementById('uniqe3');
        let element3 = document.getElementById('uniqe4');
        let element4 = document.getElementById('uniqe5');
        let element5 = document.getElementById('uniqe6');
        let element6 = document.getElementById('uniqe7');
        let element7 = document.getElementById('uniqe8');
        let element8 = document.getElementById('uniqe9');
        let element9 = document.getElementById('uniqe10');
        let element10 = document.getElementById('uniqe11');
        let element11 = document.getElementById('uniqe12');
        let element12 = document.getElementById('uniqe13');
        let element13 = document.getElementById('uniqe14');
        let element14 = document.getElementById('uniqe15');
        let element15 = document.getElementById('uniqe16');
        let element16 = document.getElementById('uniqe17');
        let element17 = document.getElementById('uniqe18');
        let element18 = document.getElementById('uniqe19');
        let element19 = document.getElementById('uniqe20');
        element.classList.remove("img_dog");
        element1.classList.remove("img_dog");
        element1.classList.remove("timer2");
        element2.classList.remove("img_dog");
        element2.classList.remove("timer3");
        element3.classList.remove("img_dog");
        element3.classList.remove("timer4");
        element4.classList.remove("img_dog");
        element4.classList.remove("timer5");
        element5.classList.remove("img_dog");
        element5.classList.remove("timer6");
        element6.classList.remove("img_dog");
        element6.classList.remove("timer7");
        element7.classList.remove("img_dog");
        element7.classList.remove("timer8");
        element8.classList.remove("img_dog");
        element8.classList.remove("timer9");
        element9.classList.remove("img_dog");
        element9.classList.remove("timer10");
        element10.classList.remove("img_dog");
        element10.classList.remove("timer11");
        element11.classList.remove("img_dog");
        element11.classList.remove("timer12");
        element12.classList.remove("img_dog");
        element12.classList.remove("timer13");
        element13.classList.remove("img_dog");
        element13.classList.remove("timer14");
        element14.classList.remove("img_dog");
        element14.classList.remove("timer15");
        element15.classList.remove("img_dog");
        element15.classList.remove("timer16");
        element16.classList.remove("img_dog");
        element16.classList.remove("timer17");
        element17.classList.remove("img_dog");
        element17.classList.remove("timer18");
        element18.classList.remove("img_dog");
        element18.classList.remove("timer19");
        element19.classList.remove("img_dog");
        element19.classList.remove("timer20");

        void element.offsetWidth;
        void element1.offsetWidth;
        void element2.offsetWidth;
        void element3.offsetWidth;
        void element4.offsetWidth;
        void element5.offsetWidth;
        void element6.offsetWidth;
        void element7.offsetWidth;
        void element8.offsetWidth;
        void element9.offsetWidth;
        void element10.offsetWidth;
        void element11.offsetWidth;
        void element12.offsetWidth;
        void element13.offsetWidth;
        void element14.offsetWidth;
        void element15.offsetWidth;
        void element16.offsetWidth;
        void element17.offsetWidth;
        void element18.offsetWidth;
        void element19.offsetWidth;

    }

    const restartButton = () => {
        setTimeout(() => {
            setGo(false)
        }, 500);

        // let classy = document.getElementsByClassName("img_dog")
        takeBackToStartLine()
        let element = document.getElementById('uniqe');
        let element1 = document.getElementById('uniqe2');
        let element2 = document.getElementById('uniqe3');
        let element3 = document.getElementById('uniqe4');
        let element4 = document.getElementById('uniqe5');
        let element5 = document.getElementById('uniqe6');
        let element6 = document.getElementById('uniqe7');
        let element7 = document.getElementById('uniqe8');
        let element8 = document.getElementById('uniqe9');
        let element9 = document.getElementById('uniqe10');
        let element10 = document.getElementById('uniqe11');
        let element11 = document.getElementById('uniqe12');
        let element12 = document.getElementById('uniqe13');
        let element13 = document.getElementById('uniqe14');
        let element14 = document.getElementById('uniqe15');
        let element15 = document.getElementById('uniqe16');
        let element16 = document.getElementById('uniqe17');
        let element17 = document.getElementById('uniqe18');
        let element18 = document.getElementById('uniqe19');
        let element19 = document.getElementById('uniqe20');

        element.classList.add("img_dog");
        element1.classList.add("img_dog");
        element1.classList.add("timer2");
        element2.classList.add("img_dog");
        element2.classList.add("timer3");
        element3.classList.add("img_dog");
        element3.classList.add("timer4");
        element4.classList.add("img_dog");
        element4.classList.add("timer5");
        element5.classList.add("img_dog");
        element5.classList.add("timer6");
        element6.classList.add("img_dog");
        element6.classList.add("timer7");
        element7.classList.add("img_dog");
        element7.classList.add("timer8");
        element8.classList.add("img_dog");
        element8.classList.add("timer9");
        element9.classList.add("img_dog");
        element9.classList.add("timer10");
        element10.classList.add("img_dog");
        element10.classList.add("timer11");
        element11.classList.add("img_dog");
        element11.classList.add("timer12");
        element12.classList.add("img_dog");
        element12.classList.add("timer13");
        element13.classList.add("img_dog");
        element13.classList.add("timer14");
        element14.classList.add("img_dog");
        element14.classList.add("timer15");
        element15.classList.add("img_dog");
        element15.classList.add("timer16");
        element16.classList.add("img_dog");
        element16.classList.add("timer17");
        element17.classList.add("img_dog");
        element17.classList.add("timer18");
        element18.classList.add("img_dog");
        element18.classList.add("timer19");
        element19.classList.add("img_dog");
        element19.classList.add("timer20");
        // console.log('init', timeTricker);
        clearTimeout(timeTricker);
        // console.log('destroyed', timeTricker);

    }
    useEffect(() => {
        place.length == 20 && racePostInfo()
    }, [place])

    // Token
    const verifiedToken = localStorage.getItem('gotVerifiedToken');

    return (
        <div>

            {loading ? <div className='d-flex align-items-center justify-content-center ' style={{ height: '100vh' }}><CircularProgress className='text-center' color="inherit" /></div> : <>
                <div ref={myRef} className='container  overflow-hidden start-0 pt-5'>

                    {
                        <div className='row mt-5 mb-5'>
                            <div className='race_width_2d3d' style={{ margin: '0 auto' }}>
                                <Fullscreen2
                                    enabled={isFullscreenEnabled2}
                                    onChange={isFullscreenEnabled2 => setIsFullscreenEnabled2(isFullscreenEnabled2)} className='full-one'>
                                    <div className='race_area col mt-1 mb-1' style={{ margin: '0' }}>
                                        <div className='single_item'>
                                            <div className='track'>
                                            </div>
                                            <div id='uniqe' className='img_dog start-position ' style={{ animationPlayState: animationHandle ? "running" : "paused", animationDuration: `${speed[0]}s`, WebkitAnimationDuration: `${speed[0]}s` }}>
                                                <img src={dogsImg[0].img} alt="dog" className={myDog.img == dogsImg[0].img ? "dog selected-dog" : "dog"} />
                                            </div>
                                        </div>
                                        <div className='single_item'>
                                            <div className='track'>
                                            </div>
                                            <div id='uniqe2' className='img_dog start-position timer2' style={{ animationPlayState: animationHandle ? "running" : "paused", animationDuration: `${speed[1]}s`, WebkitAnimationDuration: `${speed[1]}s` }}>
                                                <img src={dogsImg[1].img} alt="dog" className={myDog.img == dogsImg[1].img ? "dog selected-dog" : "dog"} />
                                            </div>
                                        </div>
                                        <div className='single_item'>
                                            <div className='track'>
                                            </div>
                                            <div id='uniqe3' className='img_dog start-position timer3' style={{ animationPlayState: animationHandle ? "running" : "paused", animationDuration: `${speed[2]}s`, WebkitAnimationDuration: `${speed[2]}s` }}>
                                                <img src={dogsImg[2].img} alt="dog" className={myDog.img == dogsImg[2].img ? "dog selected-dog" : "dog"} />
                                            </div>
                                        </div>
                                        <div className='single_item'>
                                            <div className='track'>
                                            </div>
                                            <div id='uniqe4' className='img_dog start-position timer4' style={{ animationPlayState: animationHandle ? "running" : "paused", animationDuration: `${speed[3]}s`, WebkitAnimationDuration: `${speed[3]}s` }}>
                                                <img src="https://i.ibb.co/VLLvS5f/red-female.png" alt="dog" className={myDog.img == dogsImg[3].img ? "dog selected-dog" : "dog"} />
                                            </div>
                                        </div>
                                        <div className='single_item'>
                                            <div className='track'>
                                            </div>
                                            <div id='uniqe5' className='img_dog start-position' style={{ animationPlayState: animationHandle ? "running" : "paused", animationDuration: `${speed[4]}s`, WebkitAnimationDuration: `${speed[4]}s` }}>

                                                <img src="https://i.ibb.co/4pL7Wp9/orange-male.png" alt="dog" className={myDog.img == dogsImg[4].img ? "dog selected-dog" : "dog"} />
                                            </div>
                                        </div>
                                        <div className='single_item'>
                                            <div className='track'>
                                            </div>
                                            <div id='uniqe6' className='img_dog start-position' style={{ animationPlayState: animationHandle ? "running" : "paused", animationDuration: `${speed[5]}s`, WebkitAnimationDuration: `${speed[5]}s` }}>
                                                <img src="https://i.ibb.co/P4fL15q/orange-female.png" alt="dog" className={myDog.img == dogsImg[5].img ? "dog selected-dog" : "dog"} />
                                            </div>
                                        </div>
                                        <div className='single_item'>
                                            <div className='track'>
                                            </div>
                                            <div id='uniqe7' className='img_dog start-position  timer7' style={{ animationPlayState: animationHandle ? "running" : "paused", animationDuration: `${speed[6]}s`, WebkitAnimationDuration: `${speed[6]}s` }}>
                                                <img src="https://i.ibb.co/Vw0G5N8/navyblue-male.png" alt="dog" className={myDog.img == dogsImg[6].img ? "dog selected-dog" : "dog"} />
                                            </div>
                                        </div>
                                        <div className='single_item'>
                                            <div className='track'>
                                            </div>
                                            <div id='uniqe8' className='img_dog start-position timer8' style={{ animationPlayState: animationHandle ? "running" : "paused", animationDuration: `${speed[7]}s`, WebkitAnimationDuration: `${speed[7]}s` }}>
                                                <img src="https://i.ibb.co/rZG1V0s/navyblue-female.png" alt="dog" className={myDog.img == dogsImg[7].img ? "dog selected-dog" : "dog"} />
                                            </div>
                                        </div>
                                        <div className='single_item'>
                                            <div className='track'>
                                            </div>
                                            <div id='uniqe9' className='img_dog start-position timer9' style={{ animationPlayState: animationHandle ? "running" : "paused", animationDuration: `${speed[8]}s`, WebkitAnimationDuration: `${speed[8]}s` }}>
                                                <img src="https://i.ibb.co/wMHtJKy/magenta-male.png" alt="dog" className={myDog.img == dogsImg[8].img ? "dog selected-dog" : "dog"} />
                                            </div>
                                        </div>
                                        <div className='single_item'>
                                            <div className='track'>
                                            </div>
                                            <div id='uniqe10' className='img_dog start-position timer10' style={{ animationPlayState: animationHandle ? "running" : "paused", animationDuration: `${speed[9]}s`, WebkitAnimationDuration: `${speed[9]}s` }}>
                                                <img src="https://i.ibb.co/NZ51yD4/magenta-female.png" alt="dog" className={myDog.img == dogsImg[9].img ? "dog selected-dog" : "dog"} />
                                            </div>
                                        </div>
                                        <div className='single_item'>
                                            <div className='track'>
                                            </div>
                                            <div id='uniqe11' className='img_dog start-position timer11' style={{ animationPlayState: animationHandle ? "running" : "paused", animationDuration: `${speed[10]}s`, WebkitAnimationDuration: `${speed[10]}s` }}>
                                                <img src="https://i.ibb.co/mTzs5zN/lime-male.png" alt="dog" className={myDog.img == dogsImg[10].img ? "dog selected-dog" : "dog"} />
                                            </div>
                                        </div>
                                        <div className='single_item'>
                                            <div className='track'>
                                            </div>
                                            <div id='uniqe12' className='img_dog start-position timer12' style={{ animationPlayState: animationHandle ? "running" : "paused", animationDuration: `${speed[11]}s`, WebkitAnimationDuration: `${speed[11]}s` }}>
                                                <img src="https://i.ibb.co/VND8pfj/lime-female.png" alt="dog" className={myDog.img == dogsImg[11].img ? "dog selected-dog" : "dog"} />
                                            </div>
                                        </div>
                                        <div className='single_item'>
                                            <div className='track'>
                                            </div>
                                            <div id='uniqe13' className='img_dog start-position timer13' style={{ animationPlayState: animationHandle ? "running" : "paused", animationDuration: `${speed[12]}s`, WebkitAnimationDuration: `${speed[12]}s` }}>
                                                <img src="https://i.ibb.co/KyzjLsq/grey-male.png" alt="dog" className={myDog.img == dogsImg[12].img ? "dog selected-dog" : "dog"} />
                                            </div>
                                        </div>
                                        <div className='single_item'>
                                            <div className='track'>
                                            </div>
                                            <div id='uniqe14' className='img_dog start-position timer14' style={{ animationPlayState: animationHandle ? "running" : "paused", animationDuration: `${speed[13]}s`, WebkitAnimationDuration: `${speed[13]}s` }}>
                                                <img src="https://i.ibb.co/vBzh7Zt/grey-female.png" alt="dog" className={myDog.img == dogsImg[13].img ? "dog selected-dog" : "dog"} />
                                            </div>
                                        </div>
                                        <div className='single_item'>
                                            <div className='track'>
                                            </div>
                                            <div id='uniqe15' className='img_dog start-position timer15' style={{ animationPlayState: animationHandle ? "running" : "paused", animationDuration: `${speed[14]}s`, WebkitAnimationDuration: `${speed[14]}s` }}>
                                                <img src="https://i.ibb.co/D8kxx8v/green-male.png" alt="dog" className={myDog.img == dogsImg[14].img ? "dog selected-dog" : "dog"} />
                                            </div>
                                        </div>
                                        <div className='single_item'>
                                            <div className='track'>
                                            </div>
                                            <div id='uniqe16' className='img_dog start-position timer16' style={{ animationPlayState: animationHandle ? "running" : "paused", animationDuration: `${speed[15]}s`, WebkitAnimationDuration: `${speed[15]}s` }}>
                                                <img src="https://i.ibb.co/g6nJ1W4/green-female.png" alt="dog" className={myDog.img == dogsImg[15].img ? "dog selected-dog" : "dog"} />
                                            </div>
                                        </div>
                                        <div className='single_item'>
                                            <div className='track'>
                                            </div>
                                            <div id='uniqe17' className='img_dog start-position timer17' style={{ animationPlayState: animationHandle ? "running" : "paused", animationDuration: `${speed[16]}s`, WebkitAnimationDuration: `${speed[16]}s` }}>
                                                <img src="https://i.ibb.co/bR9gM7G/brown-male.png" alt="dog" className={myDog.img == dogsImg[16].img ? "dog selected-dog" : "dog"} />
                                            </div>
                                        </div>
                                        <div className='single_item'>
                                            <div className='track'>
                                            </div>
                                            <div id='uniqe18' className='img_dog start-position timer18' style={{ animationPlayState: animationHandle ? "running" : "paused", animationDuration: `${speed[17]}s`, WebkitAnimationDuration: `${speed[17]}s` }}>
                                                <img src="https://i.ibb.co/jkK6v6z/brown-female.png" alt="dog" className={myDog.img == dogsImg[17].img ? "dog selected-dog" : "dog"} />
                                            </div>
                                        </div>
                                        <div className='single_item'>
                                            <div className='track'>
                                            </div>
                                            <div id='uniqe19' className='img_dog start-position timer19' style={{ animationPlayState: animationHandle ? "running" : "paused", animationDuration: `${speed[18]}s`, WebkitAnimationDuration: `${speed[18]}s` }}>
                                                <img src="https://i.ibb.co/4mcBhBF/aquagreen-female.png" alt="dog" className={myDog.img == dogsImg[18].img ? "dog selected-dog" : "dog"} />
                                            </div>
                                        </div>
                                        <div className='single_item'>
                                            <div className='track'>
                                            </div>
                                            <div id='uniqe20' className='img_dog start-position timer20' style={{ animationPlayState: animationHandle ? "running" : "paused", animationDuration: `${speed[19]}s`, WebkitAnimationDuration: `${speed[19]}s` }}>
                                                <img src="https://i.ibb.co/KVxW5Fy/aquagreen-male.png" alt="dog" className={myDog.img == dogsImg[19].img ? "dog selected-dog" : "dog"} />
                                            </div>
                                        </div>

                                        <span className='race-line start-line'></span>
                                        <span className='race-line end-line'></span>

                                    </div>
                                </Fullscreen2>
                                <div style={{ position: 'relative' }}>
                                    {isLoaded && <img src='https://img.icons8.com/color/48/null/switch-camera.png' onClick={() => sendMessage("Canvas", "ChangeCam")} class="fa-expand"></img>}
                                    {seconds > 0 && <span className={`count-down ${countdown == true ? 'invisible' : 'visible'}`}>{seconds}</span>}
                                    {go && <span className={`count-downGo ${go == true ? 'visible' : 'invisible'}`}>GO!</span>}

                                    <Game3D
                                        unityProvider={unityProvider}
                                        isLoaded={isLoaded}
                                        loadingProgression={loadingProgression}
                                        sendMessage={sendMessage}
                                    ></Game3D>
                                </div>
                            </div>
                            <div className='text-center' style={{ lineHeight: 1 }}>
                                <small className='text-white fst-italic' style={{ lineHeight: 1.5 }}>You can win BUSD in this <br></br>PLAY TO EARN Blockchain Game.</small>
                            </div>
                            <Box className='race_width_2d3d mx-auto' >
                                <Timer startImmediately={false}>
                                    {({ start, resume, pause, stop, reset, timerState }) => (<>
                                        <div className='race-selection-wrapper ' style={{ position: 'relative' }}>
                                            {resultBtn && <Buttons disabled={popUpBtn} className='result-btn btn btn-success border-0 rounded-3' onClick={() => { setOpen(true) }}>Previous Race</Buttons>}

                                            <SelectionModal
                                                modalShow={modalShow}
                                                setModalShow={setModalShow}
                                                dogsImg={dogsImg}
                                                setFocus={setFocus}
                                                bid={bid}
                                                setBid={setBid}
                                                focus={focus}
                                                email={email}
                                                setEmail={setEmail}
                                                setEmailVerify={setEmailVerify}
                                                biddingDog={biddingDog}
                                                handleChange={handleChange}
                                                focusBid={focusBid}
                                                isLoaded={isLoaded}
                                                alreadyVeified={alreadyVeified}
                                                setFocusBid={setFocusBid}
                                                animationPause={animationPause}
                                                checkEmail={checkEmail}
                                                setGo={setGo}
                                                handleDestrcture={handleDestrcture}
                                                setCountdown={setCountdown}
                                                setTimerOn={setTimerOn}
                                                checkInput={checkInput}
                                                validEmail={validEmail}
                                                getStartingInfo={getStartingInfo}
                                                executeScroll={executeScroll}
                                                setCatAnim={setCatAnim}
                                                reset2={reset2}
                                                reset={reset}
                                                setClockTimeTricker={setClockTimeTricker}
                                                setTime={setTime}
                                                restartButton={restartButton}
                                                catAnim={catAnim}
                                                start={start}
                                                changeRestart={changeRestart}
                                                sendMessage={sendMessage}
                                                setChangeRestart={setChangeRestart}
                                                clocktimeTricker={clocktimeTricker}
                                                stop={stop}
                                                popUpBtn={popUpBtn}
                                                setPopUpBtn={setPopUpBtn}
                                                takeBackToStartLine={takeBackToStartLine}
                                                setWin40={setWin40}
                                                winPercentage={winPercentage}
                                                money={money}
                                                setMoney={setMoney}
                                            />
                                        </div>

                                        <div className='d-flex justify-content-center' style={{ marginTop: '15px' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "center" }} className='me-2'>
                                                <div className='clock text-white '> <Timer.Seconds /> </div>
                                                <p className='text-white mb-0 time'>Seconds</p>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "center" }} className='ms-2'>
                                                <div className='clock text-white'>  <Stopwatch
                                                    time={time}
                                                    setTime={setTime}
                                                    timerOn={timerOn}
                                                    setTimerOn={setTimerOn}
                                                ></Stopwatch>  </div>
                                                <p className='text-white mb-0 time'>Miliseconds</p>
                                            </div>
                                        </div>
                                    </>
                                    )}
                                </Timer>
                            </Box>
                            <div className='text-center mt-3'>
                                <Button className='money-button1'
                                    style={{ marginBottom: "0px!important" }}
                                    onClick={() => navigate("/horsechampsdsl")}
                                ><img className='money-img win-dsl-logo' src='/dsl.jpg'></img> Win DSL (No Staking)</Button>

                            </div>
                            <div className="text-center"><button style={{ minWidth: "255px" }} className="btn btn-success mt-0 mt-lg-2 mb-3 mb-lg-0" onClick={() => navigate('/allchannel', { state: { title: { title } } })}>Race Channel</button></div>
                        </div>
                    }

                    <PositionDialog
                        handleClickOpen={handleClickOpen}
                        open={open}
                        positions={positions}
                        bid={bid}
                        nftId={nftId}
                        replay={replay}
                        setReplay={setReplay}
                        requestFullscreen={requestFullscreen}
                        myDog={myDog}
                        sendMessage={sendMessage}
                        timerOn={timerOn}
                        time={time}
                        setTime={setTime}
                        place={place}
                        racePoint={racePoint}
                        racePostInfo={racePostInfo}
                        handleClose={handleClose}>
                    </PositionDialog>
                </div>
            </>
            }
        </div>
    );
};
export default RacingPage;