import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import BusdPayment from './BusdPayment';
import CollectLoginPayment from './CollectLoginPayment';
import "./DashboardPayments.css"
import DslCatchitPayment from './DslCatchitPayment';
import DslFortunePayment from './DslFortunePayment';
import DslPayments from './DslPayments';
import WithdrawPayment from './WithdrawPayment';

const DashboardPayments = () => {
    const [data, setData] = useState([])
    const [data1, setData1] = useState([])
    const [data2, setData2] = useState([])
    const [data3, setData3] = useState([])
    const [data4, setData4] = useState([])
    const [data5, setData5] = useState([])
    useEffect(() => {
        axios.get("https://backend.playtoearnblockchain.com/api/v1/admin-payment-dsl-record")
            .then(res => {
                const flag = res.data.result
                setData(flag.filter(item => item.claim == false))
                console.log(data, "data")
            })
        axios.get("https://backend.playtoearnblockchain.com/api/v1/admin-payment-busd-record")
            .then(res => {
                const flag = res.data.result
                setData1(flag.filter(item => item.claim == false))
                console.log(data, "data")
            })

        axios.get("https://backend.playtoearnblockchain.com/api/v1/login-reward")
            .then(res => {
                const flag = res.data.result
                setData2(flag.filter(item => item.claim == false))
                console.log(data2, "data")
            })
        axios.get("https://backend.playtoearnblockchain.com/api/v1/admin-payment-withdraw-record")
            .then(res => {
                const flag = res.data.result
                setData3(flag.filter(item => item.claim == false))
                console.log(data3, "data")
            })
        axios.get("https://backend.playtoearnblockchain.com/api/v1/admin-payment-dslcatchit-record")
            .then(res => {
                const flag = res.data.result
                setData4(flag.filter(item => item.claim == false))
                console.log(data4, "data")
            })
        axios.get("https://backend.playtoearnblockchain.com/api/v1/admin-payment-fortune-record")
            .then(res => {
                const flag = res.data.result
                setData5(flag.filter(item => item.claim == false))
                console.log(data5, "data")
            })

    }, [])
    return (
        <div className="payments me-3">
            {/* <div className='d-flex justify-content-center payment'>
                <Nav variant="pills" className="flex-column flex-md-row" defaultActiveKey="link-1">
                    <Nav.Item>
                        <Nav.Link eventKey="link-1" className='text-white'>DSL payment for completing steps </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="link-2" className='text-white'>DSL payment for games
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="link-3" className='text-white'>BUSD payment for games</Nav.Link>
                    </Nav.Item>
                </Nav>
            </div> */}
            <h2 className='text-white mb-3'>Payments</h2>

            <Tabs
                defaultActiveKey="profile"
                id="justify-tab-example"
                className="mb-3  flex-column flex-md-row"
                justify
            >
                <Tab eventKey="home" className='mt-4' title={`DSL payment for completing steps (${data2.length})`}>
                    <CollectLoginPayment />
                </Tab>
                <Tab eventKey="profile" className='mt-4' title={`DSL payment for Horse Race (${data.length})`}>
                    <DslPayments />
                </Tab>
                <Tab eventKey="longer-tab" className='mt-4' title={`BUSD payment for Horse Race (${data1.length})`}>
                    <BusdPayment />
                </Tab>
                <Tab eventKey="longer" className='mt-4' title={`USD Withdraw for NFTs (${data3.length})`}>
                    <WithdrawPayment />
                </Tab>
                <Tab eventKey="long" className='mt-4' title={`DSL payment for Catch It (${data4.length})`}>
                    <DslCatchitPayment />
                </Tab>
                <Tab eventKey="lon" className='mt-4' title={`DSL payment for Fortune Wheel (${data5.length})`}>
                    <DslFortunePayment />
                </Tab>

            </Tabs>

        </div>
    );
};

export default DashboardPayments;