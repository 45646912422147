import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";

import "./DslCatch.css";
import {
  Autocomplete,
  Button,
  CircularProgress,
  TextField,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

import swal from "sweetalert";

import { useTimer } from "reactjs-countdown-hook";
import axios from "axios";
import moment from "moment";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Unity, useUnityContext } from "react-unity-webgl";

import { useLocation, useNavigate } from "react-router-dom";

import Game3D from "./Game3D";
import { CelebrityContext } from "../../context/CelebrityContext";

const useStyles = makeStyles((theme) => ({
  paper: {
    background: "linear-gradient(to right bottom, #1b1d22, #2A2E35)",
    color: "white",
  },
}));

const DslCatch = () => {
  const {
    unityProvider,
    isLoaded,
    addEventListener,
    removeEventListener,
    loadingProgression,
    sendMessage,
    requestFullscreen,
  } = useUnityContext({
    loaderUrl: "./Build/s39 1.6 - Brotli.loader.js",
    dataUrl: "./Build/s39 1.6 - Brotli.data.unityweb",
    frameworkUrl: "./Build/s39 1.6 - Brotli.framework.js.unityweb",
    codeUrl: "./Build/s39 1.6 - Brotli.wasm.unityweb",
  });
  console.log(
    "my-game",
    useUnityContext({
      loaderUrl: "./Build/Catch it 1.6 - Brotli.loader.js",
      dataUrl: "./Build/Catch it 1.6 - Brotli.data.unityweb",
      frameworkUrl: "./Build/Catch it 1.6 - Brotli.framework.js.unityweb",
      codeUrl: "./Build/Catch it 1.6 - Brotli.wasm.unityweb",
    })
  );
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const myValue = query.get("price");
  const [score, setScore] = useState(myValue ? myValue : 0);
  const [score1, setScore1] = useState(myValue ? myValue : 0);
  const [score2, setScore2] = useState(myValue ? myValue : 0);
  const [score3, setScore3] = useState(myValue ? myValue : 0);
  const [enableBtn, setEnableBtn] = useState(false);
  const Ecommerce_Collected = useCallback((Ecommerce_Score) => {
    setScore(Ecommerce_Score);
    setEnableBtn(true);
  }, []);
  const RealState_Collected = useCallback((RealState_Score) => {
    setScore1(RealState_Score);
    setEnableBtn(true);
  }, []);
  const Mineral_Collected = useCallback((Mineral_Score) => {
    setScore2(Mineral_Score);
    setEnableBtn(true);
  }, []);
  const SoilToken_Collected = useCallback((SoilToken_Score) => {
    setScore3(SoilToken_Score);
    setEnableBtn(true);
  }, []);
  const CheckUserStatus = useCallback(() => {
    sendMessage("Canvas", "SetUserStatus", 1);
  }, []);
  const ClaimBtnClicked = useCallback(() => {
    claimButton();
  }, []);
  const LoginBtnClicked = useCallback(() => {
    openWalletModal();
  }, []);
  const RestartBtnClicked = useCallback(() => {
    setEnableBtn(false);
  }, []);
  const HomeBtnClicked = useCallback(() => {
    setEnableBtn(false);
  }, []);

  useEffect(() => {
    addEventListener("GameOver_DSL_Collected", Ecommerce_Collected);
    addEventListener("CheckUserStatus", CheckUserStatus);
    addEventListener("ClaimBtnClicked", ClaimBtnClicked);
    addEventListener("LoginBtnClicked", LoginBtnClicked);
    addEventListener("RestartBtnClicked", RestartBtnClicked);
    addEventListener("HomeBtnClicked", HomeBtnClicked);
    addEventListener("SoilToken_Collected", SoilToken_Collected);
    addEventListener("Mineral_Collected", Mineral_Collected);
    addEventListener("RealState_Collected", RealState_Collected);
    return () => {
      removeEventListener("GameOver_DSL_Collected", Ecommerce_Collected);
      removeEventListener("CheckUserStatus", CheckUserStatus);
      removeEventListener("ClaimBtnClicked", ClaimBtnClicked);
      removeEventListener("LoginBtnClicked", LoginBtnClicked);
      removeEventListener("RestartBtnClicked", RestartBtnClicked);
      removeEventListener("HomeBtnClicked", HomeBtnClicked);
      removeEventListener("SoilToken_Collected", SoilToken_Collected);
      removeEventListener("Mineral_Collected", Mineral_Collected);
      removeEventListener("RealState_Collected", RealState_Collected);
    };
  }, [
    addEventListener,
    removeEventListener,
    Ecommerce_Collected,
    CheckUserStatus,
    ClaimBtnClicked,
    HomeBtnClicked,
    LoginBtnClicked,
    RestartBtnClicked,
    SoilToken_Collected,
    Mineral_Collected,
    RealState_Collected,
  ]);

  useEffect(() => {
    window.Ecommerce_Collected = (Ecommerce_Score) => {
      setScore(Ecommerce_Score);
      setEnableBtn(true);
    };
    window.SoilToken_Collected = (SoilToken_Score) => {
      setScore1(SoilToken_Score);
      setEnableBtn(true);
    };
    window.Mineral_Collected = (Mineral_Score) => {
      setScore2(Mineral_Score);
      setEnableBtn(true);
    };
    window.Mineral_Collected = (Mineral_Score) => {
      setScore3(Mineral_Score);
      setEnableBtn(true);
    };
    window.RealState_Collected = (RealState_Score) => {
      setScore(RealState_Score);
      setEnableBtn(true);
    };
    window.CheckUserStatus = () => {
      sendMessage("Canvas", "SetUserStatus", 1);
    };
    window.ClaimBtnClicked = () => {
      claimButton();
    };
    window.LoginBtnClicked = () => {
      openWalletModal();
    };
    window.RestartBtnClicked = () => {
      setEnableBtn(false);
    };
    window.HomeBtnClicked = () => {
      setEnableBtn(false);
    };
  }, [unityProvider]);

  useEffect(() => {
    isLoaded && myValue > 0 && setEnableBtn(true);
    // setInterval(() => {
    //     // isLoaded && sendMessage("Canvas", "SetUserStatus", user?.walletAddress ? 1 : 0);

    // }, 100);
  }, [isLoaded]);

  // useEffect(() => {
  //     window.CheckUserStatus = () => {
  //         sendMessage("Canvas", "SetUserStatus", true);
  //         console.log("working")
  //     };
  // }, []);
  const navigate = useNavigate();

  // console.log("unity-game", useUnityContext({
  //     loaderUrl: './Build/DSCatch 1.1 - Brotli.loader.js',
  //     dataUrl: "./Build/DSCatch 1.1 - Brotli.data.unityweb",
  //     frameworkUrl: "./Build/DSCatch 1.1 - Brotli.framework.js.unityweb",
  //     codeUrl: "./Build/DSCatch 1.1 - Brotli.wasm.unityweb",
  // }))
  const [success, setSuccess] = useState(null);
  const [checkDevice, setCheckDevice] = useState("");
  const [texts, setText] = useState("");
  useEffect(() => {
    const detecting = async () => {
      if (window.innerWidth < 1000 && typeof window.ethereum === "undefined") {
        console.log("mobile");
        setCheckDevice(() => "mobileWithoutApp");
      } else if (
        window.innerWidth < 1000 &&
        typeof window.ethereum !== "undefined"
      ) {
        setCheckDevice(() => "mobileWithApp");
      } else if (
        window.innerWidth > 1000 &&
        typeof window.ethereum !== "undefined"
      ) {
        console.log("pc");
        setCheckDevice(() => "pcWithExtention");
      } else if (
        window.innerWidth > 1000 &&
        typeof window.ethereum === "undefined"
      ) {
        setCheckDevice(() => "pcWithoutExtention");
      }
    };

    detecting();
  }, []);

  const [loading1, setLoading1] = useState(false);

  async function handleClaim() {
    setLoading1(true);

    //     await axios.post(`https://backend.playtoearnblockchain.com/api/v1/payment/${user?.walletAddress}`, { prizeAmount: score }).then(res => {
    //         if (res.status == 200) {
    //             try {
    //                 axios.post('https://backend.playtoearnblockchain.com/api/v1/admin-payment-dslcatchit-record', { walletAddress: user?.walletAddress, dslToken: score, email: user?.email, claim: false }).then(res => {
    //                     setSuccess(`You have successfully claimed ${score} DSL Tokens.`);

    //                     const wrapper = document.createElement("div");
    //                     wrapper.innerHTML = `
    //     <p style="color: white;">You have successfully claimed ${score} DSL Tokens.</p>

    //     <p style="color: white;">You will receive it within 48 hours once our admin checks</p>
    // `
    //                     swal({
    //                         content: wrapper,
    //                         icon: "success",
    //                         button: "OK!",
    //                         className: "modal_class_success",
    //                     })
    //                         .then((willDelete) => {
    //                             if (willDelete) {
    //                                 navigate('/playtoearn');
    //                             }
    //                         });

    //                 })
    //                 // const result = await contract.claimReward(score, Message, { from: account });
    //                 // console.log(result);
    //                 //                 setSuccess(`You have successfully claimed ${score} DSL Tokens.`);

    //                 //                 const wrapper = document.createElement("div");
    //                 //                 wrapper.innerHTML = `
    //                 //     <p style="color: white;">You have successfully claimed ${score} DSL Tokens.</p>

    //                 //     <p style="color: white;">You will receive it within 48 hours once our admin checks</p>
    //                 // `
    //                 // wrapper.innerHTML = `
    //                 //     <p style="color: white;">You have successfully claimed ${score} DSL Tokens.</p>
    //                 //     <div style="display: flex; justify-content: center; align-items: center; margin-bottom: 16px;">
    //                 //         <p style="color: #007bff; margin: 0;"><a style="color: #007bff;" href="https://bscscan.com/token/0x4a1530fb85bdb9250db2be251584874179eb8dc5" target="_blank">0x4A1530Fb85BdB9250Db2bE251584874179eB8Dc5</a></p>
    //                 //     </div>
    //                 //     <p style="color: white;">Copy the DSL token address and import on Your Wallet to see the claimed DSL token.</p>
    //                 // `
    //                 // swal({
    //                 //     content: wrapper,
    //                 //     icon: "success",
    //                 //     button: "OK!",
    //                 //     className: "modal_class_success",
    //                 // })
    //                 //     .then((willDelete) => {
    //                 //         if (willDelete) {
    //                 //             navigate('/');
    //                 //         }
    //                 //     });
    //             } catch (err) {
    //                 setError(err.message);
    //                 setLoading1(false)
    //             }
    //         }
    //         // else {
    //         //     swal({
    //         //         title: "Attention",
    //         //         text: res.response.data.message,
    //         //         icon: "warning",
    //         //         button: "OK!",
    //         //         className: "modal_class_success",
    //         //     });
    //         // }
    //     }).catch(error => {
    //         swal({
    //             title: "Attention",
    //             text: error.response.data.message,
    //             icon: "warning",
    //             button: "OK!",
    //             className: "modal_class_success",
    //         });
    //     });
  }

  const [animationHandle, setAnimationHandle] = useState(false);
  const [animationRestart, setAnimationRestart] = useState(false);
  const [animationPause, setAnimationPause] = useState(false);
  const [speed, setSpeed] = useState([]);
  const [positions, setPositions] = useState([]);
  const [racePost, setRacePost] = useState([]);
  const [place, setPlace] = useState([]);
  const [timeTricker, setTimeTricker] = useState(undefined);
  const [clocktimeTricker, setClockTimeTricker] = useState(undefined);
  const [catAnim, setCatAnim] = useState(undefined);
  const [position, setPosition] = useState(false);
  let speedCount = [];
  const [firstInRace, setFirstInRace] = useState("");
  const [lastInRace, setLastInRace] = useState("");
  const [email, setEmail] = useState("");
  const [open, setOpen] = React.useState(false);
  const [focus, setFocus] = React.useState(false);
  const [focusBid, setFocusBid] = React.useState(false);
  const [countdown, setCountdown] = React.useState(false);
  const [checkEmail, setCheckEmail] = React.useState(false);
  const [biddingDog, setBiddingDog] = React.useState("");
  const [myDog, setMyDog] = useState({});
  const [bid, setBid] = useState("");
  const [racePoint, setRacePoints] = React.useState([]);
  const [email1, setEmail1] = useState("");
  const [emailVerify, setEmailVerify] = useState(false);
  const [disableAfterActivation, setDisableAfterActivation] = useState(false);
  const [otpVerify, setOtpVerify] = useState();
  const [openEmail, setOpenEmail] = useState(false);
  const [isError, setError] = useState(false);
  const [replay, setReplay] = useState(false);
  const [changeRestart, setChangeRestart] = useState(false);

  const myRef = useRef(null);

  const [isFullscreenEnabled, setIsFullscreenEnabled] = useState(false);
  const [isFullscreenEnabled2, setIsFullscreenEnabled2] = useState(false);
  const [time, setTime] = useState(0);
  const [timerOn, setTimerOn] = useState(false);
  const [popUpBtn, setPopUpBtn] = useState(false);
  const [go, setGo] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [clicked, setClicked] = React.useState(true);
  const [modalShow, setModalShow] = React.useState(false);
  const [resultBtn, setResultBtn] = React.useState(false);
  const [AllData, setAllData] = React.useState(0);
  const [change, setChange] = React.useState(0);
  const { openWalletModal, user } = useContext(CelebrityContext);
  // scroll to racing section
  const executeScroll = () => myRef.current.scrollIntoView();
  // fullscreen
  const handle = useFullScreenHandle();
  const handle2 = useFullScreenHandle();
  // countdown portion object
  const {
    isActive,
    counter,
    seconds,
    minutes,
    hours,
    days,
    pause,
    resume,
    reset,
  } = useTimer(3, () => {
    setCountdown(true);
  });

  // countdown reset function
  const reset2 = () => {
    reset();
  };

  // select items css style class
  const classes = useStyles();
  // Re-send OTP functionality

  //  the dog object
  const dogsImg = [
    {
      img: "https://i.ibb.co/RNgCqsp/skyblue-male.png",
      probability: 0,
      strength: 75,
      gender: "Male Sky blue",
      track: "1",
    },
    {
      img: "https://i.ibb.co/vv7f4SL/skyblue-female.png",
      probability: 0,
      strength: 80,
      gender: "Female Sky blue",
      track: "2",
    },
    {
      img: "https://i.ibb.co/yR920bL/red-male.png",
      probability: 0,
      strength: 82,
      gender: "Male Red",
      track: "3",
    },
    {
      img: "https://i.ibb.co/VLLvS5f/red-female.png",
      probability: 0,
      strength: 75,
      gender: "Female Red",
      track: "4",
    },
    {
      img: "https://i.ibb.co/4pL7Wp9/orange-male.png",
      probability: 0,
      strength: 70,
      gender: "Male Yellow",
      track: "5",
    },
    {
      img: "https://i.ibb.co/P4fL15q/orange-female.png",
      probability: 0,
      strength: 71,
      gender: "Female Yellow",
      track: "6",
    },
    {
      img: "https://i.ibb.co/Vw0G5N8/navyblue-male.png",
      probability: 0,
      strength: 79,
      gender: "Male Navy",
      track: "7",
    },
    {
      img: "https://i.ibb.co/rZG1V0s/navyblue-female.png",
      probability: 0,
      strength: 78,
      gender: "Female Navy",
      track: "8",
    },
    {
      img: "https://i.ibb.co/wMHtJKy/magenta-male.png",
      probability: 0,
      strength: 76,
      gender: "Male Magenta",
      track: "9",
    },
    {
      img: "https://i.ibb.co/NZ51yD4/magenta-female.png",
      probability: 0,
      strength: 71,
      gender: "Female Magenta",
      track: "10",
    },
    {
      img: "https://i.ibb.co/mTzs5zN/lime-male.png",
      probability: 0,
      strength: 83,
      gender: "Male Lime",
      track: "11",
    },
    {
      img: "https://i.ibb.co/VND8pfj/lime-female.png",
      probability: 0,
      strength: 71,
      gender: "Female Lime",
      track: "12",
    },
    {
      img: "https://i.ibb.co/KyzjLsq/grey-male.png",
      probability: 0,
      strength: 74,
      gender: "Male White",
      track: "13",
    },
    {
      img: "https://i.ibb.co/vBzh7Zt/grey-female.png",
      probability: 0,
      strength: 78,
      gender: "Female White",
      track: "14",
    },
    {
      img: "https://i.ibb.co/D8kxx8v/green-male.png",
      probability: 0,
      strength: 77,
      gender: "Male Green",
      track: "15",
    },
    {
      img: "https://i.ibb.co/g6nJ1W4/green-female.png",
      probability: 0,
      strength: 75,
      gender: "Female Green",
      track: "16",
    },
    {
      img: "https://i.ibb.co/bR9gM7G/brown-male.png",
      probability: 0,
      strength: 78,
      gender: "Male Orange",
      track: "17",
    },
    {
      img: "https://i.ibb.co/jkK6v6z/brown-female.png",
      probability: 0,
      strength: 73,
      gender: "Female Orange",
      track: "18",
    },
    {
      img: "https://i.ibb.co/KVxW5Fy/aquagreen-male.png",
      probability: 0,
      strength: 75,
      gender: "Male Aqua Green",
      track: "19",
    },
    {
      img: "https://i.ibb.co/4mcBhBF/aquagreen-female.png",
      probability: 0,
      strength: 76,
      gender: "Female Aqua Green",
      track: "20",
    },
  ];

  // position dialog modal
  const handleClickOpen = () => {
    setOpen(true);
  };

  const claimButton = () => {
    swal({
      // title: "S",
      text: "We are updating for you",
      icon: "warning",
      button: "OK!",
      className: "modal_class_success",
    });
  };

  const racePostInfo = () => {
    axios
      .post(
        "https://backend.grighund.net/api/latestrace",
        { race: place, selectedDog: myDog },
        {
          headers: {
            authorization: `Bearer bs2zDM01DMMEgt33CrKZt8x4abs2zDM01DMMEgt33Cr6atzL3CJdExpP4x4abs2zDM01DMMEgt33Cr`,
          },
        }
      )
      .then(() => {
        console.log("latest race posted");
      });

    axios
      .post("https://backend.grighund.net/api/race", {
        race: place,
        email: user?.email,
        raceNumber: AllData,
      })
      .then(() => {
        console.log("posted");
        setChange((change) => change + 1);
      });
  };

  const timeoutFunc = () => {
    setTimeTricker(
      setTimeout(() => {
        setPosition(true);
        setOpen(true);
        setBiddingDog("");
        setBid("");
        setEmail("");
        setFocus(false);
        setFocusBid(false);
        setCheckEmail(false);
        setChangeRestart(true);
        setPopUpBtn(false);
        setResultBtn(true);
      }, 38000 + 4600)
    );
  };

  const generateSpeed = () => {
    speedCount = [];
    let i = 0;
    do {
      let value = Math.floor(Math.random() * (400 - 355 + 1)) + 330;
      if (value !== speedCount[speedCount.length - 1]) {
        speedCount.push(value);
        i++;
      }
    } while (i < 20);
    let newarray = speedCount;
    let first = Math.min(...speedCount);

    const random = Math.floor(Math.random() * (2 - 1 + 1)) + 1;
    console.log(random, "quickselect(speedCount,2)");
    if (random == 1) {
      first = Math.min(...speedCount);
    } else if (random == 2) {
      first = newarray.reduce(
        (pre, cur) => (cur < pre && cur !== first ? cur : pre),
        Infinity
      );
    }
    const mydogIndex = parseInt(myDog?.track) - 1;
    const fromIndex = speedCount.indexOf(first);
    const toIndex = mydogIndex;
    const flag = speedCount[toIndex];
    speedCount[toIndex] = first;
    speedCount[fromIndex] = flag;

    return speedCount;
  };
  const generateRacePoints = () => {
    let n = [];
    let flag = 100;
    for (let i = 0; i < 20; i++) {
      n[i] = flag;
      flag = flag - 5;
    }
    setRacePoints(n);
  };

  const generateDogs = (positionSort, speed) => {
    setPlace([]);
    generateRacePoints();
    let pointTotal = 105;
    let sumPoint = [];
    console.log(sumPoint);
    console.log("racePoint", racePoint);
    let bidCalculate = 10;
    const date = `${moment(new Date()).format("DD-MM-YYYY hh:mm A")}`;
    console.log(firstInRace, "first in race");
    for (let i = 0; i < 20; i++) {
      console.log("dogImg", positionSort[i]);
      pointTotal -= 5;
      sumPoint.push({ pointTotal });
      const prizeAmount = bidCalculate * bid;
      console.log("prizeAmount", bidCalculate);
      setPlace((place) => [
        ...place,
        {
          img: dogsImg[positionSort[i]].img,
          gender: dogsImg[positionSort[i]].gender,
          probability: racePoint[i],
          strength: speed[positionSort[i]] + racePoint[i] - 350,
          prize: prizeAmount,
          time: speed[positionSort[i]],
          date: date,
          position: i + 1,
          track: dogsImg[positionSort[i]].track,
        },
      ]);

      if (i == 0) {
        bidCalculate = 6;
      } else if (i == 1) {
        bidCalculate = 4;
      } else if ((i) => 2) {
        bidCalculate = 0;
      }
    }
  };

  const generatePosition = (speed) => {
    setFirstInRace(Math.min(...speed));
    setLastInRace(Math.max(...speed));

    const positionSort = Array.from(Array(speed.length).keys()).sort((a, b) =>
      speed[a] < speed[b] ? -1 : (speed[b] < speed[a]) | 0
    );
    setPositions(positionSort);

    console.log(positions, "p", speed);
    timeoutFunc();
    generateDogs(positionSort, speed);
    console.log(lastInRace, "lastinrace");
  };

  const [isFullscreen, setIsFullscreen] = React.useState(false);
  const locataion = useLocation();
  // Watch for fullscreenchange
  React.useEffect(() => {
    locataion?.state?.name == "replay" && setResultBtn(true);
    locataion?.state?.name == "replay" &&
      setTimeout(() => {
        handleClickOpen();
      }, 1500);
    console.log(locataion, "location");

    function onFullscreenChange() {
      setIsFullscreen(Boolean(document.fullscreenElement));
      requestFullscreen(Boolean(document.fullscreenElement));
    }

    document.addEventListener("fullscreenchange", onFullscreenChange);
    document.addEventListener("fullscreenerror", function () {
      requestFullscreen(true);
    });

    return () =>
      document.removeEventListener("fullscreenchange", onFullscreenChange);
  }, []);

  const takeBackToStartLine = () => {
    let element = document.getElementById("uniqe");
    let element1 = document.getElementById("uniqe2");
    let element2 = document.getElementById("uniqe3");
    let element3 = document.getElementById("uniqe4");
    let element4 = document.getElementById("uniqe5");
    let element5 = document.getElementById("uniqe6");
    let element6 = document.getElementById("uniqe7");
    let element7 = document.getElementById("uniqe8");
    let element8 = document.getElementById("uniqe9");
    let element9 = document.getElementById("uniqe10");
    let element10 = document.getElementById("uniqe11");
    let element11 = document.getElementById("uniqe12");
    let element12 = document.getElementById("uniqe13");
    let element13 = document.getElementById("uniqe14");
    let element14 = document.getElementById("uniqe15");
    let element15 = document.getElementById("uniqe16");
    let element16 = document.getElementById("uniqe17");
    let element17 = document.getElementById("uniqe18");
    let element18 = document.getElementById("uniqe19");
    let element19 = document.getElementById("uniqe20");
    element.classList.remove("img_dog");
    element1.classList.remove("img_dog");
    element1.classList.remove("timer2");
    element2.classList.remove("img_dog");
    element2.classList.remove("timer3");
    element3.classList.remove("img_dog");
    element3.classList.remove("timer4");
    element4.classList.remove("img_dog");
    element4.classList.remove("timer5");
    element5.classList.remove("img_dog");
    element5.classList.remove("timer6");
    element6.classList.remove("img_dog");
    element6.classList.remove("timer7");
    element7.classList.remove("img_dog");
    element7.classList.remove("timer8");
    element8.classList.remove("img_dog");
    element8.classList.remove("timer9");
    element9.classList.remove("img_dog");
    element9.classList.remove("timer10");
    element10.classList.remove("img_dog");
    element10.classList.remove("timer11");
    element11.classList.remove("img_dog");
    element11.classList.remove("timer12");
    element12.classList.remove("img_dog");
    element12.classList.remove("timer13");
    element13.classList.remove("img_dog");
    element13.classList.remove("timer14");
    element14.classList.remove("img_dog");
    element14.classList.remove("timer15");
    element15.classList.remove("img_dog");
    element15.classList.remove("timer16");
    element16.classList.remove("img_dog");
    element16.classList.remove("timer17");
    element17.classList.remove("img_dog");
    element17.classList.remove("timer18");
    element18.classList.remove("img_dog");
    element18.classList.remove("timer19");
    element19.classList.remove("img_dog");
    element19.classList.remove("timer20");

    void element.offsetWidth;
    void element1.offsetWidth;
    void element2.offsetWidth;
    void element3.offsetWidth;
    void element4.offsetWidth;
    void element5.offsetWidth;
    void element6.offsetWidth;
    void element7.offsetWidth;
    void element8.offsetWidth;
    void element9.offsetWidth;
    void element10.offsetWidth;
    void element11.offsetWidth;
    void element12.offsetWidth;
    void element13.offsetWidth;
    void element14.offsetWidth;
    void element15.offsetWidth;
    void element16.offsetWidth;
    void element17.offsetWidth;
    void element18.offsetWidth;
    void element19.offsetWidth;
  };

  const restartButton = () => {
    setTimeout(() => {
      setGo(false);
    }, 500);
    // let classy = document.getElementsByClassName("img_dog")
    takeBackToStartLine();
    let element = document.getElementById("uniqe");
    let element1 = document.getElementById("uniqe2");
    let element2 = document.getElementById("uniqe3");
    let element3 = document.getElementById("uniqe4");
    let element4 = document.getElementById("uniqe5");
    let element5 = document.getElementById("uniqe6");
    let element6 = document.getElementById("uniqe7");
    let element7 = document.getElementById("uniqe8");
    let element8 = document.getElementById("uniqe9");
    let element9 = document.getElementById("uniqe10");
    let element10 = document.getElementById("uniqe11");
    let element11 = document.getElementById("uniqe12");
    let element12 = document.getElementById("uniqe13");
    let element13 = document.getElementById("uniqe14");
    let element14 = document.getElementById("uniqe15");
    let element15 = document.getElementById("uniqe16");
    let element16 = document.getElementById("uniqe17");
    let element17 = document.getElementById("uniqe18");
    let element18 = document.getElementById("uniqe19");
    let element19 = document.getElementById("uniqe20");

    element.classList.add("img_dog");
    element1.classList.add("img_dog");
    element1.classList.add("timer2");
    element2.classList.add("img_dog");
    element2.classList.add("timer3");
    element3.classList.add("img_dog");
    element3.classList.add("timer4");
    element4.classList.add("img_dog");
    element4.classList.add("timer5");
    element5.classList.add("img_dog");
    element5.classList.add("timer6");
    element6.classList.add("img_dog");
    element6.classList.add("timer7");
    element7.classList.add("img_dog");
    element7.classList.add("timer8");
    element8.classList.add("img_dog");
    element8.classList.add("timer9");
    element9.classList.add("img_dog");
    element9.classList.add("timer10");
    element10.classList.add("img_dog");
    element10.classList.add("timer11");
    element11.classList.add("img_dog");
    element11.classList.add("timer12");
    element12.classList.add("img_dog");
    element12.classList.add("timer13");
    element13.classList.add("img_dog");
    element13.classList.add("timer14");
    element14.classList.add("img_dog");
    element14.classList.add("timer15");
    element15.classList.add("img_dog");
    element15.classList.add("timer16");
    element16.classList.add("img_dog");
    element16.classList.add("timer17");
    element17.classList.add("img_dog");
    element17.classList.add("timer18");
    element18.classList.add("img_dog");
    element18.classList.add("timer19");
    element19.classList.add("img_dog");
    element19.classList.add("timer20");
    console.log("init", timeTricker);
    clearTimeout(timeTricker);
    console.log("destroyed", timeTricker);
  };
  useEffect(() => {
    place.length == 20 && racePostInfo();
  }, [place]);

  // Token
  const verifiedToken = localStorage.getItem("gotVerifiedToken");
  return (
    <div>
      {loading ? (
        <div
          className="d-flex align-items-center justify-content-center "
          style={{ height: "100vh" }}
        >
          <CircularProgress className="text-center" color="inherit" />
        </div>
      ) : (
        <div>
          <div
            ref={myRef}
            className="container catch1 overflow-hidden start-0 pt-5 dsl-catch s39-catch"
          >
            {
              <div className="row my-5">
                <div className="col-12">
                  <div
                    className="race_width_2d3d"
                    style={{ margin: "24px auto 5px auto" }}
                  >
                    <div style={{ position: "relative" }}>
                      <Game3D
                        unityProvider={unityProvider}
                        isLoaded={isLoaded}
                        loadingProgression={loadingProgression}
                        sendMessage={sendMessage}
                      ></Game3D>

                      <div className="login-catchit">
                        {enableBtn ? (
                          !user?.walletAddress ||
                          user?.walletAddress === "undefined" ? (
                            <div className="landing-button text-center claimdsl">
                              {checkDevice === "mobileWithoutApp" && (
                                <a
                                  href={`https://metamask.app.link/dapp/https://playtoearnblockchain.com/dslcatchit?price=${score}`}
                                  // href={href}
                                  target={"_blank"}
                                  className="text-decoration-none"
                                >
                                  <button className="text-uppercase button-metamask  px-lg-4 px-2">
                                    {/* <img
                                      className="me-2"
                                      width="20px"
                                      src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                                      alt=""
                                    />{" "} */}
                                    CONNECT WITH WALLET to earn all Tokens
                                    {/* {score} {score > 1 ? "DSL Tokens" : "DSL Token"} */}
                                  </button>
                                </a>
                              )}
                              {checkDevice === "mobileWithApp" && (
                                <>
                                  {!user?.walletAddress ||
                                  user?.walletAddress === "undefined" ? (
                                    <button
                                      className="text-uppercase button-metamask px-lg-4 px-2"
                                      onClick={() => openWalletModal()}
                                    >
                                      {" "}
                                      {/* <img
                                        className="me-2"
                                        width="20px"
                                        src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                                        alt=""
                                      />{" "} */}
                                      CONNECT WITH WALLET to earn all Tokens
                                      {/* {score} {score > 1 ? "DSL Tokens" : "DSL Token"} */}
                                    </button>
                                  ) : (
                                    <button
                                      className="text-uppercase button-metamask px-lg-4 px-2"
                                      onClick={() => navigate("/profile")}
                                    >
                                      {" "}
                                      {/* <img
                                        className="me-2"
                                        width="20px"
                                        src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                                        alt=""
                                      />{" "} */}
                                      profile
                                    </button>
                                  )}
                                </>
                              )}
                              {checkDevice === "pcWithExtention" && (
                                <>
                                  {!user?.walletAddress ||
                                  user?.walletAddress === "undefined" ? (
                                    <button
                                      className="text-uppercase button-metamask px-lg-4 px-2"
                                      onClick={() => openWalletModal()}
                                      style={{ width: "auto" }}
                                    >
                                      {" "}
                                      {/* <img
                                        className="me-2"
                                        width="20px"
                                        src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                                        alt=""
                                      /> */}
                                      CONNECT WITH WALLET TO EARN YOUR ALL
                                      TOKENS
                                      {/* {score} {score > 1 ? "DSL TOKENS" : "DSL TOKEN"} */}
                                    </button>
                                  ) : (
                                    <button
                                      className="text-uppercase button-metamask px-lg-4 px-2"
                                      onClick={() => navigate("/profile")}
                                    >
                                      {" "}
                                      {/* <img
                                        className="me-2"
                                        width="20px"
                                        src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                                        alt=""
                                      />{" "} */}
                                      profile
                                    </button>
                                  )}
                                </>
                              )}
                              {checkDevice === "pcWithoutExtention" && (
                                <a
                                  href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
                                  target={"_blank"}
                                  className="text-decoration-none"
                                >
                                  <button className="text-uppercase button-metamask px-lg-4 px-2">
                                    {/* <img
                                      className="me-2"
                                      width="20px"
                                      src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                                      alt=""
                                    />{" "} */}
                                    CONNECT WITH WALLET
                                  </button>
                                </a>
                              )}
                              {enableBtn && (
                                <i
                                  class="fa-solid fa-xmark"
                                  onClick={() => setEnableBtn(false)}
                                ></i>
                              )}
                            </div>
                          ) : (
                            <div>
                              {" "}
                              <button
                                onClick={handleClaim}
                                disabled={loading1 || score == 0}
                                className="button-18"
                              >
                                Claim your All Tokens
                              </button>
                              {enableBtn && (
                                <i
                                  class="fa-solid fa-xmark"
                                  onClick={() => setEnableBtn(false)}
                                ></i>
                              )}
                            </div>
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="text-center text-white">
                      {isLoaded && (
                        <p>
                          Catch only the black S39 tokens.<br></br>Red S39
                          tokens will reduce your points
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      )}
    </div>
  );
};

export default DslCatch;
