import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';

const VidoeSlider = () => {
    // const [duration, setDuration] = useState(23000);
    // const [refetch, setRefetch] = useState(false);

    const dataSlider = [
        { id: 1, image: "/gamevideo1gif.gif" },
        { id: 2, image: "/gamevideo2gif.gif" }
    ]

    // useEffect(() => {

    //     if (duration === 23000) {
    //         setTimeout(() => {
    //             setDuration(15000);
    //             setRefetch(!refetch);
    //         }, 23000);
    //     }
    //     else {
    //         setTimeout(() => {
    //             setDuration(23000);
    //             setRefetch(!refetch);
    //         }, 15000);
    //     }

    // }, [refetch])

    let settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        speed: 1500,
        autoplaySpeed: 23000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite: true,
                    dots: false,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite: true,
                    dots: false
                }
            }
        ]
    };
    return (
        <div className='container-slider'>
            <Slider {...settings} className="gap-5">
                {
                    dataSlider?.map(obj => (
                        <div
                            key={obj.id}
                            style={{ cursor: "pointer" }}
                            className="d-itemVideo"
                            dangerouslySetInnerHTML={{
                                __html: ` <img src=${obj.image} alt="gif" class="handleVideoWidth" style="outline: none;"/>`
                            }}
                        >

                            {/* {obj.image &&
                                <img src={obj.image} alt="gif" height="400px" width="400px" />
                            } */}
                        </div>
                    ))
                }

            </Slider>
        </div>
    );
};

export default VidoeSlider;