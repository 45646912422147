import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Form } from "react-bootstrap";
import './LeaderBoard.css'
import Pagination from "@mui/material/Pagination";
import { CircularProgress } from '@mui/material';
import MetaData from '../../MetaData/MetaData';

const LeaderBoard = () => {
    const [newData, setNewData] = useState([])
    const [category, setCategory] = useState("All");
    const [month, setMonth] = useState("All Time")

    var monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    useEffect(() => {
        axios
            .get("https://backend.playtoearnblockchain.com/api/v1/user/all", {
                headers: {
                    authorization: `Bearer bIq7Olx4abs2zDM01DMMEgt33fbEe54fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4`,
                },
            })
            .then((res) => {
                if (category == "All") {
                    setNewData(res.data.sort(compareObjects))
                }
                else if (category == "Previous") {
                    setNewData(res.data.sort(compareObjectsPrev))
                }
                else if (category == "This") {
                    setNewData(res.data.sort(compareObjectsThis))
                }
                else {
                    setNewData(res.data.sort(compareObjects))
                }
            });
    }, [category])
    function compareObjects(a, b) {
        // Sort by prize amount
        if (a.busdWon !== 0 && b.busdWon !== 0) {
            return b.busdWon - a.busdWon;
        } else if (a.busdWon !== 0) {
            return -1;
        } else if (b.busdWon !== 0) {
            return 1;
        } else {
            return b.dslWon - a.dslWon;
        }
    }
    function compareObjectsThis(a, b) {
        // Sort by prize amount
        if (a?.thisMonth?.busdWon !== 0 && b?.thisMonth?.busdWon !== 0) {
            return b?.thisMonth?.busdWon - a?.thisMonth?.busdWon;
        } else if (a?.thisMonth?.busdWon !== 0) {
            return -1;
        } else if (b?.thisMonth?.busdWon !== 0) {
            return 1;
        } else {
            return b?.thisMonth?.dslWon - a?.thisMonth?.dslWon;
        }
    }
    function compareObjectsPrev(a, b) {
        // Sort by prize amount
        if (a?.prevMonth?.busdWon !== 0 && b?.prevMonth?.busdWon !== 0) {
            return b?.prevMonth?.busdWon - a?.prevMonth?.busdWon;
        } else if (a?.prevMonth?.busdWon !== 0) {
            return -1;
        } else if (b?.prevMonth?.busdWon !== 0) {
            return 1;
        } else {
            return b?.prevMonth?.dslWon - a?.prevMonth?.dslWon;
        }
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [pagedData, setPagedData] = useState([]);
    const [page1, setPage1] = useState(1);


    const handleChange = (event) => {
        setCategory(event.target.value);
        setCurrentPage(1);
        setPage1(1);
        if (event.target.value == "This") {
            setMonth(monthNames[new Date().getMonth()])
        }
        else if (event.target.value == "Previous") {
            setMonth(monthNames[new Date().getMonth() - 1])
        }
        else {
            setMonth("All Time")
        }

    };

    const itemsPerPage = 20;

    useEffect(() => {

        const numPages = Math.ceil(newData.length / itemsPerPage);

        const pages = Array.from({ length: numPages }, (_, i) =>
            newData.slice(i * itemsPerPage, (i + 1) * itemsPerPage)
        );


        setPagedData(pages[currentPage - 1]);
    }, [newData, currentPage, itemsPerPage]);


    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        console.log(data1, "data1");
    }, [currentPage]);

    const data1 = newData;
    const itemsPerPage1 = 20;
    const pageCount1 = Math.ceil(newData.length / itemsPerPage1);

    const handleChange1 = (event, value) => {
        window.scrollTo(0, 0);
        setPage1(value);
    };

    const getPageData = () => {
        const start = (page1 - 1) * itemsPerPage1;
        const end = start + itemsPerPage1;
        return newData.slice(start, end);
    };

    return (
        <div className="pb-5" >
  <MetaData pageTitle={"Earn money to play DSL CATCH it game"} pageDescription={"Playtoearnblockchain.com NFTs can be sold in marketplaces or used in our PLAY TO EARN Blockchain Games. It is a blockchain-based game that allows players to earn rewards & Purchase NFTs."}></MetaData>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div>
                            <h1 className="text-gradient text-center pt-5 text-uppercase" style={{ marginTop: '80px', fontSize: "1.75rem" }}>LEADER BOARD</h1>
                            <div className="small-border bg-color-2"></div>
                            <p className='text-white text-center mb-4'>Every month, Top 3 in the Leaderboard will win 100 BUSD each</p>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className='d-flex justify-content-between align-items-center mb-4'>
                            <p className="text-dark bg-white px-4 py-1 m-0" style={{ borderRadius: "5px" }}>{month}</p>
                            <Form.Select
                                aria-label="Default select example"
                                value={category}
                                onChange={handleChange}
                                className='leader-dropdown'
                            >
                                <option value="All">All Time</option>
                                <option value="This">This Month</option>
                                <option value="Previous">Previous Month</option>


                            </Form.Select>
                        </div>
                    </div>
                    <div className="col-12">
                        {!newData?.length > 0 ? <div
                            className="d-flex align-items-center justify-content-center "
                            style={{ height: "70vh" }}
                        >
                            <CircularProgress className="text-center" color="inherit" />
                        </div> : <div className=" text-start table-responsive">
                            <table className="table table-borderless ">
                                <thead>
                                    <tr className='text-white mb-4 position-table-header'>
                                        <th scope="col">Position</th>
                                        <th scope="col">User Id</th>
                                        <th scope="col">BUSD Won</th>
                                        <th scope="col">DSL Won</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        getPageData()?.map((item, index) =>
                                            <tr className='text-white'>
                                                <td scope="row">{index + 1}
                                                </td>
                                                <td>
                                                    {
                                                        item?.myReferralCode
                                                    }
                                                </td>
                                                <td >
                                                    {
                                                        category == "All" &&
                                                        (item?.busdWon ? item?.busdWon : 0)
                                                    }
                                                    {
                                                        category == "Previous" &&
                                                        (item?.prevMonth?.busdWon ? item?.prevMonth?.busdWon : 0)
                                                    }
                                                    {
                                                        category == "This" &&
                                                        (item?.thisMonth?.busdWon ? item?.thisMonth?.busdWon : 0)
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        category == "All" &&
                                                        (item?.dslWon ? item?.dslWon : 0)
                                                    }
                                                    {
                                                        category == "Previous" &&
                                                        (item?.prevMonth?.dslWon ? item?.prevMonth?.dslWon : 0)
                                                    }
                                                    {
                                                        category == "This" &&
                                                        (item?.thisMonth?.dslWon ? item?.thisMonth?.dslWon : 0)
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }

                                </tbody>
                            </table>
                        </div>}
                    </div>
                    <div className="col-12">
                        <div className="pagination-btn mx-auto text-center">
                            <Pagination
                                count={pageCount1}
                                page={page1}
                                onChange={handleChange1}
                            />
                        </div>
                    </div>
                </div>

            </div>

        </div >
    );
};

export default LeaderBoard;