import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import React, { useEffect, useState } from "react";
import AllRace from "./AllRace";

const AllRaces = () => {
  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-12 text-center">
          <h2 className="text-white text-center mt-5 mb-3">All Race Replay</h2>
        </div>
        <div className="col-12 mt-4">
          <Tabs
            defaultActiveKey="profile"
            id="justify-tab-example"
            className="mb-3"
            justify
          >
            <Tab eventKey="home" className="mt-4" title={`DSL Horse Races`}>
              <AllRace title={"dsl"}></AllRace>
            </Tab>
            <Tab eventKey="profile" className="mt-4" title={`BUSD Horse Races`}>
              <AllRace title={"busd"}></AllRace>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};
export default AllRaces;
