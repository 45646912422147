import { Typography } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import InterestModal from '../../layout/InterestModal';
import MealSlider from '../MealNft/Meal.slider';
import SouvenirSlider from '../Souvenir/Souvenir.slider';
import './home.css';


function Home() {
  const [meals, setMeals] = useState([]);
  const [souvenirs, setSouvenirs] = useState([]);
  const [autoOpen, setAutoOpen] = useState(false);
  console.log(meals, souvenirs)

  const pull_meal = (meal) => {
    setMeals(meal)
  }
  const pull_souvenir = (souvenir) => {
    setSouvenirs(souvenir)
  }

  const AutoOpenModalClick = () => setAutoOpen(true)



  return (
    <div className='home-parent'>
      <div id="card-overlay">
        <div id="card-overlay">
          {/* <video className="background-video" autoPlay loop muted>
          <source src="./bgs.mp4" type="video/mp4" />
        </video> */}

          <img className="background-video" src="https://im3.ezgif.com/tmp/ezgif-3-8b94cdbe1a.png" alt="" />
        </div>
      </div>
      <div className="bannerBg mt-5 pt-4 handleLogoDiv">
        <div
          className="text-white no-top no-bottom home-padding"
        >
          <div className="v-center">
            <div className="container">
              <div className="row align-items-center">

                <div className="col-md-5">

                  <div className="spacer-single"></div>
                  <h6 className="s1  line-one text-uppercase line-one" style={{ lineHeight: '30px' }}>
                    DS Legends Pte Ltd
                  </h6>
                  <h6 className="s1 line-one text-uppercase line-one" style={{ lineHeight: '30px' }}>
                    Presents
                  </h6>

                  <div className="spacer-10"></div>
                  <div className="banner-text d-grid">
                    <div
                      className="s1 text-uppercase font-resize me-3"

                    >
                      CELEBRITY
                    </div>
                    <div
                      className="s1 text-uppercase font-resize"

                      style={{ lineHeight: '76px' }}
                    >
                      NFTs
                    </div>
                  </div>
                  <p className=" lead" data-wow-delay="2s">
                    <h4 style={{ fontSize: '1rem', marginTop: '1.2rem' }}>Buy the NFTs and Earn Daily</h4>
                  </p>
                  <div className="mb-sm-30"></div>

                  <div className='reg_button-landHome'>
                    <button onClick={AutoOpenModalClick} className="banner-button wow fadeInUp" data-wow-delay="1.25s">Register your interest</button>
                  </div>



                </div>
                <div className="col-md-6 offset-md-1 d-flex home-image" style={{ justifyContent: 'center' }}>
                  <img
                    // src="/assets/images/misc/celebrity-banner.jpeg"
                    src="https://i.ibb.co/KNHV8bt/celebrity-banner2.jpg"
                    className="lazy fadeIn handleImgforRespons"
                    style={{ borderRadius: '1px', width: '90%', }}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <a
            href="#section-intro"
            className="mouse-icon-click scroll-to wow "
            data-wow-delay="2s"
          >
            <span className="mouse fadeScroll relative" data-scroll-speed="2">
              <span className="scroll"></span>
            </span>
          </a> */}
        </div>
      </div>
      <div className="no-bottom backgroundSection">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-12">
              <div className="text-center">
                <h2>
                  How It <span className="text-gradient">Works</span>
                </h2>
                <div className="small-border bg-color-2"></div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="de-card has-border text-center boxesNft">
                <i className=" bg-color-2 i-boxed icon_wallet"></i>
                <div className="text">
                  <h6 className="home-txt">Login with your wallet</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="de-card has-border text-center boxesNft">
                <i className=" bg-color-2 i-boxed icon_cart_alt"></i>
                <div className="text">
                  <h6 className="home-txt">Buy our NFTs and utilize the perks</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 lastDiv">
              <div className="de-card has-border text-center boxesNft">
                <div className="NftDetailsDiv">
                  <i className=" bg-color-2 i-boxed icon_menu-square_alt2"></i>
                  <div className="text">
                    <h6 className="home-txt">Start Earning at our Play To Earn Platforms</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <InterestModal autoOpen={autoOpen} setAutoOpen={setAutoOpen} />

      <div style={{ backgroundColor: '#1a1a25', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
        <Link
          to="/nfts"
          className="btn-main wow fadeInUp lead mt-3"
          data-wow-delay="1.25s"
        >
          Explore
        </Link>
      </div>

      <div className='backgroundSection'>
        <div className="container">


          <div className="spacer-double" style={{ height: '32px' }}></div>
          <div className="row fadeIn">
            <div className="col-lg-12">
              <div className="text-center">
                <h2>
                  Celebrity NFTs
                </h2>
                <div className="small-border bg-color-2"></div>
                {/* <Typography variant="h6" style={{ color: '#d0d7c2', textAlign: 'center', fontSize: "16px", marginTop: "0.5rem", marginBottom: "1rem" }}>
                  NFTs from <a href="https://testnet.celebrity.sg/" target="_blank" className="text-primary">testnet</a> and <a href="https://mainnet.celebrity.sg/" target="_blank" className="text-primary">mainnet</a> are for testing purposes only. Only NFTs in <a href="https://celebrity.sg/" target="_blank" className="text-primary">Publicnet</a> has real value.
                </Typography> */}
              </div>
              <MealSlider />
              {/* <div className='d-flex' style={{ justifyContent: 'center' }}>
                {meals ?
                  <Typography variant="h6" style={{ color: '#d0d7c2', fontSize: "16px", marginTop: "1rem" }}>
                    Pay by DSL and get 30% discount.
                  </Typography>
                  :
                  <Typography variant="h6" style={{ color: '#d0d7c2', fontSize: "16px", marginTop: "1rem" }}>
                    Stay Tuned!
                  </Typography>}
              </div> */}
            </div>
            <div className="mx-auto text-center py-5 forTransformgrigHunRace">
              <div className="text-center">
                <h3>
                  Sample Play To Earn
                </h3>
                <h3 className="py-1">
                  Blockchain Game
                </h3>
                <div className="small-border bg-color-2"></div>
              </div>
              <video controls className="grugHundRace" poster='./coverGrig.jpeg'>
                <source src="./grighundRace.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <Typography variant="h6" className="text-gradient" style={{ textAlign: 'center', fontSize: "18px", marginTop: "1rem", marginBottom: "1rem" }}>
                Synopsis
              </Typography>
              <Typography variant="h6" style={{ color: '#d0d7c2', textAlign: 'center', fontSize: "16px", marginTop: "0.5rem" }} className="grugHundRaceText mx-auto">
                Your celebrities own wide variety of animals for Metaverse Racing World. When you buy their NFT, you can stake your NFT in the racing animal and win  as much as you want.  Buy the NFT and start earning from 1 December 2022. Only 2000 Darkkey Nagaraja NFTs are available.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
