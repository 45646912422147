import { Typography } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  generatePath,
  Link,
  Navigate,
  useNavigate,
  useParams,
} from "react-router-dom";
import ReactPlayer from "react-player";
import "../../App.css";
import MealSlider from "../page/MealNft/Meal.slider";
import SouvenirSlider from "../page/Souvenir/Souvenir.slider";
import InterestModal from "./InterestModal";
import Particles from "react-tsparticles";

import { loadFull } from "tsparticles";
import Slider from "react-slick";
import VidoeSlider from "./VidoeSlider";
import akImage from "../../Images/ak.jpeg";
import tbImage from "../../Images/tb.jpeg";
import "./Landing.css";
import MealNFT from "../page/MealNft/MealNFT";
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import { pageViewEvent } from "../../MetaPixelEvents/MetaPixelEvents";
import { CelebrityContext } from "../../context/CelebrityContext";
import LoginButton from "../Shared/LoginButton";
import { Helmet } from "react-helmet";
import Head from "./Head";
import axios from "axios";
import CheckLogin from "../Shared/CheckLogin";
import BuyOurNftModal from "./BuyOurNftModal";
import MetaData from "../MetaData/MetaData";

const dataSliderImage = [
  {
    id: 1,
    image: "./1stprize.png",
    name: "MacBook Pro 16 inch",
    price: "(Worth USD 3,499.00)",
    position: "1st Prize",
  },
  {
    id: 2,
    image: "./4rthprize.png",
    name: "iPad 10th generation",
    price: "(Worth USD 1,999.00)",
    position: "2nd Prize",
  },
  {
    id: 3,
    image: "./2ndprize.png",
    name: "iPhone 15 Pro",
    price: "(Worth USD 1,649.00)",
    position: "3rd Prize",
  },
  {
    id: 4,
    image: "./3rdprize.png",
    name: "Apple Watch Series 8",
    price: "(Worth USD 579.00)",
    position: "4th Prize",
  }, {
    id: 5,
    image: "./5th prize.jpeg",
    name: "10 Lucky Winners will win one of the 100th Birth Anniversary of Mr Lee Kuan Yew Commemorative Coin",
    price: "(Worth USD 100.00)",
    // position: "4th Prize",
  },
];

const dataSlider = [
  { id: 1, video: "/gamevideo1.mp4" },
  { id: 2, video: "/gamevideo2.mp4" },
];

export default function Landing() {
  useEffect(() => { }, []);
  const [datas, setandelAutoCall] = useState("");
  const [autoOpen, setAutoOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const title = "dsl"
  const { openWalletModal, openWalletModalProfile, user, searchNftTitle } =
    useContext(CelebrityContext);
  useEffect(() => {
    pageViewEvent();
  }, []);

  const { affiliate } = useParams();
  const [slider, setSlider] = useState([]);
  useEffect(() => {
    const dataOfSlider = async () => {
      await axios
        .get("https://backend.playtoearnblockchain.com/api/v1/banner/")
        .then((res) => {
          setSlider(res.data.result);
        });
    };

    dataOfSlider();
  }, []);

  // Carousel functionality
  const [slideIndex, setSlideIndex] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      if (slideIndex !== dataSlider.length) {
        setSlideIndex(slideIndex + 1);
      }
    }, 23000);
    const intervalTwo = setInterval(() => {
      if (slideIndex === dataSlider.length) {
        setSlideIndex(1);
      }
    }, 15000);

    return () => clearInterval(interval, intervalTwo);
  }, [slideIndex]);

  const slidingIssue = () => {
    console.log("Alhamdulillah, hocche");
    if (slideIndex !== dataSlider.length) {
      setSlideIndex(slideIndex + 1);
    } else {
      setSlideIndex(1);
    }
  };

  const AutoOpenModalClick = () => setAutoOpen(true);

  const particlesInit = (engine) => {
    console.log(engine);
    loadFull(engine);
  };

  const particlesLoaded = (container) => { };
  const [play, setPlay] = useState(false);
  const handleBuyNFTs = () => {
    navigate("/nfts");
  };
  const handleNftPlay = (id) => {
    const playVideo = document.getElementById(`${id}`);
    const pauseVideo = [];

    dataSlider?.map((data) => {
      if (data?.id === id) {
        playVideo.play();
      }

      if (data?._id !== id) {
        pauseVideo.push(data?._id);
      }
    });

    pauseVideo?.map((video) => {
      document.getElementById(`${video}`).pause();
    });
  };

  const handleNftPause = (id) => {
    const video = document.getElementById(`${id}`);
    video.pause();
  };

  const handleAllPause = () => {
    dataSlider?.map((data) => {
      document.getElementById(`${data?.id}`).pause();
    });
  };

  // const akImg = useMemo(() => ak, [])
  // const tbImg = useMemo(() => tb, [])
  const [checkDevice, setCheckDevice] = useState("");

  useEffect(() => {
    const detecting = async () => {
      if (window.innerWidth < 1000 && typeof window.ethereum === "undefined") {
        console.log("mobile");
        setCheckDevice(() => "mobileWithoutApp");
      } else if (
        window.innerWidth < 1000 &&
        typeof window.ethereum !== "undefined"
      ) {
        setCheckDevice(() => "mobileWithApp");
      } else if (
        window.innerWidth > 1000 &&
        typeof window.ethereum !== "undefined"
      ) {
        console.log("pc");
        setCheckDevice(() => "pcWithExtention");
      } else if (
        window.innerWidth > 1000 &&
        typeof window.ethereum === "undefined"
      ) {
        setCheckDevice(() => "pcWithoutExtention");
      }
    };

    detecting();
  }, []);
  console.log(affiliate, "affilate");
  const handelOnclik = () => {
    // navigate("/why-buy-our-nfts");
    setOpen(true)
  }

  const removedUrl = "https://backend.celebritygames.net";

  return (
    <div className="no-bottom no-top home-parent">
      <MetaData pageTitle={"Playtoearnblockchain.com"} pageDescription={"Playtoearnblockchain.com NFTs can be sold in marketplaces or used in our PLAY TO EARN Blockchain Games. It is a blockchain-based game that allows players to earn rewards & Purchase NFTs."}></MetaData>
      {/* <MetaData pageTitle={"Play to earn block chain | Play fortune wheel earn money"} pageDescription={"Playtoearnblockchain.com NFTs can be sold in marketplaces or used in our PLAY TO EARN Blockchain Games. It is a blockchain-based game that allows players to earn rewards & Purchase NFTs."}></MetaData> */}
      {affiliate && <Head></Head>}
      <div id="top"></div>
      <div className="bannerBg bannerDiv handleLogoDiv block100vh">
        <div className="text-white no-top  no-bottom home-padding">
          <div className="banner-part">
            <div className="container g-0">
              {/* Banner in mobile */}
              <div className="row g-0 banner-in-mobile">
                <div className="col-12" onClick={() => navigate("/playtoearn")}>
                  <Swiper
                    slidesPerView={1}
                    loop={true}
                    centeredSlides={true}
                    autoplay={{
                      delay: 15000,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                    className="mySwiper"
                  >
                    {slider?.map((res) => (
                      <SwiperSlide>
                        <div className="banner-wrapper">
                          <img
                            className="img-fluid w-100"
                            src={res?.img.replace(removedUrl, "https://backend.playtoearnblockchain.com")}
                            alt="CelebrityGames Banner"
                          />
                          <Link
                            to="/playtoearn"
                            className="banner-btn3"
                            rel="playtoearn"
                          ></Link>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
              {/* Banner in dekstop */}
              <div className="row g-2 banner-in-dekstop">
                <div className="col-6" onClick={() => navigate("/playtoearn")}>
                  <Swiper
                    slidesPerView={1}
                    loop={true}
                    centeredSlides={true}
                    autoplay={{
                      delay: 15000,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                    className="mySwiper"
                  >
                    {slider
                      ?.filter((number, index) => index % 2 == 0)
                      ?.map((res) => (
                        <SwiperSlide>
                          <div className="banner-wrapper">
                            <img
                              className="img-fluid w-100"
                              src={res?.img.replace(removedUrl, "https://backend.playtoearnblockchain.com")}
                              alt="CelebrityGames Banner"
                            />
                            <Link
                              to="/playtoearn"
                              className="banner-btn3"
                              rel="playtoearn"
                            ></Link>
                          </div>
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </div>
                <div className="col-6" onClick={() => navigate("/playtoearn")}>
                  <Swiper
                    slidesPerView={1}
                    loop={true}
                    centeredSlides={true}
                    autoplay={{
                      delay: 15000,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                    className="mySwiper"
                  >
                    {slider
                      ?.filter((number, index) => index % 2 !== 0)
                      ?.map((res) => (
                        <SwiperSlide>
                          <div className="banner-wrapper">
                            <img
                              className="img-fluid w-100"
                              src={res?.img.replace(removedUrl, "https://backend.playtoearnblockchain.com")}
                              alt="CelebrityGames Banner"
                            />
                            <Link
                              to="/playtoearn"
                              className="banner-btn3"
                              rel="playtoearn"
                            ></Link>
                          </div>
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="section-intro"
        className="no-bottom landing-padding"
        style={{ paddingTop: "35px" }}
      >
        <div className="text-center mb-5">
          <p className="text-center mx-auto d-block mb-3">
            <span
              onClick={handelOnclik}
              className="text-decoration-none playtoearn text-center mx-auto"
              rel="playtoearn"
              style={{ cursor: "pointer", backgroundColor: "#36698c" }}
            >
              Why Buy Our NFTS?
            </span>
            <BuyOurNftModal open={open} setOpen={setOpen}></BuyOurNftModal>
          </p>
        </div>
        <div className="mx-auto text-center mb-4 forTransformgrigHunRace ">
          <div className="text-center">
            <h3
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/rewards")}
              className="font-size"
            >
              Sign up to get your{" "}
              <span style={{ color: "#C27400" }}>Free NFT</span> and <br /> Earn{" "}
              <span style={{ color: "#C27400" }}>BNB</span> by inviting Friends
            </h3>
            <p className="sighupSmall">
              No payment required. Just gas fees to mint FREE NFT.
            </p>
          </div>
        </div>
        <div className="text-center mb-5 mt-5 play-btn-margin">
          <p className="text-center mx-auto d-block mb-3">

            <button className="text-decoration-none playtoearn text-center mx-auto " style={{ border: "none" }} onClick={() => navigate('/allchannel', { state: { title: { title } } })}>Race Channel</button>

          </p>
        </div>
        <hr className="py-1" />
        <div className="container">
          <div className="row g-4"></div>
        </div>
      </div>

      <InterestModal autoOpen={autoOpen} setAutoOpen={setAutoOpen} />
      <section
        style={{ padding: "0px 0 10px 0" }}
        id="section"
        className="NFtsection"
      >
        <div className="container">
          <div className="spacer-double"></div>
          <div className="row wow fadeIn">
            <div className="col-lg-12"></div>
            <MealSlider />
          </div>

          <div className="text-center mb-5 mt-5">
            <p className="text-center mx-auto d-block mb-3">
              <Link
                to="/nfts"
                className="text-decoration-none playtoearn text-center mx-auto"
                rel="playtoearn"
              >
                Explore more gaming NFTs
              </Link>
            </p>
          </div>
          <p className="buttonbelow">
            <span className="text-warning">Playtoearnblockchain.com</span> NFTs can be
            sold in marketplaces and can be used in our Play to Earn Blockchain
            Games.
          </p>
        </div>
      </section>
      <hr />

      <p
        className="text-center text-light pt-4 buy-nft-home"
        style={{
          fontSize: "25px",
          fontWeight: "600",
          textAlign: "center",
          alignSelf: "center",
        }}
      >
        BUY NFTs NOW TO WIN
      </p>
      <h3
        className="prize-color text-center"
        style={{ fontSize: "50px", fontWeight: "700", marginTop: "-18px" }}
      >
        GRAND PRIZES
      </h3>

      <div className="container pt-3 mt-4">
        <div className="row justify-content-center">
          {dataSliderImage.map((item, index) => (
            <div key={item?.id} className=" col-lg-6 col-md-12 col-sm-12 mb-2">
              <div
                class="card "
                style={{ borderRadius: "10px", backgroundColor: "#241d2b" }}
              >
                <div className="px-4 pt-4 pb-2">
                  <img src={item?.image} alt="" className="w-100" />
                  <div className="d-flex justify-content-between mt-3 ">
                    <p
                      className="text-light prize-name"
                      style={{ fontSize: "22px", fontWeight: "600" }}
                    >
                      {item.name}{" "}
                      <span
                        className="priceOffer"
                        style={{ fontWeight: "600", color: "red" }}
                      >
                        {item.price}
                      </span>
                    </p>
                    {item.position && <button className="prize-btn">{item.position}</button>}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="my-5">
        <div className="container">
          <p
            className="text-center py-4 text-light close-date closing"
            style={{ fontSize: "30px", fontWeight: "500" }}
          >
            Closing Date - 31 August 2024
          </p>
        </div>
        <p className="text-center fontsizePrizing">
          Sign up to get our FREE NFT by 31 August 2024 to win the prizes.
          Anyone from any country can participate.
        </p>
      </div>
      <hr />

      <p
        className="text-center text-light pt-4"
        style={{
          fontSize: "25px",
          fontWeight: "600",
          textAlign: "center",
          alignSelf: "center",
        }}
      >
        HOW IT WORKS
      </p>

      <div className="container pt-3">
        <div className="row">
          <div className=" col-lg-4 col-md-6 col-sm-12 mb-2">
            <div
              class="card "
              style={{
                borderRadius: "10px",
                backgroundColor: "#241d2b",
                // minWidth: "100%",
              }}
            >
              <CheckLogin register={"code"}>
                <div className="px-4 pt-4 pb-2">
                  <div className="de-card text-center boxesNft home-txt-pd">
                    <i
                      className="wow fadeInUp i-boxed icon_wallet"
                      style={{ background: "#4A4351" }}
                    ></i>
                    <div className="text">
                      <h6
                        className="wow fadeInUp home-txt pt-5"
                        style={{
                          marginTop: "-10px",
                          position: "relative",
                          top: "-17px",
                        }}
                      >
                        Login with wallet
                      </h6>
                    </div>
                  </div>
                </div>
              </CheckLogin>
            </div>
          </div>
          <div className=" col-lg-4 col-md-6 col-sm-12 mb-2">
            <Link to="/nfts" style={{ textDecoration: "none" }}>
              <div
                class="card "
                style={{ borderRadius: "10px", backgroundColor: "#241d2b" }}
              >
                <div className="px-4 pt-4 pb-2">
                  <div className="de-card text-center boxesNft home-txt-pd">
                    <i
                      className="wow fadeInUp i-boxed icon_cart_alt"
                      style={{ background: "#4A4351" }}
                    ></i>
                    <div className="text">
                      <h6
                        className="wow fadeInUp home-txt pt-5"
                        style={{
                          marginTop: "-10px",
                          position: "relative",
                          top: "-17px",
                        }}
                      >
                        Buy our NFTs
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className=" col-lg-4 col-md-6 col-sm-12 mb-2 mx-auto">
            <Link to="/playtoearn" style={{ textDecoration: "none" }}>
              <div
                class="card "
                style={{ borderRadius: "10px", backgroundColor: "#241d2b" }}
              >
                <div className="px- pt-4 pb-2 ">
                  <div
                    className="de-card text-center boxesNft home-txt-pd"
                    style={{ paddingRight: "5px", paddingLeft: "5px" }}
                  >
                    <i
                      className="wow fadeInUp i-boxed icon_menu-square_alt2"
                      style={{ background: "#4A4351" }}
                    ></i>
                    <div className="text">
                      <h6 className="wow fadeInUp home-txt pt-3">
                        Start earning BUSD
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div className="text-center mb-5 mt-5">
          <p className="text-center mx-auto d-block mb-3">
            <Link
              to="/nfts"
              className="text-decoration-none playtoearn text-center mx-auto"
              rel="playtoearn"
            >
              Explore more gaming nfts
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
