import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUnityContext } from 'react-unity-webgl';
import swal from 'sweetalert';

import Button from '@mui/material/Button';
import Game3D from '../Racing/Game3D/Game3D';
import axios from 'axios';

const AllRaceReplay = () => {
    const [timeOuts, setTimeouts] = useState('')
    const location = useLocation();
    const { unityProvider, isLoaded, loadingProgression, sendMessage, requestFullscreen
    } = useUnityContext({
        loaderUrl: './Build/Celebrity 1.23 - Brotli.loader.js',
        dataUrl: "./Build/Celebrity 1.23 - Brotli.data.unityweb",
        frameworkUrl: "./Build/Celebrity 1.23 - Brotli.framework.js.unityweb",
        codeUrl: "./Build/Celebrity 1.23 - Brotli.wasm.unityweb",
    });
    const navigate = useNavigate();

    useEffect(() => {
        console.log(location?.state?.myDog, "location?.state?.myDog", location?.state)
        axios
            .post(
                "https://backend.playtoearnblockchain.com/api/v1/replay",
                { race: location?.state?.place, selectedDog: location?.state?.myDog },
                {
                    headers: {
                        authorization: `Bearer bs2zDM01DMMEgt33CrKZt8x4abs2zDM01DMMEgt33Cr6atzL3CJdExpP4x4abs2zDM01DMMEgt33Cr`,
                    },
                }
            )
            .then(() => {
                // console.log('latest race posted')
            });
        return () => {
            console.log("outside the page")
            myCleanupFunction();
        };
    }, []);

    const myCleanupFunction = () => {
        clearTimeout(timeOuts);
        sendMessage("GameManager", "QuitGame")
    };

    const raceReplay = () => {

        setTimeouts(setTimeout(() => {
            swal({
                title: `REPLAY`,
                text: "Do you want to replay again!",
                icon: "warning",
                buttons: ["Yes", "No"],
                className: "your-footer-class",
                dangerMode: true,
            }).then(function (isConfirm) {
                if (!isConfirm) {
                    // navigate("/horsechamps",{state:{name:'replay'}}); 
                    // sendMessage("GameManager", "WebReStartBtnClickedForTMP", 4);
                    sendMessage("GameManager", "WebReStartBtnClickedForTMP", 6)
                    raceReplay()
                }
            });

        }, 48000))
    }

    const locataion = useLocation()
    useEffect(() => {
        if (isLoaded == true) {
            // sendMessage("GameManager", "WebReStartBtnClickedForTMP", 4);
            sendMessage("GameManager", "WebReStartBtnClickedForTMP", 6)
            // sendMessage("GameManager" , "WebReplay" , 2);
            raceReplay();
        }

    }, [isLoaded])
    return (
        <>
            <div className='container replay-container'>
                <div style={{ paddingTop: "110px", position: 'relative' }} className="replays text-center">
                    {/* <h5 onClick={() => {
                    navigate("/racing", { state: { name: 'replay', autoOpen: true } });
                    sendMessage("GameManager", "QuitGame"); clearTimeout(timeOuts)
                }} className='text-white mt-2' style={{ position: 'absolute', right: 0, cursor: 'pointer' }}>X</h5> */}
                    <h1 className='text-white text-center pb-4' style={{ fontSize: "2rem" }}>REPLAY</h1>
                    <div>
                        {isLoaded && <img src='https://img.icons8.com/color/48/null/switch-camera.png' onClick={() => sendMessage("Canvas", "ChangeCam")} class="fa-expand"></img>}
                        <Game3D

                            unityProvider={unityProvider}
                            isLoaded={isLoaded}
                            loadingProgression={loadingProgression}
                            sendMessage={sendMessage}
                        ></Game3D>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                    <Button
                        onClick={() => {
                            sendMessage("GameManager", "QuitGame");
                            clearTimeout(timeOuts);
                            navigate("/allrace", { state: { name: 'replay', autoOpen: true, AllData: locataion?.state?.AllData } });
                        }}
                        className="btn-main lead text-white"
                    >
                        Close
                    </Button>
                </div>
            </div>
        </>
    );
};

export default AllRaceReplay;