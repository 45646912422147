import mint from "./MintNFT.json";

import dslmainnet from "./DSLMainnet.json";
import usdsc from "./USDSC.json";
import CelebrityNFTAbi from "./CelebrityNFTMint.json";

export const mintAddressTestnet = "0x8e22dF0A8997f9FA2be376B3eBA54e79A89A5C6A";
export const mintABITestnet = mint.abi;

// export const private_key = "4284f6eae0ebc914437b42cd3a523a67c70428dd1888c6ae6059f26c74c452cc";

export const RPC = "https://bsc-dataseed.binance.org/";

export const chainId = "56";

export const USDSCtokenAddressMainnet =
  "0x13b852e276f10281C72ccF33EdF81d81DD198Aae";
export const USDSCtokenABIMainnet = usdsc.abi;

export const DSLtokenAddressMainnet =
  "0x4A1530Fb85BdB9250Db2bE251584874179eB8Dc5";
export const DSLtokenABIMainnet = dslmainnet.abi;

// export const MintContractAddress = "0xE57D77f30dBFcFB429D29408605666A527665aeA";//testNet
export const MintContractAddress = "0x23A227D3b78bE61bbC34d6FC36439A2e9CdE2D46"; //mainnet
export const MintContractAbi = CelebrityNFTAbi;
