import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import DashboardModal from "./DashboardModal";
import Table from "react-bootstrap/Table";
import { BsPencilFill } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import EditNftModal from "./EditNftModal";
import "./DashboardNfts.css";
import axios from "axios";
import swal from "sweetalert";
import Loading from "../../Loading/Loading";
import { Link } from "react-router-dom";
import DashboardActive from "./DashboardActive";
import DashboardExpired from "./DashboardExpired";
import DashboardDraft from "./DashboardDraft";
import Swal from "sweetalert2";
import Pagination from "@mui/material/Pagination";

const DashboardNfts = () => {
  const [modalShow, setModalShow] = useState(false);
  const [editNftmodalShow, setEditNftModalShow] = useState(false);
  const [nfts, setnfts] = useState([]);
  const [nftsPro, setNftsPro] = useState({});
  const [refetch, setRefetch] = useState(false);
  const [isloading, SetIsloading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("nft");

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  // Date
  // const todayDate = new Date();

  var newDate = new Date();
  let dd = String(newDate.getDate()).padStart(2, "0");
  let mm = String(newDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = newDate.getFullYear();
  let hh = newDate.getHours();
  let min = newDate.getMinutes();
  let ss = newDate.getSeconds();

  if (min < 10) {
    newDate = dd + "/" + mm + "/" + yyyy + "  " + hh + ":" + 0 + min + ":" + ss;
  } else {
    newDate = dd + "/" + mm + "/" + yyyy + "  " + hh + ":" + min + ":" + ss;
  }

  useEffect(() => {
    const todayDate = new Date();
    axios
      .get("https://backend.playtoearnblockchain.com/api/nft/all", {
        headers: {
          authorization: `Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54`,
        },
      })
      .then((res) => {
        // const filtering = res.data.nft.filter(items => items.isDraft === false && new Date(`${items?.purchaseDate.slice(5, 7)}/${items?.purchaseDate.slice(8, 10)}/${items?.purchaseDate.slice(0, 4)}`) > todayDate);
        setnfts(res?.data?.nft);
        // setIsMeal(res.data.nft.reverse());
        // const array = res?.data?.nft.map(res => { return res.type })
        // let uniqueArray = [...new Set(array)];
        // setcategories(uniqueArray.sort())
        // setIsMeal(filtering);
        // setFilterData(res.data.slice(0, 5))
      });
    // fetch('https://backend.playtoearnblockchain.com/api/nft/all', {
    //   method: "GET",
    //   headers: {
    //     "Authorization":`Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54`
    //   }
    // })
    //   .then(res => res.json())
    //   .then(data => {
    //     console.log(data);
    // const filtering = data.nft.filter(items => items.isDraft === false);
    // const filtering = data.nft.filter(items => items.isDraft === false && new Date(`${items?.purchaseDate.slice(5, 7)}/${items?.purchaseDate.slice(8, 10)}/${items?.purchaseDate.slice(0, 4)}`) > todayDate);
    // setnfts(filtering);
    //   setnfts(data.nft);
    // })
    setRefetch(false);
  }, [refetch]);

  console.log(nfts);
  // if (nfts.length <= 0) {
  //   return <Loading></Loading>
  // }
  // if (isloading) {
  //   return <Loading></Loading>
  // }

  const handleOrderDelete = (id) => {
    // const confirmDelete = window.confirm("Are you sure you want to delete this NFT? You can't recover.")

    Swal.fire({
      text: "Are you sure you want to delete this NFT? You can't recover.",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
      // denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`https://backend.playtoearnblockchain.com/api/nft/delete/${id}`)
          .then((res) => {
            if (res.status === 200) {
              swal({
                title: "Succesfully deleted",
                text: "You successfully deleted the NFT",
                icon: "success",
                button: "OK",
                className: "modal_class_success",
              });
              setnfts(nfts.filter((nft) => nft._id !== id));
            }
          })
          .catch((error) => {
            swal({
              title: "Attention",
              text: `${error.response.data.message}`,
              icon: "warning",
              button: "OK!",
              className: "modal_class_success",
            });
          });
      }
    });
  };

  const handleDuplicateNft = (id) => {
    const theNFT = nfts?.find((nft) => nft?._id === id);
    console.log(theNFT, "--Real NFT");

    const duplicateNFT = {
      name: theNFT.name,
      date: newDate,
      price: theNFT.price,
      availableNfts: theNFT.availableNfts,
      perkNft: theNFT.perkNft,
      description: theNFT.description,
      startDate: theNFT.startDate,
      startTime: theNFT.startTime,
      endTime: theNFT.endTime,
      venue: theNFT.venue,
      vidLink: theNFT.vidLink,
      purchaseDate: theNFT.purchaseDate,
      briefDetails: theNFT.briefDetails,
      type: theNFT.type,
      isDraft: theNFT.isDraft,
      avatar: theNFT.avatar,
    };
    Swal.fire({
      text: "Are you sure you want to Duplicate this NFT?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch("https://backend.playtoearnblockchain.com/api/nft/repost", {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(duplicateNFT),
        })
          .then((res) => res.json())
          .then((result) => {
            if (result) {
              swal({
                title: "Success",
                text: `NFT successfully duplicated.`,
                icon: "success",
                button: "OK!",
                className: "modal_class_success",
              });
              setRefetch(true);
            }
          });
      }
    });
  };

  // Set the number of items per page
  const itemsPerPage = 10;

  // Use the useState hook to store the current page and an array of data for each page
  const [currentPage, setCurrentPage] = useState(1);
  const [pagedData, setPagedData] = useState([]);

  // Use the useEffect hook to paginate the data when the component mounts or the data changes
  useEffect(() => {
    // Calculate the number of pages needed
    const numPages = Math.ceil(nfts?.length / itemsPerPage);

    // Create an array of arrays, with each inner array containing the items for a single page
    const pages = Array.from({ length: numPages }, (_, i) =>
      nfts?.slice(i * itemsPerPage, (i + 1) * itemsPerPage)
    );

    // Set the paged data to the appropriate page
    setPagedData(pages[currentPage - 1]);
  }, [nfts, currentPage, itemsPerPage]);

  // Create a function to handle changing the page
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [page1, setPage1] = useState(1);
  const data1 = nfts;
  const itemsPerPage1 = 10;
  const pageCount1 = Math.ceil(data1.length / itemsPerPage1);

  const handleChange1 = (event, value) => {
    window.scrollTo(0, 0);
    setPage1(value);
  };

  const getPageData = () => {
    const start = (page1 - 1) * itemsPerPage1;
    const end = start + itemsPerPage1;
    return data1.slice(start, end);
  };

  return (
    <>
      <div
        className="handleHeightNFTS"
        style={{ height: nfts?.length <= 5 ? "120vh" : "auto" }}
      >
        {/* <p className="ms-2 mb-3 d-inline">NdFTS</p> */}
        {/* /dashboard/addnfts */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "30px",
            marginLeft: "-36px",
          }}
        >
          <Link to={"/dashboard/addnfts"}>
            <Button
              variant="primary"
              className="border-0 text-uppercase button_width w-100"
              style={{ backgroundColor: "blueviolet" }}
            >
              New NFT
            </Button>
          </Link>
        </div>

        <div className="mb-3 nftButtonDiv">
          <Button
            variant="primary"
            className={` border-0 text-uppercase ${
              selectedTab === "nft" ? "bg-danger" : "bg-success"
            } button_width`}
            style={{ backgroundColor: "blueviolet" }}
            onClick={() => setSelectedTab("nft")}
          >
            Active
          </Button>

          <Button
            variant="primary"
            className={`ms-2 border-0 text-uppercase ${
              selectedTab === "expired" ? "bg-danger" : "bg-success"
            } button_width`}
            style={{ backgroundColor: "blueviolet" }}
            onClick={() => setSelectedTab("expired")}
          >
            Expired
          </Button>
          <Button
            variant="primary"
            className={`ms-2 border-0 text-uppercase ${
              selectedTab === "draft" ? "bg-danger" : "bg-success"
            } button_width`}
            style={{ backgroundColor: "blueviolet" }}
            onClick={() => setSelectedTab("draft")}
          >
            Draft
          </Button>
        </div>
        <Container fluid>
          <div className="nftsBox">
            <Container fluid className="mt-3">
              {selectedTab === "nft" && (
                <>
                  {/* <Button
                    variant="primary"
                    className="border-0 text-uppercase"
                    style={{ backgroundColor: "blueviolet" }}
                    onClick={() => setModalShow(true)}
                  >
                    New NFT
                  </Button> */}

                  <div className="text-end">
                    {/* <input type="text" placeholder='Search...' className='ps-2 rounded border border-white' style={{ backgroundColor: "#272d47" }} /> */}
                  </div>

                  <div className="mt-4">
                    <Table bordered responsive className="border-0 text-light">
                      <thead>
                        <tr>
                          <th className="th-font-size">
                            NFT
                            <br />
                            Image
                          </th>
                          <th className="th-font-size">
                            NFT
                            <br />
                            Name
                          </th>
                          <th className="th-font-size">
                            Price
                            <br />
                            (USD)
                          </th>
                          <th className="handleForDnoneinRespo th-font-size">
                            NFT
                            <br />
                            Type
                          </th>
                          <th className="handleForDnoneinRespo th-font-size">
                            NFT
                            <br />
                            Created
                          </th>
                          <th className="th-font-size">
                            NFT
                            <br />
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {getPageData()?.map((data) => (
                          <tr data={data} key={data?.id}>
                            <td className="ps-0">
                              <img
                                src={data.avatar}
                                alt=""
                                style={{ width: "65px", hight: "65px" }}
                              />
                            </td>
                            <td className="td-font-size">{data.name}</td>
                            <td className="td-font-size">{data.price}</td>
                            <td className="handleForDnoneinRespo td-font-size">
                              CelebrityGames NFTs
                            </td>
                            <td className="handleForDnoneinRespo td-font-size">
                              {data.date}
                            </td>
                            <td className="pt-3">
                              <div className="d-flex justify-content-start align-items-start">
                                <CustomTooltip title="Edit NFT">
                                  <Link to={`editNft/${data._id}`}>
                                    <button className="editBtn">
                                      <i className="fas fa-edit"></i>
                                    </button>
                                  </Link>
                                </CustomTooltip>{" "}
                                <CustomTooltip title="Delete NFT">
                                  <span
                                    className="rounded nft-delete-button"
                                    onClick={() => handleOrderDelete(data._id)}
                                  >
                                    <button className="deleteBtn">
                                      <i className="fas fa-trash"></i>
                                    </button>
                                  </span>
                                </CustomTooltip>{" "}
                                <CustomTooltip title="Duplicate NFT">
                                  <span
                                    className="rounded nft-delete-button ms-1 me-0"
                                    onClick={() => handleDuplicateNft(data._id)}
                                  >
                                    <button
                                      className="saveBtn me-0"
                                      style={{ padding: "5px" }}
                                    >
                                      <i className="fa-solid fa-clone"></i>
                                    </button>
                                  </span>
                                </CustomTooltip>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <h6 className="text-center text-danger">
                      No of NFTs: {nfts?.length ? nfts?.length : "0"}
                    </h6>
                  </div>
                </>
              )}

              {selectedTab === "expired" && <DashboardExpired />}
              {selectedTab === "draft" && <DashboardDraft />}
            </Container>
          </div>
        </Container>
        {/* Display the pagination controls */}
        <div
          className="width-91"
          style={{
            textAlign: "center",
            marginTop: "16px",
            backgroundColor: "transparent",
          }}
        >
          {/* {currentPage > 1 && (
        <button onClick={() => handlePageChange(currentPage - 1)} className="btn btn-secondary btn-sm me-2">Prev</button>
      )}

      {Array.from({ length: Math.ceil(nfts?.length / itemsPerPage) }, (_, i) => (
        <button
          key={i + 1}
          onClick={() => handlePageChange(i + 1)}
          disabled={i + 1 === currentPage}
          className="btn btn-primary btn-sm ms-1 me-1"
        >
          {i + 1}
        </button>
      ))}

    
      {currentPage < Math.ceil(nfts?.length / itemsPerPage) && (
        <button onClick={() => handlePageChange(currentPage + 1)} className="btn btn-secondary btn-sm ms-2">Next</button>
      )} */}
          <div className="pagination-btn mx-auto text-center ">
            <Pagination
              count={pageCount1}
              page={page1}
              onChange={handleChange1}
            />
          </div>
        </div>
      </div>

      <DashboardModal
        show={modalShow}
        setModalShow={setModalShow}
        onHide={() => setModalShow(false)}
        setRefetch={setRefetch}
        refetch={refetch}
        SetIsloading={SetIsloading}
      />
      <EditNftModal
        show={editNftmodalShow}
        onHide={() => setEditNftModalShow(false)}
      />
    </>
  );
};

export default DashboardNfts;
