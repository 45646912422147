import React, { useEffect, useState } from 'react';

const Stopwatch = ({time,setTime,timerOn,setTimerOn}) => {


  useEffect(() => {
    let interval = null;

    if (timerOn) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 10);
      }, 10);
    } else if (!timerOn) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timerOn]);
  return (
    <div>
      <div className="Timers text-white ">
      <div id="display">
          <span>{("00" + ((time / 10) % 100)).slice(-3)}</span>
        </div>
      </div>
    </div>
  );
};

export default Stopwatch;