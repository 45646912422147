import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import swal from 'sweetalert'
import axios from "axios";
import Slider from 'react-slick';
import { Typography } from '@mui/material';
import Tooltip from 'react-bootstrap/Tooltip';
import { Button, OverlayTrigger } from 'react-bootstrap';
import { CelebrityContext } from '../../../../context/CelebrityContext';
import { getNfts } from '../../CheckNft/api';
const { ethereum } = window;
const GameNFT = () => {
    const { openWalletModal, user, searchNftTitle } = useContext(CelebrityContext);

    const [isMeal, setIsMeal] = useState([]);
    const [walletAddress, setWalletAddress] = useState('');
    const [nft, setNft] = useState([]);
    const [myGameNft, setmyGameNft] = useState([]);
    const [nftId, setnftId] = useState([]);
    const [isExpand, setIsExpand] = useState(false);
    // const [stakeAmount, setstakeAmount] = useState(0);
    const [stakeAmount1, setstakeAmount1] = useState(1);
    let stakeAmount = 1
    const [stakeFlag, setstakeFlag] = useState(0);
    const inputref = useRef()
    const [load, setLoad] = useState(false);
    const [showTooltip1, setShowTooltip1] = useState(false);
    const [showTooltip2, setShowTooltip2] = useState(false);
    const [showTooltip3, setShowTooltip3] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    console.log(nft);



    useEffect(() => {

        axios.get("https://backend.playtoearnblockchain.com/api/v1/verifymint", {
            headers: { "authorization": `Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54` }
        })
            .then(res => {
                console.log(res, "res")
                const filtering = res.data.result.filter(items => items.walletAddress == user?.walletAddress && items.contractAddress != "0x31219e8af07699054B690116675546939a0c362F");
                // && items.contractAddress != "0x31219e8af07699054B690116675546939a0c362F"
                // && items.price > 0
                setmyGameNft(filtering)

            });


    }, [load])



    useEffect(() => {
        const dataIsMeal = async () => {
            await axios.get("https://backend.playtoearnblockchain.com/api/nft/all", {
                headers: { "authorization": `Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54` }
            })
                .then(res => {
                    // const filtering = res.data.nft.filter(items => items.isDraft === false && new Date(`${items?.purchaseDate.slice(5, 7)}/${items?.purchaseDate.slice(8, 10)}/${items?.purchaseDate.slice(0, 4)}`) > todayDate);
                    // setIsMeal(filtering);
                    setIsMeal(res?.data?.nft);
                    // setFilterData(res.data.slice(0, 5))
                });
        }
        dataIsMeal();
    }, [])

    // useEffect(() => {
    //     if (stakeAmount == 0) {
    //         stakeAmount = 1
    //     }
    // }, [stakeAmount])

    // console.log(isMeal);
    const getData = async () => {

        console.log(isMeal)
        console.log(walletAddress)
        const nfts = await getNfts(
            `${walletAddress}`
        );

        // console.log(nfts.assets);
        setNft(nfts.assets);
        setIsExpand(true);
    }

    const navigate = useNavigate()
    const stakeConfirm = (id) => {
        stakeAmount > 0 ?
            swal({
                // title: `You want to unfollow ${name} ?`,
                text: `You are staking USD ${stakeAmount} from your NFT.\n Are you sure you want to stake?`,
                icon: "warning",
                buttons: ["No", "Yes"],
                dangerMode: true,
            }).then(function (isConfirm) {
                if (isConfirm) {
                    nftId && navigate("/horsechampsbusd", { state: { name: "gamenft", stake: stakeAmount, autoOpen: true, nft: id } });
                    // navigate("/horsechamps")
                }
                else {
                }
            }) : swal({
                // title: `You want to unfollow ${name} ?`,
                text: `Please Stake an Amount`,
                icon: "warning",
                // buttons: ["No", "Yes"],
                dangerMode: true,
            })
    }

    useEffect(() => {

        setWalletAddress(user?.walletAddress)

    }, [user?.walletAddress]);
    useEffect(() => {

        console.log('nftid', nftId, "nftId")

    }, [nftId]);


    const stakeInputValue = (event, data, id, index) => {
        const currentValues = document.getElementById(`tentacles${index}`).value;
        console.log(currentValues, "cyrrent")
        const amount = event.target.value;
        console.log(amount, "my-amount");
        const newId = id;

        if (amount > data) {
            stakeAmount = data;

        }
        if (amount <= data && id === newId) {
            stakeAmount = Math.floor(amount);

        }
    }

    const reload = () => {
        // window.location.reload(true)
        setLoad(load == true ? false : true);
    }

    let settings = {
        dots: false,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        speed: 1500,
        autoplaySpeed: 5000,
        cssEase: "linear",
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    // arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite: true,
                }
            }
        ]
    };
    const confirmStake = (index, price) => {
        const value = document.getElementById(`tentacles${index}`).value;
        console.log(value, price, "new")
        if (value > price) { stakeAmount = price }
        else if (value <= price) {
            stakeAmount = value
        }
    }

    return (
        <div className="pb-5" style={{ height: "auto" }}>
            <div>
                <h1 className="text-gradient text-center pt-5 text-uppercase" style={{ marginTop: '80px', fontSize: "1.75rem" }}>STAKE YOUR NFT</h1>
                <div className="small-border bg-color-2"></div>
            </div>

            <div className='container mx-auto  text-light' style={{ fontFamily: "Arial" }}>
                <div className='row'>
                    <div>

                        {console.log("isMeal", isMeal)}
                        {!myGameNft.length > 0 ? <div className='text-center' style={{ height: "60vh" }}>
                            <button style={{ fontSize: '13px' }} class="button-18" role="button" onClick={() => reload()} >Refresh</button>
                            <Link to="/nfts" className='text-white text-center d-block mt-4'>You have not bought any NFTs yet. Click here to buy NFTs.</Link>
                        </div> : <Slider {...settings} className="gap-2 game-nft">

                            {
                                myGameNft.map((item, index) => <div className='d-item1' key={index}>
                                    <div className='card'>
                                        <div class="card-img" style={{ backgroundImage: `url(${item?.image ? item?.image : "https://backend.playtoearnblockchain.com/assets/1671536589619.jpeg"})` }}>
                                            {/* <img src="https://i.ibb.co/Pwt1fRw/9ee03415-e591-4320-bf25-af881b8c27a6.jpg" alt="" className="img-fluid nft-watermark2" /> */}
                                        </div>
                                        <div class="card-content">
                                            <div className="row" style={{ minHeight: '90px' }}>
                                                <Typography className="slider_nft_text pb-2" variant="div">
                                                    <span className="text-primary">NFT Contract Address : </span> {item?.contractAddress}
                                                </Typography>
                                                <Typography className="slider_nft_text pb-2" variant="div">
                                                    <span className="text-primary">NFT ID : <br /> </span> {item?.tokenId}
                                                </Typography>
                                                <Typography className="pb-2" variant="body2">
                                                    <span className="text-primary">NFT Price (USD) :<OverlayTrigger
                                                        key="bottom"
                                                        placement="bottom"
                                                        overlay={
                                                            <Tooltip id={`tooltip-bottom`}
                                                            >
                                                                Price of NFT is the price you purchase the NFT.
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <p
                                                            style={{ cursor: 'pointer' }}
                                                            className='d-inline ps-2'
                                                            color="primary "
                                                        >
                                                            <i class="fas fa-info-circle text-white"></i>
                                                        </p>
                                                    </OverlayTrigger>

                                                        <br /> <sapn className="text-light">{item?.orginalPrice ? item?.orginalPrice : 1}</sapn>
                                                        <Tooltip

                                                            arrow
                                                            title=" Price of NFT is the price you purchase the NFT."
                                                            open={showTooltip}
                                                            onClose={() => setShowTooltip2(false)}
                                                        >
                                                            <p
                                                                style={{ cursor: 'pointer' }}
                                                                className='d-inline ps-2'
                                                                color="primary "
                                                                onClick={() => setShowTooltip2(!showTooltip2)}
                                                            >
                                                                <i class="fas fa-info-circle text-white"></i>
                                                            </p>
                                                        </Tooltip>
                                                    </span>
                                                </Typography>
                                                <Typography className="pb-2" variant="body2">
                                                    <span className="text-primary">NFT Value (USD) :<OverlayTrigger
                                                        key="bottom"
                                                        placement="bottom"
                                                        overlay={
                                                            <Tooltip id={`tooltip-bottom`}
                                                            >
                                                                Value of NFT is the value the NFT has. You can withdraw the eligible value as BUSD.
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <p
                                                            style={{ cursor: 'pointer' }}
                                                            className='d-inline ps-2'
                                                            color="primary "
                                                        >
                                                            <i class="fas fa-info-circle text-white"></i>
                                                        </p>
                                                    </OverlayTrigger>


                                                        <br /><span className="text-light">{item?.price >= 0 ? item?.price : 0}</span>
                                                        <Tooltip

                                                            arrow
                                                            title="Value of NFT is the value the NFT has. You can withdraw the eligible value as BUSD."
                                                            open={showTooltip3}
                                                            onClose={() => setShowTooltip3(false)}
                                                        >
                                                            <p
                                                                style={{ cursor: 'pointer' }}
                                                                className='d-inline ps-2'
                                                                color="primary "
                                                                onClick={() => setShowTooltip3(!showTooltip3)}
                                                            >
                                                                <i class="fas fa-info-circle text-white"></i>
                                                            </p>
                                                        </Tooltip>

                                                    </span>
                                                </Typography>
                                                <Typography className="slider_nft_text pb-2" variant="subtitle2" gutterBottom component="div">
                                                    <span className="text-primary">Experience Points:<OverlayTrigger
                                                        key="bottom"
                                                        placement="bottom"
                                                        overlay={
                                                            <Tooltip id={`tooltip-bottom`}
                                                            >
                                                                Experience Points are accumulated when you play our blockchain games.
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <p
                                                            style={{ cursor: 'pointer' }}
                                                            className='d-inline ps-2'
                                                            color="primary "
                                                        >
                                                            <i class="fas fa-info-circle text-white"></i>
                                                        </p>
                                                    </OverlayTrigger>

                                                        <br /><span className="text-light">{item?.experiencePoints}</span>

                                                        <Tooltip

                                                            arrow
                                                            title="Experience Points are accumulated when you play our blockchain games."
                                                            open={showTooltip}
                                                            onClose={() => setShowTooltip(false)}
                                                        >
                                                            <p
                                                                style={{ cursor: 'pointer' }}
                                                                className='d-inline ps-2'
                                                                color="primary "
                                                                onClick={() => setShowTooltip(!showTooltip)}
                                                            >
                                                                <i class="fas fa-info-circle text-white"></i>
                                                            </p>
                                                        </Tooltip>


                                                    </span>
                                                </Typography>
                                            </div>
                                            <hr style={{ margin: "10px 0px 10px 0px" }} />
                                            <div className='d-flex justify-content-center flex-column'>
                                                <label for={`tentacles${index}`} className='mx-auto '>How much you want to stake?</label>
                                                <input type="number"
                                                    defaultValue={item?.price >= 0 ? item?.price : 0}
                                                    // onChange={(e) => {
                                                    //     setstakeAmount(e.target.value > item?.price ? item?.price : Math.floor(e.target.value));
                                                    // }}
                                                    value={stakeAmount1[`tentacles${index}`]}
                                                    ref={inputref}
                                                    onChange={(e) => {
                                                        if (item?.price > 0) {
                                                            stakeInputValue(e, item?.price, item?._id, index);
                                                            setstakeAmount1({
                                                                ...stakeAmount1,
                                                                [e.target.name]: e.target.value > item?.price ? item?.price : (e.target.value == 0 ? 1 : parseInt(Math.floor(e.target.value)))
                                                            });
                                                        }
                                                        else {
                                                            stakeInputValue(e, item?.price, item?._id, index);
                                                            setstakeAmount1({
                                                                ...stakeAmount1,
                                                                [e.target.name]: e.target.value > item?.price ? item?.price : parseInt(Math.floor(e.target.value))
                                                            });
                                                        }
                                                        // setstakeAmount1(e.target.value > item?.price ? item?.price : Math.floor(e.target.value));
                                                    }}
                                                    id={`tentacles${index}`} className='mx-auto mt-1 text-black' style={{ width: "66%" }} name={`tentacles${index}`}
                                                    min="1" step="1" pattern="\d+" max={item?.price ? item?.price : 0} oninput="validity.valid||(value='');" />
                                            </div>
                                            <div className="d-flex card_bottom_btn_main " style={{ margin: '15px 0 8px 0' }}>

                                                <div className="col-8 col-md-10 col-lg-8 d-grid">
                                                    <span className="d-grid"> <button disabled={item?.price > 0 ? false : true} className={`card_button2 fs-6 ${item?.price > 0 ? "bg-danger" : "bg-gray"}  glow_crypto_button`}
                                                        onClick={() => {
                                                            confirmStake(index, item?.price)
                                                            // (setstakeAmount(inputref.current.value))
                                                            setnftId(item?._id);
                                                            // console.log("hello", inputref.current)
                                                            stakeConfirm(item?._id);
                                                        }}>Stake to win BUSD</button> </span>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>)
                            }

                        </Slider>}

                    </div>
                </div >
                <p className='mt-4' style={{ color: 'white', textAlign: "center" }}>Number of NFTs from Playtoearnblockchain.com : {myGameNft.length}</p>
                <p className='text-center mt-3'><Link to="/nfts" className='text-primary'>Click here to buy NFTs</Link></p>

            </div >
        </div >
    );
};

export default GameNFT;