import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { Link, Typography } from "@mui/material";
import axios from 'axios';
import htmlToDraft from 'html-to-draftjs';
import swal from 'sweetalert';
import { MdClose } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { TimePicker } from 'antd';
import { Leaderboard } from '@mui/icons-material';

const DashboardAddNft = () => {
    const [saveAsDraft, setSaveAsDraft] = useState(false);
    const [img, setImg] = useState(null);
    const [event, setEvent] = useState();
    const [startTimeInput, setStartTimeInput] = useState('');
    const [endTimeInput, setEndTimeInput] = useState('');
    const [allCategory, setAllCategory] = useState([]);
    const [firstValue, setfirstValue] = useState(() => EditorState.createEmpty());
    const stepOne = draftToHtml(convertToRaw(firstValue.getCurrentContent()));
    const [catagories, setCatagories] = useState([]);
    const [secondValue, setSecondValue] = useState(() => EditorState.createEmpty());
    const stepTwo = draftToHtml(convertToRaw(secondValue.getCurrentContent()));

    const [perkNfts, setPerkNfts] = useState(() => EditorState.createEmpty());
    const stepPerkNft = draftToHtml(convertToRaw(perkNfts.getCurrentContent()));

    const [selectedImage, setSelectedImage] = useState()
    const [image, setImage] = useState();

    // console.log(saveAsDraft);
    const navigate = useNavigate();
    var newDate = new Date();
    let dd = String(newDate.getDate()).padStart(2, '0');
    let mm = String(newDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = newDate.getFullYear();
    let hh = newDate.getHours();
    let min = newDate.getMinutes();
    let ss = newDate.getSeconds();

    if (min < 10) {
        newDate = dd + '/' + mm + '/' + yyyy + '  ' + hh + ':' + 0 + min + ':' + ss;

    } else {
        newDate = dd + '/' + mm + '/' + yyyy + '  ' + hh + ':' + min + ':' + ss;
    }

    useEffect(() => {
        if (!selectedImage) {
            // setImage("https://i.ibb.co/Pwt1fRw/9ee03415-e591-4320-bf25-af881b8c27a6.jpg")
            return
        }

        const objectUrl = URL.createObjectURL(selectedImage)
        setImage(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedImage])

    useEffect(() => {
        const catagory = async () => {
            await axios.get("https://backend.playtoearnblockchain.com/api/v1/category")
                .then(res => {
                    console.log(res.data.Categories);
                    setCatagories(res.data.Categories);
                })
        }
        catagory();
    }, [])


    const changePhoto = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedImage(undefined)
            return
        }

        // I've kept this example simple by using the first image instead of multiple
        setSelectedImage(e.target.files[0])
    }


    const handleTimeChange = (e) => {
        const startTimeChange = parseInt(e.target.value);
        const endTimeChange = startTimeChange + 3;
        setEndTimeInput(endTimeChange);
    }

    useEffect(() => {
        fetch("https://backend.playtoearnblockchain.com/api/v1/category")
            .then(res => res.json())
            .then(data => {
                setAllCategory(data.Categories)
                console.log(data.Categories)
                console.log(data.Categories[0].category)
            })
    }, [])

    const onSubForm = async (e) => {
        e.preventDefault();
        // SetIsloading(true);

        const name = e.target.name.value;
        const price = e.target.price.value;
        const availableNfts = e.target.availableNfts.value;
        const perkNft = stepPerkNft;
        const description = stepOne;
        const startDate = e.target.startDate.value;
        // const startTime = e.target.startTime.value;
        // const endTime = e.target.endTime.value;
        // const venue = e.target.venue.value;
        // const vidLink = e.target.vidLink.value;
        // const purchaseDate = e.target.purchaseDate.value;
        const briefDetails = stepTwo;
        const type = e.target.type.value;
        const isDraft = saveAsDraft;
        const image = e.target.image.files[0];
        const tokenAddress = e.target.tokenAddress.value;
        const experiencePoints = e.target.experiencePoints.value;

        const formData = new FormData()
        formData.append('name', name)
        formData.append('price', price)
        formData.append('availableNfts', availableNfts)
        formData.append('perkNft', perkNft)
        formData.append('date', newDate)
        formData.append('description', description)
        formData.append('startDate', startDate)
        // formData.append('startTime', startTime)
        // formData.append('endTime', endTime)
        // formData.append('venue', venue)
        // formData.append('vidLink', vidLink)
        // formData.append('purchaseDate', purchaseDate)
        formData.append('briefDetails', briefDetails)
        formData.append('type', type)
        formData.append('isDraft', isDraft)
        formData.append('image', image)
        formData.append('totalNfts', availableNfts)
        formData.append('tokenAddress', tokenAddress)
        formData.append('experiencePoints', experiencePoints)


        await axios.post('https://backend.playtoearnblockchain.com/api/nft/add', formData, {
            headers: {
                'authorization': `Bearer ${localStorage.getItem('tokenCelebrity')}`
            }
        })
            .then(res => {
                if (res.status === 200) {
                    // alert(res.data.message);
                    if (saveAsDraft) {
                        swal({
                            title: "Success",
                            text: `NFT successfully saved in draft.`,
                            icon: "success",
                            button: "OK!",
                            className: "modal_class_success",
                        });

                    } else {
                        swal({
                            title: "Success",
                            text: `${res.data.message}`,
                            icon: "success",
                            button: "OK!",
                            className: "modal_class_success",
                        });
                    }
                }
                navigate(-1);
            })
            .catch(error => {
                // alert(error.response.data.message);
                swal({
                    title: "Attention",
                    text: `${error.response.data.message}`,
                    icon: "warning",
                    button: "OK!",
                    className: "modal_class_success",
                });
            });

    };

    const imageChange = (e) => {
        const selected = e.target.files[0];

        if (selected) {
            let reader = new FileReader();
            reader.onload = () => {

                setImg(reader?.result)

            }
            reader.readAsDataURL(selected);
        }
    }


    const loadFile = (event) => {
        setImg(event.target.files[0]);
        let output = document.getElementById('output');
        output.src = URL.createObjectURL(event.target.files[0]);
        output.onload = function () {
            URL.revokeObjectURL(output.src) // free memory
        }
    };

    const redirectToNftPage = () => {
        navigate("/dashboard/nfts");
    }

    // console.log(event);

    var date = new Date();

    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();

    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;

    var today = year + "-" + month + "-" + day;

    function addDays(n) {
        var t = new Date();
        t.setDate(t.getDate() + n);
        var month = "0" + (t.getMonth() + 1);
        var date = "0" + t.getDate();
        month = month.slice(-2);
        date = date.slice(-2);
        var date = t.getFullYear() + "-" + month + "-" + date;
        return date;
    }


    return (
        <div>
            <div style={{ backgroundColor: "#272d47", color: 'white' }} className='mx-auto forRespoMarginReduce'>
                <h4 className='py-3 ps-3 container'>Add NFT</h4>
                <div className='container pb-5 pt-0'>
                    <form onSubmit={onSubForm}>
                        <label className="mb-1">Category</label>
                        <InputGroup className="mb-3" style={{ backgroundColor: "#272d47", color: 'white' }}>

                            <Form.Select aria-label="Default select example"
                                required
                                name="type"
                                className='' style={{ backgroundColor: "#272d47", color: 'white' }}>
                                {/* <option>Type Of NFT</option> */}
                                {/* {
                                    catagories.map(catagory => <option value={catagory.category_name}>{catagory.category_name}</option>)
                                } */}
                                {allCategory?.map(category => <option value={category?.category_name}>{category?.category_name}</option>)}
                            </Form.Select>
                        </InputGroup>
                        <div className="imageDivNft">

                            <img src={image} width={200} height={200} className='d-flex justify-content-center' alt="" />
                        </div>


                        <label className='mb-1'>Image of NFT</label>
                        <input
                            type="file"
                            accept='image/*'
                            name="image"
                            className='border w-100 rounded mb-3'
                            style={{ backgroundColor: "#272d47", color: 'white' }}
                            onChange={changePhoto}
                            required
                        />
                        <label className='mb-1'>Name of NFT</label>
                        <input
                            type="text"
                            name="name"
                            className='border w-100 rounded mb-3 p-2'
                            style={{ backgroundColor: "#272d47", color: 'white' }}
                            required
                        />

                        <label className='mb-1'>Token Address</label>
                        <input
                            type="text"
                            name="tokenAddress"
                            className='border w-100 rounded mb-3 p-2'
                            style={{ backgroundColor: "#272d47", color: 'white' }}
                            required
                        />

                        <label className='mb-1'>Price of NFT(USD)</label>
                        <input
                            type="number"
                            min="0"
                            inputmode="numeric"
                            pattern="[0-9]*"

                            name="price"
                            className='border w-100 rounded mb-3 p-2'
                            style={{ backgroundColor: "#272d47", color: 'white' }}
                            required
                        />

                        <label className='mb-1'>Available NFTs</label>
                        <input
                            type="number"
                            min="0"
                            inputmode="numeric"
                            pattern="[0-9]*"

                            name="availableNfts"
                            className='border w-100 rounded mb-3 p-2'
                            style={{ backgroundColor: "#272d47", color: 'white' }}
                            required
                        />

                        {/* <label className='mb-2'>NFT Details</label>

                        <Editor
                            editorState={firstValue}
                            handlePastedText={() => false}
                            onEditorStateChange={setfirstValue}
                            required={true}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class border mt-2 p-2 bg-white text-black"
                            toolbarClassName="toolbar-class text-black"
                            toolbar={{
                                image: {
                                    urlEnabled: true,
                                    uploadEnabled: true,
                                    alignmentEnabled: true,
                                    uploadCallback: undefined,
                                    previewImage: true,
                                    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                    alt: { present: false, mandatory: false },
                                    defaultSize: {
                                        height: 'auto',
                                        width: 'auto',
                                    },
                                    fontSize: {
                                        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                                        className: undefined,
                                        component: undefined,
                                        dropdownClassName: undefined,
                                    },
                                    fontFamily: {
                                        options: ['Arial', 'sans-serif', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                                        className: undefined,
                                        component: undefined,
                                        dropdownClassName: undefined,
                                    },
                                },
                            }}
                        /> */}


                        <label className='mb-2'>Brief Details of Celebrity</label>

                        <Editor
                            editorState={secondValue}
                            handlePastedText={() => false}
                            required={true}
                            onEditorStateChange={setSecondValue}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class border mt-2 p-2 bg-white text-black"
                            toolbarClassName="toolbar-class text-black"
                            toolbar={{
                                image: {
                                    urlEnabled: true,
                                    uploadEnabled: true,
                                    alignmentEnabled: true,
                                    uploadCallback: undefined,
                                    previewImage: true,
                                    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                    alt: { present: false, mandatory: false },
                                    defaultSize: {
                                        height: 'auto',
                                        width: 'auto',
                                    },
                                    fontFamily: {
                                        options: ['sans-serif', 'Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                                        className: undefined,
                                        component: undefined,
                                        dropdownClassName: undefined,
                                    },
                                },
                            }}
                        />

                        {/* <label className='mb-1'>Perks of NFT</label>
                        <Editor
                            editorState={perkNfts}
                            handlePastedText={() => false}
                            required={true}
                            onEditorStateChange={setPerkNfts}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class border mt-2 p-2 bg-white text-black"
                            toolbarClassName="toolbar-class text-black"
                            toolbar={{
                                image: {
                                    urlEnabled: true,
                                    uploadEnabled: true,
                                    alignmentEnabled: true,
                                    uploadCallback: undefined,
                                    previewImage: true,
                                    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                    alt: { present: false, mandatory: false },
                                    defaultSize: {
                                        height: 'auto',
                                        width: 'auto',
                                    },
                                    fontSize: {
                                        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                                        className: undefined,
                                        component: undefined,
                                        dropdownClassName: undefined,
                                    },
                                    fontFamily: {
                                        options: ['Arial', 'sans-serif', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                                        className: undefined,
                                        component: undefined,
                                        dropdownClassName: undefined,
                                    },
                                },
                            }}
                        /> */}


                        <label className='mb-2 mt-3'>Date</label>

                        <InputGroup className="mb-3">

                            <Form.Control
                                style={{ backgroundColor: "#272d47", color: 'white' }}
                                type='date'
                                defaultValue={today}
                                name="startDate"
                                required
                                aria-label="Amount (to the nearest dollar)" />

                        </InputGroup>

                        {/* <label className='mb-1'>Start Time</label>

                        <InputGroup className="mb-3">

                            <Form.Control
                                style={{ backgroundColor: "#272d47", color: 'white' }}
                                type='time'
                                defaultValue="14:00"
                                name="startTime"
                                required
                                aria-label="Amount (to the nearest dollar)" />

                        </InputGroup> */}


                        {/* <label className='mb-1'>End Time</label>

                        <InputGroup className="mb-3">

                            <Form.Control
                                style={{ backgroundColor: "#272d47", color: 'white' }}
                                type='time'
                                defaultValue="16:00"
                                name="endTime"
                                required
                                aria-label="Amount (to the nearest dollar)" />

                        </InputGroup> */}

                        <label className='mb-1'>Experience Points</label>
                        <input
                            type="text"
                            name="experiencePoints"
                            className='border w-100 rounded mb-3 p-2'
                            style={{ backgroundColor: "#272d47", color: 'white' }}
                            required
                        />

                        {/* <label className='mb-1'>Venue</label>
                        <input
                            type="text"
                            name="venue"
                            className='border w-100 rounded mb-3 p-2'
                            style={{ backgroundColor: "#272d47", color: 'white' }}
                            required
                        /> */}

                        {/* <label className='mb-1'>Purchase Till</label>
                        <InputGroup className="mb-3" style={{ textAlign: 'left' }}>

                            <Form.Control
                                style={{ backgroundColor: "#272d47", color: 'white' }}
                                type='date'
                                defaultValue={addDays(30)}
                                name="purchaseDate"
                                required
                                value={event}
                                onChange={e => setEvent(e.target.value)}
                                aria-label="Amount (to the nearest dollar)" />

                        </InputGroup> */}

                        {/* <label className='mb-1'>Youtube Link</label>
                        <input
                            type="text"
                            name="vidLink"
                            className='border w-100 rounded mb-3 p-2'
                            style={{ backgroundColor: "#272d47", color: 'white' }}
                        /> */}


                        <div className='mx-auto text-center mt-3'>
                            <Button onClick={redirectToNftPage} type='button' style={{ backgroundColor: '#dc3545', width: '120px', fontSize: "13px" }} className='border-0 text-uppercase modal-btn ms-3 me-3 extraCare'>CANCEL</Button>
                            <Button
                                onClick={() => setSaveAsDraft(true)}
                                type='submit' style={{ backgroundColor: 'blueviolet', width: '120px', fontSize: "13px" }} className='bg-primary border-0 text-uppercase modal-btn ms-3 me-3 extraCare'>Draft</Button>
                            <Button
                                onClick={() => setSaveAsDraft(false)}
                                type='submit' style={{ backgroundColor: 'blueviolet', width: '120px', fontSize: "13px" }} className='border-0 text-uppercase modal-btn ms-3 me-3 extraCare'>publish</Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default DashboardAddNft;