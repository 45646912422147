import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import MetaData from "../../MetaData/MetaData";

const PlayToEarn = () => {
  const navigate = useNavigate();
  const [allNft, setallNft] = useState([]);
  const [totalNftValue, setTotalNftValue] = useState();
  const [nftSold, setnftSold] = useState();
  const nftValues = totalNftValue?.toLocaleString();
  const nftSoldValues = nftSold?.toLocaleString();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const dataNftSold = async () => {
      await axios
        .get("https://backend.playtoearnblockchain.com/api/v1/mint/sum")
        .then((res) => {
          setnftSold(res?.data?.TotalAmount);
        });
    };
    dataNftSold();
  }, []);

  const [slider, setSlider] = useState([1, 2, 3, 4]);
  useEffect(() => {
    const dataOfSlider = async () => {
      setLoading(true);
      await axios
        .get("https://backend.playtoearnblockchain.com/api/v1/banner/")
        .then((res) => {
          setSlider(res.data.result);
        })
        .finally(() => setLoading(false));
    };

    dataOfSlider();
  }, []);

  useEffect(() => {
    const nftValue = async () => {
      await axios
        .get("https://backend.playtoearnblockchain.com/api/v1/total-nft")
        .then((res) => {
          setTotalNftValue(res?.data?.TotalAvailabaleNft);
        });
    };
    nftValue();
  }, []);

  useEffect(() => {
    const dataIsMeal = async () => {
      await axios
        .get("https://backend.playtoearnblockchain.com/api/nft/all", {
          headers: {
            authorization: `Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54`,
          },
        })
        .then((res) => {
          setallNft(res?.data?.nft);
        });
    };
    dataIsMeal();
  }, []);

  const handlePlayGame = () => {
    navigate("/selectwinhorsechamp", { replace: true });
  };
  const removedUrl = "https://backend.celebritygames.net";

  const launchAlert = () => {
    swal({
      // title: "Success",
      text: `Coming to you soon!`,
      icon: "warning",
      button: "OK!",
      className: "modal_class_success",
    });
  };
  return (
    <div style={{ height: "auto", backgroundColor: "#130A1D" }}>
      <MetaData pageTitle={"Play Blockchain games | Buy NFT and win prizes"} pageDescription={"Playtoearnblockchain.com NFTs can be sold in marketplaces or used in our PLAY TO EARN Blockchain Games. It is a blockchain-based game that allows players to earn rewards & Purchase NFTs."}></MetaData>
      <div>
        <h1
          className="text-gradient text-center pt-5 text-uppercase"
          style={{ marginTop: "80px", fontSize: "1.75rem" }}
        >
          Play To Earn
        </h1>
        <div className="small-border bg-color-2"></div>
      </div>

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <Container>
          <div className="mt-4 mx-auto text-center">
            <img
              // src={slider[0]?.img.replace(removedUrl, "https://backend.playtoearnblockchain.com")}
              // src="https://backend.playtoearnblockchain.com/assets/1681292000502.png"
              src="/bg2.jpeg"
              className="grugHundRace"
              alt="gif"
              style={{ cursor: "pointer" }}
              onClick={() => handlePlayGame()}
            // onClick={() => (navigate("/selectwinhorsechamp"))}
            />
          </div>
          <div className="mt-4 mx-auto text-center">
            <img
              // src={slider[1]?.img?.replace(removedUrl, "https://backend.playtoearnblockchain.com")}
              // src="https://backend.playtoearnblockchain.com/assets/1681292017470.png"
              src="/bg4.jpeg"
              className="grugHundRace"
              alt="gif"
              style={{ cursor: "pointer" }}
              // onClick={() => handlePlayGame()}
              onClick={() => navigate("/dslcatchit")}
            />
          </div>
          <div className="mt-4 mx-auto text-center">
            <img
              // src={slider[2]?.img?.replace(removedUrl, "https://backend.playtoearnblockchain.com")}
                // src="https://backend.playtoearnblockchain.com/assets/1681292037122.png"
                 src="/bg1.jpeg"
              className="grugHundRace"
              alt="gif"
              style={{ cursor: "pointer" }}
              // onClick={() => handlePlayGame()}
              onClick={() => navigate("/fortunewheel")}
            />
          </div>
          <div className="mt-4 mx-auto text-center">
            <img
              // src={slider[3]?.img?.replace(removedUrl, "https://backend.playtoearnblockchain.com")}
                // src="https://backend.playtoearnblockchain.com/assets/1681310433647.jpeg"
                src="/bg3.jpeg"
              className="grugHundRace"
              alt="gif"
              style={{ cursor: "pointer" }}
              // onClick={() => handlePlayGame()}
              onClick={() => navigate("/savethehostages")}
            />
          </div>

          <div className="text-center mx-auto mt-4 w-75">
            <h4 className="text-warning" style={{ fontSize: "1.1rem" }}>
              We will be continuously adding{" "}
              <span
                // onClick={() => navigate("/launching")}
                onClick={() => navigate("/s39catchit")}
                style={{ cursor: "pointer" }}
                className="text-decoration-underline"
              >
                PLAY TO EARN Blockchain Games
              </span>
              .<br /> The games will be either skill based or chance based.
            </h4>
          </div>
        </Container>
      )}

      {allNft?.length > 0 && (
        <div>
          <>
            <p
              className="text-gradient text-center fs-6 pt-4"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/nfts")}
            >
              Types of NFTs available: {allNft?.length}
            </p>

            <p
              style={{ fontSize: "0.9rem" }}
              className="text-white text-center pt-1 mb-0"
            >
              Total value of NFTs: {nftValues} USD
            </p>
            <p
              style={{ fontSize: "0.9rem" }}
              className="text-white text-center pt-"
            >
              Total value of NFTs sold: {nftSoldValues} USD
            </p>
          </>
        </div>
      )}
    </div>
  );
};

export default PlayToEarn;
