import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { RiAdminFill } from 'react-icons/ri';
import './DashboardModalNewAdmin.css';
import 'react-phone-number-input/style.css';
import axios from 'axios';
import swal from 'sweetalert';
import { MdClose } from 'react-icons/md';
import { CelebrityContext } from '../../../context/CelebrityContext';

const DashboardModalEditCategory = (props) => {
    const {
        setIsLoadingCategory,
        modalEditCategory,
        refetch,
        setRefetch, data } = props;

        const { categoryEdited, setCategoryEdited } = useContext(CelebrityContext);


    const subEditCat = (e) => {
        e.preventDefault();

        const updateData = {
            category_name: e.target.category_name.value
            // name: req.body.name,
            // price: req.body.price
        }

        axios.put(`https://backend.playtoearnblockchain.com/api/v1/category/${data?._id}`, updateData)
                .then(res => {
                    if (res.status === 200) {
                        props.onHide();
                        setCategoryEdited(true);
                    }
                })
                .catch(error => {
                    console.log(error);
                })
            }

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header style={{ backgroundColor: "#272d47", color: 'white' }}>
                <Modal.Title id="contained-modal-title-vcenter" className='fs-5 text-light'>
                    Edit Category
                </Modal.Title>
                <MdClose onClick={props.onHide} color='#fff' size={30} style={{ cursor: 'pointer' }} />
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#272d47", color: 'white' }}>
                <div>
                    <div>
                        <form onSubmit={subEditCat}>
                            <div className="row addAdminDiv">
                                <div className="col-lg-12">

                                    <p className='mb-1'>Category Name</p>
                                    <input
                                        className='form-control'
                                        placeholder='Enter category name'
                                        type="text"
                                        name="category_name"
                                        defaultValue={data?.category_name}
                                        required
                                    />

                                </div>
                                <Modal.Footer className='mt-5'>
                                    <button type="button" className='adminBtnAdd11 text-uppercase' onClick={props.onHide}>Cancel</button>
                                    <button type="submit" className='adminBtnAdd text-uppercase'>Save</button>
                                </Modal.Footer>

                            </div>
                        </form>
                    </div>
                    <div>

                    </div>
                </div>
            </Modal.Body>

        </Modal>
    );
};

export default DashboardModalEditCategory;