import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { forwardRef, useContext, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { CelebrityContext } from '../../context/CelebrityContext';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="top" ref={ref} {...props} />;
});

const WalletModalToProfile = () => {
  const { connectToMetamaskProfile, walletModalProfile, closeWalletModalProfile, bringToProfile, user } = useContext(CelebrityContext);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.walletAddress) {
      location.pathname == "/selectwinhorsechamp" && navigate('/horsechampsbusd/gamenft')
    }
  }, [user?.walletAddress])

  useEffect(() => {
    if (bringToProfile) {
      navigate("/profile");
    }
  }, [bringToProfile]);
  return (
    <div className='dialogDiv'>
      <Dialog
        open={walletModalProfile}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeWalletModalProfile}
        aria-describedby="alert-dialog-slide-description"
        className='dialog'
      >
        <div className="dialogWallet pt-4">


          <DialogContent className='alertWalletDiv'>


            <DialogContentText id="alert-dialog-slide-description">

              <Row xs={1} md={1} className="g-2">
                <Col>
                  <Card className='walletDiv walletModal_icon'>
                    <Card.Img variant="top" src="https://i.ibb.co/vVf533V/1.png" className="imgWallet" />
                    <Card.Body>
                      <Card.Title className='walletName'>Metamask</Card.Title>
                      <button className='border-0 text-light rounded pt-1 pb-1' style={{ backgroundColor: "#ff6c09" }} onClick={() => connectToMetamaskProfile()}>Click here to Login</button>

                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <div className="contentDiv">
                <p className='contents'>You can use Binance Chain to connect.</p>
                <p className='contents'>Add Binance Chain in your Metamask as follows.</p>
                <p className='contents px-2'><a className='contents1' href="https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain" target="_any" >https://academy.binance.com/en/articles
                  /connecting-metamask-to-binance-smart-chain</a></p>
              </div>
              <p className='text-center mt-4'>
                <Button onClick={closeWalletModalProfile} className="text-white fs-6 bg-danger">Cancel</Button>
              </p>
            </DialogContentText>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};

export default WalletModalToProfile;