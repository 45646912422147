import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Paper } from '@mui/material';
import swal from 'sweetalert';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledPaper = styled(Paper)`

  max-width:780px!important;
  background:linear-gradient(to right bottom, #4e4c4c, #4e4c4c);
  color:white;

  @media screen and (max-width: 768px) {
text-align:center;
  }
`;

export default function MoneyModal({ open,setMoney, handleClose,setModalShow }) {

const navigate=useNavigate()
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        PaperComponent={StyledPaper}
        aria-describedby="alert-dialog-slide-description"

      >
        <DialogTitle className='border-white border-bottom fw-light money-title'>{"Select what you want to win"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" className='text-white pt-4'>
<Button className='money-button1' onClick={()=>{setMoney("DSL");setModalShow(true);handleClose()}}><img className='money-img' src='/dsl.jpg'></img> Win DSL (No Staking)</Button>
<Button className='money-button2'  onClick={()=>{
  navigate("gamenft")
  // swal({
  //     title: ``,
  //     text: "We are upgrading for you!",
  //     icon: "warning",
     
  //     dangerMode: true,
  //   })
  // setMoney("BUSD");setModalShow(true);handleClose()
  }}><img className='money-img' src='/busd.jpeg'></img>Win BUSD (NFT Staking)</Button>

          </DialogContentText>
        </DialogContent>
        <DialogActions className='justify-content-center'>
          <Button onClick={handleClose} className='text-danger fw-bold border-danger border-1 border'>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}