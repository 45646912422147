import React from 'react';

const Head = () => {
    return (
        <div>
            <meta property='og:image' content='https://i.ibb.co/SyYNxxM/Whats-App-Image-2022-12-11-at-4-41-40-PM.jpg' />
            <meta name="description" content="Use my affiliate code to get 10% discount in Thunivu and Varisu NFTs. You can use the NFTs to win BUSD. Try out the Play To Earn Blockchain Games." />
        </div>
    );
};

export default Head;