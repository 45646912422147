import React, { useEffect, useState } from 'react';
import { Button, Link, Typography } from "@mui/material";
import axios from 'axios';
import Loading from '../../Loading/Loading';


const DashboardBanner = () => {
    const [slider, setSlider] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [refetch, setRefetch] = useState(false);
    const [showMore, setshowMore] = useState([]);


    useEffect(() => {
        window.scrollTo(0, 0);
        if (loading) {
            return <Loading></Loading>
        }
    }, []);

    useEffect(() => {

        const dataOfSlider = async () => {
            setLoading(true);
            await axios.get("https://backend.playtoearnblockchain.com/api/v1/banner/")
                .then(res => {
                    setSlider(res.data.result);
                })
                .finally(() => setLoading(false))
        }

        dataOfSlider();

    }, [refetch])


    const subSlider = async (e, id) => {
        e.preventDefault();
        setLoading1(true)
        const img = e.target.img.files[0];
        const url = e.target.url.value;
        const formData = new FormData();

        formData.append('img', img);
        formData.append('url', url);

        await axios.put(`https://backend.playtoearnblockchain.com/api/v1/banner/${id}`, formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("adminCelebrity")}`
            }
        })
            .then(res => {
                if (res.status === 200) {
                    alert(res.data.message);
                    setRefetch(!refetch);
                    setLoading1(false)
                }
            })
            .catch(error => {
                alert(error.response.data.message);
                setLoading1(false)
            })


    }
    const addSubSlider = async (e) => {
        e.preventDefault();
        setLoading1(true)
        const img = e.target.img.files[0];
        const url = e.target.url.value;
        const formDatas = new FormData();

        formDatas.append('img', img);
        formDatas.append('url', url);

        await axios.post(`https://backend.playtoearnblockchain.com/api/v1/banner/`, formDatas, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("adminCelebrity")}`
            }
        })
            .then(res => {
                if (res.status === 200) {
                    alert(res.data.message);
                    setRefetch(!refetch);
                    setLoading1(false);
                    setshowMore(showMore => showMore.slice(0, showMore.length - 1));
                }
            })
            .catch(error => {
                alert(error.response.data.message);
                setLoading1(false)
            })


    }
    const deleteBanner = async (id) => {
        setLoading1(true)
        await axios.delete(`https://backend.playtoearnblockchain.com/api/v1/banner/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("adminCelebrity")}`
            }
        })
            .then(res => {
                if (res.status === 200) {
                    alert(res.data.message);
                    setRefetch(!refetch);
                    setLoading1(false)
                }
            })
            .catch(error => {
                alert(error.response.data.message);
                setLoading1(false)
            })
    }
    return (
        <div className="container text-light pb-5" style={{ height: loading ? "100vh" : "auto" }}>
            <h4 className='text-uppercase'>Celebrity Games Banner</h4>

            <div>
                {slider.length > 1 &&
                    slider.map((data, index) => <div key={data._id} data={data} index={index}>

                        <form onSubmit={(e) => subSlider(e, data?._id)}>
                            <h5 className='text-uppercase mt-5'>Slider {index + 1}</h5>

                            <div className='mt-4'>
                                <p style={{ marginBottom: "3px" }}>Image</p>
                                <div className="row">
                                    <div className="col-10 col-md-6">
                                        <input
                                            className='form-control mb-3'
                                            type="file"
                                            name="img"
                                            accept="image/*"
                                            required
                                        />
                                        <div className='mt-3 mb-4'>
                                            <p style={{ marginBottom: "3px" }}>Url</p>
                                            <input
                                                className='form-control mb-3'
                                                type="text"
                                                name="url"
                                                defaultValue={(data?.url) ? data?.url : ""}
                                                placeholder='Url'
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div>
                                <Button disabled={loading1} className="nftDrop text-uppercase adminBtn text-white" style={{ padding: "7px 20px", backgroundColor: loading1 ? "gray" : '#6958BE' }} type='submit'>Update now</Button>
                                <button disabled={loading1} style={{ padding: "7px 20px" }} onClick={() => deleteBanner(data?._id)} className='btn btn-danger ms-3'>DELETE</button>
                            </div>
                        </form>

                    </div>)
                }
            </div>

            <div>
                {showMore.length > 0 &&
                    showMore.map((data, index) => <div>

                        <form onSubmit={(e) => addSubSlider(e)}>
                            <h5 className='text-uppercase mt-5'>Slider {slider?.length + index + 1}</h5>

                            <div className='mt-4'>
                                <p style={{ marginBottom: "3px" }}>Image</p>
                                <div className="row">
                                    <div className="col-10 col-md-6">
                                        <input
                                            className='form-control mb-3'
                                            type="file"
                                            name="img"
                                            accept="image/*"
                                            required
                                        />
                                        <div className='mt-3 mb-4'>
                                            <p style={{ marginBottom: "3px" }}>Url</p>
                                            <input
                                                className='form-control mb-3'
                                                type="text"
                                                name="url"
                                                placeholder='Url'
                                                required
                                            />
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div>
                                <Button disabled={loading1} className="nftDrop text-uppercase adminBtn text-white" style={{ padding: "7px 20px", backgroundColor: loading1 ? "gray" : '#6958BE' }} type='submit'>Update now</Button>
                                <button disabled={loading1} style={{ padding: "7px 20px" }} onClick={() => setshowMore(showMore => showMore.slice(0, showMore.length - 1))} className='btn btn-danger ms-3'>CANCEL</button>
                            </div>
                        </form>

                    </div>)
                }
            </div>
            <div>
                <button onClick={() => setshowMore(showMore => [...showMore, "add"])} className='btn btn-primary mt-5'>ADD MORE</button>
            </div>
        </div>
    );
};

export default DashboardBanner;