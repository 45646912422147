import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Buttons from 'react-bootstrap/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { CircularProgress, Paper, Slide } from '@mui/material';
import { useEffect } from 'react';
import './Dialog.css';
import { Link, useNavigate } from 'react-router-dom';
import Info from './Info/Info';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;


    return (
        <DialogTitle sx={{ m: 0, p: 2, background: 'linear-gradient(to right bottom, #4e4c4c, #4e4c4c)' }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};
const StyledPaper = styled(Paper)`

  max-width:780px!important;
`;

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};



const PositionDialog = ({ handleClickOpen, requestFullscreen, setReplay, sendMessage, handleClose, open, positions, place, bid, myDog, racePoint, racePostInfo, nftId, setTime }) => {
    // console.log(place, "bid", bid);
    // console.log(racePoint, "racePoint");
    // console.log(myDog, "myDog");

    const [loading, setLoading] = React.useState(true)
    const [AllData, setAllData] = React.useState([])
    const [newData, setNewData] = React.useState(place)
    const [myPosition, setmyPosition] = React.useState(0)
    const [openInfo, setOpenInfo] = React.useState(false);

    useEffect(() => {
        fetch('https://backend.playtoearnblockchain.com/api/v1/latestraceBUSD', {
            headers: { "authorization": `Bearer bs2zDM01DMMEgt33CrKZt8x4abs2zDM01DMMEgt33Cr6atzL3CJdExpP4x4abs2zDM01DMMEgt33Cr` }
        })
            .then(res => res.json())
            .then(data => setAllData(data?.data[0]))
        // console.log(AllData?.race, "AllDataAllData")
        if (AllData) {
            setNewData(AllData.race);
            // console.log('places', place)
        }
    }, [AllData])


    const handleClickOpenInfo = () => {
        setOpenInfo(true);
    };

    const handleCloseInfo = () => {
        setOpenInfo(false);
    };
    // useEffect(() => {
    //     setTime(0);
    // }, [])

    // console.log(myPosition);

    const onPageLoad = () => {
        setLoading(false);
    };

    useEffect(() => {
        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    const timingOneDec = (t) => {
        const time = t;
        const q = time / 10;
        const qOneDec = q.toFixed(1);
        return qOneDec;
    }
    const navigate = useNavigate();
    useEffect(() => {
        place?.map((item, index) => { myDog.img === item.img && setmyPosition(item) })
    }, [place])

    return (

        <div>
            {/* <span className='fs-3 mb-3 text-white'  onClick={handleClickOpen}>Positions</span> */}
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className='text-center text-sm-start '
                PaperComponent={StyledPaper}
                TransitionComponent={Transition}
            >
                {loading ? <div className='bg-dark d-flex align-items-center justify-content-center' style={{ height: '60vh' }}>
                    <CircularProgress color="inherit" />
                </div> : (<>
                    <BootstrapDialogTitle className='fw-bold text-white fs-4 pe-5' id="customized-dialog-title" onClose={handleClose}>
                        Race Results
                    </BootstrapDialogTitle>
                    <DialogContent sx={{ background: 'linear-gradient(to right bottom, #4e4c4c, #4e4c4c)' }} className='border-white border-bottom-0 ' dividers>
                        {/* 'linear-gradient(to right bottom, #1b1d22, #2A2E35) */}
                        <div className='time-show'>
                            <p className='text-center text-white'>Race Start Time: {place == [] ? place[0]?.date : newData ? newData[0]?.date : ''} (SG Time)</p>
                        </div>
                        {/* <div className='position-content'>
              <img className='img-fluid img-standing' src='https://i.ibb.co/qrpd1nW/istockphoto-1352086599-612x612-removebg-preview-4.png' alt='postions'></img>
              <div className='text-white text-center firstPosition'>

                <img src={place[0]?.img} alt='dog' className='dog mb-2 dog-size'></img>

                <p className='m-0'>Strength: {place[0]?.strength}</p>
                <p>Probability: {racePoint[0]} </p>
                <p>Total Point : {place[0]?.strength + racePoint[0]} </p>
                {/* {(place[0]?.probability + .20).toFixed(2)}% 
              </div>
              <div className='text-white text-center secondPosition'>

                <img src={place[1]?.img} alt='dog' className='dog mb-2 dog-size'></img>

                <p className='m-0'>Strength: {place[1]?.strength}</p>
                <p>Probability: {racePoint[1]}</p>
                <p>Total Point : {place[1]?.strength + racePoint[1]} </p>
              </div>
              <div className='text-white text-center thirdPosition'>

                <img src={place[2]?.img} alt='dog' className='dog mb-2 dog-size'></img>

                <p className='m-0'>Strength: {place[2]?.strength}</p>
                <p>Probability: {racePoint[2]}</p>
                <p>Total Point : {place[2]?.strength + racePoint[2]} </p>
              </div>
            </div> */}
                        <div className='d-flex justify-content-center'>
                            <button className='btn btn-info mx-auto text-white' style={{ background: "#006e7a" }} onClick={() => navigate("/replaysbusd")}>REPLAY</button>
                            {/* { sendMessage("GameManager", "WebReStartBtnClicked");setReplay(true)} */}
                            {/* requestFullscreen(true) */}
                        </div>
                        <div className='text-white mt-3 my-selection '>

                            {!place ? <p className='my-selection-wrapper'>You have selected track : {myDog.track} <img alt='dog' className='dog  mx-1 dog-size mb-1' src={myDog.img}></img> {myDog.gender} <br /> Your position is : {myPosition?.position} and you have won {myPosition?.prize?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} BUSD
                            </p> : <p className='my-selection-wrapper'>You have selected track : {AllData?.selectedDog?.track} <img alt='dog' className='dog  mx-1 dog-size mb-1' src={AllData?.selectedDog?.img}></img> {AllData?.selectedDog?.gender} <br /> Your position is : {newData?.filter(res => res.img == AllData?.selectedDog?.img)[0]?.position} and you have won {newData?.filter(res => res.img == AllData?.selectedDog?.img)[0]?.prize?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} BUSD
                            </p>}
                        </div>

                        <p className='text-white my-2 text-center d-block d-md-none'>
                            {/* <img className='arrow me-2' src="https://img.icons8.com/nolan/64/arrow-pointing-left.png"/> */}
                            {/* <img className='arrow me-2' src="https://img.icons8.com/office/48/null/less-than.png"/> */}
                            <i class="fa-solid fa-less-than arrow"></i>
                            Scroll Sideways
                            <i class="fa-solid fa-greater-than arrow"></i>
                            {/* <img className='arrow ms-2' src="https://img.icons8.com/office/48/null/more-than.png"/> */}
                            {/* <img className='img-fluid' src="assets/images/icons8-arrow.gif"/> */}
                        </p>
                        <div className=" text-start table-responsive">
                            <table className="table table-borderless ">
                                <thead>
                                    <tr className='text-white mb-4 position-table-header'>
                                        <th scope="col">Position</th>
                                        <th scope="col">Track</th>
                                        <th scope="col">Horse</th>
                                        <th scope="col">Strength</th>
                                        <th scope="col">Probability</th>
                                        <th scope="col">Points</th>
                                        <th scope="col">Timing</th>
                                        <th scope="col">Won(BUSD)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        newData?.map((item, index) =>
                                            <tr className={`text-white position-table-body ${!place ? (myDog.img === item.img) ? "grayanimation" : "" : (AllData?.selectedDog?.img == item?.img) ? "grayanimation" : ""}`} key={index} style={{ backgroundColor: (!place) ? (myDog.img === item.img) ? "gray" : "" : (AllData?.selectedDog?.img == item?.img) ? "gray" : "" }}>
                                                <td scope="row">{index + 1}
                                                    {!place ? (myDog.img === item.img) && index < 3 && <Buttons
                                                        onClick={() => navigate("/claimbusd", { state: { nftId: nftId } })} className='ms-2 claim-btn btn-primary p-1 rounded-3'>Claim</Buttons> : (AllData?.selectedDog?.img == item?.img) && index < 3 && <Buttons onClick={() => navigate("/claimbusd", { state: { nftId: nftId } })} className='ms-2 claim-btn btn-primary p-1 rounded-3'>Claim</Buttons>}
                                                </td>
                                                <td>
                                                    {item?.track}

                                                </td>
                                                <td className='text-start'>
                                                    {/* handleAlignDogResult */}
                                                    <img src={item?.img} alt='dog' className='dog mb-2 dog-size d-block'></img> <span className='hideSm '>{item?.gender}</span><span className='hideLg text-start'>{item?.gender}</span>
                                                </td>
                                                <td>{item?.strength}</td>
                                                <td>{racePoint[index]}</td>
                                                <td>{item?.strength + racePoint[index]}</td>
                                                <td>{timingOneDec(item?.time)}s</td>
                                                <td>{item?.prize?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} BUSD <br /> {!place ? ((myDog.img === item.img) && index < 3 && <i onClick={handleClickOpenInfo} class="fas fa-info-circle text-white"></i>) :
                                                    ((item?.img == AllData?.selectedDog?.img) && index < 3 && <i onClick={handleClickOpenInfo} class="fas fa-info-circle text-white"></i>)}</td>

                                            </tr>
                                        )
                                    }

                                </tbody>
                            </table>
                        </div>
                        <div>

                        </div>

                    </DialogContent>
                    <Info
                        handleClose={handleCloseInfo}
                        open={openInfo}
                        setOpen={setOpenInfo}
                    ></Info>
                </>
                )}
            </BootstrapDialog>
        </div >

    );
};

export default PositionDialog;