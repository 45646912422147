import { ethers, Contract, BigNumber } from "ethers";
import { createContext, useEffect, useState } from "react";
// import 'dotenv/config';
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import swal from "sweetalert";
import mint from "../utils/MintNFT.json";
import usdsc from "../utils/USDSC.json";
import dslmainnet from "../utils/DSLMainnet.json";
import { MintContractAddress, MintContractAbi } from "../utils/constant";
const DSLtokenAddressMainnet1 = "0x4A1530Fb85BdB9250Db2bE251584874179eB8Dc5";
const DSLtokenABIMainnet1 = dslmainnet.abi;
const USDSCtokenAddressMainnet1 = "0x13b852e276f10281C72ccF33EdF81d81DD198Aae";
const USDSCtokenABIMainnet1 = usdsc.abi;
const mintAddressTestnet1 = "0x8e22dF0A8997f9FA2be376B3eBA54e79A89A5C6A";

export const CelebrityContext = createContext();

const { ethereum } = window;

//Celebrit provider Context
export default function CelebrityProvider({ children }) {
  const [loginModal, setLoginModal] = useState(false);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [accountChangeLoading, setAccountChangeLoading] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [chain, setChain] = useState(null);
  const [walletModal, setWalletModal] = useState(false);
  const [metamaskBalance, setMetamaskBalance] = useState({});
  const [metamaskBalanceLoading, setMetamaskBalanceLoading] = useState(false);
  const [coinbaseModal, setCoinbaseModal] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const [userRefetch, setUserRefetch] = useState(false);
  const [bringToProfile, setBringToProfile] = useState("");
  // backend supply
  const [RPC, setRpc] = useState("");
  const [chainId, setChainId] = useState("");
  const [walletModalProfile, setWalletModalProfile] = useState(false);
  const [abi, setAbi] = useState([]);
  const [mintABITestnet, setMintABITestnet] = useState([]);
  const [mintAddressTestnet, setMintAddressTestnet] = useState([]);
  const [USDSCtokenAddressMainnet, setUSDSCtokenAddressMainnet] = useState([]);
  const [USDSCtokenABIMainnet, setUSDSCtokenABIMainnet] = useState([]);
  const [DSLtokenAddressMainnet, setDSLtokenAddressMainnet] = useState([]);
  const [DSLtokenABIMainnet, setDSLtokenABIMainnet] = useState([]);

  //data from backend

  // fetches  and sets data for the states
  // changes on localstorage account change
  useEffect(() => {
    const fetchingDataFromBackend = async () => {
      await axios
        .get("https://backend.playtoearnblockchain.com/api/v1/constant/", {
          headers: {
            authorization: `Bearer ${localStorage.getItem("tokenCelebrity")}`,
          },
        })
        .then((res) => {
          setAbi(res?.data?.nftabi);
          setMintAddressTestnet(res?.data?.mintAddressTestnet);
          setMintABITestnet(res?.data?.mintABITestnet);
          setUSDSCtokenAddressMainnet(res?.data?.USDSCtokenAddressMainnet);
          setUSDSCtokenABIMainnet(res?.data?.USDSCtokenABIMainnet);
          setDSLtokenAddressMainnet(res?.data?.DSLtokenAddressMainnet);
          setDSLtokenABIMainnet(res?.data?.DSLtokenABIMainnet);
          setRpc(res?.data?.RPC);
          setChainId(res?.data?.chainId);
        });
    };
    fetchingDataFromBackend();
  }, [localStorage.getItem("tokenCelebrity")]);

  // gets contract for NFT to Mint
  // This is for Mainnet not for Testnet
  const getMintContractTestnet = () => {
    console.log("ABI HERE....................", mint.abi);
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const MintNFTContract = new ethers.Contract(
      mintAddressTestnet1,
      mint.abi,
      signer
    );

    console.log("MintNFTContract", MintNFTContract);

    return MintNFTContract;
  };

  // gets contract for USDC to Mint
  // This is for Mainnet not for Testnet
  const getUSDSCtokenContractMainnet = () => {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const tokenContract = new ethers.Contract(
      USDSCtokenAddressMainnet1,
      USDSCtokenABIMainnet1,
      signer
    );

    return tokenContract;
  };

  // gets contract for DSA to Mint
  // This is for Mainnet not for Testnet
  const getDSLtokenContractMainnet = () => {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const tokenContract = new ethers.Contract(
      DSLtokenAddressMainnet1,
      DSLtokenABIMainnet1,
      signer
    );

    return tokenContract;
  };

  // gives provider and NFT Contract
  const getAllItemBlockchain = async () => {
    const provider = new ethers.providers.JsonRpcProvider(RPC);
    return {
      provider,
      // deployer: new ethers.Wallet(private_key, provider),
      NFTContract: new Contract(mintAddressTestnet, abi, provider),
    };
  };

  // No idea why it is using useless states here
  const genSignature = async (types, voucher, auth) => {
    const domain = {
      name: "NFT-Voucher",
      version: "1",
      verifyingContract: auth.contract,
      chainId: chainId,
    };
    const BuyNFTVoucher = {
      id: voucher.id,
      price: voucher.price,
      tokenAddress: voucher.tokenAddress,
      nonce: voucher.nonce,
    };

    // const signature = await auth.signer._signTypedData(domain, types, BuyNFTVoucher);

    return {
      ...voucher,
      // signature,
    };
  };

  // Function To Buy NFTS
  const signBuyFunction = async (id, price, tokenAddress, refAddress, uri) => {
    const contracts = await getAllItemBlockchain();
    const auth = {
      signer: contracts.deployer,
      contract: contracts.NFTContract.address,
    };

    const types = {
      BuyNFTStruct: [
        { name: "id", type: "string" },
        { name: "price", type: "uint256" },
        { name: "tokenAddress", type: "address" },
        { name: "nonce", type: "string" },
      ],
    };
    console.log("111111111111111: ", id, price, tokenAddress, refAddress, uri);

    // Generate nonce as transaction id
    const nonce = uuidv4();
    const voucher = {
      id: id,
      price: BigNumber.from(price),
      tokenAddress: tokenAddress,
      refAddress:
        refAddress.length !== 0
          ? refAddress
          : "0x0000000000000000000000000000000000000000",
      nonce: nonce,
      uri: uri,
    };
    return {
      ...(await genSignature(types, voucher, auth)),
      price: price.toString(),
    };
  };

  // Their names explains their purposes
  const openWalletModal = () => {
    (!user?.walletAddress || user?.walletAddress === "undefined") &&
      setWalletModal(true);
  };
  const closeWalletModal = () => setWalletModal(false);

  const openCoinbaseModal = () => {
    setCoinbaseModal(true);
  };
  const closeCoinbaseModal = () => setCoinbaseModal(false);

  const openLoginModal = () => setLoginModal(true);
  const closeLoginModal = () => setLoginModal(false);

  useEffect(() => {
    checkIfWalletIsConnect();
  }, []);

  // get balances of the connect wallet
  const getBalanceMainnet = async () => {
    const USDSCtokenContract = getUSDSCtokenContractMainnet();
    const DSLtokenContract = getDSLtokenContractMainnet();
    const USDSCbalance = await USDSCtokenContract.balanceOf(currentAccount);
    const USDSCamount = ethers.utils.formatEther(USDSCbalance);
    const DSLbalance = await DSLtokenContract.balanceOf(currentAccount);
    const DSLamount = ethers.utils.formatEther(DSLbalance);
    const provider = new ethers.providers.Web3Provider(ethereum);
    const balance1 = await provider.getBalance(currentAccount);
    const metamask = {
      usdsc: USDSCamount,
      bnb: ethers.utils.formatEther(balance1),
      dsl: DSLamount,
    };
    return setMetamaskBalance(metamask);
  };

  //funtion used to mint NFT using BNB
  const mintTicketNFTTestnetBNB = async (data) => {
    try {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = await provider.getBalance(currentAccount);
      console.log("signer here", Number(signer));
      if (ethereum) {
        const chainid = await window.ethereum.request({
          method: "eth_chainId",
        });
        console.log("This is Chain ID: ", chainid);
        if (chainid === "0x38") {
          console.log("issues here");
          const MintNFTContract = getMintContractTestnet();
          console.log(MintNFTContract);
          const provider = new ethers.providers.Web3Provider(ethereum);
          // const parsedAmount = ethers.utils.parseEther(mintPrice);
          const admin = "0x626D20125da6a371aA48023bF9dad94BD66588F7";

          const object = {
            id: data.id,
            price: data.price,
            tokenAddress: data.tokenAddress,
            refAddress: data.refAddress,
            nonce: data.nonce,
            uri: data.uri,
            signature: data.signature,
          };
          // console.log("valueeee", object);

          const Val = await MintNFTContract.buyNFT(object, {
            value: BigNumber.from(object.price),
          });
          await Val.wait();
          let txn_test = await provider.getTransaction(Val.hash);
          while (txn_test.blockNumber === null) {
            // console.log("Minting...");
            txn_test = await provider.getTransaction(Val.hash);
          }
          console.log("txn_test.blockNumber: " + txn_test.blockNumber);
          let mint_hash = "https://bscscan.com/tx/" + Val.hash;
          // console.log("Mint link: " + mint_hash);
          const ID = await MintNFTContract.totalSupply();
          // console.log("Token ID: ", ID.toString());
          // console.log("this is Token ID: 10000" + ID.toString());
          // console.log("this is Contract Address: : " + abi);

          let details = { mint: mint_hash, Id: ID };
          // console.log(details);

          if (ID.toString() < 10) {
            return {
              mint_hash: mint_hash,
              ID: "100000" + ID.toString(),
              mintPrice: data.price,
            };
          } else {
            return {
              mint_hash: mint_hash,
              ID: "10000" + ID.toString(),
              mintPrice: data.price,
            };
          }
        } else {
          console.log("No ethereum object");
        }
      }
    } catch (error) {
      throw error;
    }
  };

  //funtion used to mint NFT using USDC
  const mintTicketNFTTestnetUSDSC = async (data) => {
    try {
      if (ethereum) {
        const MintNFTContract = getMintContractTestnet();
        const USDSCTokenContract = getUSDSCtokenContractMainnet();
        // console.log(USDSCTokenContract);
        const provider = new ethers.providers.Web3Provider(ethereum);
        // console.log(
        //   "USDC",
        //   MintNFTContract.address,
        //   BigNumber.from(ethers.constants.MaxUint256)
        // );
        console.log("USDC contract", USDSCTokenContract);
        const payment = await USDSCTokenContract.approve(
          MintNFTContract.address,
          BigNumber.from(ethers.constants.MaxUint256)
        );
        let payment_test = await provider.getTransaction(payment.hash);
        while (payment_test.blockNumber === null) {
          // console.log("Approve In Progress...");
          payment_test = await provider.getTransaction(payment.hash);
        }
        // console.log(payment_test.blockNumber);
        let payment_hash = "https://bscscan.com/tx/" + payment.hash;
        // console.log("Payment link: " + payment_hash);
        const object = {
          id: data.id,
          price: data.price,
          tokenAddress: data.tokenAddress,
          refAddress: data.refAddress,
          nonce: data.nonce,
          uri: data.uri,
          signature: data.signature,
        };
        // console.log("valueeee", object);

        const Val = await MintNFTContract.buyNFT(object);
        await Val.wait();
        let txn_test = await provider.getTransaction(Val.hash);
        if (txn_test) {
          const wrapper = document.createElement("div");
          wrapper.innerHTML = `<p></p><div class="loaders"></div> <p class="wait"><b>Transaction Pending...<b></p> `;
          swal({
            content: wrapper,
            button: false,
            className: "modal_class_success",
          });
          while (txn_test.blockNumber === null) {
            // console.log("Minting...");
            txn_test = await provider.getTransaction(Val.hash);
          }
          // console.log("txn_test.blockNumber: " + txn_test.blockNumber);
        }
        const ID = await MintNFTContract.totalSupply();
        // console.log(ID.toString());
        let mint_hash = "https://bscscan.com/tx/" + Val.hash;
        // console.log("Mint link: " + mint_hash);

        if (ID.toString() < 10) {
          return {
            mint_hash: mint_hash,
            ID: "100000" + ID.toString(),
            mintPrice: data.price,
          };
        } else {
          return {
            mint_hash: mint_hash,
            ID: "10000" + ID.toString(),
            mintPrice: data.price,
          };
        }
      }
    } catch (error) {
      console.log(error);
      // console.log("No ethereum object");
      //setRequestLoading(false);
      if (error.code === -32603) {
        swal({
          title: "Attention",
          text: "Insufficient funds for minting!",
          icon: "warning",
          button: "OK",
          // dangerMode: true,
          className: "modal_class_success",
        });
      } else {
        swal({
          title: "Attention",
          text: "Minting Failed",
          icon: "warning",
          button: "OK",
          // dangerMode: true,
          className: "modal_class_success",
        });
      }
      throw error;
    }
  };

  //funtion used to mint NFT using DSL
  const mintTicketNFTTestnetDSL = async (data) => {
    try {
      if (ethereum) {
        const MintNFTContract = getMintContractTestnet();
        const USDSCTokenContract = getDSLtokenContractMainnet();
        const provider = new ethers.providers.Web3Provider(ethereum);
        // const parsedAmount = ethers.utils.parseEther(mintPrice);
        // const admin = "0x626D20125da6a371aA48023bF9dad94BD66588F7";
        // const gasLimit = await USDSCTokenContract.estimateGas.transfer(
        //   admin,
        //   parsedAmount._hex
        // );
        // const gasPrice = await await provider.getGasPrice();
        // console.log("USDC", MintNFTContract.address,
        //   BigNumber.from(ethers.constants.MaxUint256))
        // console.log("usdc: " + USDSCTokenContract)
        const payment = await USDSCTokenContract.approve(
          MintNFTContract.address,
          BigNumber.from(ethers.constants.MaxUint256)
        );
        let payment_test = await provider.getTransaction(payment.hash);
        while (payment_test.blockNumber === null) {
          // console.log("Approve In Progress...");
          payment_test = await provider.getTransaction(payment.hash);
        }
        // console.log(payment_test.blockNumber);
        let payment_hash = "https://bscscan.com/tx/" + payment.hash;
        // console.log("Payment link: " + payment_hash);
        // const recipient = currentAccount;
        // const Val = await MintNFTContract.mint(uriNft, recipient);
        const object = {
          id: data.id,
          price: data.price,
          tokenAddress: data.tokenAddress,
          refAddress: data.refAddress,
          nonce: data.nonce,
          uri: data.uri,
          signature: data.signature,
        };
        // console.log("valueeee", object)

        const Val = await MintNFTContract.buyNFT(object);
        await Val.wait();
        let txn_test = await provider.getTransaction(Val.hash);
        if (txn_test) {
          while (txn_test.blockNumber === null) {
            // console.log("Minting...");
            txn_test = await provider.getTransaction(Val.hash);
          }
          // console.log("txn_test.blockNumber: " + txn_test.blockNumber);
        }
        const ID = await MintNFTContract.totalSupply();
        // console.log(ID.toString());
        let mint_hash = "https://bscscan.com/tx/" + Val.hash;
        // console.log("Mint link: " + mint_hash);

        if (ID.toString() < 10) {
          return {
            mint_hash: mint_hash,
            ID: "100000" + ID.toString(),
            mintPrice: data.price,
          };
        } else {
          return {
            mint_hash: mint_hash,
            ID: "10000" + ID.toString(),
            mintPrice: data.price,
          };
        }
      }
    } catch (error) {
      console.log(error);
      // console.log("No ethereum object");
      //setRequestLoading(false);
      if (error.code === -32603) {
        swal({
          title: "Attention",
          text: "Insufficient funds for minting!",
          icon: "warning",
          button: "OK",
          className: "modal_class_success",
        });
      } else {
        swal({
          title: "Attention",
          text: "Minting Failed",
          icon: "warning",
          button: "OK",
          className: "modal_class_success",
        });
      }
      throw error;
    }
  };

  const openWalletModalProfile = () => {
    if (!user?.walletAddress || user?.walletAddress === "undefined") {
      setWalletModalProfile(true);
      // console.log("clicked");
    }
    // console.log("Outside condition");
  };
  const closeWalletModalProfile = () => setWalletModalProfile(false);

  const checkIfWalletIsConnect = async () => {
    try {
      if (!ethereum) {
        return console.log("please use metamask");
      }

      const accounts = await ethereum.request({ method: "eth_accounts" });

      if (accounts.length) {
        setCurrentAccount(accounts[0]);
        const chainid = await window.ethereum.request({
          method: "eth_chainId",
        });
        setChain(chainid);
      } else {
        // console.log("No accounts found");
      }
    } catch (error) {
      console.log(error);
    }
  };

  //used to get data on account change in profile
  const tryThis = async () => {
    let provider = window.ethereum;
    try {
      const chainid = await provider.request({
        method: "eth_chainId",
      });
      console.log("This is Chain ID: ", chainid);
      setChain(chainid);
      if (chainid === "0x38") {
        setAccountChangeLoading(true);
        const accounts = await provider.request({
          method: "eth_requestAccounts",
        });
        // console.log(accounts[0]);
        setCurrentAccount(accounts[0]);

        await axios
          .post(`https://backend.playtoearnblockchain.com/api/v1/user/`, {
            walletAddress: accounts[0],
          })
          .then((res) => {
            if (res.data.user) {
              setUser(res.data.user);
              getBalanceMainnet();

              setLoading(false);
              closeWalletModal();
              localStorage.setItem("tokenCelebrity", res.data.token);
              const wrapper = document.createElement("div");
              wrapper.innerHTML = `<p class='text-break text-white fs-6'>You have successfully logged in with <br/>Binance Chain.</p>`;
              console.log("checking how many times this gets");
              setAccountChangeLoading(false);
              return null;
            } else {
              console.log("NO data Here");
              return null;
            }
          });
      } else {
        console.log("Please Switch to Binance Chain");
        swal({
          title: "Attention",
          text: "Please change to Binance Chain (Mainnet) before connecting.",
          icon: "warning",
          button: "OK",
          dangerMode: true,
          className: "modal_class",
        });
      }
    } catch (error) {
      throw new Error("User Rejected");
    }
  };

  //used to connect to the wallet (Metamask)
  const connectToMetamaskProfile = async () => {
    connectToMetamask();
  };
  const connectToMetamask = async () => {
    getBalanceMainnet();
    if (typeof window.ethereum === "undefined") {
      // ask the user to install the extension
      return swal({
        title: "Attention",
        text: "Please open this website with wallet browsers",
        icon: "warning",
        button: "OK",
        dangerMode: true,
        className: "modal_class",
      });
    }
    let provider = null;
    if (typeof window.ethereum !== "undefined") {
      let provider = window.ethereum;
      // edge case if MM and CBW are both installed
      if (window.ethereum.providers?.length) {
        window.ethereum.providers.forEach(async (p) => {
          if (p.isMetaMask) provider = p;
        });
      }
      try {
        const chainid = await provider.request({
          method: "eth_chainId",
        });
        console.log("This is Chain ID: ", chainid);
        setChain(chainid);
        if (chainid === "0x38") {
          const accounts = await provider.request({
            method: "eth_requestAccounts",
          });
          console.log(accounts[0]);
          setCurrentAccount(accounts[0]);

          await axios
            .post(`https://backend.playtoearnblockchain.com/api/v1/user/`, {
              walletAddress: accounts[0],
            })
            .then((res) => {
              if (res.data.user) {
                setUser(res.data.user);
                getBalanceMainnet();

                setLoading(false);
                closeWalletModal();
                localStorage.setItem("tokenCelebrity", res.data.token);
                const wrapper = document.createElement("div");
                wrapper.innerHTML = `<p class='text-break text-white fs-6'>You have successfully logged in with <br/>Binance Chain.</p>`;
                return swal({
                  title: "Success",
                  // text: "You have succesfully logged in with Binance Chain.",
                  content: wrapper,
                  icon: "success",
                  button: "OK",
                  // dangerMode: true,
                  className: "modal_class_success",
                });
              }
            });
        } else {
          console.log("Please Switch to Binance Chain");
          swal({
            title: "Attention",
            text: "Please change to Binance Chain (Mainnet) before connecting.",
            icon: "warning",
            button: "OK",
            dangerMode: true,
            className: "modal_class",
          });
        }
      } catch (error) {
        throw new Error("User Rejected");
      }
    } else {
      throw new Error("No MetaMask Wallet found");
    }
    console.log("MetaMask provider", provider);
    return provider;
  };

  //NFTs searching
  //used in search bar
  const searchNftTitle = (isMeal, searchInput) => {
    // let searchtext;
    const trimmedInput = searchInput.trim();

    const matchedNfts = isMeal.filter(
      (element) =>
        element.name.toLowerCase().includes(trimmedInput.toLowerCase()) ||
        element.type.toLowerCase().includes(trimmedInput.toLowerCase())
    );
    console.log("from context", matchedNfts, searchInput.trim());
    if (matchedNfts.length > 0 && !trimmedInput == " ") {
      setSearchResults(matchedNfts);
    } else {
      setSearchResults("notFound");
    }
  };

  //used to remove data stored in local storage and logout
  const logOut = async () => {
    setBringToProfile(false);
    setCurrentAccount(null);

    setUser({});
    localStorage.removeItem("tokenCelebrity");
    localStorage.removeItem("token");
  };

  //changes on user account change
  useEffect(() => {
    if (currentAccount && localStorage.getItem("tokenCelebrity")) {
      setLoading(true);
      axios
        .get(`https://backend.playtoearnblockchain.com/api/v1/user/`, {
          headers: {
            authorization: `Bearer ${localStorage.getItem("tokenCelebrity")}`,
          },
        })
        .then((res) => {
          setUser(res.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
          getBalanceMainnet();
        });
      // setUserRefetch(false);
    }
  }, [currentAccount, userRefetch, localStorage.getItem("tokenCelebrity")]);

  useEffect(() => {
    if (requestLoading) {
      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p></p><div class="loaders"></div> <p class="wait"><b>Please wait, don't exit screen.<b></p> `;
      swal({
        content: wrapper,
        button: false,
        className: "modal_class_success",
      });
    }
  }, [requestLoading]);

  const getMintContractInteraction = () => {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const MINT = new ethers.Contract(
      MintContractAddress,
      MintContractAbi,
      signer
    );
    return MINT;
  };

  const getNFTPrice = async () => {
    // try {
    const res = await getMintContractInteraction().mintPrice();
    let data = res.toString();
    const getPriceInWei = await getMintContractInteraction().getPrice(data);
    let priceInWei = ethers.utils.formatEther(getPriceInWei);
    let priceToString = priceInWei.toString();
    return priceToString;
    // } catch (err) {
    //   console.log("Error in getNFTPrice", err);
    // }
  };

  const mintNft = async (data, referralAddressPara) => {
    console.log("-->");
    let state = { status: "", message: "", return: "", data: "" };
    try {
      let referralAddress = referralAddressPara
        ? referralAddressPara
        : "0x0000000000000000000000000000000000000000";
      let mintingContract = getMintContractInteraction();
      console.log("1-->");

      let priceOfNFT = await getNFTPrice();
      console.log("2-->");

      let returningPriceNFT = String(Number(priceOfNFT) + 0.00101);

      console.log("3-->");

      const provider = new ethers.providers.Web3Provider(ethereum);
      console.log("4-->");

      var temp = data.uri.replace(
        "https://backend.playtoearnblockchain.com/assets/json/",
        ""
      );

      let transaction;
      console.log("5-->");

      try {
        const estimatedGasLimit = await mintingContract.estimateGas.buyNFT(
          referralAddress,
          temp,
          {
            from: currentAccount,
            value: ethers.utils.parseEther(`${returningPriceNFT}`),
          }
        );

        transaction = await mintingContract.buyNFT(referralAddress, temp, {
          from: currentAccount,
          value: ethers.utils.parseEther(`${returningPriceNFT}`),
          // gasLimit: 1000000,
          gasLimit: estimatedGasLimit,
          gasPrice: null,
        });
        console.log("6-->");
      } catch (error) {
        console.log("mint failed", error.code);
        if (error.code === 4001) {
          console.log("User rejected the transaction.");
          state.status = "warning";
          state.message = "User rejected the transaction.";
          state.return = false;
          return state;
        } else if (error.code === -32603) {
          console.log("insufficient funds for transfer");
          state.status = "warning";
          state.message = "Insufficient funds for transfer.";
          state.return = false;
          return state;
        } else if (error.code === "UNPREDICTABLE_GAS_LIMIT") {
          console.log("🚀 ~ cant estimate gas :");
          transaction = await mintingContract.buyNFT(referralAddress, temp, {
            from: currentAccount,
            value: ethers.utils.parseEther(`${returningPriceNFT}`),
            gasLimit: 1000000,
            gasPrice: null,
          });
        } else {
          console.log("mint failed", error);
          state.status = "warning";
          state.message = "Transaction failed.";
          state.return = false;
          return state;
        }
      }

      transaction.wait();
      let txn_test = await provider.getTransaction(transaction.hash);
      while (txn_test.blockNumber === null) {
        console.log("Minting...");
        txn_test = await provider.getTransaction(transaction.hash);
      }
      const receipt = await provider.getTransactionReceipt(txn_test.hash);

      if (receipt.status === 1) {
        let mint_hash = "https://bscscan.com/tx/" + transaction.hash;
        let ID = await mintingContract.totalSupply();
        let IdNumber = 1000000 + parseInt(ID);

        let object = {
          mint_hash,
          IdNumber,
          // mintPrice: data.price,
        };
        state.status = "Success";
        state.message = "Transaction successful";
        state.data = object;
        state.return = true;
        return state;
      } else if (receipt.status === 0) {
        console.log("Transaction unsuccessful");
        state.status = "warning";
        state.message = "Transaction unsuccessful";
        state.return = false;
        return state;
      }
    } catch (error) {
      console.log("mint failed", error.code);
      if (error.code === 4001) {
        console.log("User rejected the transaction.");
        state.status = "warning";
        state.message = "User rejected the transaction.";
        state.return = false;
        return state;
      } else if (error.code === -32603) {
        console.log("insufficient funds for transfer");
        state.status = "warning";
        state.message = "Insufficient funds for transfer.";
        state.return = false;
        return state;
      } else {
        console.log("mint failed", error);
        state.status = "warning";
        state.message = "Transaction failed.";
        state.return = false;
        return state;
      }
    }
  };

  return (
    <CelebrityContext.Provider
      value={{
        loginModal,
        mintAddressTestnet,
        openLoginModal,
        requestLoading,
        signBuyFunction,
        closeLoginModal,
        currentAccount,
        loading,
        DSLtokenAddressMainnet,
        USDSCtokenAddressMainnet,
        user,
        walletModal,
        openWalletModal,
        closeWalletModal,
        setUser,
        chain,
        setRequestLoading,
        logOut,
        mintTicketNFTTestnetBNB,
        mintTicketNFTTestnetUSDSC,
        mintTicketNFTTestnetDSL,
        metamaskBalance,
        metamaskBalanceLoading,
        setMetamaskBalanceLoading,
        getBalanceMainnet,
        connectToMetamask,
        userRefetch,
        openWalletModalProfile,
        closeWalletModalProfile,
        walletModalProfile,
        setUserRefetch,
        coinbaseModal,
        connectToMetamaskProfile,
        openCoinbaseModal,
        closeCoinbaseModal,
        nftabi: abi,
        searchNftTitle,
        bringToProfile,
        setSearchResults,
        searchResults,
        tryThis,
        setAccountChangeLoading,
        accountChangeLoading,
        //---blockchain Work
        mintNft,
        MintContractAddress,
      }}
    >
      {children}
    </CelebrityContext.Provider>
  );
}
