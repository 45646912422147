import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InterestModal from '../../layout/InterestModal';

const Experience = () => {
    const [autoOpen, setAutoOpen] = useState(false);
    const AutoOpenModalClick = () => setAutoOpen(true);
    const navigate = useNavigate();

    return (
        <div className="pb-5" style={{ backgroundColor: '#1A1A25', height: "100vh" }}>
            <div>
                <h1 className="text-gradient text-center pt-5 text-uppercase" style={{ marginTop: '80px', fontSize: "1.75rem" }}>Experience our metaverse</h1>
                <div className="small-border bg-color-2"></div>
            </div>
            <div className='container mx-auto text-center'>
                <div className="reg_button-landXp ">
                    <button onClick={AutoOpenModalClick} className="banner-button wow fadeInUp" data-wow-delay="1.25s">Register your interest</button>
                </div>
                <div className="reg_button-landXp">
                    <p className="text-warning text-center handleStandTextWidth" data-wow-delay=".5s">Stand a chance to win one of the 10 Darkkey Nagaraja Celebrity NFTs we give away.</p>
                </div>
                <p className='text-center fs-4' style={{ color: "#ad1ff9", cursor: "pointer" }} onClick={() => navigate(-1)}>Back</p>
            </div>
            <InterestModal autoOpen={autoOpen} setAutoOpen={setAutoOpen} />
        </div>
    );
};

export default Experience;