import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import Buttons from 'react-bootstrap/Button';
import Box from '@mui/material/Box';
import '../Racing/Racing.css';
import { Autocomplete, Button, CircularProgress, TextField, Tooltip } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import { Form } from 'react-bootstrap';
import swal from 'sweetalert';
import Timer from 'react-compound-timer/build';
import { useTimer } from "reactjs-countdown-hook";
import axios from 'axios';
import moment from 'moment';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Unity, useUnityContext } from "react-unity-webgl";
import Fullscreens from 'react-fullscreen-crossbrowser';
import Fullscreen2 from 'react-fullscreen-crossbrowser';
import { CelebrityContext } from '../../../context/CelebrityContext';

import Stopwatch from '../Racing/StopWatch/Stopwatch';
import PositionDialog from '../Racing/PositionDialog';
import SelectionModal from '../Racing/SelectionModal/SelectionModal';
import Game3D from '../Racing/Game3D/Game3D';
import { isMobile } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';



const useStyles = makeStyles((theme) => ({
    paper: {
        background: 'linear-gradient(to right bottom, #1b1d22, #2A2E35)',
        color: 'white'
    }
}));

const SaveTheHostAges = () => {
    const { unityProvider, isLoaded, loadingProgression, addEventListener, removeEventListener, sendMessage, requestFullscreen
    } = useUnityContext({
        loaderUrl: './Build/FPS Shooting 3.0 - Brotli.loader.js',
        dataUrl: "./Build/FPS Shooting 3.0 - Brotli.data.unityweb",
        frameworkUrl: "./Build/FPS Shooting 3.0 - Brotli.framework.js.unityweb",
        codeUrl: "./Build/FPS Shooting 3.0 - Brotli.wasm.unityweb",
    });

    // console.log("unity-game", useUnityContext({
    // loaderUrl: './Build/Celebrity 1.12.loader.js',
    // dataUrl: "./Build/Celebrity 1.12.unityweb",
    // frameworkUrl: "./Build/Celebrity 1.12.framework.js.unityweb",
    // codeUrl: "./Build/Celebrity 1.12.wasm.unityweb",
    //     loaderUrl: './Build/Celebrity 1.9 - Brotli.loader.js',
    //     dataUrl: "./Build/Celebrity 1.9 - Brotli.data.unityweb",
    //     frameworkUrl: "./Build/Celebrity 1.9 - Brotli.framework.js.unityweb",
    //     codeUrl: "./Build/Celebrity 1.9 - Brotli.wasm.unityweb",
    // }))

    const [animationHandle, setAnimationHandle] = useState(false);
    const [animationRestart, setAnimationRestart] = useState(false);
    const [animationPause, setAnimationPause] = useState(false);
    const [speed, setSpeed] = useState([])
    const [positions, setPositions] = useState([])
    const [racePost, setRacePost] = useState([])
    const [place, setPlace] = useState([])
    const [timeTricker, setTimeTricker] = useState(undefined)
    const [clocktimeTricker, setClockTimeTricker] = useState(undefined)
    const [catAnim, setCatAnim] = useState(undefined)
    const [position, setPosition] = useState(false)
    let speedCount = [];
    const [firstInRace, setFirstInRace] = useState('');
    const [lastInRace, setLastInRace] = useState('')
    const [email, setEmail] = useState('')
    const [open, setOpen] = React.useState(false);
    const [focus, setFocus] = React.useState(false);
    const [focusBid, setFocusBid] = React.useState(false);
    const [countdown, setCountdown] = React.useState(false);
    const [checkEmail, setCheckEmail] = React.useState(false);
    const [biddingDog, setBiddingDog] = React.useState('');
    const [myDog, setMyDog] = useState({});
    const [bid, setBid] = useState("");
    const [racePoint, setRacePoints] = React.useState([]);
    const [email1, setEmail1] = useState('');
    const [emailVerify, setEmailVerify] = useState(false);
    const [disableAfterActivation, setDisableAfterActivation] = useState(false);
    const [otpVerify, setOtpVerify] = useState();
    const [openEmail, setOpenEmail] = useState(false);
    const [isError, setError] = useState(false);
    const [replay, setReplay] = useState(false);
    const [changeRestart, setChangeRestart] = useState(false);
    const location = useLocation()
    const query = new URLSearchParams(location.search);
    const myValue = query.get('price');
    const [score, setScore] = useState(myValue ? myValue : 0)
    const myRef = useRef(null)

    const [isFullscreenEnabled, setIsFullscreenEnabled] = useState(false);
    const [isFullscreenEnabled2, setIsFullscreenEnabled2] = useState(false);
    const [time, setTime] = useState(0);
    const [timerOn, setTimerOn] = useState(false);
    const [popUpBtn, setPopUpBtn] = useState(false);
    const [go, setGo] = useState(false);
    const [loading, setLoading] = React.useState(true)
    const [clicked, setClicked] = React.useState(true)
    const [modalShow, setModalShow] = React.useState(false);
    const [resultBtn, setResultBtn] = React.useState(false);
    const [AllData, setAllData] = React.useState(0);
    const [change, setChange] = React.useState(0);
    const [enableBtn, setEnableBtn] = useState(false);
    const { user, openWalletModal } = useContext(CelebrityContext);
    // scroll to racing section 
    const executeScroll = () => myRef.current.scrollIntoView()
    // fullscreen 
    const handle = useFullScreenHandle();
    const handle2 = useFullScreenHandle();
    // countdown portion object
    const {
        isActive,
        counter,
        seconds,
        minutes,
        hours,
        days,
        pause,
        resume,
        reset,
    } = useTimer(3, () => { setCountdown(true) });

    // countdown reset function 
    const reset2 = () => {
        reset();

    }

    // select items css style class 
    const classes = useStyles();
    // Re-send OTP functionality

    //  the dog object 
    const dogsImg = []
    const onPageLoad = () => {
        setLoading(false);
    };
    const LevelCompleted = useCallback((num) => {
        if (num == 1) {
            setScore(100)
            setEnableBtn(true)
            setTimeout(() => {
                sendMessage("Canvas", "ResumeGame");
                console.log("resume done")
            }, 2000);
        }
        else if (num == 2) {
            setScore(150)
            setEnableBtn(true)
            sendMessage("Canvas", "ResumeGame");
        }
        else if (num == 3) {
            setScore(200)
            setEnableBtn(true)
            sendMessage("Canvas", "ResumeGame");
        }

        console.log(num, "num")
    }, []);

    useEffect(() => {
        console.log("in the page")
        return () => {
            console.log("outside the page")
            if (unityProvider) {
                sendMessage("GameManager", "QuitGame")
            }
        };
    }, [isLoaded, unityProvider]);


    useEffect(() => {
        addEventListener("LevelCompleted", LevelCompleted);

        return () => {
            removeEventListener("LevelCompleted", LevelCompleted);

        };
    }, [LevelCompleted]);

    useEffect(() => {

        window.LevelCompleted = (num) => {
            if (num == 1) {
                setScore(100)
                setEnableBtn(true)
                setTimeout(() => {
                    sendMessage("Canvas", "ResumeGame");
                    console.log("resume done")
                }, 2000);

            }
            else if (num == 2) {
                setScore(150)
                setEnableBtn(true)
                sendMessage("Canvas", "ResumeGame");
            }
            else if (num == 3) {
                setScore(200)
                setEnableBtn(true)
                sendMessage("Canvas", "ResumeGame");
            }
        };
    }, [unityProvider]);


    const navigate = useNavigate();

    // console.log("unity-game", useUnityContext({
    //     loaderUrl: './Build/DSCatch 1.1 - Brotli.loader.js',
    //     dataUrl: "./Build/DSCatch 1.1 - Brotli.data.unityweb",
    //     frameworkUrl: "./Build/DSCatch 1.1 - Brotli.framework.js.unityweb",
    //     codeUrl: "./Build/DSCatch 1.1 - Brotli.wasm.unityweb",
    // }))
    const [success, setSuccess] = useState(null);
    const [checkDevice, setCheckDevice] = useState("");
    const [texts, setText] = useState("");
    useEffect(() => {
        const detecting = async () => {
            if (window.innerWidth < 1000 && typeof window.ethereum === "undefined") {
                console.log("mobile");
                setCheckDevice(() => "mobileWithoutApp");
            } else if (
                window.innerWidth < 1000 &&
                typeof window.ethereum !== "undefined"
            ) {
                setCheckDevice(() => "mobileWithApp");
            } else if (
                window.innerWidth > 1000 &&
                typeof window.ethereum !== "undefined"
            ) {
                console.log("pc");
                setCheckDevice(() => "pcWithExtention");
            } else if (
                window.innerWidth > 1000 &&
                typeof window.ethereum === "undefined"
            ) {
                setCheckDevice(() => "pcWithoutExtention");
            }
        };

        detecting();
    }, []);

    const [loading1, setLoading1] = useState(false)

    async function handleClaim() {
        setLoading1(true)
        // console.log("work1")
        // await axios.post(`https://backend.playtoearnblockchain.com/api/v1/payment/${user?.walletAddress}`, { prizeAmount: score }).then(res => {
        //     if (res.status == 200) {
        try {
            console.log("work2")
            axios.post('https://backend.playtoearnblockchain.com/api/v1/admin-payment-shooting-record', { walletAddress: user?.walletAddress, dslToken: score, email: user?.email, claim: false }).then(res => {
                console.log("work3")
                setSuccess(`You have successfully claimed ${score} DSL Tokens.`);

                const wrapper = document.createElement("div");
                wrapper.innerHTML = `
        <p style="color: white;">You have successfully claimed ${score} DSL Tokens.</p>
       
        <p style="color: white;">You will receive it within 48 hours once our admin checks</p>
    `
                swal({
                    content: wrapper,
                    icon: "success",
                    button: "OK!",
                    className: "modal_class_success",
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            if (score == 200) {
                                navigate('/playtoearn');
                            }
                            else {
                                sendMessage("Canvas", "ResumeGame");
                            }

                        }
                    });

            })

        } catch (err) {
            setError(err.message);
            setLoading1(false)
        }
        // }
        // 
        // }).catch(error => {
        //     setLoading1(false)
        //     swal({
        //         title: "Attention",
        //         text: error.response.data.message,
        //         icon: "warning",
        //         button: "OK!",
        //         className: "modal_class_success",
        //     });

        // });

        //     }
        // }).catch(error => {
        //     swal({
        //         title: "Attention",
        //         text: error.response.data.message,
        //         icon: "warning",
        //         button: "OK!",
        //         className: "modal_class_success",
        //     });
        // });



    }


    const handleChange = (event) => {
        setBiddingDog(event.target.value);

        const select = dogsImg.find(dog => dog.img === event.target.value);
        // console.log(select);
        setMyDog(select);
    };

    // position dialog modal 
    const handleClickOpen = () => {
        setOpen(true);
    };

    // position dialog modal 
    const handleClose = () => {
        setOpen(false);
    };


    // function isMobile() {
    //     const mediaQuery = window.matchMedia('(max-width: 767px)');
    //     return mediaQuery.matches;
    // }

    const [timerId, setTimerId] = useState(null);

    useEffect(() => {

        if (isLoaded) {
            if (isMobile) {
                const id = setInterval(() => {
                    sendMessage("Canvas", "ReadyMobileView")
                }, 2000);
                setTimerId(id);
                setTimeout(() => {
                    clearInterval(timerId);
                }, 20000);
            }
            else {
                const id = setInterval(() => {
                    sendMessage("Canvas", "ReadyComputerView")
                    console.log("runninggg")
                }, 2000);
                setTimerId(id);
                setTimeout(() => {
                    clearInterval(timerId);
                }, 20000);
            }
            // if (isMobile()) {
            //     sendMessage("Canvas", "ReadyMobileView");
            // }

        }
    }, [isLoaded, score]);
    fetch("https://backend.grighund.net/api/race")
        .then((res) => res.json())
        .then((data) => {
            setAllData(data?.data.length)
        });
    const racePostInfo = () => {
        axios.post('https://backend.grighund.net/api/latestrace', { race: place, selectedDog: myDog }).then(() => {
            console.log('latest race posted')

        })

        axios.post('https://backend.grighund.net/api/race', { race: place, email: user?.email, raceNumber: AllData }).then(() => {
            console.log('posted')
            setChange(change => change + 1)
        })
    }

    const timeoutFunc = () => {
        setTimeTricker(setTimeout(() => {
            setPosition(true)
            setOpen(true)
            setBiddingDog('')
            setBid('')
            setEmail('')
            setFocus(false)
            setFocusBid(false)
            setCheckEmail(false)
            setChangeRestart(true);
            setPopUpBtn(false)
            setResultBtn(true)
        }, 38000 + 4600))
    }

    const generateSpeed = () => {
        speedCount = []
        let i = 0;
        do {
            let value = Math.floor(Math.random() * (400 - 355 + 1)) + 330;
            if (value !== speedCount[speedCount.length - 1]) {
                speedCount.push(value);
                i++;
            }

        } while (i < 20);
        let newarray = speedCount;
        let first = Math.min(...speedCount);

        const random = Math.floor(Math.random() * (2 - 1 + 1)) + 1;
        console.log(random, "quickselect(speedCount,2)")
        if (random == 1) {
            first = Math.min(...speedCount);
        }
        else if (random == 2) {
            first = newarray.reduce((pre, cur) => (cur < pre && cur !== first) ? cur : pre
                , Infinity)
        }
        const mydogIndex = parseInt(myDog?.track) - 1
        const fromIndex = speedCount.indexOf(first);
        const toIndex = mydogIndex;
        const flag = speedCount[toIndex]
        speedCount[toIndex] = first;
        speedCount[fromIndex] = flag

        return speedCount;

    }
    const generateRacePoints = () => {
        let n = [];
        let flag = 100
        for (let i = 0; i < 20; i++) {
            n[i] = flag
            flag = flag - 5;
        }
        setRacePoints(n)
    }

    const generateDogs = (positionSort, speed) => {
        setPlace([]);
        generateRacePoints();
        let pointTotal = 105;
        let sumPoint = [];
        console.log(sumPoint);
        console.log("racePoint", racePoint)
        let bidCalculate = 10;
        const date = `${moment(new Date()).format("DD-MM-YYYY hh:mm A")}`
        console.log(firstInRace, 'first in race')
        for (let i = 0; i < 20; i++) {
            console.log("dogImg", positionSort[i])
            pointTotal -= 5;
            sumPoint.push({ pointTotal });
            const prizeAmount = bidCalculate * bid;
            console.log("prizeAmount", bidCalculate);
            setPlace(place => [...place, { img: dogsImg[positionSort[i]].img, gender: dogsImg[positionSort[i]].gender, probability: racePoint[i], strength: (speed[positionSort[i]] + racePoint[i]) - 350, prize: prizeAmount, time: speed[positionSort[i]], date: date, position: i + 1, track: dogsImg[positionSort[i]].track }])

            if (i == 0) {
                bidCalculate = 6
            }
            else if (i == 1) {
                bidCalculate = 4
            }
            else if (i => 2) {
                bidCalculate = 0
            }

        }
    }

    const generatePosition = (speed) => {
        setFirstInRace(Math.min(...speed))
        setLastInRace(Math.max(...speed))

        const positionSort = Array.from(Array(speed.length).keys()).sort((a, b) => speed[a] < speed[b] ? -1 : (speed[b] < speed[a]) | 0)
        setPositions(positionSort)

        console.log(positions, "p", speed)
        timeoutFunc();
        generateDogs(positionSort, speed);
        console.log(lastInRace, "lastinrace")


    }
    const checkInput = () => {
        swal({
            // title: "S",
            // text: "Select the dog and stake amount!",
            text: "Please complete all steps!",
            icon: "warning",
            button: "OK!",
            className: "modal_class_success",
        });
    }
    const validEmail = () => {
        swal({
            // title: "S",
            text: "Verify your email!",
            icon: "warning",
            button: "OK!",
            className: "modal_class_success",
        });
    }
    useEffect(() => {

        setSpeed(generateSpeed())
        generateRacePoints()
        setCountdown(true);
        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            return () => window.removeEventListener('load', onPageLoad);
        }

    }, [])

    const [isFullscreen, setIsFullscreen] = React.useState(false);
    const locataion = useLocation()
    // Watch for fullscreenchange
    React.useEffect(() => {

        locataion?.state?.name == "replay" && setResultBtn(true);
        locataion?.state?.name == "replay" && setTimeout(() => {
            handleClickOpen()
        }, 1500);;
        console.log(locataion, 'location')

        function onFullscreenChange() {
            setIsFullscreen(Boolean(document.fullscreenElement));
            requestFullscreen(Boolean(document.fullscreenElement))
        }

        document.addEventListener('fullscreenchange', onFullscreenChange);
        document.addEventListener("fullscreenerror", function () {
            requestFullscreen(true)
        });

        return () => document.removeEventListener('fullscreenchange', onFullscreenChange);


    }, []);


    const redirectFunc = (myValue) => {
        setEnableBtn(true);
        sendMessage("CheckRedirector", "Redirected", parseInt(myValue));
    }

    useEffect(() => {
        isLoaded && (myValue > 0 && redirectFunc(myValue))
        // setInterval(() => {
        //     // isLoaded && sendMessage("Canvas", "SetUserStatus", user?.walletAddress ? 1 : 0);

        // }, 100);

    }, [isLoaded])

    const takeBackToStartLine = () => {
        let element = document.getElementById('uniqe');
        let element1 = document.getElementById('uniqe2');
        let element2 = document.getElementById('uniqe3');
        let element3 = document.getElementById('uniqe4');
        let element4 = document.getElementById('uniqe5');
        let element5 = document.getElementById('uniqe6');
        let element6 = document.getElementById('uniqe7');
        let element7 = document.getElementById('uniqe8');
        let element8 = document.getElementById('uniqe9');
        let element9 = document.getElementById('uniqe10');
        let element10 = document.getElementById('uniqe11');
        let element11 = document.getElementById('uniqe12');
        let element12 = document.getElementById('uniqe13');
        let element13 = document.getElementById('uniqe14');
        let element14 = document.getElementById('uniqe15');
        let element15 = document.getElementById('uniqe16');
        let element16 = document.getElementById('uniqe17');
        let element17 = document.getElementById('uniqe18');
        let element18 = document.getElementById('uniqe19');
        let element19 = document.getElementById('uniqe20');
        element.classList.remove("img_dog");
        element1.classList.remove("img_dog");
        element1.classList.remove("timer2");
        element2.classList.remove("img_dog");
        element2.classList.remove("timer3");
        element3.classList.remove("img_dog");
        element3.classList.remove("timer4");
        element4.classList.remove("img_dog");
        element4.classList.remove("timer5");
        element5.classList.remove("img_dog");
        element5.classList.remove("timer6");
        element6.classList.remove("img_dog");
        element6.classList.remove("timer7");
        element7.classList.remove("img_dog");
        element7.classList.remove("timer8");
        element8.classList.remove("img_dog");
        element8.classList.remove("timer9");
        element9.classList.remove("img_dog");
        element9.classList.remove("timer10");
        element10.classList.remove("img_dog");
        element10.classList.remove("timer11");
        element11.classList.remove("img_dog");
        element11.classList.remove("timer12");
        element12.classList.remove("img_dog");
        element12.classList.remove("timer13");
        element13.classList.remove("img_dog");
        element13.classList.remove("timer14");
        element14.classList.remove("img_dog");
        element14.classList.remove("timer15");
        element15.classList.remove("img_dog");
        element15.classList.remove("timer16");
        element16.classList.remove("img_dog");
        element16.classList.remove("timer17");
        element17.classList.remove("img_dog");
        element17.classList.remove("timer18");
        element18.classList.remove("img_dog");
        element18.classList.remove("timer19");
        element19.classList.remove("img_dog");
        element19.classList.remove("timer20");

        void element.offsetWidth;
        void element1.offsetWidth;
        void element2.offsetWidth;
        void element3.offsetWidth;
        void element4.offsetWidth;
        void element5.offsetWidth;
        void element6.offsetWidth;
        void element7.offsetWidth;
        void element8.offsetWidth;
        void element9.offsetWidth;
        void element10.offsetWidth;
        void element11.offsetWidth;
        void element12.offsetWidth;
        void element13.offsetWidth;
        void element14.offsetWidth;
        void element15.offsetWidth;
        void element16.offsetWidth;
        void element17.offsetWidth;
        void element18.offsetWidth;
        void element19.offsetWidth;

    }

    const restartButton = () => {
        setTimeout(() => {
            setGo(false)
        }, 500);
        // let classy = document.getElementsByClassName("img_dog")
        takeBackToStartLine()
        let element = document.getElementById('uniqe');
        let element1 = document.getElementById('uniqe2');
        let element2 = document.getElementById('uniqe3');
        let element3 = document.getElementById('uniqe4');
        let element4 = document.getElementById('uniqe5');
        let element5 = document.getElementById('uniqe6');
        let element6 = document.getElementById('uniqe7');
        let element7 = document.getElementById('uniqe8');
        let element8 = document.getElementById('uniqe9');
        let element9 = document.getElementById('uniqe10');
        let element10 = document.getElementById('uniqe11');
        let element11 = document.getElementById('uniqe12');
        let element12 = document.getElementById('uniqe13');
        let element13 = document.getElementById('uniqe14');
        let element14 = document.getElementById('uniqe15');
        let element15 = document.getElementById('uniqe16');
        let element16 = document.getElementById('uniqe17');
        let element17 = document.getElementById('uniqe18');
        let element18 = document.getElementById('uniqe19');
        let element19 = document.getElementById('uniqe20');

        element.classList.add("img_dog");
        element1.classList.add("img_dog");
        element1.classList.add("timer2");
        element2.classList.add("img_dog");
        element2.classList.add("timer3");
        element3.classList.add("img_dog");
        element3.classList.add("timer4");
        element4.classList.add("img_dog");
        element4.classList.add("timer5");
        element5.classList.add("img_dog");
        element5.classList.add("timer6");
        element6.classList.add("img_dog");
        element6.classList.add("timer7");
        element7.classList.add("img_dog");
        element7.classList.add("timer8");
        element8.classList.add("img_dog");
        element8.classList.add("timer9");
        element9.classList.add("img_dog");
        element9.classList.add("timer10");
        element10.classList.add("img_dog");
        element10.classList.add("timer11");
        element11.classList.add("img_dog");
        element11.classList.add("timer12");
        element12.classList.add("img_dog");
        element12.classList.add("timer13");
        element13.classList.add("img_dog");
        element13.classList.add("timer14");
        element14.classList.add("img_dog");
        element14.classList.add("timer15");
        element15.classList.add("img_dog");
        element15.classList.add("timer16");
        element16.classList.add("img_dog");
        element16.classList.add("timer17");
        element17.classList.add("img_dog");
        element17.classList.add("timer18");
        element18.classList.add("img_dog");
        element18.classList.add("timer19");
        element19.classList.add("img_dog");
        element19.classList.add("timer20");
        console.log('init', timeTricker);
        clearTimeout(timeTricker);
        console.log('destroyed', timeTricker);

    }
    useEffect(() => {
        place.length == 20 && racePostInfo()
    }, [place])

    // Token
    const verifiedToken = localStorage.getItem('gotVerifiedToken');
    return (
        <div className="savethehostage">
            {loading ? <div className='d-flex align-items-center justify-content-center ' style={{ height: '100vh' }}><CircularProgress className='text-center' color="inherit" /></div> : <>
                <div ref={myRef} className='container  overflow-hidden start-0 pt-5'>

                    {
                        <div className='row mt-5 mb-5'>
                            <div className='race_width_2d3d' style={{ margin: '24px auto' }}>

                                <div style={{ position: 'relative' }}>
                                    {/* {isLoaded && <img src='https://img.icons8.com/color/48/null/switch-camera.png' onClick={() => sendMessage("Canvas", "ChangeCam")} class="fa-expand"></img>}
                                    {seconds > 0 && <span className={`count-down ${countdown == true ? 'invisible' : 'visible'}`}>{seconds}</span>}
                                    {go && <span className={`count-downGo ${go == true ? 'visible' : 'invisible'}`}>GO!</span>} */}

                                    <Game3D
                                        unityProvider={unityProvider}
                                        isLoaded={isLoaded}
                                        loadingProgression={loadingProgression}
                                        sendMessage={sendMessage}
                                    ></Game3D>
                                    <div className="login-catchit">

                                        {
                                            enableBtn ? ((!user?.walletAddress || user?.walletAddress === "undefined") ?
                                                <div className="landing-button text-center claimdsl">

                                                    {checkDevice === "mobileWithoutApp" && (
                                                        <a
                                                            href={`https://metamask.app.link/dapp/https://playtoearnblockchain.com/savethehostages?price=${score}`}
                                                            // href={href}
                                                            target={"_blank"}
                                                            className="text-decoration-none"
                                                        >
                                                            <button className="text-uppercase button-metamask  px-lg-4 px-2">
                                                                {/* <img
                                                                    className="me-2"
                                                                    width="20px"
                                                                    src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                                                                    alt=""
                                                                />{" "} */}
                                                                CONNECT WITH WALLET to earn {score} {score > 1 ? "DSL Tokens" : "DSL Token"}
                                                            </button>
                                                        </a>
                                                    )}
                                                    {checkDevice === "mobileWithApp" && (
                                                        <>
                                                            {!user?.walletAddress ||
                                                                user?.walletAddress === "undefined" ? (
                                                                <button
                                                                    className="text-uppercase button-metamask px-lg-4 px-2"
                                                                    onClick={() => openWalletModal()}
                                                                >
                                                                    {" "}
                                                                    {/* <img
                                                                        className="me-2"
                                                                        width="20px"
                                                                        src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                                                                        alt=""
                                                                    />{" "} */}
                                                                    CONNECT WITH WALLET to earn {score} {score > 1 ? "DSL Tokens" : "DSL Token"}
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    className="text-uppercase button-metamask px-lg-4 px-2"
                                                                    onClick={() => navigate("/profile")}
                                                                >
                                                                    {" "}
                                                                    {/* <img
                                                                        className="me-2"
                                                                        width="20px"
                                                                        src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                                                                        alt=""
                                                                    />{" "} */}
                                                                    profile
                                                                </button>
                                                            )}
                                                        </>
                                                    )}
                                                    {checkDevice === "pcWithExtention" && (
                                                        <>
                                                            {!user?.walletAddress ||
                                                                user?.walletAddress === "undefined" ? (
                                                                <button
                                                                    className="text-uppercase button-metamask px-lg-4 px-2"
                                                                    onClick={() => openWalletModal()}
                                                                    style={{ width: "auto" }}
                                                                >
                                                                    {" "}
                                                                    {/* <img
                                                                        className="me-2"
                                                                        width="20px"
                                                                        src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                                                                        alt=""
                                                                    /> */}
                                                                    CONNECT WITH WALLET TO EARN YOUR {score} {score > 1 ? "DSL TOKENS" : "DSL TOKEN"}
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    className="text-uppercase button-metamask px-lg-4 px-2"
                                                                    onClick={() => navigate("/profile")}
                                                                >
                                                                    {" "}
                                                                    {/* <img
                                                                        className="me-2"
                                                                        width="20px"
                                                                        src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                                                                        alt=""
                                                                    />{" "} */}
                                                                    profile
                                                                </button>
                                                            )}
                                                        </>
                                                    )}
                                                    {checkDevice === "pcWithoutExtention" && (
                                                        <a
                                                            href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
                                                            target={"_blank"}
                                                            className="text-decoration-none"
                                                        >
                                                            <button className="text-uppercase button-metamask px-lg-4 px-2">
                                                                {/* <img
                                                                    className="me-2"
                                                                    width="20px"
                                                                    src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                                                                    alt=""
                                                                />{" "} */}
                                                                CONNECT WITH WALLET
                                                            </button>
                                                        </a>
                                                    )}
                                                    {enableBtn && <i class="fa-solid fa-xmark" onClick={() => { setEnableBtn(false); sendMessage("Canvas", "ResumeGame"); }}></i>}
                                                </div>
                                                :

                                                <div> <button onClick={handleClaim} disabled={loading1 || score == 0} className='button-18'>Claim your {score} DSL Tokens</button>{enableBtn && <i class="fa-solid fa-xmark" onClick={() => { setEnableBtn(false); sendMessage("Canvas", "ResumeGame"); }}></i>}</div>) : ""
                                        }
                                    </div>
                                </div>
                                <div className='game_instructions_for_pc_mobile'>
                                    <div className='text-white mt-3 forMobile width-50'
                                        style={{ background: "#413d42", padding: '20px 25px' }}
                                    >
                                        <div>
                                            <div style={{ display: 'block' }}>For PC</div>
                                            <div className='py-2'>1. Movement Controls:</div>
                                            <div>
                                                <table class="table table-bordered border-light" >
                                                    <tbody className='text-white'>
                                                        <tr>
                                                            <th scope="row" style={{ width: "30%" }}>W</th>
                                                            <td colspan="2">Forward Movement ( Hold "Left-Shift" to Sprint)</td>

                                                        </tr>
                                                        <tr>
                                                            <th scope="row">A</th>
                                                            <td colspan="2">Left Movement</td>

                                                        </tr>
                                                        <tr>
                                                            <th scope="row">S</th>
                                                            <td colspan="2">Backward Movement</td>

                                                        </tr>
                                                        <tr>
                                                            <th scope="row">D</th>
                                                            <td colspan="2">Right Movement</td>

                                                        </tr>
                                                        <tr>
                                                            <td colspan="2">(Use Mouse to Look around)</td>

                                                        </tr>
                                                        <tr>
                                                            <td colspan="2">(Press Escape to Switch Mouse icon back on)</td>


                                                        </tr>
                                                    </tbody>
                                                </table></div>
                                            {/* <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '16px', marginTop: '8px' }}>
                                                <small>W ---&gt;&gt; Forward Movement ( Hold "Left-Shift" to Sprint)</small>
                                                <small>A ---&gt;&gt; Left Movement</small>
                                                <small>S ---&gt;&gt; Backward Movement</small>
                                                <small>D ---&gt;&gt; Right Movement</small>
                                                <small>(Use Mouse to Look around)</small>
                                                <small>(Press Escape to Switch Mouse icon back on)</small>
                                            </div> */}
                                        </div>
                                        <div className='mt-3'>
                                            <div className='pb-2'>2. Shooting Controls:</div>
                                            <table class="table table-bordered border-light" >
                                                <tbody className='text-white'>
                                                    <tr>
                                                        <th scope="row" style={{ width: "30%" }}>Shoot</th>
                                                        <td colspan="2">Left Mouse Button</td>

                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Aim</th>
                                                        <td colspan="2">Right Mouse Button</td>

                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Change Weapon</th>
                                                        <td colspan="2">Scroll Up or Down</td>

                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Q</th>
                                                        <td colspan="2">Change Weapon</td>

                                                    </tr>


                                                </tbody>
                                            </table>
                                            {/* <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '16px', marginTop: '8px' }}>
                                                <small>Shoot -----&gt;&gt; Left Mouse Button</small>
                                                <small>Aim -----&gt;&gt; Right Mouse Button</small>
                                                <small>Change Weapon -----&gt;&gt; Scroll Up or Down</small>
                                                <small>Q ---------&gt;&gt; Change Weapon</small>
                                            </div> */}
                                        </div>
                                        <div className='mt-3'>
                                            <div className='pb-2'>3. Claim:</div>
                                            <table class="table table-bordered border-light" >
                                                <tbody className='text-white'>
                                                    <tr>
                                                        <th scope="row" style={{ width: "30%" }}>Claim Button</th>
                                                        <td colspan="2">Use Esc to claim your rewards while playing game</td>

                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                    <div className='text-white mt-3 forPc width-50'
                                        style={{ background: "#2c173a", padding: '20px 25px' }}
                                    >
                                        <div>
                                            <div className='pb-2' style={{ display: 'block' }}>For Mobile</div>
                                            <div className='pb-2'>1. Movement Controls:</div>
                                            <table class="table table-bordered border-light" >
                                                <tbody className='text-white'>
                                                    <tr>
                                                        <th scope="row" style={{ width: "30%" }}>Drag  the Joystick button</th>
                                                        <td colspan="2">( [Right/Left/Up/Down] placed on Left side of Screen) to Control player's movement.</td>

                                                    </tr>


                                                </tbody>
                                            </table>
                                            {/* <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '16px', marginTop: '8px' }}>
                                                <small>Drag  the Joystick button ( [Right/Left/Up/Down] placed on Left side of Screen) to Control player's movement.</small>
                                            </div> */}
                                        </div>
                                        <div className='mt-3'>
                                            <div className='pb-2' style={{}}>2. Player Aim:</div>
                                            <table class="table table-bordered border-light" >
                                                <tbody className='text-white'>
                                                    <tr>
                                                        <th scope="row" style={{ width: "30%" }}>Aim</th>
                                                        <td >Drag in the center of the screen to change the aim.</td>

                                                    </tr>


                                                </tbody>
                                            </table>
                                            {/* <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '16px', marginTop: '8px' }}>
                                                <small>Drag in the center of the screen to change the aim.</small>
                                            </div> */}
                                        </div>
                                        <div className='mt-3'>
                                            <div className='pb-2'>3. Fire Controls:</div>
                                            <table class="table table-bordered border-light" >
                                                <tbody className='text-white'>
                                                    <tr>
                                                        <th scope="row" style={{ width: "30%" }}>Bullet Button</th>
                                                        <td colspan="2">to Fire Bullet</td>

                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Weapon Button</th>
                                                        <td colspan="2">to Switch the  Weapon</td>

                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Circular Arrows</th>
                                                        <td colspan="2">to Reload selected Gun's Bullets</td>

                                                    </tr>

                                                </tbody>
                                            </table>
                                            {/* <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '16px', marginTop: '8px' }}>
                                                <small>Bullet Button ---&gt;&gt; to Fire Bullet</small>
                                                <small>Weapon Button ---&gt;&gt; to Switch the  Weapon</small>
                                                <small>Circular Arrows ---&gt;&gt; to Reload selected Gun's Bullets</small>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </>
            }
        </div >
    );
};

export default SaveTheHostAges;