import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Paper } from '@mui/material';
import styled from 'styled-components';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledPaper = styled(Paper)`

  max-width:780px!important;
  background:linear-gradient(to right bottom, #4e4c4c, #4e4c4c);
  color:white;

  @media screen and (max-width: 768px) {
text-align:center;
  }
`;

export default function Info({ open, setOpen, handleClose }) {


  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        PaperComponent={StyledPaper}
        aria-describedby="alert-dialog-slide-description"

      >
        {/* <DialogTitle className='border-white border-bottom'>{"Why you need 100 points Grighund NFT?"}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" className='text-white pt-4'>

            <p className='mb-5 mt-1 text-center'>
              {/* Collect your DSL. You can use it to purchase our NFTs. You can use the NFTs to earn BUSD. */}
              Collect Your DSL Tokens if you have won. You can use the DSL Tokens to purchase our NFTs. You can use the NFTs to earn BUSD.
            </p>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className='text-danger fw-bold border-danger border-1 border'>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}