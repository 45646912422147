import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Button from 'react-bootstrap/Button';
import CloseIcon from '@mui/icons-material/Close';
import QRCode from 'qrcode';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: '#1a1a25',
    // border: '2px solid white',
    boxShadow: 24,
    color: "white",
    borderRadius: '5px',
    p: 4
};

const ProfileQRModal = ({ openQRModal, setOpenQRModal, email, qrCode }) => {
    const [src, setSrc] = useState('');
    const [random, setRandom] = useState();
    const [prevRandom, setPrevRandom] = useState();

    const handleClose = () => setOpenQRModal(false);

    // QR code functionality
    useEffect(() => {
      QRCode.toDataURL(qrCode?.toString())
        .then(setSrc);
    }, [qrCode])

    return (
        <div>
            <Modal
                open={openQRModal} P
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} id="">
                    <div className='closeD'>
                        <Button className='iconClose' onClick={handleClose}><CloseIcon className='iconClose' style={{ color: "red" }} /></Button>
                    </div>
                    {/* <Typography id="modal-modal-title text-light" className='text-light' variant="h6" component="h2">
                        QR CODE
                    </Typography> */}
                    <Typography id="modal-modal-description text-light" sx={{ mb: 1, fontWeight: '400', fontSize: '14px' }}>
                        SFF 2022 Wheel of Fortune QR Code
                    </Typography>
                    <img src={src} alt="barcode" style={{width: '100%'}} />
                    <img src='./finTechModalImg.jpeg' alt="fintech" style={{width: '100%', marginTop: '16px'}} />
                    <Typography id="modal-modal-description text-light" sx={{ mt: 1, fontWeight: '400', fontSize: '14px' }}>
                        Spin the wheel and get your lucky prize.
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
};

export default ProfileQRModal;