import React, { useContext } from "react";
import { CelebrityContext } from "../../../context/CelebrityContext";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules

const { ethereum } = window;
const removedUrl = "https://backend.celebritygames.net";

const ReleatedNft = ({
  isSouvenir,
  setIsClicked,
  isClicked,
  isDetails,
  setDetails,
  likes,
}) => {
  const {
    user,
    setRequestLoading,
    openWalletModal,
    mintTicketNFTTestnetBNB,
    mintTicketNFTTestnetUSDSC,
    mintTicketNFTTestnetDSL,
    mintAddressTestnet,
    signBuyFunction,
    USDSCtokenAddressMainnet,
    DSLtokenAddressMainnet,
  } = useContext(CelebrityContext);

  const handleClick = () => {
    if (!user?.walletAddress || user?.walletAddress === "undefined") {
      openWalletModal();
    } else {
      if (isClicked) {
        // setLikes(likes - 1);
        localStorage.setItem("like", likes - 1);
      } else {
        // setLikes(likes + 1);
        localStorage.setItem("like", likes + 1);
      }
      setIsClicked(!isClicked);
      // setLikes(likess);
      // console.log(likess);
    }
  };

  // like functionality
  const likeNft = (id) => {
    if (!user?.walletAddress || user?.walletAddress === "undefined") {
      openWalletModal();
    } else {
      fetch("https://backend.playtoearnblockchain.com/api/nft/like", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54",
        },
        body: JSON.stringify({
          nftId: id,
          walletAddress: user?.walletAddress,
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          if (isDetails._id == result._id) {
            setDetails(result);
          } else {
            setDetails(isDetails);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const unlikeNft = (id) => {
    if (!user?.walletAddress || user?.walletAddress === "undefined") {
      openWalletModal();
    } else {
      fetch("https://backend.playtoearnblockchain.com/api/nft/unlike", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54",
        },
        body: JSON.stringify({
          nftId: id,
          walletAddress: user?.walletAddress,
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          if (isDetails._id == result._id) {
            setDetails(result);
          } else {
            setDetails(isDetails);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  let settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          speed: 1500,
          infinite: true,
        },
      },
    ],
  };
  return (
    <>
      <div className="text-light onHideSmSlider">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          className="mySwiper"
        >
          {isSouvenir?.map((aNft) => (
            <SwiperSlide>
              <div key={aNft?._id} className="d-item1">
                <div class="card">
                  <div
                    onClick={() =>
                      aNft.likes?.includes(user?.walletAddress)
                        ? unlikeNft(aNft?._id)
                        : likeNft(aNft?._id)
                    }
                    className="nft_item_like like_card"
                  >
                    <i
                      className={`fa fa-heart ${
                        aNft?.likes?.includes(user?.walletAddress) &&
                        "heart-icon"
                      }`}
                    ></i>
                    <span style={{ marginBottom: "2.2px" }}>
                      {aNft?.likes?.length}
                    </span>
                  </div>
                  <div
                    class="card-img"
                    style={{ backgroundImage: `url(${aNft?.avatar.replace(removedUrl, "https://backend.playtoearnblockchain.com")})` }}
                  >
                    {/* <img
                      src="https://i.ibb.co/Pwt1fRw/9ee03415-e591-4320-bf25-af881b8c27a6.jpg"
                      alt=""
                      className="img-fluid nft-watermark2"
                    /> */}
                    <div
                      class="overlay d-grid "
                      style={{ alignContent: "center", justifyItems: "center" }}
                    >
                      <Link to={`/nft/${aNft?._id}/${aNft?.imageName}`}>
                        <button className="card_hover_button mt-5" href="#!">
                          BUY THIS NFT FOR USD {aNft?.price}
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div class="card-content">
                    <div className="row" style={{ minHeight: "90px" }}>
                      <Typography className="slider_nft_text" variant="div">
                        <span className="text-primary">Category :</span>{" "}
                        {aNft?.type}
                      </Typography>
                      <a href="#!">
                        <Typography className="slider_nft_text" variant="div">
                          <span className="text-primary">Name of NFT :</span>{" "}
                          {aNft?.name}
                        </Typography>
                      </a>
                      <Typography className="" variant="body2">
                        <span className="text-primary">
                          Price of NFT (USD):
                        </span>{" "}
                        {aNft?.price}
                      </Typography>
                      <Typography className="mt-2" variant="body2">
                        <span className="text-primary">Total NFTs:</span>{" "}
                        {aNft?.totalNfts
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </Typography>
                    </div>
                    <hr style={{ margin: "10px 0px 10px 0px" }} />
                    <div
                      className="d-flex card_bottom_btn_main "
                      style={{ margin: "15px 0 8px 0" }}
                    >
                      <div className="col-10 d-grid">
                        <Link
                          to={`/nft/${aNft?._id}/${aNft?.imageName}`}
                          className="d-grid"
                        >
                          {" "}
                          <button
                            className={
                              ethereum
                                ? "card_button2 bg-success glow_crypto_button fs-6"
                                : "card_button2 bg-success fs-6"
                            }
                            href="#!"
                          >
                            Buy this NFT!
                          </button>{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="text-light onHideLgSlider">
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          className="mySwiper"
        >
          {isSouvenir?.map((aNft) => (
            <SwiperSlide>
              <div key={aNft?._id} className="d-item1">
                <div class="card">
                  <div
                    onClick={() =>
                      aNft.likes?.includes(user?.walletAddress)
                        ? unlikeNft(aNft?._id)
                        : likeNft(aNft?._id)
                    }
                    className="nft_item_like like_card"
                  >
                    <i
                      className={`fa fa-heart ${
                        aNft?.likes?.includes(user?.walletAddress) &&
                        "heart-icon"
                      }`}
                    ></i>
                    <span style={{ marginBottom: "2.2px" }}>
                      {aNft?.likes?.length}
                    </span>
                  </div>
                  <div
                    class="card-img"
                    style={{ backgroundImage: `url(${aNft?.avatar.replace(removedUrl, "https://backend.playtoearnblockchain.com")})` }}
                  >
                    {/* <img
                      src="https://i.ibb.co/Pwt1fRw/9ee03415-e591-4320-bf25-af881b8c27a6.jpg"
                      alt=""
                      className="img-fluid nft-watermark2"
                    /> */}
                    <div
                      class="overlay d-grid "
                      style={{ alignContent: "center", justifyItems: "center" }}
                    >
                      <Link to={`/nft/${aNft?._id}/${aNft?.imageName}`}>
                        <button className="card_hover_button mt-5" href="#!">
                          BUY THIS NFT FOR USD {aNft?.price}
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div class="card-content">
                    <div className="row" style={{ minHeight: "90px" }}>
                      <Typography className="" variant="div">
                        <span className="text-primary">Category :</span>{" "}
                        {aNft?.type}
                      </Typography>

                      <Typography className="" variant="div">
                        <span className="text-primary">Name of NFT :</span>{" "}
                        {aNft?.name}
                      </Typography>

                      <Typography className="" variant="body2">
                        <span className="text-primary">
                          Price of NFT (USD):
                        </span>{" "}
                        {aNft?.price}
                      </Typography>
                      <Typography className="" variant="body2">
                        <span className="text-primary">Total NFTs:</span>{" "}
                        {aNft?.totalNfts
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </Typography>
                    </div>
                    <hr style={{ margin: "10px 0px 10px 0px" }} />
                    <div
                      className="d-flex card_bottom_btn_main "
                      style={{ margin: "15px 0 8px 0" }}
                    >
                      <div className="col-10 d-grid">
                        <Link
                          to={`/nft/${aNft?._id}/${aNft?.imageName}`}
                          className="d-grid"
                        >
                          {" "}
                          <button
                            className={
                              ethereum
                                ? "card_button2 bg-success glow_crypto_button fs-6"
                                : "card_button2 bg-success fs-6"
                            }
                            href="#!"
                          >
                            Pay USD {aNft?.price} by BNB
                          </button>{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <Typography
        variant="h6"
        style={{
          color: "#d0d7c2",
          textAlign: "center",
          fontSize: "15px",
          marginTop: "1rem",
          marginBottom: "2rem",
        }}
      >
        Please scroll sideways to view all.
      </Typography>
    </>
  );
};

export default ReleatedNft;
