import PersonIcon from '@mui/icons-material/Person';
import { Typography } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';

import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import './News.css';
import LoaderTop from './LoaderTop';

function News() {
   const [loading, setLoading] = useState(false);
   const [allNews, setAllNews] = useState([]);
   const [news, setNews] = useState({});

   const search = window.location.search;
   const params = new URLSearchParams(search);
   const title = params.get('news');



   const navigate = useNavigate();

   const [totalNftValue, setTotalNftValue] = useState();
   const [nftSold, setnftSold] = useState();
   const [allNft, setallNft] = useState([]);

   const nftValues = totalNftValue?.toLocaleString()
   const nftSoldValues = nftSold?.toLocaleString()

   useEffect(() => {
      const dataNftSold = async () => {
         await axios.get("https://backend.playtoearnblockchain.com/api/v1/mint/sum")
            .then(res => {
               setnftSold(res?.data?.TotalAmount);
            });

      }
      dataNftSold();
   }, [])


   useEffect(() => {
      const nftValue = async () => {
         await axios.get("https://backend.playtoearnblockchain.com/api/v1/total-nft")
            .then(res => {

               setTotalNftValue(res?.data?.TotalAvailabaleNft);
            });

      }
      nftValue();
   }, [])



   useEffect(() => {
      const dataIsMeal = async () => {
         await axios.get("https://backend.playtoearnblockchain.com/api/nft/all", {
            headers: { "authorization": `Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54` }
         })
            .then(res => {
               
               setallNft(res?.data?.nft);
            });
      }
      dataIsMeal();
   }, [])




   useEffect(() => {
      setNews(allNews.find(news => news.title === title));
   }, [title])

   console.log(title)

   useEffect(() => {
      axios.get("https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@dslsingapore")
         .then(res => {
            const allNewsObj = res.data.items;
            const allNews = [];
            for (const item in allNewsObj) {
               allNews.push(allNewsObj[item]);
            }
            setAllNews(allNews);
            setNews(allNews[0]);
         })
   }, []);

   useEffect(() => {
      if (allNews.length <= 0) {
         setLoading(true);
      }
   }, [allNews.length])

   return (
      <div className="news-wrapper" >
         <div>
            <h1 className="text-gradient text-center pt-5 text-uppercase" style={{ marginTop: '80px', fontSize: "1.75rem" }}>News</h1>
            <div className="small-border bg-color-2"></div>
         </div>
         <div>
            <Container className='section-pading2'>
               <div className='row'>
                  <div className="col-12 col-lg-8">
                     <img src={news?.thumbnail} alt="" className=' w-100 mx-auto' />

                     <div className="d-flex justify-content-between mt-2">
                        <Link className='d-flex footerLink' to='# '>
                           <PersonIcon className='personIcon' />
                           <div>
                              <p className="byAdmin text-white">
                                 By Admin
                              </p>
                           </div>
                        </Link>
                  
                     </div>
                     <div className="text-start pb-3">
                        <Link to={`/news?news=${news?.title}`} className="news-title py-4">
                           {news?.title}
                        </Link>
                     </div>
                     <div className='news-description text-start' dangerouslySetInnerHTML={{ __html: news?.description }}></div>
                  </div>
                  <div className="col-12 col-lg-4 text-start">
                     <Typography variant="h6">
                        RECENT NEWS
                     </Typography>
                     {
                        allNews.map((news, index) => <Link className="d-flex mt-4 align-items-center text-decoration-none text-dark" to={`/news?news=${news?.title}`} key={index}>
                         
                           <div className="text-start ms- text-decoration-none">
                              <p className='mb-2 text-white' style={{ fontSize: 14 }}>{news?.title}</p>
                              <p className="mb-0 text-white" style={{ fontSize: 12, fontWeight: 500 }}>{news?.publication_date}</p>
                           </div>
                        </Link>
                        )
                     }

                  </div>

               </div>

            </Container>
         </div>


         {allNft?.length > 0 &&
            <div>

               <>

                  <p className="text-gradient text-center fs-6 pt-4" style={{ cursor: "pointer" }} onClick={() => navigate("/nfts")}>Types of NFTs available: {allNft?.length}</p>

                  <p style={{ fontSize: "0.9rem" }} className="text-white text-center pt-1 mb-0">Total value of NFTs: {nftValues} USD</p>
                  <p style={{ fontSize: "0.9rem" }} className="text-white text-center pt-">Total value of NFTs sold: {nftSoldValues} USD</p>


               </>
            </div>
         }
      </div>
   );
}

export default News;
