import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CelebrityContext } from "../../../context/CelebrityContext";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import axios from "axios";
import Slider from "react-slick";
import { accessSync } from "fs";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import swal from "sweetalert";
import LoginButton from "../../Shared/LoginButton";
import { Button } from "react-bootstrap";
import MetaData from "../../MetaData/MetaData";
const { ethereum } = window;

const CheckNft = () => {
  const { openWalletModal, user, searchNftTitle, getMintContractTestnet } =
    useContext(CelebrityContext);
  const [isMeal, setIsMeal] = useState([]);
  const [walletAddress, setWalletAddress] = useState("");
  const [nft, setNft] = useState([]);
  const [newnft, setNewNft] = useState([]);
  const [img, setImg] = useState("");
  const [isExpand, setIsExpand] = useState(false);
  console.log(nft);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [checkNftState, setcheckNftState] = useState(false);
  const GetTokenId = async () => {
    {
      nft?.map(async (nft, index) => {
        const MintNFTContract = getMintContractTestnet();
        const Val = await MintNFTContract.tokenURI(nft?.TokenId);
        console.log(Val);
        const resp = await axios.get(Val).then((res) => {
          console.log(res.data);
          console.log(res.data.image);
          return res.data.image;
        });

        const object = {
          TokenAddress: nft.TokenAddress,
          TokenId: nft.TokenId,
          Imageuri: resp,
        };
        setNewNft((nftList) => [...nftList, object]);
      });
      setcheckNftState(true);
    }

    console.log("New Nft", newnft);
  };

  const CheckNftss = async () => {
    setNewNft([]);
    {
      await GetTokenId();
    }
  };

  const [totalNftValue, setTotalNftValue] = useState();
  const [nftSold, setnftSold] = useState();
  const nftValues = totalNftValue?.toLocaleString();
  const nftSoldValues = nftSold?.toLocaleString();
  const navigate = useNavigate();

  useEffect(() => {
    const datanft = async () => {
      await axios
        .get(
          `https://api.bscscan.com/api?module=account&action=addresstokennftinventory&address=${user.walletAddress}&contractaddress=0x7Eb18A5F7DB1e8b0a321bc563fE0b67dCD86F504&page=1&offset=100&apikey=6KE4U6S3GD197UYZVBDP5IGPI7CQKQ49X7`
        )
        .then((res) => {
          setNft(res?.data?.result);
          console.log(nft);
        });
    };

    datanft();
  }, []);
  const [load, setLoad] = useState(false);
  const [myGameNft, setmyGameNft] = useState([]);
  const reload = () => {
    setLoad(load == true ? false : true);
  };
  const [showTooltip, setShowTooltip] = useState(false);

  const checkNfts = async () => {
    // await axios
    //   .get("https://backend.playtoearnblockchain.com/api/v1/verifymint", {
    //     headers: {
    //       authorization: `Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54`,
    //     },
    //   })
    //   .then((res) => {
    //     console.log(res, "res");
    //     const filtering = res.data.result.filter(
    //       (items) =>
    //         items.walletAddress ==
    // &&
    //         items.contractAddress !=
    //           "0x31219e8af07699054B690116675546939a0c362F"
    //     );
    //     console.log("0x626D20125da6a371aA48023bF9dad94BD66588F7", filtering);
    //     setmyGameNft(filtering);
    //     setcheckNftState(true);
    //   });
    const response = await axios.get(
      "https://backend.playtoearnblockchain.com/api/v1/verifymint",
      {
        headers: {
          authorization: `Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54`,
        },
      }
    );

    const filtering = response?.data?.result?.filter(
      (items) => items.walletAddress == user?.walletAddress.toLowerCase()
    );

    await axios
      .get(
        `https://backend.playtoearnblockchain.com/api/v1/check-nft/bscscan?walletAddress=${user?.walletAddress}`
      )
      .then((res) => {
        const updatedNft = res?.data?.result?.map((item1) => {
          const matchingItem = filtering?.find(
            (item) => item.tokenId === item1.tokenID
          );

          if (matchingItem) {
            return {
              ...item1,
              price: matchingItem.price,
            };
          }
          return item1;
        });

        setmyGameNft(updatedNft);
        setcheckNftState(true);
      });
  };

  useEffect(() => {
    const dataNftSold = async () => {
      await axios
        .get("https://backend.playtoearnblockchain.com/api/v1/mint/sum")
        .then((res) => {
          setnftSold(res?.data?.TotalAmount);
        });
    };
    dataNftSold();
  }, []);

  useEffect(() => {
    const nftValue = async () => {
      await axios
        .get("https://backend.playtoearnblockchain.com/api/v1/total-nft")
        .then((res) => {
          setTotalNftValue(res?.data?.TotalAvailabaleNft);
        });
    };
    nftValue();
  }, []);

  useEffect(() => {
    const dataIsMeal = async () => {
      setLoading(true);

      await axios
        .get("https://backend.playtoearnblockchain.com/api/nft/all", {
          headers: {
            authorization: `Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54`,
          },
        })
        .then((res) => {
          setIsMeal(res?.data?.nft);
        })
        .finally(() => setLoading(false));
    };
    dataIsMeal();
  }, []);

  useEffect(() => {
    setWalletAddress(user?.walletAddress);
  }, [user?.walletAddress]);
  let settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
        },
      },
    ],
  };

  const withdrawValue = async (rewardAmount, nftId) => {
    swal({
      text: `You are withdrawing USD ${rewardAmount} from your NFT. Are you sure you want to withdraw?`,
      icon: "warning",
      buttons: ["No", "Yes"],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        try {
          axios
            .post(
              "https://backend.playtoearnblockchain.com/api/v1/admin-payment-withdraw-record",
              {
                walletAddress: user?.walletAddress,
                usdToken: rewardAmount,
                email: user?.email,
                claim: false,
              }
            )
            .then((res) => {
              axios
                .put(
                  `https://backend.playtoearnblockchain.com/api/v1/verifymint/${nftId}`,
                  {
                    experiencePoints: 0,
                    bid: rewardAmount,
                    prizeAmount: 0,
                  },
                  {
                    headers: {
                      authorization: `Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54`,
                    },
                  }
                )
                .then(() => {
                  setSuccess(
                    `You have successfully claimed ${rewardAmount} USD.`
                  );

                  const wrapper = document.createElement("div");
                  wrapper.innerHTML = `
        <p style="color: white;">You have successfully claimed ${rewardAmount} USD.</p>
       
        <p style="color: white;">You will receive it within 48 hours once our admin checks</p>
    `;
                  swal({
                    content: wrapper,
                    icon: "success",
                    button: "OK!",
                    className: "modal_class_success",
                  }).then((willDelete) => {
                    if (willDelete) {
                      navigate("/");
                    }
                  });
                });
            });
        } catch (err) {
          setError(err.message);
        }
      } else {
      }
    });
  };
  const [showTooltip1, setShowTooltip1] = useState(false);
  const [showTooltip2, setShowTooltip2] = useState(false);
  const [showTooltip3, setShowTooltip3] = useState(false);
  const [showTooltip4, setShowTooltip4] = useState(false);
  return (
    <div
      className="pb-5"
      style={{ height: myGameNft?.length > 0 ? "auto" : "100vh" }}
    >
       <MetaData pageTitle={"Play horse champs game earn money"} pageDescription={"Playtoearnblockchain.com NFTs can be sold in marketplaces or used in our PLAY TO EARN Blockchain Games. It is a blockchain-based game that allows players to earn rewards & Purchase NFTs."}></MetaData>
      <div>
        <h1
          className="text-gradient text-center pt-5 text-uppercase"
          style={{ marginTop: "80px", fontSize: "1.75rem" }}
        >
          Check NFT
        </h1>
        <div className="small-border bg-color-2"></div>
      </div>

      <div
        className="container mx-auto  text-light"
        style={{ fontFamily: "Arial" }}
      >
        <p className="text-center">
          You can check the value of your NFTs in this page.
        </p>
        {checkNftState && myGameNft.length > 0 && (
          <p className="text-center">Scroll Sideways to View</p>
        )}
        {!user?.walletAddress || user?.walletAddress === "undefined" ? (
          <LoginButton register="login"></LoginButton>
        ) : (
          ""
        )}
        {checkNftState && myGameNft.length == 0 && (
          <div
            className="menuTextCheck mx-auto text-center py-4"
            style={{ marginLeft: "0px" }}
          >
            <p style={{ cursor: "pointer" }} onClick={checkNfts}>
              We cant detect any NFTs in your wallet.{" "}
              <span style={{ borderBottom: "1px solid white" }}>
                Click here to refresh
              </span>
            </p>{" "}
          </div>
        )}
        {!checkNftState &&
          (!user.walletAddress || user.walletAddress === "undefined" ? (
            ""
          ) : (
            <div
              className="menuTextCheck mx-auto text-center text-uppercase mb-3"
              style={{ marginLeft: "0px" }}
            >
              <button
                style={{ fontSize: "13px" }}
                class="button-18"
                role="button"
                onClick={checkNfts}
              >
                {/* <img
                  className="me-2"
                  width="18px"
                  src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                  alt=""
                />{" "} */}
                CHECK NFT{" "}
              </button>{" "}
            </div>
          ))}

        {checkNftState && myGameNft.length > 0 && (
          <p className="text-center text-gradient">
            Number of playtoearnblockchain.com NFTs you have: {myGameNft.length}
          </p>
        )}
        <div className="row">
          <div>
            <Slider {...settings} className="gap-2">
              {myGameNft?.map((nft, index) => {
                return (
                  <div className="d-item1" key={index}>
                    <div className="card">
                      {/* <div
                        class="card-img"
                        style={{
                          backgroundImage: `url(${
                            nft?.image
                              ? nft?.image
                              : "https://backend.playtoearnblockchain.com/assets/1671536589619.jpeg"
                          })`,
                        }}
                      >
                        <img
                          src="https://i.ibb.co/Pwt1fRw/9ee03415-e591-4320-bf25-af881b8c27a6.jpg"
                          alt=""
                          className="img-fluid nft-watermark2"
                        />
                      </div> */}
                      <div class="card-content rounded border-top-0">
                        <div className="row" style={{ minHeight: "90px" }}>
                          <Typography
                            className="slider_nft_text pb-2"
                            variant="div"
                          >
                            <span className="text-primary">
                              NFT Contract Address :{" "}
                            </span>{" "}
                            {nft?.contractAddress}
                          </Typography>
                          <Typography
                            className="slider_nft_text pb-2"
                            variant="div"
                          >
                            <span className="text-primary">
                              NFT Token Name :{" "}
                            </span>{" "}
                            {nft?.tokenName}
                          </Typography>
                          <Typography
                            className="slider_nft_text pb-2"
                            variant="div"
                          >
                            <span className="text-primary">NFT ID : </span>
                            <br></br> {nft?.tokenID}
                          </Typography>
                          {/* <Typography
                            className="slider_nft_text pb-2"
                            variant="div"
                            component="div"
                          >
                            <span className="text-primary">
                              Price of NFT (USD):
                              <OverlayTrigger
                                key="bottom"
                                placement="bottom"
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>
                                    Price of NFT is the price you purchase the
                                    NFT.
                                  </Tooltip>
                                }
                              >
                                <p
                                  style={{ cursor: "pointer" }}
                                  className="d-inline ps-2"
                                  color="primary "
                                >
                                  <i class="fas fa-info-circle text-white"></i>
                                </p>
                              </OverlayTrigger>
                              <br />
                              <span className="text-light">
                                {nft?.orginalPrice}
                              </span>
                              <Tooltip
                                arrow
                                title=" Price of NFT is the price you purchase the NFT."
                                open={showTooltip}
                                onClose={() => setShowTooltip2(false)}
                              >
                                <p
                                  style={{ cursor: "pointer" }}
                                  className="d-inline ps-2"
                                  color="primary "
                                  onClick={() => setShowTooltip2(!showTooltip2)}
                                >
                                  <i class="fas fa-info-circle text-white"></i>
                                </p>
                              </Tooltip>
                            </span>
                          </Typography> */}
                          <Typography
                            className="slider_nft_text pb-2"
                            variant="div"
                            component="div"
                          >
                            <span className="text-primary">
                              NFT Value (USD) :
                              <OverlayTrigger
                                key="bottom"
                                placement="bottom"
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>
                                    Value of NFT is the value the NFT has. You
                                    can withdraw the eligible value as BUSD.
                                  </Tooltip>
                                }
                              >
                                <p
                                  style={{ cursor: "pointer" }}
                                  className="d-inline ps-2"
                                  color="primary "
                                >
                                  <i class="fas fa-info-circle text-white"></i>
                                </p>
                              </OverlayTrigger>
                              <br />
                              <span className="text-light">
                                {nft?.price >= 0 ? nft?.price : 0}
                              </span>
                              <Tooltip
                                arrow
                                title="Value of NFT is the value the NFT has. You can withdraw the eligible value as BUSD."
                                open={showTooltip3}
                                onClose={() => setShowTooltip3(false)}
                              >
                                <p
                                  style={{ cursor: "pointer" }}
                                  className="d-inline ps-2"
                                  color="primary "
                                  onClick={() => setShowTooltip3(!showTooltip3)}
                                >
                                  <i class="fas fa-info-circle text-white"></i>
                                </p>
                              </Tooltip>
                            </span>
                          </Typography>

                          {/* <Typography className="pb-2" variant="body2">
                                                        <span className="text-primary">NFT Value (USD) :</span><br></br> {nft?.price >= 0 ? nft?.price : 0}
                                                    </Typography> */}
                          {/* <Typography
                            className="slider_nft_text pb-2"
                            variant="div"
                            component="div"
                          >
                            <span className="text-primary">
                              Withdrawal Value (USD) :
                              <OverlayTrigger
                                key="bottom"
                                placement="bottom"
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>
                                    Value of NFT is the value the NFT has. You
                                    can withdraw the eligible value as BUSD.
                                  </Tooltip>
                                }
                              >
                                <p
                                  style={{ cursor: "pointer" }}
                                  className="d-inline ps-2"
                                  color="primary "
                                >
                                  <i class="fas fa-info-circle text-white"></i>
                                </p>
                              </OverlayTrigger>
                              <br />
                              <span className="text-light">
                                {nft?.price - nft?.orginalPrice > 0
                                  ? nft?.price - nft?.orginalPrice
                                  : 0}
                              </span>
                              <Tooltip
                                arrow
                                title="Value of NFT is the value the NFT has. You can withdraw the eligible value as BUSD."
                                open={showTooltip3}
                                onClose={() => setShowTooltip4(false)}
                              >
                                <p
                                  style={{ cursor: "pointer" }}
                                  className="d-inline ps-2"
                                  color="primary "
                                  onClick={() => setShowTooltip4(!showTooltip4)}
                                >
                                  <i class="fas fa-info-circle text-white"></i>
                                </p>
                              </Tooltip>
                            </span>
                          </Typography> */}
                          {/* <Typography className="pb-2" variant="body2">
                                                        <span className="text-primary">Withdrawal Value (USD) :</span><br></br> {(nft?.price - nft?.orginalPrice) > 0 ? (nft?.price - nft?.orginalPrice) : 0}
                                                    </Typography> */}
                          {/* <Typography
                            className="slider_nft_text pb-2"
                            variant="div"
                            component="div"
                          >
                            <span className="text-primary">
                              Experience Points:
                              <OverlayTrigger
                                key="bottom"
                                placement="bottom"
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>
                                    Experience Points are accumulated when you
                                    play our blockchain games.
                                  </Tooltip>
                                }
                              >
                                <p
                                  style={{ cursor: "pointer" }}
                                  className="d-inline ps-2"
                                  color="primary "
                                >
                                  <i class="fas fa-info-circle text-white"></i>
                                </p>
                              </OverlayTrigger>
                              <br />
                              <span className="text-light">
                                {nft?.experiencePoints}
                              </span>
                              <Tooltip
                                arrow
                                title="Experience Points are accumulated when you play our blockchain games."
                                open={showTooltip}
                                onClose={() => setShowTooltip(false)}
                              >
                                <p
                                  style={{ cursor: "pointer" }}
                                  className="d-inline ps-2"
                                  color="primary "
                                  onClick={() => setShowTooltip(!showTooltip)}
                                >
                                  <i class="fas fa-info-circle text-white"></i>
                                </p>
                              </Tooltip>
                            </span>
                          </Typography> */}
                        </div>
                        <hr style={{ margin: "10px 0px 10px 0px" }} />
                        <div
                          className="d-flex card_bottom_btn_main "
                          style={{ margin: "15px 0 8px 0" }}
                        >
                          <div className="col-10 col-md-10 col-lg-10 d-grid">
                            <span className="d-grid">
                              {" "}
                              <button
                                onClick={() => {
                                  withdrawValue(
                                    nft?.price - nft?.orginalPrice,
                                    nft?._id
                                  );
                                }}
                                disabled={
                                  nft?.price - nft?.orginalPrice > 0
                                    ? false
                                    : true
                                }
                                className={`fs-6 ${
                                  nft?.price - nft?.orginalPrice > 0
                                    ? "card_button2 bg-success"
                                    : "card_button2 bg-gray"
                                }`}
                              >
                                Withdraw Value
                              </button>{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>

        <p className="text-center mt-3">
          <Link to="/nfts" className="text-primary">
            Click here to buy NFTs
          </Link>
        </p>
        <p className="text-warning pt-2 text-center">
          You can check only NFTs in the wallet you are connecting to.
        </p>
      </div>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <div>
          <>
            <p
              className="text-gradient text-center fs-6 pt-4"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/nfts")}
            >
              NFTs available: {isMeal?.length}
            </p>

            <p
              style={{ fontSize: "0.9rem" }}
              className="text-white text-center pt-1 mb-0"
            >
              Total value of NFTs: {nftValues} USD
            </p>
            <p
              style={{ fontSize: "0.9rem" }}
              className="text-white text-center pt-"
            >
              Total value of NFTs sold: {nftSoldValues} USD
            </p>
          </>
        </div>
      )}
    </div>
  );
};

export default CheckNft;
