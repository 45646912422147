import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CelebrityContext } from "../../context/CelebrityContext";

const CheckLogin = ({ register, Id, children }) => {
  const { openWalletModal, openWalletModalProfile, user, searchNftTitle } =
    useContext(CelebrityContext);
  const [checkDevice, setCheckDevice] = useState("");
  const [texts, setText] = useState("");
  const [url, setUrl] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const detecting = async () => {
      if (window.innerWidth < 1000 && typeof window.ethereum === "undefined") {
        console.log("mobile");
        setCheckDevice(() => "mobileWithoutApp");
      } else if (
        window.innerWidth < 1000 &&
        typeof window.ethereum !== "undefined"
      ) {
        setCheckDevice(() => "mobileWithApp");
      } else if (
        window.innerWidth > 1000 &&
        typeof window.ethereum !== "undefined"
      ) {
        console.log("pc");
        setCheckDevice(() => "pcWithExtention");
      } else if (
        window.innerWidth > 1000 &&
        typeof window.ethereum === "undefined"
      ) {
        setCheckDevice(() => "pcWithoutExtention");
      }
    };

    detecting();
    console.log(register, "register");
    if (register == "login") {
      setText("Login with Wallet");
      setUrl(
        "https://metamask.app.link/dapp/http://playtoearnblockchain.com/checknft"
      );
    } else if (register == "code") {
      setText("REGISTER TO GET YOUR AFFILIATE CODE");
      setUrl("https://metamask.app.link/dapp/http://playtoearnblockchain.com");
    } else if (register == "pay") {
      setText("Connect Wallet to Buy");
      setUrl(
        `https://metamask.app.link/dapp/http://playtoearnblockchain.com/nft/${Id}/nfts`
      );
    } else if (register == "busdgame") {
      setText("Login with Wallet");
      setUrl(
        `https://metamask.app.link/dapp/http://playtoearnblockchain.com/selectwinhorsechamp`
      );
    }
  }, []);
  return (
    <div>
      {checkDevice === "mobileWithoutApp" && (
        <a href={url} target={"_blank"} className="text-decoration-none">
          <button className="check-login">{children}</button>
        </a>
      )}
      {checkDevice === "mobileWithApp" && (
        <>
          {!user?.walletAddress || user?.walletAddress === "undefined" ? (
            <button className="check-login" onClick={() => openWalletModal()}>
              {children}
            </button>
          ) : (
            <button className="check-login">{children}</button>
          )}
        </>
      )}
      {checkDevice === "pcWithExtention" && (
        <>
          {!user?.walletAddress || user?.walletAddress === "undefined" ? (
            <button className="check-login" onClick={() => openWalletModal()}>
              {children}
            </button>
          ) : (
            <button className="check-login">{children}</button>
          )}
        </>
      )}
      {checkDevice === "pcWithoutExtention" && (
        <a
          href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
          target={"_blank"}
          className="text-decoration-none"
        >
          <button className="check-login">{children}</button>
        </a>
      )}
    </div>
  );
};

export default CheckLogin;
