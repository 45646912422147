import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUnityContext } from 'react-unity-webgl';
import swal from 'sweetalert';

import Button from '@mui/material/Button';
import Game3D from '../Racing/Game3D/Game3D';
import axios from 'axios';
import PositionDialog from './PositionDialog';

const AllChanel = () => {
    const [timeOuts, setTimeouts] = useState('')
    const location = useLocation();
    const [number, setNumber] = useState(0)
    const [open, setOpen] = useState(0)
    const [AllData, setAllData] = useState([])
    let num = 0;
    const { unityProvider, isLoaded, addEventListener, removeEventListener, loadingProgression, sendMessage, requestFullscreen
    } = useUnityContext({
        loaderUrl: './Build/Celebrity 1.28 - Brotli.loader.js',
        dataUrl: "./Build/Celebrity 1.28 - Brotli.data.unityweb",
        frameworkUrl: "./Build/Celebrity 1.28 - Brotli.framework.js.unityweb",
        codeUrl: "./Build/Celebrity 1.28 - Brotli.wasm.unityweb",
    });
    const navigate = useNavigate();

    // position dialog modal
    const handleClickOpen = () => {
        setOpen(true);
    };

    // position dialog modal
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        let url = "";
        console.log(location?.state?.title.title, "location?.state?.title");
        if (location?.state?.title?.title == "busd") {
            console.log("inside busd");
            url = "https://backend.playtoearnblockchain.com/api/v1/raceBUSD";
        } else {
            console.log("inside dsl");
            url = "https://backend.playtoearnblockchain.com/api/v1/raceDSL/";

        }
        axios
            .get(url, {
                headers: {
                    authorization: `Bearer bs2zDM01DMMEgt33CrKZt8x4abs2zDM01DMMEgt33Cr6atzL3CJdExpP4x4abs2zDM01DMMEgt33Cr`,
                },
            })
            .then((res) => {
                // setAllData(res?.data.data.reverse());
                let lastSerialNumber = 1;
                let newSerialNumberObjects = res?.data.data.map((object, index) => {
                    let newObject = { ...object };
                    let serialNumber = lastSerialNumber + index;
                    newObject.sno = serialNumber.toString().padStart(15, "0");
                    console.log(newObject, "newObject")
                    return newObject;
                });
                setAllData(newSerialNumberObjects.reverse());

            });
    }, [location?.state?.title]);

    const RaceFinish = useCallback(() => {
        // sendMessage("Bridge", "InstantRestart");
        // sendMessage("GameManager", "WebReStartBtnClickedForTMP", 5);
        // // sendMessage("GameManager", "WebStartBtnClicked");
        // setNumber(number => number + 1)
        // handleClickOpen()
        // setTimeout(() => {
        //     sendMessage("Bridge", "FeedJsonData", JSON.stringify({ data: [AllData[number]] }));
        // }, 2000);
    }, []);

    useEffect(() => {
        addEventListener("RaceFinish", RaceFinish);

        return () => {
            removeEventListener("RaceFinish", RaceFinish);

        };
    }, [addEventListener, removeEventListener, RaceFinish]);

    useEffect(() => {

        window.RaceFinish = () => {
            // sendMessage("Bridge", "InstantRestart");
            // sendMessage("GameManager", "WebReStartBtnClickedForTMP", 5);
            setNumber(number => number + 1)
            // // setTimeout(() => {
            // //     sendMessage("GameManager", "WebStartBtnClicked");
            // // }, 3000);
            // setTimeout(() => {
            //     sendMessage("Bridge", "FeedJsonData", JSON.stringify({ data: [AllData[number]] }));
            // }, 4000);
            // num = num + 1;
            // setNumber(number => number + 1)
            handleClickOpen()
        };

    }, [unityProvider]);




    useEffect(() => {
        console.log("in the page")
        return () => {
            console.log("outside the page")
            if (unityProvider) {
                sendMessage("GameManager", "QuitGame")
            }
        };
    }, [isLoaded, unityProvider]);

    const raceReplay = () => {
        num = num + 1;
        sendMessage("GameManager", "SwitchCamContinuously")
        handleClose()
        setTimeouts(setTimeout(() => {

            sendMessage("Bridge", "InstantRestart");

            setTimeout(() => {
                sendMessage("GameManager", "WebStartBtnClicked");

            }, 1000);
            setTimeout(() => {
                sendMessage("Bridge", "FeedJsonData", JSON.stringify({ data: [AllData[num]] }));
                sendMessage("GameManager", "SwitchCamContinuously");
                console.log(AllData[num], "AllData[number]", num, number)
            }, 2000);
            raceReplay()

        }, 50000))
    }

    const locataion = useLocation()
    useEffect(() => {
        if (isLoaded == true) {
            sendMessage("GameManager", "WebStartBtnClicked");
            setTimeout(() => {
                sendMessage("Bridge", "FeedJsonData", JSON.stringify({ data: [AllData[number]] }));
                console.log(AllData[number], "AllData[number]222", num)
                raceReplay()
            }, 1000);
            // setInterval(() => {
            //     sendMessage("Bridge", "FeedJsonData", JSON.stringify(AllData[number]));
            // }, 100);
        }

    }, [isLoaded])
    return (
        <>
            <div className='container replay-container'>
                <div style={{ paddingTop: "110px", position: 'relative' }} className="replays text-center">
                    {/* <h5 onClick={() => {
                    navigate("/racing", { state: { name: 'replay', autoOpen: true } });
                    sendMessage("GameManager", "QuitGame"); clearTimeout(timeOuts)
                }} className='text-white mt-2' style={{ position: 'absolute', right: 0, cursor: 'pointer' }}>X</h5> */}
                    <h1 className='text-white text-center pb-2' style={{ fontSize: "2rem" }}>Race Channel</h1>
                    <button
                        className="btn px-4 text-white mb-3"
                        onClick={() => navigate("/selectwinhorsechamp")}
                        style={{ backgroundColor: "#36698c" }}
                    >
                        Play Now
                    </button>
                    <h4 className='text-white text-center pb-4'>Race Number : {AllData[number]?.sno ? AllData[number]?.sno : 0}</h4>
                    <div>
                        {isLoaded && <img src='https://img.icons8.com/color/48/null/switch-camera.png' onClick={() => sendMessage("Canvas", "ChangeCam")} class="fa-expand"></img>}
                        <Game3D

                            unityProvider={unityProvider}
                            isLoaded={isLoaded}
                            loadingProgression={loadingProgression}
                            sendMessage={sendMessage}
                        ></Game3D>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                    <Button
                        onClick={() => {
                            sendMessage("GameManager", "QuitGame");
                            clearTimeout(timeOuts);
                            navigate("/allrace", { state: { name: 'replay', autoOpen: true, AllData: locataion?.state?.AllData } });
                        }}
                        className="btn-main lead text-white"
                    >
                        Close
                    </Button>
                    <PositionDialog
                        handleClickOpen={handleClickOpen}
                        open={open}
                        title={location?.state?.title.title ? location?.state?.title.title : "dsl"}
                        sno={AllData[number - 1]?.sno}
                        // positions={positions}
                        // myDog={myDog}
                        raceNumber={number}
                        handleClose={handleClose}
                    ></PositionDialog>

                </div>
            </div>
        </>
    );
};

export default AllChanel;