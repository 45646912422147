import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const MetaData = ({pageTitle,pageDescription}) => {
  const location = useLocation();

    useEffect(() => {
      // Extract information from the location object or use any other logic
  
      // Update the document title and meta description
      document.title = pageTitle;
    }, [location.pathname]); // Re-run the effect when the pathname changes

  return (
    <HelmetProvider>
      <Helmet>
        {/* Set dynamic title and meta description */}
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />


      </Helmet>

          <div>
              
        {/* Your component content */}
      </div>
    </HelmetProvider>
  );
};

export default MetaData;
