import { faSquarePollVertical, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { MdCategory, MdOutlinePayments, MdPayment } from 'react-icons/md';
// import { GrighundContext } from '../../../context/GrighundContext';
import './DashboardMenu.css';


const DashboardMenu = () => {
    const [allAdmin, setAllAdmin] = useState([])
    const [mintNft, setMintNft] = useState([])
    const [allCategory, setAllCategory] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get('https://backend.playtoearnblockchain.com/api/v1/admin/')
            .then(res => {
                setAllAdmin(res.data.length);
                console.log(res.data)
            })
    }, [])

    useEffect(() => {
        axios.get('https://backend.playtoearnblockchain.com/api/nft/all', {
            headers: { "authorization": `Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54` }
        })
            .then(res => {
                setMintNft(res?.data?.nft?.length);
            })
    }, [])

    useEffect(() => {
        fetch("https://backend.playtoearnblockchain.com/api/v1/category")
            .then(res => res.json())
            .then(data => {
                setAllCategory(data.Categories)
            })
    }, [])

    const [data, setData] = useState([])
    const [data1, setData1] = useState([])
    const [data2, setData2] = useState([])
    const [data3, setData3] = useState([])
    const [data4, setData4] = useState([])
    const [data5, setData5] = useState([])
    const [total, setTotal] = useState(0)
    const [total1, setTotal1] = useState(0)
    const [total2, setTotal2] = useState(0)
    const [total3, setTotal3] = useState(0)
    const [total4, setTotal4] = useState(0)
    const [total5, setTotal5] = useState(0)
    useEffect(() => {
        axios.get("https://backend.playtoearnblockchain.com/api/v1/admin-payment-dsl-record")
            .then(res => {
                const flag = res.data.result
                setTotal(flag.length)
                setData(flag.filter(item => item.claim == false).length)
                console.log(data, "datax")
            })
        axios.get("https://backend.playtoearnblockchain.com/api/v1/admin-payment-busd-record")
            .then(res => {
                const flag = res.data.result
                setTotal1(flag.length)
                setData1(flag.filter(item => item.claim == false).length)
                console.log(data, "datay")
            })

        axios.get("https://backend.playtoearnblockchain.com/api/v1/login-reward")
            .then(res => {
                const flag = res.data.result
                setTotal2(flag.length)
                setData2(flag.filter(item => item.claim == false).length)
                console.log(data2, "dataz")
            })
        axios.get("https://backend.playtoearnblockchain.com/api/v1/admin-payment-withdraw-record")
            .then(res => {
                const flag = res.data.result
                setTotal3(flag.length)
                setData3(flag.filter(item => item.claim == false).length)
                console.log(data3, "dataz")
            })
        axios.get("https://backend.playtoearnblockchain.com/api/v1/admin-payment-dslcatchit-record")
            .then(res => {
                const flag = res.data.result
                setTotal4(flag.length)
                setData4(flag.filter(item => item.claim == false).length)
                console.log(data3, "dataz")
            })
        axios.get("https://backend.playtoearnblockchain.com/api/v1/admin-payment-fortune-record")
            .then(res => {
                const flag = res.data.result
                setTotal5(flag.length)
                setData5(flag.filter(item => item.claim == false).length)
                console.log(data3, "dataz")
            })

    }, [])


    const handleClickOpenAdmin = () => {
        navigate('/dashboard/admin');
    }


    const handleClickOpenPolygon = () => {
        navigate('/dashboard/nfts')
    }


    return (
        <div className='handleHeightDMenu'>
            <div className='container'>

                <h4 className='text-white text-start marginAlign dashboardTitle'>Dashboard</h4>
                <Row xs={1} md={2} lg={3} xl={4} className="gx-5 card-row">

                    <Col onClick={handleClickOpenAdmin} className="marginAlign">
                        <Card className='cardDash mx-auto'>
                            <Card.Body className="card-body w-100 d-flex align-items-center justify-content-between">
                                <Card.Text className='dashboardTxt'>
                                    <p>ADMINS</p>
                                    <h2 className='text-start'>{allAdmin}</h2>
                                </Card.Text>
                                <div className="iconDas">
                                    <p className='text-white coinsIcon'>
                                        <FontAwesomeIcon icon={faUsers} />
                                    </p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col onClick={handleClickOpenPolygon} className="marginAlign">
                        <Card className='cardDash mx-auto'>
                            <Card.Body className="card-body w-100 d-flex align-items-center justify-content-between">
                                <Card.Text className='dashboardTxt'>
                                    <p>NFTS</p>
                                    <h2 className='text-start'>{mintNft}</h2>
                                </Card.Text>
                                <div className="iconDas">
                                    <p className='text-white coinsIcon'><i class="fas fa-dot-circle"></i></p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col onClick={() => navigate("/dashboard/categories")} className="marginAlign">
                        <Card className='cardDash mx-auto'>
                            <Card.Body className="card-body w-100 d-flex align-items-center justify-content-between">
                                <Card.Text className='dashboardTxt'>
                                    <p>CATEGORIES</p>
                                    <h2 className='text-start'>{allCategory.length}</h2>
                                </Card.Text>
                                <div className="iconDas">
                                    <p className='text-white coinsIcon'><MdCategory></MdCategory></p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col onClick={() => navigate("/dashboard/payments")} className="marginAlign">
                        <Card className='cardDash mx-auto' style={{ width: 'auto' }}>
                            <Card.Body className="card-body w-100 d-flex align-items-center justify-content-between">
                                <Card.Text className='dashboardTxt'>
                                    <p>PAYMENT MADE</p>
                                    <h2 className='text-start'>{(total + total1 + total2 + total3 + total4 + total5) - (data + data1 + data2 + data3 + data4 + data5)}</h2>
                                </Card.Text>
                                <div className="iconDas">
                                    <p className='text-white coinsIcon'><MdPayment></MdPayment></p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col onClick={() => navigate("/dashboard/payments")} className="marginAlign pt-lg-3">
                        <Card className='cardDash mx-auto' style={{ width: 'auto' }}>
                            <Card.Body className="card-body w-100 d-flex align-items-center justify-content-between">
                                <Card.Text className='dashboardTxt'>
                                    <p>PAYMENT PENDING</p>
                                    <h2 className='text-start'>{data + data1 + data2 + data3 + data4 + data5}</h2>
                                </Card.Text>
                                <div className="iconDas">
                                    <p className='text-white coinsIcon'><MdOutlinePayments></MdOutlinePayments></p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>


            </div>
        </div>
    );
};

export default DashboardMenu;