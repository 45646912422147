import React, { useEffect } from 'react';
import { Unity, useUnityContext } from "react-unity-webgl";
import CircularProgressWithLabel from '../CircularProgressWithLabel/CircularProgressWithLabel';

const Game3D = ({ unityProvider, isLoaded, loadingProgression, sendMessage, }) => {

  const loadingPercentage = Math.round(loadingProgression * 100);
  return (
    <div style={{ position: 'relative' }}>
      {isLoaded === false && (
        // We'll conditionally render the loading overlay if the Unity
        // Application is not loaded.
        <div className="loading-overlay text-center mx-auto">
          <CircularProgressWithLabel value={loadingPercentage} />

          {/* <p className='text-white'>Loading... ({loadingPercentage}%)</p> */}
        </div>
      )}
      <Unity className="unity" style={{ width: '100%' }} unityProvider={unityProvider} />

    </div>
  );
};

export default Game3D;