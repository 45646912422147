
const India = () => {

    return (
        <div style={{ height: '500px', backgroundColor: '#1A1A25', height: "100vh" }}>
            <h1 style={{ marginTop: '80px' }} className='text-gradient text-center pt-5 text-uppercase'>India</h1>
        </div>
    );

};

export default India;