import { CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import ReactPaginate from "react-paginate";
import Loader from "react-spinners/BounceLoader";
import "./AllRace.css";
import { DatePicker } from "antd";
import "antd/dist/reset.css";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const AllRace = ({ title }) => {
  const [race, setRace] = useState([]);
  const [allData, setAllData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loading, setloading] = useState(true);
  const [serialNumbers, setSerialNumbers] = useState([]);
  const itemsPerPage = 20;

  console.log("race", race);

  useEffect(() => {
    let url = "";
    if (title == "busd") {
      console.log("inside busd");
      url = "https://backend.playtoearnblockchain.com/api/v1/raceBUSD";
    } else {
      console.log("inside dsl");
      url = "https://backend.playtoearnblockchain.com/api/v1/raceDSL/";
    }
    axios
      .get(url, {
        headers: {
          authorization: `Bearer bs2zDM01DMMEgt33CrKZt8x4abs2zDM01DMMEgt33Cr6atzL3CJdExpP4x4abs2zDM01DMMEgt33Cr`,
        },
      })
      .then((res) => {
        console.log(res?.data.data, "myy data");
        setRace(res?.data.data.reverse());
        setAllData(res?.data.data.reverse());
        setloading(false);
        let lastSerialNumber = 1;
        let newSerialNumberObjects = res?.data.data.map((object, index) => {
          let newObject = { ...object };
          let serialNumber = lastSerialNumber + index;
          newObject.sno = serialNumber.toString().padStart(15, "0");
          console.log(newObject, "newObject")
          return newObject;
        });
        setAllData(newSerialNumberObjects.reverse());
      });
  }, [title]);

  function searchItem(event) {
    const searchText = event.target.value;
    console.log(race, searchText);
    const searchEmail = allData.filter((proc) =>
      proc?.email?.toLowerCase().includes(searchText.toLowerCase())
    );
    setRace(searchEmail);
  }
  // axios.get('https://backend.grighund.net/api/race').then(data => setRace(data?.data));

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;

  const currentItems = allData?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(allData.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % allData.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  // todays date
  let today = new Date();
  let day = today.getDate();
  let month = today.getMonth() + 1;
  let year = today.getFullYear().toString();

  if (day < 10) {
    day = "0" + day;
  }

  if (month < 10) {
    month = "0" + month;
  }

  let formattedTodayDate = day + "/" + month + "/" + year;
  // ---

  // 3 months previous date
  let prevthreeMonthsAgo = new Date(
    today.getFullYear(),
    today.getMonth() - 3,
    today.getDate()
  );
  let prevday = prevthreeMonthsAgo.getDate();
  let prevmonth = prevthreeMonthsAgo.getMonth() + 1;
  let prevyear = prevthreeMonthsAgo.getFullYear().toString();

  if (prevday < 10) {
    prevday = "0" + prevday;
  }

  if (prevmonth < 10) {
    prevmonth = "0" + prevmonth;
  }
  let formattedPrevDate = prevday + "/" + prevmonth + "/" + prevyear;

  useEffect(() => {
    setStartDate(formattedPrevDate);
    setEndDate(formattedTodayDate);
  }, []);

  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];

  const handleSearchByDate = () => {
    const startDateString = new Date(
      Date.parse(startDate.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"))
    );
    const endDateString = new Date(
      Date.parse(endDate.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"))
    );

    const filteredData = race?.filter((item) => {
      const createdAt = new Date(item.createdAt);
      return createdAt >= startDateString && createdAt <= endDateString;
    });

    console.log(
      "startDate",
      startDate,
      "endDate",
      endDate,
      "race",
      race,
      "filteredData",
      filteredData
    );
    setAllData(filteredData);
  };
  // console.log("filteredData", filteredData);

  const onChangeStartDate = (date, dateString) => {
    setStartDate(dateString);
  };
  const onChangeEndDate = (date, dateString) => {
    setEndDate(dateString);
  };
  const navigate = useNavigate();
  return (
    <div className="pt-3 container">
      <div className="row">
        <div className="col-12 col-lg-6 text-center text-lg-start">
          <div className="d-flex align-items-center flex-sm-row flex-column justify-content-center justify-content-lg-start">
            <button
              className="btn px-4 text-white mb-3 mb-lg-0 me-0 me-lg-3"
              onClick={() => navigate("/selectwinhorsechamp")}
              style={{ backgroundColor: "#36698c", minWidth: "130px" }}
            >
              Play
            </button>
            <button style={{ minWidth: "130px" }} className="btn btn-success mb-3 mb-lg-0" onClick={() => navigate('/allchannel', { state: { title: { title } } })}>Race Channel</button>
          </div>
        </div>
        <div className="col-12 col-lg-6 text-center text-lg-end">
          <h6 className="text-white">Search:</h6>

          <div className="d-flex justify-content-end date-container">
            <DatePicker
              defaultValue={dayjs(formattedPrevDate, "DD/MM/YYYY")}
              format={dateFormatList}
              className="me-1"
              onChange={onChangeStartDate}
            />
            <div className="d-flex">
              <DatePicker
                className="ms-1"
                defaultValue={dayjs(formattedTodayDate, "DD/MM/YYYY")}
                format={dateFormatList}
                onChange={onChangeEndDate}
              />
              <button
                style={{
                  borderTop: "1px solid #585858",
                  borderLeft: "1px solid #585858",
                  borderBottom: "1px solid #585858",
                  borderRight: "none",
                }}
                className="bg-dark text-center cursor-pointer px-2 searchRadious text-white"
                type="button"
                onClick={handleSearchByDate}
              >
                <i class="fas fa-search"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 text-center mt-4">
          {/* <div>
         <div className='row'>
          <div className='col-sm-12 col-md-4 offset-md-8'>
         <div class="input-group mb-3 mt-4 ">
                              <input type="text" class="form-control" placeholder="Enter email"
                              onChange={searchItem} aria-label="Recipient's username" aria-describedby="button-addon2"/>
                              <button class="btn btn-success border-rad" type="button" id="button-addon2">Search</button>
                            </div>
         </div>
         </div>
          </div> */}
          {console.log("allData", allData)}
          <Accordion defaultActiveKey={0}>
            {!loading ? (
              <>
                {allData?.length > 0 ? (
                  <>
                    {currentItems?.map((item, index) => (
                      <Accordion.Item key={index} eventKey={index}>
                        <Accordion.Header className="accordation-header accordian-lg">
                          S/NO: {item?.sno} Time: {item?.race[0]?.date}
                        </Accordion.Header>
                        <Accordion.Header className="accordation-header accordian-sm">
                          S/NO: {item?.sno} <br /> Time: {item?.race[0]?.date}
                        </Accordion.Header>
                        <Accordion.Body className="bg-dark">
                          <div>
                            {" "}
                            <button
                              className="btn btn-info mx-auto text-white"
                              style={{ background: "#006e7a" }}
                              onClick={() => { navigate("/allreplay", { state: { place: item?.race, myDog: item?.selectedDog } }); console.log(item, "item?.selectedDog") }}
                            >
                              REPLAY
                            </button>
                          </div>
                          <div className=" mt-md-4 mt-sm-2 text-start table-responsive">
                            <table className="table table-borderless">
                              <thead>
                                <tr className="text-white mb-4 position-table-header">
                                  <th scope="col">Position</th>
                                  <th scope="col">Track</th>
                                  <th scope="col">Horse</th>
                                  <th scope="col">Strength</th>
                                  <th scope="col">Probability</th>
                                  <th scope="col">Points</th>
                                  <th scope="col">Timing</th>
                                  <th scope="col">Won(USD)</th>
                                </tr>
                              </thead>
                              <tbody>
                                {item?.race?.map((item, index) => (
                                  <tr
                                    key={index}
                                    className={`text-white position-table-body`}
                                  >
                                    <td scope="row">{item?.position}</td>
                                    <td>{item?.track}</td>
                                    <td className="text-start">
                                      {/* handleAlignDogResult */}
                                      <img
                                        src={item?.img}
                                        alt="dog"
                                        className="dog mb-2 dog-size"
                                      ></img>
                                      <br></br>{" "}
                                      <span className="hideSm ">
                                        {item?.gender}
                                      </span>
                                      <span className="hideLg text-start">
                                        {item?.gender}
                                      </span>
                                    </td>
                                    <td>{item?.strength}</td>
                                    <td>{item?.probability}</td>
                                    <td>
                                      {item?.strength + item?.probability}
                                    </td>
                                    <td>{item?.time / 10}s</td>
                                    <td>
                                      $
                                      {item?.prize
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </>
                ) : (
                  <p className="text-danger">Data not found</p>
                )}
              </>
            ) : (
              <div
                className="d-flex align-items-center justify-content-center "
                style={{ height: "70vh" }}
              >
                <CircularProgress className="text-center" color="inherit" />
              </div>
            )}
          </Accordion>
        </div>
        <div className="col-12">
          <div className="pagination">
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              marginPagesDisplayed={2}
              containerClassName="pagination"
              activeClassName="active"
            />
          </div>
        </div>
      </div>
    </div >
  );
};
// {race[0]?.race?.map((place,index)=>{
//     <Accordion.Item key={index} eventKey={index}>
//     <Accordion.Header>Race {index} Time : {place?.date}</Accordion.Header>
//     <Accordion.Body>
//     <div className=" mt-md-4 mt-sm-2 text-start table-responsive">
//                <table className="table table-borderless ">
//                  <thead>
//                    <tr className='text-white mb-4 position-table-header'>
//                      <th scope="col">Position</th>
//                      <th scope="col">Track</th>
//                      <th scope="col">Dog</th>
//                      <th scope="col">Strength</th>
//                      <th scope="col">Probability</th>
//                      <th scope="col">Points</th>
//                      <th scope="col">Timing</th>
//                      <th scope="col">Won(USD)</th>
//                    </tr>
//                  </thead>
//                  <tbody>
//                    {
//                      place?.race?.map((item, index) =>
//                        <tr key={index} className={`text-white position-table-body`}>
//                          <td scope="row">{place.position}</td>
//                          <td>
//                          {place.track}
//                          </td>
//                           <td className='text-start'>
//                            {/* handleAlignDogResult */}
//                           <img src={item?.img} alt='dog' className='dog mb-2 dog-size'></img><br></br> <span className='hideSm '>{item?.gender}</span><br /> <span className='hideLg text-start'>{item?.gender}</span>
//                          </td>
//                          <td>{item?.strength}</td>
//                          <td>{item?.probability}</td>
//                          <td>{item?.strength + item?.probability}</td>
//                          <td>{item?.time}s</td>
//                          <td>${item?.prize.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</td>
//                        </tr>
//                      )
//                    }

//                  </tbody>
//                </table>
//                {/* {
//                  sumPoint.map(point => <p>{point}</p>)
//                } */}
//              </div>
//     </Accordion.Body>
//    </Accordion.Item>
//    })}

export default AllRace;
