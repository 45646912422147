import React, { useContext, useEffect, useState, useCallback } from "react";
import "./Profile.css";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappIcon,
  WhatsappShareButton,
  PinterestIcon,
  PinterestShareButton,
  TelegramIcon,
  TelegramShareButton,
} from "react-share";
import { Link, useNavigate } from "react-router-dom";
import { CelebrityContext } from "../../../context/CelebrityContext";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useTimer } from "react-timer-hook";
import EmailVerifyModal from "../MealNft/EmailVerifyModal";
import ProfileQRModal from "./ProfileQRModal";
import LoaderPro from "../../Loading/LoaderPro";
import CopyToClipboard from "react-copy-to-clipboard";

const Profile = ({ expiryTimestamp }) => {
  const {
    user,
    logOut,
    metamaskBalanceLoading,
    userRefetch,
    accountChangeLoading,
    setUserRefetch,
    getBalanceMainnet,
    tryThis,
  } = useContext(CelebrityContext);
  const [email1, setEmail] = useState("");
  const [useremail, setUserEmail] = useState(
    user?.email || "Verify Email to View"
  );
  const [emailVerify, setEmailVerify] = useState(false);
  const [disableAfterActivation, setDisableAfterActivation] = useState(false);
  const [otpVerify, setOtpVerify] = useState();
  const [openEmail, setOpenEmail] = useState(false);
  const [openQRModal, setOpenQRModal] = useState(false);
  const [qRFromDatabase, setQRFromDatabase] = useState("");
  const [qRFromDatabaseGet, setQRFromDatabaseGet] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [CurrentFile, setCurrentFile] = useState("");
  const [isError, setError] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const navigate = useNavigate();
  // let history = useHistory();

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", async function () {
        console.warn("TATIIIIIIIIIIII");

        tryThis();
      });
    }
  }, []);


  useEffect(() => {
    setUserEmail(user?.email);
    window.scrollTo(0, 0);
    getBalanceMainnet();
  }, [user]);

  useEffect(() => {
    if (metamaskBalanceLoading) {
      return <LoaderPro></LoaderPro>;
    }
  }, []);

  useEffect(() => {
    // if (!user.email || !user.enail === "undefined") {
    //   swal({
    //     text: "Please update your email before proceeding further. You stand to win attractive prizes monthly.",
    //     icon: "warning",
    //     button: "OK",
    //     dangerMode: true,
    //     className: "modal_class_success",
    //   });
    // }
  }, [user]);

  const LogOut = () => {
    logOut();
    navigate("/");
    swal({
      title: "Success",
      text: "You have successfully logged out",
      icon: "success",
      button: "OK",
      className: "modal_class_success",
    });
  };
  //image update
  const handleUpdateImage = async () => {
    const ImageFormData = new FormData();
    ImageFormData.append("image", CurrentFile);

    if (CurrentFile) {
      await axios
        .put(
          `https://backend.playtoearnblockchain.com/api/v1/user/update/${user?._id}`,
          ImageFormData,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem(
                "TestGptnftToken"
              )}`,
            },
          }
        )
        .then((res) => {
          console.log("update");
          if (res.status === 200) {
            console.log(res.data);
            swal({
              title: "Successful",
              text: "Image updated successfully",
              icon: "success",
              button: "OK",
              className: "modal_class_success",
            });
            setPreviewImage("");
            setCurrentFile("");
            setUserRefetch(!userRefetch);
          }
        });
    }
  };

  // Re-send OTP functionality
  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
  });

  const restarting = (sec) => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + sec);
    restart(time);
  };
  const removedUrl = "https://backend.celebritygames.net";

  const handleVerifyEmail = async (e) => {
    setDisableAfterActivation(true);
    if (email1.length > 0 && email1.includes("@" && ".")) {
      setEmailVerify(true);
      await axios
        .post("https://backend.playtoearnblockchain.com/api/v1/verifymint/mail", {
          email: email1,
        })
        .then((res) => {
          if (res.status === 200) {
            restarting(180);
            swal({
              text: res.data.message,
              icon: "success",
              button: "OK!",
              className: "modal_class_success",
            });
            setOtpVerify(res.data.otp);
            console.log("otppp: " + res.data.otp);
            setTimeout(() => {
              setDisableAfterActivation(false);
            }, 120000);
          }
          setOpenEmail(true);
        })
        .catch((err) => {
          setEmailVerify(false);
          swal({
            title: "Attention",
            text: err.response.data.message,
            icon: "warning",
            button: "OK!",
            className: "modal_class_success",
          });
        })
        .finally(() => { });
    } else {
      swal({
        title: "Attention",
        text: "Please enter a valid email address",
        icon: "warning",
        button: "OK!",
        className: "modal_class_success",
      });
    }
  };

  const updateProfile = async () => {
    const verifiedEmail = email1;
    console.log(verifiedEmail);

    const email = JSON.stringify({ email: verifiedEmail });

    await axios
      .put(
        `https://backend.playtoearnblockchain.com/api/v1/user/update/${user?._id}`,
        email,
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        setUserRefetch(!userRefetch);
      })
      .catch((err) => {
        swal({
          title: "Attention",
          text: `${err.response.data.message}`,
          icon: "warning",
          button: "OK!",
          className: "modal_class_success",
        });
      });
  };
  const selectFile = (event) => {
    setCurrentFile(event.target.files[0]);
    setPreviewImage(URL.createObjectURL(event.target.files[0]));
  };
  useEffect(() => {
    axios
      .get(`https://backend.dsl.sg/api/v1/qr1/qrcode/${user?.walletAddress}`)
      .then((res) => {
        setQRFromDatabaseGet(res?.data?.qrcode);

        console.log(res?.data?.qrcode, "--qr");
      });
    setRefetch(false);
  }, [refetch]);

  const profileClickHere = async () => {
    if (!user.email) {
      swal({
        title: "Attention",
        text: "Please update your profile first!",
        icon: "warning",
        button: "OK!",
        className: "modal_class_success",
      });
    } else {
      if (!qRFromDatabaseGet) {
        const formData = {
          email: user?.email,
          walletAddress: user?.walletAddress,
          // walletAddress: 'goigreijgi',
          status: true,
          webName: "https://backend.dsl.sg/",
        };

        await axios
          .post("https://backend.dsl.sg/api/v1/qr1/qrcode/", formData)
          .then((res) => {
            if (res.status === 200) {
              setQRFromDatabase(res.data.qrcode);
              console.log(res.data.qrcode);
              setRefetch(true);
            }
          })
          .catch((error) => {
            console.log(error);
          });
        setOpenQRModal(true);
      } else {
        setQRFromDatabase(qRFromDatabaseGet);
        setOpenQRModal(true);
      }
    }
  };

  const mintInfo = () => {
    navigate("/mintedDetails");
  };

  const onCopy = useCallback(() => {
    // setCopied(true);
    console.log("copyieddd");
    alert("Copied");
  }, []);

  return (
    <>
      <div className="handleTheProfileBody mb-5">
        <div className="container pt-5 text-white ">
          <div
            className="position-change text-info ms-md-2 desktopProfile"
            style={{ display: "none" }}
          >
            <h3 className="mb-4 ms-4 ms-md-5  profileTitile">Profile</h3>
          </div>

          <div className="shadow-lg rounded-lg py-5 px-4 p-md-5 align-items-center topmarginn">
            <div className="profileTextBtn">
              <div
                className="ps-2 pe-2"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h3 className="ms-md-5">Profile</h3>
                <button onClick={mintInfo} className="btn btn-danger btn-sm">
                  MINTED
                </button>
              </div>
            </div>
            <br />
            <div className="desktopProfile">
              <div
                className="ps-2 pe-2 mb-3"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h3>Profile</h3>
                <button onClick={mintInfo} className="btn btn-danger btn-sm">
                  MINTED
                </button>
              </div>
            </div>
            <div className="row" style={{ rowGap: "10px" }}>
              <div className="col-md-6 px-3">
                <div className="mb-2">
                  <div className="imageDivClass">
                    {!user?.image && !previewImage && (
                      <img
                        src="https://i.ibb.co/JtVwzXs/default.jpg"
                        width={200}
                        height={200}
                        className=""
                        alt=""
                      />
                    )}
                    {user?.image && !previewImage && (
                      <img
                        src={user?.image}
                        width={200}
                        height={200}
                        className=""
                        alt=""
                      />
                    )}
                    {previewImage && (
                      <img
                        src={previewImage}
                        width={200}
                        height={200}
                        className=""
                        alt=""
                      />
                    )}
                  </div>
                  <div className="mt-3">
                    <label className="mb-1">Profile image</label>
                    <div className="d-flex rounded-3 border ps-2">
                      <input
                        className="w-100 py-1 cursor-pointer"
                        onChange={selectFile}
                        type="file"
                        accept="image/*"
                        name="image"
                        id="image"
                      />
                      {CurrentFile ? (
                        <button
                          disabled={!previewImage || !CurrentFile}
                          onClick={() => handleUpdateImage()}
                          className="border-0 px-4 p-2 w-25 d-flex justify-content-center"
                          style={{
                            color: "#ffffff",
                            backgroundColor:
                              !previewImage || !CurrentFile
                                ? "rgb(151, 145, 145)"
                                : "#FF5421",
                            borderRadius: "0 .5rem .5rem 0",
                          }}
                        >
                          Update
                        </button>
                      ) : (
                        <button
                          disabled={!previewImage || !CurrentFile}
                          onClick={() => handleUpdateImage()}
                          className="border-0 px-4 p-2 w-25 d-flex justify-content-center"
                          style={{
                            color: "#ffffff",
                            backgroundColor:
                              !previewImage || !CurrentFile
                                ? "rgb(151, 145, 145)"
                                : "#FF5421",
                            borderRadius: "0 .5rem .5rem 0",
                          }}
                        >
                          {user?.image ? "Updated" : "Update"}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mb-2">
                  <label htmlFor="walletAddress">Wallet Address</label>
                  <div className="d-flex">
                    <input
                      type="text"
                      id="walletAddress"
                      name="walletAddress"
                      value={
                        accountChangeLoading
                          ? "Updating your data"
                          : user?.walletAddress
                      }
                      className="form-control bg-transparent text-white rounded-0 rounded-start"
                      disabled
                    />

                    <CopyToClipboard text={user?.walletAddress} onCopy={onCopy}>
                      <button
                        type="button"
                        className="border bg-success rounded-0 rounded-end"
                      >
                        <FontAwesomeIcon icon={faCopy} />
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>
                <div className="mb-2">
                  <label htmlFor="walletAddress">Email Address</label>
                  <div className="d-flex">
                    <input
                      style={
                        user.email
                          ? {
                            textTransform: "lowercase",
                            borderTopRightRadius: "inherit",
                            borderBottomRightRadius: "inherit",
                          }
                          : {
                            textTransform: "lowercase",
                            borderTopRightRadius: "inherit",
                            borderBottomRightRadius: "inherit",
                          }
                      }
                      type="email"
                      name="email"
                      className="form-control bg-transparent text-white"
                      placeholder="Email Address"
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setUserEmail(e.target.value);
                        setEmailVerify(false);
                      }}
                      value={
                        accountChangeLoading
                          ? "Updating your data"
                          : useremail
                            ? useremail
                            : email1
                      }
                      required
                    />
                    <button
                      type="button"
                      onClick={handleVerifyEmail}
                      disabled={
                        email1.length === 0 || emailVerify ? true : false
                      }
                      className={
                        email1.length === 0 || emailVerify
                          ? "border bg-secondary text-white rounded-0 rounded-end"
                          : "border bg-danger text-white rounded-0 rounded-end"
                      }
                    >
                      {email1.length === 0 || emailVerify
                        ? "Verified"
                        : "Verify"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-3">
                <div className="mb-2">
                  <label htmlFor="referralID">Referral ID</label>
                  <div className="d-flex">
                    <input
                      placeholder="Verify Email to View"
                      type="text"
                      id="referralID"
                      name="referralID"
                      value={
                        accountChangeLoading
                          ? "Updating your data"
                          : user?.email
                            ? user?.myReferralCode
                            : ""
                      }
                      className="form-control bg-transparent text-white rounded-0 rounded-start"
                      disabled
                    />
                    {/* <button type="button" onClick={() => copyToClipboard(user?.myReferralCode)} className="border bg-success rounded-0 rounded-end">
                      <FontAwesomeIcon icon={faCopy} />
                    </button> */}
                    <CopyToClipboard
                      text={user?.email ? user?.myReferralCode : ""}
                      onCopy={onCopy}
                    >
                      <button
                        type="button"
                        // onClick={() => myFunction(user?.walletAddress)}
                        className="border bg-success rounded-0 rounded-end"
                      >
                        <FontAwesomeIcon icon={faCopy} />
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>

                <div className="mb-2">
                  <label htmlFor="referralID">Affiliate Link</label>
                  <div className="d-flex">
                    <input
                      placeholder="Verify Email to View"
                      type="text"
                      id="referralID"
                      name="referralID"
                      value={
                        accountChangeLoading
                          ? "Updating you data"
                          : user?.email
                            ? window.location.origin + "/" + user?.myReferralCode
                            : ""
                      }
                      className="form-control bg-transparent text-white rounded-0 rounded-start"
                      disabled
                    />

                    <CopyToClipboard
                      text={
                        user?.email
                          ? window.location.origin + "/" + user?.myReferralCode
                          : ""
                      }
                      onCopy={onCopy}
                    >
                      <button
                        type="button"
                        // onClick={() => myFunction(user?.walletAddress)}
                        className="border bg-success rounded-0 rounded-end"
                      >
                        <FontAwesomeIcon icon={faCopy} />
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>

                <div className="mb-2 social-div">
                  <div>
                    <label className="mobilecenter">Share Affiliate Link</label>
                    <div className="d-flex gap-2 mt-1">
                      <TwitterShareButton
                        url={
                          window.location.origin + "/" + user?.myReferralCode
                        }
                        title={`Get 10% discount at playtoearnblockchain.com when you use my code.`}
                      >
                        <TwitterIcon size={40} round={true} />
                      </TwitterShareButton>
                      <LinkedinShareButton
                        url={
                          window.location.origin + "/" + user?.myReferralCode
                        }
                        summary={``}
                        title={`Get 10% discount at playtoearnblockchain.com when you use my code.`}
                      >
                        <LinkedinIcon size={40} round={true} />
                      </LinkedinShareButton>
                      <WhatsappShareButton
                        url={
                          window.location.origin + "/" + user?.myReferralCode
                        }
                        title={`Get 10% discount at playtoearnblockchain.com when you use my code.`}
                      >
                        <WhatsappIcon size={40} round={true} />
                      </WhatsappShareButton>
                      <FacebookShareButton
                        url={
                          window.location.origin + "/" + user?.myReferralCode
                        }
                        title={`Get 10% discount at playtoearnblockchain.com when you use my code.`}
                      >
                        <FacebookIcon size={40} round={true} />
                      </FacebookShareButton>
                      <PinterestShareButton
                        url={
                          window.location.origin + "/" + user?.myReferralCode
                        }
                        title={`Get 10% discount at playtoearnblockchain.com when you use my code.`}
                      >
                        <PinterestIcon size={40} round={true} />
                      </PinterestShareButton>
                      <TelegramShareButton
                        url={
                          window.location.origin + "/" + user?.myReferralCode
                        }
                        title={`Get 10% discount at playtoearnblockchain.com when you use my code.`}
                      >
                        <TelegramIcon size={40} round={true} />
                      </TelegramShareButton>
                    </div>
                  </div>
                </div>
                <p className="text-md-start text-center">
                  Share your affiliate code to earn 10% of our sales which comes
                  from you. Your friend enjoy another 10% too.
                </p>

                <div className="mb-2 mt-2">
                  <label htmlFor="referralID">One time code for Free NFT</label>
                  <div className="d-flex">
                    <input
                      placeholder="Verify Email to View"
                      type="text"
                      id="referralID"
                      name="referralID"
                      value={
                        accountChangeLoading
                          ? "Updating your data"
                          : user?.email
                            ? user?.oneTimeCode
                            : ""
                      }
                      className="form-control bg-transparent text-white rounded-0 rounded-start"
                      disabled
                    />
                    {/* <button type="button" onClick={() => copyToClipboard(user?.myReferralCode)} className="border bg-success rounded-0 rounded-end">
                      <FontAwesomeIcon icon={faCopy} />
                    </button> */}
                    <CopyToClipboard
                      text={user?.email ? user?.oneTimeCode : ""}
                      onCopy={onCopy}
                    >
                      <button
                        type="button"
                        // onClick={() => myFunction(user?.walletAddress)}
                        className="border bg-success rounded-0 rounded-end"
                      >
                        <FontAwesomeIcon icon={faCopy} />
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>

                <div className="imageDivClass">
                  {/* <button className='btn btn-success text-white me-2' type='submit' disabled={(user.email) ? true : false}>Update</button> */}
                  {/* <Link to={"/"}><button className='btn btn-danger me-2'>Cancel</button></Link> */}
                  <Link to={"/nfts"}>
                    <button
                      className="btn btn-danger btn-sm me-2"
                      style={{ width: "96px" }}
                    >
                      NFTs
                    </button>
                  </Link>
                  <button
                    className="btn btn-warning btn-sm text-light mx-2"
                    type="button"
                    onClick={LogOut}
                    style={{ width: "96px" }}
                  >
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <EmailVerifyModal
        userRefetch={userRefetch}
        updateProfile={updateProfile}
        handleVerifyEmail={handleVerifyEmail}
        minutes={minutes}
        seconds={seconds}
        open={openEmail}
        setOpenEmail={setOpenEmail}
        otpVerify={otpVerify}
        setError={setError}
      />
      <ProfileQRModal
        openQRModal={openQRModal}
        setOpenQRModal={setOpenQRModal}
        email={user?.email}
        qrCode={qRFromDatabase}
      />
    </>
  );
};

export default Profile;
