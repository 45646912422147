import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ScrollToTop from "./components/ScrollToTop";
import Landing from "./components/layout/Landing";
import NotFound from "./components/layout/NotFound/NotFound";
import Publiclayout from "./components/layout/Publiclayout";
import Home from "./components/page/Home/Home";
import Connect_wallet from "./components/Connect_wallet";
import AboutUs from "./components/page/AboutUs/AboutUs";
import HowItWorks from "./components/page/HowItWorks/HowItWorks";
import India from "./components/page/India";
import Malaysia from "./components/page/Malaysia";
import MealDetails from "./components/page/MealNft/Details.Meal";
import MealNFT from "./components/page/MealNft/MealNFT";
import Singapore from "./components/page/Singapore";
import SouvenirDetails from "./components/page/Souvenir/Details.Souvenir";
import SouvenirNFT from "./components/page/Souvenir/SouvenirNFT";
import Dashboard from "./components/page/Dashboard/Dashboard";
import DashboardAdmin from "./components/page/Dashboard/DashboardAdmin";
import DashboardAdminEditProfile from "./components/page/Dashboard/DashboardAdminEditProfile";
import DashboardNfts from "./components/page/Dashboard/DashboardNfts";
import EditNft from "./components/page/Dashboard/EditNft";
import DashboardMenu from "./components/page/Dashboard/DashboardMenu";
import Login from "./components/page/Login/Login";
import Forgetpassword from "./components/page/Login/Forgetpassword";
import Otp from "./components/page/Login/Otp";
import WalletModal from "./components/Shared/WalletModal";
import AdminRoute from "./components/AdminRoute/AdminRoute";
import Profile from "./components/page/Profile/Profile";
import ResetPassword from "./components/page/Login/ResetPassword";
import NftDetailsPage from "./components/page/Dashboard/NftDetailsPage";
import DashboardAddNft from "./components/page/Dashboard/DashboardAddNft";
import MintDetails from "./components/page/MintDetails/MintDetails";
import CoinbaseModal from "./components/Shared/CoinbaseModal";
import EditDraftNft from "./components/page/Dashboard/EditDraftNft";
import News from "./components/page/News/News";
import DetailsPayNow from "./components/page/MealNft/DetailsPayNow";
import PayNowPayment from "./components/page/MealNft/PayNowPayment";
import ProfileProtected from "./components/AdminRoute/ProfileProtected";
import MealSearchedNFT from "./components/page/MealNft/MealSearchedNFT";
import Categories from "./components/page/Dashboard/Categories";
import { useEffect, useState } from "react";
import LoaderPro from "./components/Loading/LoaderPro";
import PlayToEarn from "./components/page/PlayToEarn/PlayToEarn";
import FestModal from "./components/FestModal/FestModal";
import RacingPage from "./components/page/Racing/Racing";
import RacingPage2 from "./components/page/RacingBusd/Racing";
import CheckNft from "./components/page/CheckNft/CheckNft";
import Experience from "./components/page/Experience/Experience";
import DetailsPayByCard from "./components/page/MealNft/DetailsPayByCard";
import DetailsGPay from "./components/page/MealNft/DetailsGPay";
import MintedDetails from "./components/page/MintedDetails/MintedDetails";
import Launching from "./components/page/PlayToEarn/Launching";
import GetDSToken from "./components/page/GetDSToken/GetDSToken";
import DashboardPayments from "./components/page/Dashboard/DashboardPayments";
import MakePayment from "./components/page/Dashboard/MakePayment";
import WalletModalToProfile from "./components/Shared/WalletModalToProfile";
import Replays from "./components/page/Racing/Replays/Replays";
import Replays2 from "./components/page/RacingBusd/Replays/Replays";
import ClaimRewards from "./components/page/Racing/Claim/ClaimRewards";
import ClaimRewardsBUSD from "./components/page/RacingBusd/Claim/ClaimRewardsBUSD";
import GameNFT from "./components/page/Racing/GameNFT/GameNFT";
import DashboardLike from "./components/page/Dashboard/DashboardLike";
import DashboardBanner from "./components/page/Dashboard/DashboardBanner";
import DslCatch from "./components/DslCatch/DslCatch";
import DslCatch2 from "./components/S39DslCatch/DslCatch";
import SpinWheel from "./components/SpinWheel/SpinWheel";
import SaveTheHostAges from "./components/page/SaveTheHostAges/SaveTheHostAges";
import Rewards from "./components/page/Rewards/Rewards";
import WarGame from "./components/WarGame/WarGame";
import AllRace from "./components/page/AllRace/AllRace";
import AllRaces from "./components/page/AllRace/AllRaces";
import AllRaceReplay from "./components/page/AllRace/AllRaceReplay";
import LeaderBoard from "./components/page/LeaderBoard/LeaderBoard";
import AllChanel from "./components/page/AllRace/AllChanel";
import SelectWin from "./components/page/PlayToEarn/SelectWin";
import NftTest from "./components/page/MealNft/NftTest";
import BuyOurNftModal from "./components/layout/BuyOurNftModal";

function App() {
  const time = new Date();
  time.setSeconds(time.getSeconds() + 180);
  const [isLoading, setIsLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    setModalShow(true);
    setTimeout(() => {
      setModalShow(false);
    }, 6000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  if (isLoading) {
    return <LoaderPro></LoaderPro>;
  }

  return (
    <div
      className="dark-scheme de-clivus"
      style={{ backgroundColor: "#130a1d" }}
    >
      <div id="wrapper" className="wrap">
        <ScrollToTop />
        <WalletModal />
        <WalletModalToProfile />
        <CoinbaseModal />
        {/* <FestModal show={modalShow}
          onHide={() => setModalShow(false)} /> */}
        <Routes>
          <Route path="/" element={<Publiclayout></Publiclayout>}>
            <Route path="/" element={<Landing />} />
            <Route path="/:affiliate" element={<Landing />} />
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/why-buy-our-nfts" element={<BuyOurNftModal />} />
            <Route path="/nfttest" element={<NftTest />} />
            {/* <Route path="/racing" element={<RacingPage />} /> */}
            <Route path="/horsechampsdsl" element={<RacingPage />} />
            <Route path="/horsechampsbusd" element={<RacingPage2 />} />
            <Route path="/selectwinhorsechamp" element={<SelectWin />} />
            <Route path="/allrace" element={<AllRaces />} />
            <Route path="/replaysdsl" element={<Replays />} />
            <Route path="/replaysbusd" element={<Replays2 />} />
            <Route path="/allreplay" element={<AllRaceReplay />} />
            <Route path="/allchannel" element={<AllChanel />} />
            <Route path="/claimdsl" element={<ClaimRewards />} />
            <Route path="/claimbusd" element={<ClaimRewardsBUSD />} />
            <Route path="/horsechampsbusd/gamenft" element={<GameNFT />} />
            <Route path="/checknft" element={<CheckNft />} />
            <Route path="/leaderboard" element={<LeaderBoard />} />
            <Route path="/mintedDetails" element={<MintedDetails />} />
            <Route path="/experience" element={<Experience />} />
            <Route path="/horsechamps" element={<RacingPage />} />
            <Route path="/dslcatchit" element={<DslCatch />} />
            <Route path="/fortunewheel" element={<SpinWheel />} />
            <Route path="/s39catchit" element={<DslCatch2 />} />
            <Route path="/war1" element={<WarGame />} />
            <Route path="/launching" element={<Launching />} />
            <Route path="/savethehostages" element={<SaveTheHostAges />} />
            <Route path="/india" element={<India></India>} />
            <Route path="/malaysia" element={<Malaysia></Malaysia>} />
            <Route path="/dslTokens" element={<GetDSToken />} />
            <Route path="/singapore" element={<Singapore></Singapore>} />
            <Route path="/souvenirnft" element={<SouvenirNFT></SouvenirNFT>} />
            <Route
              path="/souvenirnft/:souvenirId"
              element={<SouvenirDetails />}
            />
            <Route path="/nfts" element={<MealNFT></MealNFT>} />
            <Route path="/playtoearn" element={<PlayToEarn></PlayToEarn>} />
            <Route path="/searchednft" element={<MealSearchedNFT />} />
            <Route
              path="/nft/:mealnId/:addressImg"
              element={<MealDetails expiryTimestamp={time} />}
            />
            <Route
              path="/paynow/:mealnId/:addressImg"
              element={<DetailsPayNow expiryTimestamp={time} />}
            />
            <Route
              path="/payNowPayment/:email/:price"
              element={<PayNowPayment />}
            />
            <Route
              path="/payByCard/:mealnId/:addressImg"
              element={<DetailsPayByCard expiryTimestamp={time} />}
            />
            <Route
              path="/payByGPay/:mealnId/:addressImg"
              element={<DetailsGPay expiryTimestamp={time} />}
            />
            <Route path="/about_us" element={<AboutUs />} />
            <Route path="/how_it_works" element={<HowItWorks />} />
            <Route path="/news" element={<News />} />
            <Route
              path="/profile"
              element={
                <ProfileProtected>
                  <Profile expiryTimestamp={time} />
                </ProfileProtected>
              }
            />
            <Route path="/mintednft/:id/:address" element={<MintDetails />} />
            <Route path="/rewards" element={<Rewards />} />
          </Route>

          <Route
            path="/dashboard"
            element={
              <AdminRoute>
                <Dashboard></Dashboard>
              </AdminRoute>
            }
          >
            <Route index element={<DashboardMenu></DashboardMenu>} />
            <Route
              path="/dashboard/admin"
              element={<DashboardAdmin></DashboardAdmin>}
            />
            <Route path="/dashboard/nftDetails" element={<NftDetailsPage />} />
            <Route
              path="/dashboard/adminprofile/:id"
              element={<DashboardAdminEditProfile></DashboardAdminEditProfile>}
            />
            <Route
              path="/dashboard/nfts"
              element={<DashboardNfts></DashboardNfts>}
            />
            <Route path="/dashboard/payments" element={<DashboardPayments />} />
            <Route path="/dashboard/bannerapi" element={<DashboardBanner />} />
            <Route
              path="/dashboard/makepayment/:name/:id"
              element={<MakePayment />}
            />
            <Route path="/dashboard/categories" element={<Categories />} />
            <Route
              path="/dashboard/addnfts"
              element={<DashboardAddNft></DashboardAddNft>}
            />
            <Route
              path="/dashboard/likes"
              element={<DashboardLike></DashboardLike>}
            />
            <Route
              path="/dashboard/nfts/editNft/:id"
              element={<EditNft></EditNft>}
            />
            <Route
              path="/dashboard/nfts/editDraftNft/:id"
              element={<EditDraftNft></EditDraftNft>}
            />
          </Route>

          <Route path="/login" element={<Login></Login>} />
          <Route
            path="/forgetpassword"
            element={<Forgetpassword></Forgetpassword>}
          />
          <Route path="/otp" element={<Otp expiryTimestamp={time}></Otp>} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          {/* /:token */}
          <Route path="*" element={<NotFound></NotFound>} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
