import React, { useContext, useEffect, useState } from 'react';
import { Contract, ethers, BigNumber } from 'ethers';
import Web3 from 'web3';
import swal from 'sweetalert';
import RewardsContract from '../Racing/Claim/RewardsContract';
import { useNavigate } from 'react-router-dom';
import { Button, Container } from "react-bootstrap";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { HashLink } from 'react-router-hash-link';
import { CelebrityContext } from '../../../context/CelebrityContext';
import Nav from 'react-bootstrap/Nav';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import './GetDSToken.css';


const web3 = new Web3(Web3.givenProvider || "http://localhost:8545");

// Video Modals for PC Info

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <video controls className='testnetVideo' poster='./metamask.png'>
        <source src="./testnetVideo01.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Modal>
  );
}

function MyVerticallyCenteredModal1(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <video controls className='testnetVideo' poster='./metamask.png'>
        <source src="./testnetVideo02.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Modal>
  );
}

function MyVerticallyCenteredModal2(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <video controls className='testnetVideo' poster='./metamask.png'>
        <source src="./textnetVideo03.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Modal>
  );
}
function MyVerticallyCenteredModal3(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <video controls className='testnetVideo' poster='./metamask.png'>
        <source src="./step.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Modal>
  );
}

// Video Modals for Mobile Info

function MobileMetamaskModal01(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <video controls className='testnetVideo' style={{ height: "364px", width: "auto" }} poster='./mobileposter01.png'>
        <source src="./mobileMetamask01.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Modal>
  );
}

function MobileMetamaskModal02(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <video controls className='testnetVideo' style={{ height: "364px", width: "auto" }} poster='./mobileposter02.png'>
        <source src="./mobileMetamask02.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Modal>
  );
}

function MobileMetamaskModal03(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <video controls className='testnetVideo' style={{ height: "364px", width: "auto" }} poster='./mobileposter03.png'>
        <source src="./mobileMetamask03.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Modal>
  );
}
function MobileMetamaskModal04(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <video controls className='testnetVideo' style={{ height: "364px", width: "auto" }} poster='./mobileposter03.png'>
        <source src="./mobile.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Modal>
  );
}

const GetDSToken = () => {
  const { openWalletModal, user, searchNftTitle } = useContext(CelebrityContext);
  const [showTooltip1, setShowTooltip1] = useState(false);
  const [showTooltip2, setShowTooltip2] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);
  const [mobileModalShow01, setMobileModalShow01] = useState(false);
  const [mobileModalShow02, setMobileModalShow02] = useState(false);
  const [mobileModalShow03, setMobileModalShow03] = useState(false);
  const [mobileModalShow04, setMobileModalShow04] = useState(false);
  const [claimed, setClaimed] = useState("");
  const [claimedData, setClaimedData] = useState(false);
  const [tabState, setTabState] = useState(1);
  const [rewardAmount, setRewardAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  // const [nothing, setNothing] = useState(false);
  const navigate = useNavigate();

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert("Copied!")
  }

  const [AllData, setAllData] = useState([])

  useEffect(() => {
    fetch('https://backend.playtoearnblockchain.com/api/v1/latestraceDSL', {
      headers: { "authorization": `Bearer bs2zDM01DMMEgt33CrKZt8x4abs2zDM01DMMEgt33Cr6atzL3CJdExpP4x4abs2zDM01DMMEgt33Cr` }
    })
      .then(res => res.json())
      .then(data => {
        setAllData(data?.data[0]);
        setRewardAmount(AllData?.race?.find(res => res.track == AllData?.selectedDog?.track).prize)
      })

  }, [AllData])

  useEffect(() => {
    axios.get(`https://backend.playtoearnblockchain.com/api/v1/claim/${user.walletAddress}`)
      .then(res => {
        setClaimedData(res?.data?.result?.status);
        setClaimed(false);
      });
  }, [claimed, user]);

  async function getAccount() {
    const provider = new ethers.providers.Web3Provider(web3.currentProvider);
    const accounts = await provider.listAccounts();
    return accounts[0];
  }

  const account = getAccount();

  async function handleClaim2() {
    const rewardAmount = 200;
    // const Message = RewardsContract.address2;
    // const provider = new ethers.providers.Web3Provider(web3.currentProvider);
    // const signer = provider.getSigner();
    // const contract = new Contract(RewardsContract.address, RewardsContract.abi, signer);

    try {
      // const result = await contract.claimReward(rewardAmount, Message, { from: account });
      // console.log(result);
      // setSuccess(`You have successfully claimed ${rewardAmount} DSL Tokens.`);
      setSuccess("200");

      axios.post(`https://backend.playtoearnblockchain.com/api/v1/claim/${user.walletAddress}`)
        .then(res => {
          if (res.status === 200) {
            setClaimed(true);

          }
        })
        .catch(error => {
          console.log(error);
        });

      // setSuccess(`You have successfully claimed ${rewardAmount} DSL Tokens.`);
      axios.post('https://backend.playtoearnblockchain.com/api/v1/login-reward', { walletAddress: user?.walletAddress, dslToken: rewardAmount, email: user?.email, claim: false }).then(res => {
        const wrapper = document.createElement("div");
        wrapper.innerHTML = `
    <p style="color: white;">You have successfully claimed ${rewardAmount} DSL Tokens.</p>
   
    <p style="color: white;">You will receive it within 48 hours once our admin checks</p>
`
        swal({
          content: wrapper,
          icon: "success",
          button: "OK!",
          className: "modal_class_success",
        })
          .then((willDelete) => {
            if (willDelete) {
              navigate('/');
            }
          });

      })

      // const wrapper = document.createElement("div");
      // wrapper.innerHTML = `
      //       <p style="color: white;">You have successfully claimed 200 DSL Tokens.</p>
      //       <div style="display: flex; justify-content: center; align-items: center; margin-bottom: 16px;">
      //           <p style="color: #007bff; margin: 0;"><a style="color: #007bff;" href="https://bscscan.com/token/0x4a1530fb85bdb9250db2be251584874179eb8dc5" target="_blank">0x4A1530Fb85BdB9250Db2bE251584874179eB8Dc5</a></p>
      //       </div>
      //       <p style="color: white;">Copy the DSL token address and import on Your Wallet to see the claimed DSL token.</p>
      //   `
      // swal({
      //   content: wrapper,
      //   icon: "success",
      //   button: "OK!",
      //   className: "modal_class_success",
      // })
      //   .then((willDelete) => {
      //     if (willDelete) {
      //       navigate('/');
      //     }
      //   });
    } catch (err) {
      setError(err.message);
    }
  }

  const handleTabClicked = (index) => {
    setTabState(index);
  };

  const [checkDevice, setCheckDevice] = useState("");
  const [texts, setText] = useState("");

  useEffect(() => {
    const detecting = async () => {
      if (window.innerWidth < 1000 && typeof window.ethereum === "undefined") {
        console.log("mobile");
        setCheckDevice(() => "mobileWithoutApp");
      } else if (
        window.innerWidth < 1000 &&
        typeof window.ethereum !== "undefined"
      ) {
        setCheckDevice(() => "mobileWithApp");
      } else if (
        window.innerWidth > 1000 &&
        typeof window.ethereum !== "undefined"
      ) {
        console.log("pc");
        setCheckDevice(() => "pcWithExtention");
      } else if (
        window.innerWidth > 1000 &&
        typeof window.ethereum === "undefined"
      ) {
        setCheckDevice(() => "pcWithoutExtention");
      }
    };

    detecting();

  }, []);
  return (
    <div style={{ backgroundColor: '#1A1A25', height: '100vh' }}>
      <div>
        <h1 className="text-gradient text-center pt-5 text-uppercase" style={{ marginTop: '80px', fontSize: "1.75rem" }}>Complete each step and<br />collect your DSL Tokens</h1>
        <div className="small-border bg-color-2"></div>
      </div>
      <div>
        <Container>
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="admin_tabs_menu_row">
                <div
                  className={
                    tabState === 1
                      ? "tabs_tab_btn active_tabs_tab_btn"
                      : "tabs_tab_btn"
                  }
                  onClick={() => handleTabClicked(1)}
                >
                  <p
                    style={{
                      margin: "0",
                      paddingTop: "7px",
                      paddingBottom: "7px",
                    }}
                  >
                    PC
                  </p>
                </div>

                <div
                  className={
                    tabState === 2
                      ? "tabs_tab_btn active_tabs_tab_btn"
                      : "tabs_tab_btn"
                  }
                  onClick={() => handleTabClicked(2)}
                >
                  <p
                    style={{
                      margin: "0",
                      paddingTop: "7px",
                      paddingBottom: "7px",
                    }}
                  >
                    Mobile
                  </p>
                </div>
              </div>
            </div>

            <div style={{ marginTop: "50px" }}>
              <div
                className={
                  tabState === 1
                    ? "single_tab_content active_single_tab_content"
                    : "single_tab_content"
                }
              >
                <div style={{ color: 'white' }}>
                  <p style={{ fontWeight: 'bold' }}>First Task - MetaMask</p>
                  <p style={{ marginBottom: "0", marginLeft: '8px' }}>1. Download Metamask Extension to Chrome
                    <i onClick={() => setModalShow(true)} class="fas fa-info-circle text-white ms-3"></i>
                  </p>

                  <p style={{ marginBottom: "0", marginLeft: '8px' }}>2. Create a wallet
                    <i onClick={() => setModalShow1(true)} class="fas fa-info-circle text-white ms-3"></i>
                  </p>
                  <p style={{ marginBottom: "0", marginLeft: '8px' }}>3. Add Binance Chain Network
                    <i onClick={() => setModalShow2(true)} class="fas fa-info-circle text-white ms-3"></i>
                  </p>
                  {/* <p style={{ marginBottom: "0", marginLeft: '8px' }}>4. Login with Wallet to claim 200 DSL Tokens
                    <i onClick={() => setModalShow3(true)} class="fas fa-info-circle text-white ms-3"></i>
                  </p> */}
                </div>
              </div>
              <div
                className={
                  tabState === 2
                    ? "single_tab_content active_single_tab_content"
                    : "single_tab_content"
                }
              >
                <div style={{ color: 'white' }}>
                  <p style={{ fontWeight: 'bold' }}>First Task - MetaMask</p>
                  <p style={{ marginBottom: "0", marginLeft: '8px' }}>1. Download Metamask Mobile Apps
                    <i onClick={() => setMobileModalShow01(true)} class="fas fa-info-circle text-white ms-3"></i>
                  </p>
                  <p style={{ marginBottom: "0", marginLeft: '8px' }}>2. Create a wallet
                    <i onClick={() => setMobileModalShow02(true)} class="fas fa-info-circle text-white ms-3"></i>
                  </p>
                  <p style={{ marginBottom: "0", marginLeft: '8px' }}>3. Add Binance Chain Network
                    <i onClick={() => setMobileModalShow03(true)} class="fas fa-info-circle text-white ms-3"></i>
                  </p>
                  {/* <p style={{ marginBottom: "0", marginLeft: '8px' }}>4. Login with Wallet to claim 200 DSL Tokens
                    <i onClick={() => setMobileModalShow04(true)} class="fas fa-info-circle text-white ms-3"></i>
                  </p> */}
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            {
              (!user?.walletAddress || user?.walletAddress === "undefined") ?
                <div className="landing-button mt-4 mb-4">
                  {checkDevice === "mobileWithoutApp" && (
                    <a
                      href="https://metamask.app.link/dapp/http://playtoearnblockchain.com/dslTokens"
                      target={"_blank"}
                      className="text-decoration-none"
                    >
                      <button className="text-uppercase button-metamask  px-3">
                        {/* <img
                          className="me-2"
                          width="20px"
                          src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                          alt=""
                        /> */}
                        CONNECT WITH WALLET to win 200 dsl tokens
                      </button>
                    </a>
                  )}
                  {checkDevice === "mobileWithApp" && (
                    <>
                      {!user?.walletAddress ||
                        user?.walletAddress === "undefined" ? (
                        <button
                          className="text-uppercase button-metamask px-3"
                          onClick={() => openWalletModal()}
                        >
                          {" "}
                          {/* <img
                            className="me-2"
                            width="20px"
                            src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                            alt=""
                          /> */}
                          CONNECT WITH WALLET to win 200 dsl tokens

                        </button>
                      ) : (
                        <button
                          className="text-uppercase button-metamask px-3"
                          onClick={() => navigate("/profile")}
                        >
                          {" "}
                          {/* <img
                            className="me-2"
                            width="20px"
                            src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                            alt=""
                          /> */}
                          profile
                        </button>
                      )}
                    </>
                  )}
                  {checkDevice === "pcWithExtention" && (
                    <>
                      {!user?.walletAddress ||
                        user?.walletAddress === "undefined" ? (
                        <button
                          className="text-uppercase button-metamask px-3"
                          onClick={() => openWalletModal()}
                        >
                          {" "}
                          {/* <img
                            className="me-2"
                            width="20px"
                            src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                            alt=""
                          /> */}
                          CONNECT WITH WALLET to win 200 dsl tokens
                        </button>
                      ) : (
                        <button
                          className="text-uppercase button-metamask px-3"
                          onClick={() => navigate("/profile")}
                        >
                          {" "}
                          {/* <img
                            className="me-2"
                            width="20px"
                            src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                            alt=""
                          /> */}
                          profile
                        </button>
                      )}
                    </>
                  )}
                  {checkDevice === "pcWithoutExtention" && (
                    <a
                      href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
                      target={"_blank"}
                      className="text-decoration-none"
                    >
                      <button className="text-uppercase button-metamask px-3">
                        {/* <img
                          className="me-2"
                          width="20px"
                          src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                          alt=""
                        /> */}
                        CONNECT WITH WALLET to win 200 dsl tokens
                      </button>
                    </a>
                  )}
                </div>
                // <div className='menuTextButtonWallet'><button style={{ fontSize: '13px' }} class="button-18" role="button" onClick={openWalletModal}><img className='me-2' width='18px' src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg" alt="" /> LOGIN WITH WALLET TO EARN YOUR 200 DSL TOKENS</button> </div>
                :
                <>
                  {
                    !claimedData ?
                      <button className='button-18' disabled={loading} onClick={handleClaim2}>
                        Claim Your 200 DSL Tokens
                      </button>
                      : <div className='text-gradient' style={{ fontStyle: 'italic', fontWeight: '500' }}>You have already claimed. Look out for more tasks.</div>
                  }
                </>
            }
          </div>
        </Container>
      </div>
      {/* For PC */}
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <MyVerticallyCenteredModal1
        show={modalShow1}
        onHide={() => setModalShow1(false)}
      />
      <MyVerticallyCenteredModal2
        show={modalShow2}
        onHide={() => setModalShow2(false)}
      />
      <MyVerticallyCenteredModal3
        show={modalShow3}
        onHide={() => setModalShow3(false)}
      />

      {/* For Mobile */}
      <MobileMetamaskModal01
        show={mobileModalShow01}
        onHide={() => setMobileModalShow01(false)}
      />
      <MobileMetamaskModal02
        show={mobileModalShow02}
        onHide={() => setMobileModalShow02(false)}
      />
      <MobileMetamaskModal03
        show={mobileModalShow03}
        onHide={() => setMobileModalShow03(false)}
      />
      <MobileMetamaskModal04
        show={mobileModalShow04}
        onHide={() => setMobileModalShow04(false)}
      />
    </div>
  );
};

export default GetDSToken;