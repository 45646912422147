import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import swal from 'sweetalert';
// import EmailVerifyModal from '../../Ideas/VerifyModal/Email.Modal';
// import PhoneVerifyModal from '../../Ideas/VerifyModal/Phone.verify';
import Tippy from '@tippy.js/react'
import EmailVerifyModal from '../page/MealNft/EmailVerifyModal';
import { useTimer } from 'react-timer-hook';
import './InterestModal.css'
import InterestEmailVerifyModal from '../page/MealNft/InterestEmailVerifyModal';
import InterestMobilelVerifyModal from '../page/MealNft/InterestMobilelVerifyModal';
import { Spinner } from 'react-bootstrap';

const style = {
    position: 'relative',
    top: '50%',
    left: '50%',
    zIndex: 999999999999,
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'black',
    border: '2px solid white',
    boxShadow: 24,
    color: "white",
    borderRadius: '5px',
    p: 4
};


const InterestModal = ({ autoOpen, setAutoOpen, expiryTimestamp }) => {
    const [openPhone, setOpenPhone] = useState(false);
    const [openEmail, setOpenEmail] = useState(false);
    const [countryList, setCountryList] = useState([]);
    const [countryCode, setCountryCode] = useState("");
    const [isError, setError] = useState(false);
    const [email, setEmail] = useState('');
    const [emailVerify, setEmailVerify] = useState(false);
    const [mobile, setMobile] = useState('');
    const [mobileVerify, setMobileVerify] = useState(false);
    const [isName, setInputName] = useState('');
    const [otpVerify, setOtpVerify] = useState();
    const [sendEmailOTP, setSendEmailOTP] = useState(false);
    const [sendMobileOTP, setSendMobileOTP] = useState(false);
    const [loading, setLoading] = useState(false);
    const [phoneOtpCode, setPhoneVerificationCode] = useState('')
    const [emailOtpCode, setEmailVerificationCode] = useState('')

    // Re-send OTP functionality
    const {
        seconds,
        minutes,
        resume,
        restart,
    } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });

    const restarting = (sec) => {
        const time = new Date();
        time.setSeconds(time.getSeconds() + sec);
        restart(time)

    }

    useEffect(() => {
        axios.get("https://restcountries.com/v2/all?fields=alpha2Code,callingCodes")
            .then(res => {
                setCountryList(res.data)
            })
    }, []);

    // useEffect(() => {
    //     axios.get("https://api.ipregistry.co/?key=nd2chql8jm9f7gxa")
    //         .then(res => {
    //             // setLocatedCountry(res.data);
    //             setCountryCode(res.data.location.country.calling_code)
    //         })
    // }, [])

    const handleClose = () => setAutoOpen(false);

    const sendEmailVerificationCode = (e) => {
        e.preventDefault();

        if (!email || email.length === 0) {
            swal({
                title: "Attention",
                text: "Please enter your email",
                icon: "warning",
                button: "OK!",
                className: "modal_class_success",
            });
        } else {
            setEmailVerify(true);
            axios.post("https://backend.playtoearnblockchain.com/api/v1/waiting-list/send-email-verification-code", { email })
                .then(res => {
                    if (res.status === 200) {
                        setEmailVerificationCode(res.data.emailVerificationCode)
                        swal({
                            text: res.data.message,
                            icon: "success",
                            button: "OK!",
                            className: "modal_class_success",
                        });

                        setSendEmailOTP(true);
                        localStorage.setItem("waittingListToken", res.data.token);
                    }
                    setOpenEmail(true)

                })
                .catch(error => {
                    setEmailVerify(false);
                    localStorage.setItem("waittingListToken", error.res.response.token);
                    swal({
                        title: "Attention",
                        text: error.response.data.message,
                        icon: "warning",
                        button: "OK!",
                        className: "modal_class_success",
                    });
                });
        }
    }

    const sendMobileVerificationCode = (e) => {
        e.preventDefault();

        if (!mobile || mobile.length === 0) {
            swal({
                title: "Attention",
                text: "Please enter your mobile number",
                icon: "warning",
                button: "OK!",
                className: "modal_class_success",
            });
        } else {
            setMobileVerify(true)
            axios.post("https://backend.playtoearnblockchain.com/api/v1/waiting-list/send-mobile-verification-code", {
                mobile: countryCode + mobile
            }, {
                headers: { "authorization": `Bearer ${localStorage.getItem("waittingListToken")}` }
            })
                .then(res => {
                    if (res.status === 200) {
                        setPhoneVerificationCode(res.data.phoneVerificationCode)

                        swal({
                            text: res.data.message,
                            icon: "success",
                            button: "OK!",
                            className: "modal_class_success",
                        });
                        setOpenPhone(true)
                        setSendMobileOTP(true)
                        sendMobileVerificationCode(true);

                    }
                })
                .catch(error => {
                    swal({
                        title: "Attention",
                        text: error.response.data.message,
                        icon: "warning",
                        button: "OK!",
                        className: "modal_class_success",
                    });
                });
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (sendMobileOTP && sendEmailOTP) {

            await axios.post('https://backend.dsl.sg/api/v1/registerinterest/interest', {
                email: email
            }).then(res => {
                if (res.status === 200) {
                    // alert(res.data.message);
                    // console.log(res.data);
                    swal({
                        text: "Thanks for your interest",
                        icon: "success",
                        button: "OK!",
                        className: "modal_class_success",
                    });
                    e.target.reset();
                    setEmail("");
                    setMobile("");
                    setInputName("");

                }
            }).catch(err => {
                // alert(err.response.data.message);
                swal({
                    title: "Attention",
                    text: err.response.data.message,
                    icon: "warning",
                    button: "OK!",
                    className: "modal_class_success",
                });
            })
                .finally(() => {
                    setLoading(false);
                });
        }
        else {
            setLoading(false)
        }
    }
    return (
        <div>
            <Modal
                open={autoOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='handleAutoModalBody' id="certificatModal">
                    <div className='closeD'>
                        <Button className='iconClose' onClick={handleClose}><CloseIcon className='iconClose' style={{ color: "red" }} /></Button>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="./logo512.png" style={{ height: '80px', width: '80px' }} alt="" />
                    </div>
                    <Typography id="modal-modal-title text-light mb-2 text17" variant=" " style={{ fontSize: '15px', lineHeight: '1.3', marginTop: '10px' }}>
                        <p>Stand a chance to win one of the 10 Darkkey Nagaraja Celebrity NFTs we give away.</p>

                        <p>Don’t miss it, it is worth USD 399. Closing date: 31 December 2022</p>
                    </Typography>
                    <Typography id="modal-modal-description text-light" sx={{ mt: 2 }}>

                    </Typography>

                    <form className="d-grid mt-2 mb-2" onSubmit={handleSubmit} >
                        <div className='mb-1'>
                            <label className='text14' for="comment">Name*</label>
                            <Tippy content="Please Enter Your Name">
                                <input
                                    type="text" className='form-control' name="name" value={isName} onChange={e => setInputName(e.target.value)} placeholder="Name" required />
                            </Tippy>
                        </div>

                        <div className='mb-1'>
                            <label className='text14' for="comment">Email*</label>
                            <div className='d-flex'>
                                <Tippy content="Please Enter Your Email">
                                    <input type="text" className='form-control' name="email" placeholder="Email" value={email} onChange={e => { setEmail(e.target.value); setEmailVerify(false) }} style={{ borderRadius: "3px 0px 0px 3px", textTransform: "lowercase" }} required />
                                </Tippy>

                                <button type="button" className={`btn ${email ? emailVerify ? "btn-secondary" : "btn-danger" : "btn-secondary"} emailVerifybtn`} style={{ borderRadius: "0px 3px 3px 0px" }} onClick={sendEmailVerificationCode} disabled={email ? emailVerify ? true : false : true}>Verify</button>
                            </div>
                        </div>

                        <div className='mb-1'>
                            <label className='text14' for="comment">Mobile*</label>
                            <div className='d-flex'>
                                <select className='form-control w-auto' name='countryCode' value={countryCode} onChange={e => setCountryCode(e.target.value)} style={{ borderRadius: "3px 0px 0px 3px" }}>
                                    {
                                        countryList.map((country, index) => <option value={country.callingCodes} key={index}>{country.alpha2Code} (+{country.callingCodes})</option>)
                                    }
                                </select>
                                <Tippy content="Please Enter Your Number">
                                    <input type="number" className='form-control' name="phone" placeholder="Mobile number" value={mobile} onChange={e => { setMobile(e.target.value); setMobileVerify(false) }} style={{ borderRadius: '0' }} required />
                                </Tippy>

                                <button type="button" className={`btn ${mobile ? mobileVerify ? "btn-secondary" : "btn-danger" : "btn-secondary"} mobileVerifybtn`} style={{ borderRadius: "0px 3px 3px 0px" }} onClick={sendMobileVerificationCode} disabled={mobile ? mobileVerify ? true : false : true}>Verify</button>
                            </div>
                        </div>

                        {isError ? <span style={{ color: "red" }}> {isError} </span> : ''}

                        <div className='d-flex' style={{ justifyContent: 'center', cursor: "pointer" }}>
                            {loading ? <Button variant="danger" disabled className=' mt-3'>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className="visually-hidden">Loading...</span>
                            </Button> : <button type='submit' disabled={(!isName || !mobile || !email || !mobileVerify || !emailVerify) ? true : false} className={`submit  font14 text-decoration-none mb-2 mt-3 pt-2 pb-2 ${(!isName || !mobile || !email || !mobileVerify || !emailVerify) ? "btn btn-secondary rounded text-center text-uppercase handleResponsiveBtn" : "interest-banner-button2"}`} id="font14">Submit</button>}
                        </div>
                    </form>
                </Box>
            </Modal>
            <InterestEmailVerifyModal sendEmailVerificationCode={sendEmailVerificationCode} open={openEmail} setOpenEmail={setOpenEmail} emailOtpCode={emailOtpCode} setError={setError} />
            <InterestMobilelVerifyModal sendMobileVerificationCode={sendMobileVerificationCode} open={openPhone} setOpenPhone={setOpenPhone} phoneOtpCode={phoneOtpCode} setError={setError} />
        </div>

    );
};

export default InterestModal;