import * as React from "react";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { CelebrityContext } from "../../context/CelebrityContext";
import swal from "sweetalert";
import { IoClose } from "react-icons/io5";
import "./header.css";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import { useRef } from "react";
import LoginButton from "../Shared/LoginButton";
import { shuffle } from "lodash";

function Header() {
  const { openWalletModal, user, searchNftTitle, LikeRefetch } =
    useContext(CelebrityContext);
  const [searchInput, setSearchInput] = useState("");
  const [isMeal, setIsMeal] = useState([]);
  const [allNfts, setallNfts] = useState([]);
  const [logo, setLogo] = useState("");
  const [showCloseIcone, setShowCloseIcone] = useState(false);
  const [pageRoute, setPageRoute] = useState("MENU");
  const [nftName, setNftName] = useState([
    // "Thunivu",
    // "Varisu",
    // "Jailer",
    // "Tusker",
    // "Mudslide",
    // "Flamerunner",
    // "Braveheart",
    // "Hurij Nightlove",
    // "Aeliana Sunspark",
    // "Elara Moonstone",
    // "Lyra Nightshade",
    // "Freya Moonwater",
    // "Afrin Sunflower",
    // "Lyrisa Fernwaver",
    // "Aurora Dreamweaver",
    // "Elvina Starweave",
    // "Zara Dewdrop",
    // "Serena Stardancer",
    // "Eira Snowfall",
    // "Selena Shadowleaf",
    // "Aradia Starweaver",
  ]);
  const navigate = useNavigate();
  const [checkDevice, setCheckDevice] = useState("");

  //   console.log("alnnff", nftName)

  //   const allnfts = [
  //     "Thunivu",
  //     "Varisu",
  //     "Jailer",
  //     "Tusker",
  //     "Mudslide",
  //     "Flamerunner",
  //     "Braveheart",
  //     "Hurij",
  //     "Aeliana",
  //     "Elara",
  //     "Lyra",
  //     "Freya",
  //     "Afrin",
  //     "Lyrisa",
  //     "Aurora",
  //     "Elvina",
  //     "Zara",
  //     "Serena",
  //     "Eira",
  //     "Selena",
  //     "Aradia",
  //   ]

  //   function shuffleArray(array) {
  //     // Fisher-Yates shuffle algorithm
  //     for (let i = array?.length - 1; i > 0; i--) {
  //         const j = Math.floor(Math.random() * (i + 1));
  //         [array[i], array[j]] = [array[j], array[i]];
  //     }
  //     return array;
  // }

  // useEffect(() => {
  //   setNftName(shuffleArray(allnfts));
  // }, []);

  useEffect(() => {
    const detecting = async () => {
      if (window.innerWidth < 1000 && typeof window.ethereum === "undefined") {
        console.log("mobile");
        setCheckDevice(() => "mobileWithoutApp");
      } else if (
        window.innerWidth < 1000 &&
        typeof window.ethereum !== "undefined"
      ) {
        setCheckDevice(() => "mobileWithApp");
      } else if (
        window.innerWidth > 1000 &&
        typeof window.ethereum !== "undefined"
      ) {
        console.log("pc");
        setCheckDevice(() => "pcWithExtention");
      } else if (
        window.innerWidth > 1000 &&
        typeof window.ethereum === "undefined"
      ) {
        setCheckDevice(() => "pcWithoutExtention");
      }
    };

    detecting();
  }, []);
  // useEffect(() => {
  //   axios.get(`https://backend.playtoearnblockchain.com/api/nft/all`, {
  //     headers: { "authorization": `Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54` }
  //   }).then(res => {
  //     console.log(res.data.nft, "All nft")
  //     res?.data?.nft?.map(nfts => { setNftName(nftName => [...nftName, nfts.name]) })
  //   });
  // }, [])

  const allNft = isMeal;
  const todayDate = new Date();

  useEffect(() => {
    axios
      .get(`https://backend.playtoearnblockchain.com/api/nft/all`, {
        headers: {
          authorization: `Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54`,
        },
      })
      .then((res) => {
        setallNfts(res?.data?.nft);
        const filtering = res?.data?.nft?.filter(
          (items) =>
            items.isDraft === false &&
            new Date(
              `${items?.purchaseDate?.slice(5, 7)}/${items?.purchaseDate?.slice(
                8,
                10
              )}/${items?.purchaseDate?.slice(0, 4)}`
            ) > todayDate
        );
        setIsMeal(filtering);

        // setFilterData(res.data.slice(0, 5))
      });
    // axios.get("https://backend.dsl.sg/api/v1/logo")
    //   .then(res => {
    //     setLogo(res.data.result[0].logo)
    //     // console.log(res.data.result[0].logo, 'logo')
    //   });
    // console.log(logo, 'logo')
  }, []);

  const inputRef = useRef();
  // typewriter functionality start------->
  useEffect(() => {
    function typeWriter(
      selector_target,
      text_list,
      placeholder = false,
      i = 0,
      text_list_i = 0,
      delay_ms = 400
    ) {
      if (!i) {
        if (placeholder) {
          selector_target.placeholder = "";
        } else {
          selector_target.innerHTML = "";
        }
      }
      let txt = text_list[text_list_i];
      if (i < txt?.length) {
        if (placeholder) {
          selector_target.placeholder += txt.charAt(i);
        } else {
          selector_target.innerHTML += txt.charAt(i);
        }
        i++;
        setTimeout(
          typeWriter,
          delay_ms,
          selector_target,
          text_list,
          placeholder,
          i,
          text_list_i
        );
      } else {
        text_list_i++;
        if (typeof text_list[text_list_i] === "undefined") {
          setTimeout(
            typeWriter,
            delay_ms * 5,
            selector_target,
            text_list,
            placeholder
          );
        } else {
          i = 0;
          setTimeout(
            typeWriter,
            delay_ms * 3,
            selector_target,
            text_list,
            placeholder,
            i,
            text_list_i
          );
        }
      }
    }

    let array = [
      "Thunivu",
      "Varisu",
      "Jailer",
      "Tusker",
      "Mudslide",
      "Flamerunner",
      "Braveheart",
      "Hurij Nightlove",
      "Aeliana Sunspark",
      "Elara Moonstone",
      "Lyra Nightshade",
      "Freya Moonwater",
      "Afrin Sunflower",
      "Lyrisa Fernwaver",
      "Aurora Dreamweaver",
      "Elvina Starweave",
      "Zara Dewdrop",
      "Serena Stardancer",
      "Eira Snowfall",
      "Selena Shadowleaf",
      "Aradia Starweaver",
    ];

    for (let i = array?.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    // return array;

    console.log("arrayy", array);

    let text_list = ["Thunivu", "Varisu", "Jailer"];
    // if (nftName.length > 0) {
    //   console.log(nftName, "nftName")
    typeWriter(inputRef?.current, array, true);
    // }

    console.log("working");
  }, []);
  // typewriter functionality end <-------

  const searchChange = (e) => {
    const val = e.target.value;
    setSearchInput(val);

    if (e.target.value?.length > 0) {
      setShowCloseIcone(true);
    } else {
      setShowCloseIcone(false);
    }
  };

  const removeValue = () => {
    document.getElementById("searchVal").value = "";
    if (document.getElementById("searchVal").value === "") {
      setShowCloseIcone(false);
    }
  };

  const searched = () => {
    // searchNftTitle(isMeal, searchInput);

    if (searchInput) {
      searchNftTitle(allNfts, searchInput);
      console.log("searchInput is true", searchInput, isMeal);
      navigate("/searchednft");
      window.scrollTo(0, 0);
      document.getElementById("searchVal").value = "";
      if (document.getElementById("searchVal").value === "") {
        setShowCloseIcone(false);
        setSearchInput("");
      }
    }
  };

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleCloseOrigin = (page) => {
    setPageRoute(String(page));
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Navbar
      style={{ height: "80px" }}
      bg="light"
      expand="lg"
      id="navbars"
      className="navbarsContainer"
      collapseOnSelect
    >
      <Container className="navbarsContainer">
        {/* <Navbar.Brand as={HashLink} to="/" href="#homeone" style={{ width: '10%', paddingBlock: "0px" }} >
          <div className="logo-button">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <img alt="logo" src={"/assets/images/logo-6.jpg"} className='handleImage' onClick={() => setPageRoute("MENU")} />
          </div>
        </Navbar.Brand> */}
        <Navbar.Brand
          as={HashLink}
          to="/"
          href="#homeone"
          className="logo-button"
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <img
            alt="logo"
            src={"/new-logo.jpeg"}
            width={60}
            onClick={() => setPageRoute("MENU")}
          />
        </Navbar.Brand>

        <InputGroup className="search-bar">
          {/* <input type="text" ref={inputRef} id="searchVal" /> */}
          <Form.Control
            style={{
              borderRight: "none",
              backgroundColor: "#272d47",
              color: "#fff",
              boxShadow: "none",
              borderColor: "#585858",
            }}
            aria-label=""
            className="inputBackground"
            // placeholder=''
            ref={inputRef}
            autocomplete="off"
            type="text"
            id="searchVal"
            required
            name="search"
            onChange={searchChange}
          />
          {showCloseIcone === true && (
            <div
              onClick={removeValue}
              style={{
                backgroundColor: "#272d47",
                width: "24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderTop: "1px solid #585858",
                borderRight: "1px solid #585858",
                borderBottom: "1px solid #585858",
                cursor: "pointer",
              }}
            >
              {showCloseIcone === true && <IoClose color="#bbbbbb" />}
            </div>
          )}
          <button
            style={{
              borderTop: "1px solid #585858",
              borderLeft: "1px solid #585858",
              borderBottom: "1px solid #585858",
              borderRight: "none",
            }}
            className="bg-dark text-center cursor-pointer px-2 searchRadious text-white"
            type="button"
            onClick={searched}
          >
            <i class="fas fa-search"></i>
          </button>
        </InputGroup>
        {/* <input type="text" /> */}
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="NavbarToggle"
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Stack direction="row" spacing={2}>
              <div>
                <Button
                  ref={anchorRef}
                  id="composition-button"
                  aria-controls={open ? "composition-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                  className="dropdownFont pt-2"
                  style={{ textTransform: "none" }}
                >
                  <span className="pe-2">{pageRoute}</span>{" "}
                  <span>
                    {open ? (
                      <i
                        style={{ fontSize: "18px" }}
                        class="fa fa-caret-up"
                      ></i>
                    ) : (
                      <i
                        style={{ fontSize: "18px" }}
                        class="fa fa-caret-down"
                      ></i>
                    )}
                  </span>
                </Button>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper
                        style={{ backgroundColor: "#121219", width: "180px" }}
                      >
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            autoFocusItem={open}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleListKeyDown}
                          >
                            <Nav.Link
                              as={Link}
                              to="/playtoearn"
                              href="#play"
                              className="centerForSm"
                              onClick={() => handleCloseOrigin("PLAY TO EARN")}
                            >
                              <MenuItem className="menuText">
                                PLAY TO EARN
                              </MenuItem>
                            </Nav.Link>
                            <Nav.Link
                              as={Link}
                              to="/nfts"
                              href="#about"
                              className="centerForSm"
                              onClick={() => handleCloseOrigin("NFTs")}
                            >
                              <MenuItem className="menuText">NFTs</MenuItem>
                            </Nav.Link>
                            <Nav.Link
                              as={Link}
                              to="/checknft"
                              href="#checknft"
                              className="centerForSm"
                              onClick={() => handleCloseOrigin("CHECK NFT")}
                            >
                              <MenuItem className="menuText">
                                CHECK NFT
                              </MenuItem>
                            </Nav.Link>
                            <Nav.Link
                              as={Link}
                              to="/leaderboard"
                              href="#leaderboard"
                              className="centerForSm"
                              onClick={() => handleCloseOrigin("LEADER BOARD")}
                            >
                              <MenuItem className="menuText">
                                LEADER BOARD
                              </MenuItem>
                            </Nav.Link>

                            {/* <Nav.Link
                              as={Link}
                              to="/about_us"
                              href="#about"
                              className="centerForSm"
                              onClick={() => handleCloseOrigin("ABOUT US")}
                            >
                              <MenuItem className="menuText">ABOUT US</MenuItem>
                            </Nav.Link> */}

                            <Nav.Link
                              as={Link}
                              to="/how_it_works"
                              href="#howitworks"
                              className="centerForSm"
                              onClick={() => handleCloseOrigin("HOW IT WORKS")}
                            >
                              <MenuItem className="menuText">
                                HOW IT WORKS
                              </MenuItem>
                            </Nav.Link>

                            <Nav.Link
                              as={Link}
                              to="/why-buy-our-nfts"
                              href="#howitworks"
                              className="centerForSm"
                              onClick={() => handleCloseOrigin("HOW IT WORKS")}
                            >
                              <MenuItem className="menuText">
                                WHY BUY OUR NFTS?
                              </MenuItem>
                            </Nav.Link>

                            {/* <Nav.Link
                              href="https://dsl.sg/minting"
                              target="_blank"
                              className="centerForSm"
                              onClick={() => handleCloseOrigin("HOW TO MINT")}
                            >
                              <MenuItem className="menuText">
                                HOW TO MINT
                              </MenuItem>
                            </Nav.Link> */}
                            {/* <Nav.Link
                              as={Link}
                              to="/news"
                              href="#news"
                              className="centerForSm"
                              onClick={() => handleCloseOrigin("NEWS")}
                            >
                              <MenuItem className="menuText">NEWS</MenuItem>
                            </Nav.Link> */}
                            {/* <MenuItem onClick={() => handleCloseOrigin("GET TEST TOKENS")} className='centerForSm'> <Nav.Link href="https://dsl.sg/testnettokens" target="_blank" className='menuText'>GET TEST TOKENS</Nav.Link></MenuItem> */}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </Stack>

            <div className="rewordIcon">
              <Nav.Link as={Link} href="#reward" to={"/rewards"}>
                <i class="fas fa-gift fs-4 text-warning"></i>
              </Nav.Link>
            </div>

            <div className="header-buttn">
              <LoginButton register={"code"}></LoginButton>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
