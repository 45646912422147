import axios from "axios";
import { useEffect, useState, useContext } from "react";


function Launching() {

    return (
        <div className="" style={{ backgroundColor: '#1A1A25', height: "70vh" }}>
            <div>
                <h1 className="text-gradient text-center pt-5 text-uppercase" style={{ marginTop: '80px', fontSize: "1.75rem" }}></h1>
                {/* <h3 className="text-gradient text-center pt-5 text-uppercase" style={{ marginTop: '80px' }}></h3> */}
                {/* <div className="small-border bg-color-2"></div> */}
            </div>
            <div className="" style={{ paddingTop: "100px" }}>
                <h3 className="text-center">Launching on 1 Jan 2023.</h3>
            </div>

        </div >
    )
}

export default Launching;
