
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useState } from 'react';

import sourcelink from './b11.jpeg'
import sourcelink1 from './b66.jpeg'
import sourcelink2 from './b55.jpeg'
import sourcelink3 from './b44.jpeg'
import sourcelink4 from './b33.jpeg'
import sourcelink5 from './b22.jpeg'
import Loading from '../Loading/Loading';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '45%',
    bgcolor: 'black',
    border: '2px solid white',
    boxShadow: 24,
    color: "white",
    borderRadius: '5px',

};

export default function BuyOurNftModal({ open, setOpen }) {

    const handleClose = () => setOpen(false);
    const [loading, setLoading] = useState(true)
    const hendelSubmit = (e) => {
        console.log(e.terget.value)
    }
    const ShowLoading = () => setLoading(false)


    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box className='certificatModal' id="certificatModal" sx={style}>
                    <Swiper
                        slidesPerView={1}
                        loop={true}
                        centeredSlides={true}
                        pagination={true}
                        // navigation={true}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper"
                    >

                        <SwiperSlide>
                            <img loading='lazy' onLoad={ShowLoading} src={sourcelink} alt="Certificate" className="p-2" style={{ width: "100%", height: "100%" }} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img loading='lazy' onLoad={ShowLoading} src={sourcelink1} alt="Certificate" className="p-2" style={{ width: "100%", height: "100%" }} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img loading='lazy' onLoad={ShowLoading} src={sourcelink2} alt="Certificate" className="p-2" style={{ width: "100%", height: "100%" }} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img loading='lazy' onLoad={ShowLoading} src={sourcelink3} alt="Certificate" className="p-2" style={{ width: "100%", height: "100%" }} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img loading='lazy' onLoad={ShowLoading} src={sourcelink4} alt="Certificate" className="p-2" style={{ width: "100%", height: "100%" }} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img loading='lazy' onLoad={ShowLoading} src={sourcelink5} alt="Certificate" className="p-2" style={{ width: "100%", height: "100%" }} />
                        </SwiperSlide>

                    </Swiper>
                    {/* <img loading='lazy' onLoad={ShowLoading} src={sourcelink} alt="Certificate" className="p-2" style={{ width: "100%", height: "100%" }} />
                    <img loading='lazy' onLoad={ShowLoading} src={sourcelink1} alt="Certificate" className="p-2" style={{ width: "100%", height: "100%" }} />
                    <img loading='lazy' onLoad={ShowLoading} src={sourcelink2} alt="Certificate" className="p-2" style={{ width: "100%", height: "100%" }} />
                    <img loading='lazy' onLoad={ShowLoading} src={sourcelink3} alt="Certificate" className="p-2" style={{ width: "100%", height: "100%" }} />
                    <img loading='lazy' onLoad={ShowLoading} src={sourcelink4} alt="Certificate" className="p-2" style={{ width: "100%", height: "100%" }} />
                    <img loading='lazy' onLoad={ShowLoading} src={sourcelink5} alt="Certificate" className="p-2" style={{ width: "100%", height: "100%" }} /> */}
                    {loading && <Loading></Loading>}
                </Box>
            </Modal>
        </div>
    );
}

  //https://i.ibb.co/jzMZFFW/dslcerti.jpg
  //https://i.ibb.co/G0bxDBj/Whats-App-Image-2022-07-06-at-9-23-46-PM.jpg
  // {certificate?<img loading='lazy' src={certificate} alt="Certificate" className="p-2" style={{width:"100%", height:"100%" }} />:<p>Loading</p>}