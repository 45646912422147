import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { Container } from "react-bootstrap";
import Loading from "../../Loading/Loading";
import CertificatModal from "./CertificatModal";
import { CelebrityContext } from "../../../context/CelebrityContext";
import './AboutUs.css';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";

function AboutUs() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [showDetailsBtn, setShowDetailsBtn] = useState(true);

    const navigate = useNavigate();

    const [totalNftValue, setTotalNftValue] = useState();
    const [nftSold, setnftSold] = useState();
    const [allNft, setallNft] = useState([]);

    const nftValues = totalNftValue?.toLocaleString()
    const nftSoldValues = nftSold?.toLocaleString()

    useEffect(() => {
        const dataNftSold = async () => {
            await axios.get("https://backend.playtoearnblockchain.com/api/v1/mint/sum")
                .then(res => {
                    setnftSold(res?.data?.TotalAmount);
                });

        }
        dataNftSold();
    }, [])


    useEffect(() => {
        const nftValue = async () => {
            await axios.get("https://backend.playtoearnblockchain.com/api/v1/total-nft")
                .then(res => {

                    setTotalNftValue(res?.data?.TotalAvailabaleNft);
                });

        }
        nftValue();
    }, [])



    useEffect(() => {
        const dataIsMeal = async () => {
            await axios.get("https://backend.playtoearnblockchain.com/api/nft/all", {
                headers: { "authorization": `Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54` }
            })
                .then(res => {
                    setallNft(res?.data?.nft);
                });
        }
        dataIsMeal();
    }, [])












    const expand = () => {
        const dots = document.getElementById("aboutDots");
        const moreText = document.getElementById("aboutMore");
        const btnText = document.getElementById("aboutExpandBtn");

        if (dots.style.display === "none") {
            dots.style.display = "inline";
            btnText.innerHTML = "More details";
            moreText.style.display = "none";
            moreText.style.color = "white";
        } else {
            dots.style.display = "none";
            btnText.innerHTML = "Read less";
            moreText.style.display = "inline";
            moreText.style.color = "white";
            setShowDetailsBtn(false);
        }
    }

    const {
        getBalanceTestnet,
        getBalanceMainnet,
        mintTicketNFTTestnetBNB,
        mintTicketNFTTestnetUSDSC,
        mintTicketNFTTestnetDSL
    } = useContext(CelebrityContext);


    useEffect(() => {
        setLoading(true)
        axios.get("https://backend.dsl.sg/api/v1/page/about", {
            headers: { "authorization": `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9` }
        })
            .then(res => {
                setData(res.data.page.content)
            })
            .finally(() => setLoading(false));
    }, [])

    const handelOnclik = () => {
        setOpen(true)
    }

    function Mint() {
        const uriNft = "https://jsonkeeper.com/b/QNEQ";
        const price = "100";
        mintTicketNFTTestnetDSL(uriNft, price)
    }
    return (
        <div className="vh-90 pb-5" >
            <div>
                <h1 className="text-gradient text-center pt-5 text-uppercase" style={{ marginTop: '80px', fontSize: "1.75rem" }}>About Us</h1>
                <div className="small-border bg-color-2"></div>
            </div>
            <div>
                <Container className="about-us">


                    {
                        loading && <Box sx={{ display: 'flex', justifyContent: "center" }}>
                            <CircularProgress color="secondary" />
                        </Box>
                    }
                    <style jsx global>
                        {`
                     p span {color: red;}
              `}
                    </style>
                    <div dangerouslySetInnerHTML={{ __html: data?.slice(0, 1290) }} className="text-white about_content making_white-ch" style={{ color: "white" }}>

                    </div>

                    <div id="aboutDots"></div>
                    <div id="aboutMore" dangerouslySetInnerHTML={{ __html: data?.slice(1245, data.length) }} className="text-white about_content making_white-ch"></div>

                    {showDetailsBtn && !loading && <p onClick={expand} id="aboutExpandBtn" className="text-primary text-decoration-underline" style={{ cursor: 'pointer' }}>More details</p>}

                    {!loading && <button onClick={handelOnclik} class="button-18" id="CertificatModalButton" role="button">  <span>FINTECH CERTIFICATE</span> </button>}
                </Container>
            </div>
            <CertificatModal open={open} setOpen={setOpen} />


            {allNft?.length > 0 &&
                <div>

                    <>

                        <p className="text-gradient text-center fs-6 pt-4" style={{ cursor: "pointer" }} onClick={() => navigate("/nfts")}>NFTs available: {allNft?.length}</p>

                        <p style={{ fontSize: "0.9rem" }} className="text-white text-center pt-1 mb-0">Total value of NFTs: {nftValues} USD</p>
                        <p style={{ fontSize: "0.9rem" }} className="text-white text-center pt-">Total value of NFTs sold: {nftSoldValues} USD</p>


                    </>
                </div>
            }
        </div>
    )
}

export default AboutUs
