import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Loading from '../../Loading/Loading';
import LikeModal from './LikeModal';

const DashboardLike = () => {

    const [data, setData] = useState([])
    const [open, setOpen] = useState(false)
    useEffect(() => {
        const fetching = async () => {
            await axios.get("https://backend.playtoearnblockchain.com/api/nft/all", {
                headers: { "authorization": `Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54` }
            })
                .then(res => {
                    setData(res?.data?.nft.reverse())
                })
        }
        fetching();

    }, [])
    const handleClose = () => {
        setOpen(false)
    }

    // Get the data to paginate

    // Set the number of items per page
    const itemsPerPage = 10;

    // Use the useState hook to store the current page and an array of data for each page
    const [currentPage, setCurrentPage] = useState(1);
    const [pagedData, setPagedData] = useState([]);

    // Use the useEffect hook to paginate the data when the component mounts or the data changes
    React.useEffect(() => {
        // Calculate the number of pages needed
        const numPages = Math.ceil(data.length / itemsPerPage);

        // Create an array of arrays, with each inner array containing the items for a single page
        const pages = Array.from({ length: numPages }, (_, i) =>
            data.slice(i * itemsPerPage, (i + 1) * itemsPerPage)
        );

        // Set the paged data to the appropriate page
        setPagedData(pages[currentPage - 1]);
    }, [data, currentPage, itemsPerPage]);

    // Create a function to handle changing the page
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const [ids, setId] = useState('')


    const navigate = useNavigate()
    return (
        <div className='payments' style={{
            height: pagedData?.length > 7 ? "auto" : "100vh",
        }}
        >
            <div class="table-responsive">
                <table class="table text-white">
                    <thead>
                        <tr>
                            <th scope="col">S/No</th>
                            <th className='th-font-size'>NFT Image</th>
                            <th className='th-font-size'>NFT Name</th>
                            <th className='th-font-size'>Price (USD)</th>
                            <th className="th-font-size">NFT Type</th>
                            <th className="th-font-size">NFT Likes</th>
                        </tr>
                    </thead>
                    <tbody style={{ verticalAlign: "revert" }}>
                        {
                            pagedData?.map((items, index) =>
                                <tr>
                                    <th scope="row">{index + 1}</th>
                                    <td className='ps-0'>
                                        <img src={items.avatar} alt="" style={{ width: "65px", hight: "65px" }} />
                                    </td>
                                    <td className='td-font-size'>{items.name}</td>
                                    <td className='td-font-size'>{items.price}</td>
                                    <td className="td-font-size">
                                        {items.type}
                                    </td>
                                    <td className="td-font-size">
                                        {items.likes[0].slice(0, 22)}<br />{items.likes[0].slice(23, items.likes[0].length)} and <span style={{ cursor: "pointer", borderBottom: '1px solid #0cd2ff', color: '#0cd2ff' }} onClick={() => { setOpen(true); setId(items._id) }}>{items.likes.length - 1} others</span>
                                    </td>

                                </tr>

                            )
                        }

                    </tbody>
                </table>


            </div>
            <LikeModal
                ids={ids}
                open={open}
                setOpen={setOpen}
                handleClose={handleClose}
            ></LikeModal>
            {/* Display the pagination controls */}
            <div className='text-center pt-4'>
                {/* Only show the "prev" button if we're not on the first page */}
                {currentPage > 1 && (
                    <button
                        className='btn btn-primary me-1'
                        onClick={() => handlePageChange(currentPage - 1)}>
                        Prev
                    </button>
                )}

                {/* Show the page numbers */}
                {Array.from(
                    { length: Math.ceil(data.length / itemsPerPage) },
                    (_, i) => (
                        <button
                            className='btn btn-primary me-1'
                            key={i + 1}
                            onClick={() => handlePageChange(i + 1)}
                            disabled={i + 1 === currentPage}
                        >
                            {i + 1}
                        </button>
                    )
                )}

                {/* Only show the "next" button if we're not on the last page */}
                {currentPage < Math.ceil(data.length / itemsPerPage) && (
                    <button
                        className='btn btn-primary me-1'
                        onClick={() => handlePageChange(currentPage + 1)}>
                        Next
                    </button>
                )}
            </div>

        </div >
    );
};

export default DashboardLike;