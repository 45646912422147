import React from 'react';
import { Link } from 'react-router-dom';
import './Rewards.css'

const Rewards = () => {
  return (
    <div style={{ minHeight: "100vh", backgroundColor: '#130A1D' }}>
      <div>
        <h1 className="text-gradient text-center pt-5 text-uppercase" style={{ marginTop: '80px', fontSize: "1.75rem" }}>REWARDS</h1>
        <div className="small-border bg-color-2"></div>
        <div className="container my-3">
          <h5 className='text-start'>Start Earning Today with Us</h5>
          <br />
          <h5 style={{ color: "#D60359", fontSize: "22px" }}>You can earn in three ways</h5>
          <p className='rewardBg1'>1. Sign up and get our NFT FREE. Sell it in Opensea and Binance NFTs for a profit.</p>
          <p className='rewardBg2'>2. Invite your friends and earn 10% referral fees in the form of BNB. No payment needed.</p>
          <p className='rewardBg3'>3. Play our games to earn coins and tokens. You can use our FREE NFT</p>
          <br />

          <h5 style={{ color: "#D60359", fontSize: "22px" }}>What you need to do?</h5>
          <h6 style={{ color: "#00E4FF", fontSize: "17px" }}>For Android and Apple Devices</h6>
          <p>1. Download Metamask mobile apps at <a href="https://metamask.io/" target="_any" style={{ textDecoration: "none", color: "rgb(160, 69, 246)" }}>https://metamask.io/</a></p>
          <p>2. Create or Import wallet.</p>
          <p>3. Set the network as below.</p>
          <p>4. Open <a href="https://playtoearnblockchain.com/" target="_any" style={{ textDecoration: "none", color: "rgb(160, 69, 246)" }}>https://playtoearnblockchain.com/</a> with Metamask Browser.</p>
          <p>5. Login with wallet</p>
          <p>6. Go to profile and get your Free Code to mint.</p>
          <p>7. Mint your NFT.</p>
          <p>8. Invite your friends.</p>
          <p className='text-warning'>You need BNB as gas fees to mint.</p>
          <br />

          <h6 style={{ color: "#00E4FF", fontSize: "17px" }}>For Chrome Browsers</h6>
          <p>1. Download Metamask Extension.</p>
          <p>2. Create or Import wallet.</p>
          <p>3. Set the network as below.</p>
          <p>4. Open <a href="https://playtoearnblockchain.com/" target="_any" style={{ textDecoration: "none", color: "rgb(160, 69, 246)" }}>https://playtoearnblockchain.com/</a> with Metamask Browser.</p>
          <p>5. Login with wallet</p>
          <p>6. Go to profile and get your Free Code to mint.</p>
          <p>7. Mint your NFT.</p>
          <p>8. Invite your friends.</p>
          <p className='text-warning'>You need BNB as gas fees to mint.</p>
          <br />

          <h5 style={{ color: "#D60359", fontSize: "22px" }}>Settings for Network</h5>
          <h6 style={{ color: "#00E4FF", fontSize: "17px" }}>Mainnet</h6>
          <p>Network Name: Binance Chain</p>
          <p>New RPC URL: https://bsc-dataseed.binance.org/</p>
          <p>ChainID: 56</p>
          <p>Symbol: BNB</p>
          <p>Block Explorer URL: https://bscscan.com</p>

          <div className="mb-5 mt-5 play-btn-margin">
            {/* <p className="mb-3">
            <Link
              to="/playtoearn"
              className="text-decoration-none playtoearnReword text-center"
              rel="playtoearn"
            >
          Finally you can start earning by playing our Play To Earn Games            
          </Link>
          </p> */}
          </div>

        </div>
      </div>
    </div>
  );
};

export default Rewards;