import { InputLabel, MenuItem, Select, Typography, Box } from "@mui/material";
import axios from "axios";
import FormControl from "@mui/material/FormControl";
import { Fragment, useContext, useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { CelebrityContext } from "../../../context/CelebrityContext";
import Slider from "react-slick";
import { Swiper, SwiperSlide } from "swiper/react";
import CircularProgress from "@mui/material/CircularProgress";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import CheckLogin from "../../Shared/CheckLogin";
import Pagination from "@mui/material/Pagination";
import MetaData from "../../MetaData/MetaData";

const { ethereum } = window;

const MealNFT = () => {
  const navigate = useNavigate();
  const [isMeal, setIsMeal] = useState([]);
  const [allAvailable, setAllAvailable] = useState([]);
  const [data, setData] = useState([]);
  const { openWalletModal, user } = useContext(CelebrityContext);
  const [loading, setLoading] = useState(false);

  const [category, setCategory] = useState("All");
  const [categories, setcategories] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pagedData, setPagedData] = useState([]);
  const [page1, setPage1] = useState(1);

  const handleChange = (event) => {
    setCategory(event.target.value);
    setCurrentPage(1);
    setPage1(1);
  };

  const [totalNftValue, setTotalNftValue] = useState();
  const [nftSold, setnftSold] = useState();

  const nftValues = totalNftValue?.toLocaleString();
  const nftSoldValues = nftSold?.toLocaleString();

  useEffect(() => {
    const dataNftSold = async () => {
      await axios
        .get("https://backend.playtoearnblockchain.com/api/v1/mint/sum")
        .then((res) => {
          setnftSold(res?.data?.TotalAmount);
        });
    };
    dataNftSold();
  }, []);

  useEffect(() => {
    const nftValue = async () => {
      await axios
        .get("https://backend.playtoearnblockchain.com/api/v1/total-nft")
        .then((res) => {
          setTotalNftValue(res?.data?.TotalAvailabaleNft);
        });
    };
    nftValue();
  }, []);

  let allNft = [];
  // const [allNft, setallNft] = useState(isMeal)
  if (category == "All") {
    allNft = isMeal;
  } else {
    allNft = isMeal?.filter((res) => res.type == category);
  }
  const todayDate = new Date();
  // useEffect(()=>{

  // },[pagedData])
  useEffect(() => {
    axios
      .get("https://backend.playtoearnblockchain.com/api/v1/mint/mint-nft", {
        headers: {
          authorization: `Bearer x4abs2zDM01DMMEgt33CrKZt86atzL3CJdExpP4`,
        },
      })
      .then((res) => {
        setAllAvailable(res.data);
      });
  }, []);
  const removedUrl = "https://backend.celebritygames.net";

  useEffect(() => {
    setLoading(false);
    axios
      .get("https://backend.playtoearnblockchain.com/api/nft/all", {
        headers: {
          authorization: `Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54`,
        },
      })
      .then((res) => {
        const copyarr = [...res?.data?.nft];
        copyarr.sort((a, b) => a.name.trim().localeCompare(b.name.trim()));

        setIsMeal(copyarr);
        const array = res?.data?.nft.map((res) => {
          return res.type;
        });
        let uniqueArray = [...new Set(array)];
        const othersIndex = uniqueArray.sort().indexOf("Others");

        if (othersIndex !== -1) {
          uniqueArray.splice(othersIndex, 1);
          uniqueArray.push("Others");
        }
        setcategories(uniqueArray);
      })
      .finally(() => setLoading(false));
  }, []);

  const likess = localStorage.getItem("like");

  // Get the data to paginate

  // Set the number of items per page
  const itemsPerPage = 12;

  // Use the useState hook to store the current page and an array of data for each page

  // Use the useEffect hook to paginate the data when the component mounts or the data changes
  useEffect(() => {
    // Calculate the number of pages needed

    const numPages = Math.ceil(allNft.length / itemsPerPage);

    // Create an array of arrays, with each inner array containing the items for a single page
    const pages = Array.from({ length: numPages }, (_, i) =>
      allNft.slice(i * itemsPerPage, (i + 1) * itemsPerPage)
    );

    // Set the paged data to the appropriate page
    setPagedData(pages[currentPage - 1]);
  }, [allNft, currentPage, itemsPerPage]);

  // Create a function to handle changing the page
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  // like functionality
  const likeNft = (id) => {
    if (!user?.walletAddress || user?.walletAddress === "undefined") {
      // openWalletModal();
    } else {
      fetch("https://backend.playtoearnblockchain.com/api/nft/like", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54",
        },
        body: JSON.stringify({
          nftId: id,
          walletAddress: user?.walletAddress,
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          const newNft = isMeal?.map((data) => {
            if (data._id == result._id) {
              return result;
            } else {
              return data;
            }
          });
          setIsMeal(newNft);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const unlikeNft = (id) => {
    if (!user?.walletAddress || user?.walletAddress === "undefined") {
      // openWalletModal();
    } else {
      fetch("https://backend.playtoearnblockchain.com/api/nft/unlike", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54",
        },
        body: JSON.stringify({
          nftId: id,
          walletAddress: user?.walletAddress,
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          // console.log(result)
          const newNft = isMeal?.map((data) => {
            if (data._id == result._id) {
              return result;
            } else {
              return data;
            }
          });
          setIsMeal(newNft);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // Slider
  let settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
          // arrows: false
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
        },
      },
    ],
  };

  const data1 = allNft;
  const itemsPerPage1 = 12;
  const pageCount1 = Math.ceil(data1.length / itemsPerPage1);

  const handleChange1 = (event, value) => {
    window.scrollTo(0, 0);
    setPage1(value);
  };

  const getPageData = () => {
    const start = (page1 - 1) * itemsPerPage1;
    const end = start + itemsPerPage1;
    return data1.slice(start, end);
  };

  return (
    <div>
      <MetaData pageTitle={"Buy NFT and win prizes | Play to earn block chain"} pageDescription={"Playtoearnblockchain.com NFTs can be sold in marketplaces or used in our PLAY TO EARN Blockchain Games. It is a blockchain-based game that allows players to earn rewards & Purchase NFTs."}></MetaData>
      <Fragment>
        <Box
          className="souvenirNFT_Box"
          id="Meal"
          style={
            allNft.length
              ? { paddingBottom: "0" }
              : { paddingBottom: "0", height: "100vh" }
          }
        >
          <div>
            <h1
              className="text-gradient text-center pt-5"
              style={{ marginTop: "40px", fontSize: "1.75rem" }}
            >
              NFT<span style={{ textTransform: "lowercase" }}>s</span>
            </h1>
            <div className="small-border bg-color-2"></div>
          </div>

          <div className="row mb-0 mb-md-4 g-0">
            <div className="col-12">
              <Form.Select
                aria-label="Default select example"
                value={category}
                onChange={handleChange}
              >
                <option value="All">All</option>
                {/* <option value="India">India</option>
                <option value="Malaysia ">Malaysia</option>
                <option value="Philippines">Philippines</option>
                <option value="Thailand">Thailand</option>
                <option value="Others">Others</option> */}
                {categories?.map((data) => (
                  <option value={data}>{data}</option>
                ))}
              </Form.Select>
            </div>
          </div>
          <p className="sighupSmall text-center mt-2 container d-block d-md-none">
            The images generated by AI do not represent any  real person or entity. The images are created by  DALL E and Midjouney with suitable prompts. Apps like Prequel are used to enhance.
          </p>
          <p className="sighupSmall text-center mt-2 d-none d-md-block">
            The images generated by AI do not represent any  real person or entity.<br />The images are created by  DALL E and Midjouney with suitable prompts.<br />Apps like Prequel are used to enhance.
          </p>
          {allNft?.length === 0 ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress color="secondary" />
            </Box>
          ) : (
            <>
              <p
                className="text-gradient text-center fs-6 pt-1 mb-lg-4 mb-1"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/nfts")}
              >
                {category} NFTs: {allNft?.length}
              </p>

              <div className="text-light mealnft container">
                <div className="row">
                  {!getPageData()?.length > 0 ? (
                    <p className="text-white text-center">
                      No NFTs are available
                    </p>
                  ) : (
                    getPageData()?.map((data) => (
                      // <SwiperSlide>
                      <div className="col-12 col-lg-3 col-md-6">
                        <div key={data?._id} className="d-item1">
                          <div class="card">
                            <CheckLogin register={"code"}>
                              <div
                                onClick={() =>
                                  data.likes?.includes(user?.walletAddress)
                                    ? unlikeNft(data?._id)
                                    : likeNft(data?._id)
                                }
                                className="nft_item_like like_card"
                              >
                                <i
                                  className={`fa fa-heart ${data?.likes?.includes(
                                    user?.walletAddress
                                  ) && "heart-icon"
                                    }`}
                                ></i>
                                <span style={{ marginBottom: "2.2px" }}>
                                  {data?.likes?.length}
                                </span>
                              </div>
                            </CheckLogin>
                            <div
                              class="card-img"
                              style={{ backgroundImage: `url(${data.avatar.replace(removedUrl, "https://backend.playtoearnblockchain.com")})` }}
                            >
                              <div
                                class="overlay d-grid "
                                style={{
                                  alignContent: "center",
                                  justifyItems: "center",
                                }}
                              >
                                <div className="d-flex card_hover_icon">
                                  <Link
                                    to={`/nft/${data?._id}/${data?.imageName}`}
                                  >
                                    <button
                                      className="card_hover_button mt-5"
                                      href="#!"
                                    >
                                      BUY THIS NFT!
                                    </button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div class="card-content">
                              <div
                                className="row"
                                style={{ minHeight: "90px" }}
                              >
                                <Typography
                                  className="mt-2 slider_nft_text"
                                  variant="div"
                                >
                                  <span className="text-primary">
                                    Category :
                                  </span>{" "}
                                  {data?.type}
                                </Typography>
                                <Typography
                                  className="mt-2 slider_nft_text"
                                  variant="div"
                                >
                                  <span className="text-primary">
                                    Name of NFT :
                                  </span>{" "}
                                  {data?.name}
                                </Typography>

                                <Typography className="mt-2" variant="body2">
                                  <span className="text-primary">
                                    Price of NFT(USD):
                                  </span>{" "}
                                  {data.price}
                                </Typography>
                                <Typography className="mt-2" variant="body2">
                                  <span className="text-primary">
                                    Total NFTs:
                                  </span>{" "}
                                  {data?.totalNfts
                                    ?.toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </Typography>
                              </div>
                              <hr style={{ margin: "10px 0px 10px 0px" }} />
                              <div
                                className="d-flex card_bottom_btn_main "
                                style={{ margin: "15px 0 8px 0" }}
                              >
                                <div className="col-10 d-grid me-2">
                                  <Link
                                    to={`/nft/${data?._id}/${data?.imageName}`}
                                    className="d-grid"
                                  >
                                    {" "}
                                    <button
                                      className={
                                        ethereum
                                          ? "card_button2 bg-success glow_crypto_button fs-6"
                                          : "card_button2 bg-success fs-6"
                                      }
                                      href="#!"
                                    >
                                      Buy this NFT!
                                    </button>{" "}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      // </SwiperSlide>
                    ))
                  )}
                  <div className="col-12">
                    <div className="pagination-btn mx-auto text-center">
                      <Pagination
                        count={pageCount1}
                        page={page1}
                        onChange={handleChange1}
                      />
                    </div>

                    {/* <div className="text-center py-4 pagination-btn">
                  {currentPage > 1 && (
                    <button
                      style={{ backgroundColor: "#004c55", color: "white" }}
                      className="btn me-1"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      Prev
                    </button>
                  )}

               
                  {Array.from(
                    { length: Math.ceil(allNft.length / itemsPerPage) },
                    (_, i) => (
                      <button
                        style={{ backgroundColor: "#004c55", color: "white" }}
                        className="btn me-1"
                        key={i + 1}
                        onClick={() => handlePageChange(i + 1)}
                        disabled={i + 1 === currentPage}
                      >
                        {i + 1}
                      </button>
                    )
                  )}

             
                  {currentPage < Math.ceil(allNft.length / itemsPerPage) && (
                    <button
                      style={{ backgroundColor: "#004c55", color: "white" }}
                      className="btn me-1"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      Next
                    </button>
                  )}
                </div> */}
                  </div>
                </div>
              </div>
            </>
          )}

          <div>
            {allNft?.length > 0 ? (
              <div>
                <p
                  className="text-gradient text-center fs-6 pt-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/nfts")}
                >
                  Types of NFTs available: {isMeal?.length}
                </p>

                <p
                  style={{ fontSize: "0.9rem" }}
                  className="text-white text-center pt-1 mb-0"
                >
                  Total value of NFTs: {nftValues} USD
                </p>
                <p
                  style={{ fontSize: "0.9rem" }}
                  className="text-white text-center pt-"
                >
                  Total value of NFTs sold: {nftSoldValues} USD
                </p>
              </div>
            ) : (
              <Typography
                variant="h6"
                style={{
                  color: "#d0d7c2",
                  textAlign: "center",
                  fontSize: "16px",
                  marginTop: "1rem",
                }}
              >
                Stay Tuned!
              </Typography>
            )}
          </div>
          <div className="d-flex" style={{ justifyContent: "center" }}></div>
          <Container style={{ marginTop: "16px" }}></Container>
        </Box>
      </Fragment>
    </div>
  );
};

export default MealNFT;
