import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { Fragment, useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CelebrityContext } from "../../../context/CelebrityContext";
import CheckLogin from "../../Shared/CheckLogin";
const { ethereum } = window;

const MealSearchedNFT = () => {
  const {
    openWalletModal,
    user,
    searchResults,
    setSearchResults,
    setLikeRefetch,
    LikeRefetch,
  } = useContext(CelebrityContext);
  const [isMeal, setIsMeal] = useState([]);
  const [allAvailable, setAllAvailable] = useState([]);
  const [refetch, setrefetch] = useState(false);

  const todayDate = new Date();

  useEffect(() => {
    axios
      .get("https://backend.playtoearnblockchain.com/api/v1/mint/mint-nft", {
        headers: {
          authorization: `Bearer x4abs2zDM01DMMEgt33CrKZt86atzL3CJdExpP4`,
        },
      })
      .then((res) => {
        setAllAvailable(res.data);
      });
  }, []);
  useEffect(() => {
    console.log("PAGE NUMBER CHANGED");
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    axios
      .get("https://backend.playtoearnblockchain.com/api/nft/all", {
        headers: {
          authorization: `Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54`,
        },
      })
      .then((res) => {
        // const filtering = res.data?.nft?.filter(items => items?.isDraft === false && new Date(`${items?.purchaseDate.slice(5, 7)}/${items?.purchaseDate.slice(8, 10)}/${items?.purchaseDate.slice(0, 4)}`) > todayDate);

        // setIsMeal(filtering);
        setIsMeal(res?.data?.nft?.reverse());
      });
  }, []);

  // like functionality
  const likeNft = (id) => {
    console.log("inside like");
    if (!user?.walletAddress || user?.walletAddress === "undefined") {
      // openWalletModal();
    } else {
      console.log(id);
      fetch("https://backend.playtoearnblockchain.com/api/nft/like", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54",
        },
        body: JSON.stringify({
          nftId: id,
          walletAddress: user?.walletAddress,
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          console.log(result);
          const newNft = isMeal?.map((data) => {
            if (data._id == result._id) {
              return result;
            } else {
              return data;
            }
          });
          const final = newNft.filter((element) =>
            searchResults.map((item) => item._id).includes(element._id)
          );
          setSearchResults(final);

          setIsMeal(newNft);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const removedUrl = "https://backend.celebritygames.net";

  const unlikeNft = (id) => {
    if (!user?.walletAddress || user?.walletAddress === "undefined") {
      // openWalletModal();
    } else {
      console.log(id);
      fetch("https://backend.playtoearnblockchain.com/api/nft/unlike", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54",
        },
        body: JSON.stringify({
          nftId: id,
          walletAddress: user?.walletAddress,
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          // console.log(result)
          const newNft = isMeal?.map((data) => {
            if (data._id == result._id) {
              return result;
            } else {
              return data;
            }
          });
          const final = newNft?.filter((element) =>
            searchResults?.map((item) => item._id).includes(element._id)
          );
          setSearchResults(final);

          setIsMeal(newNft);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const allNft = isMeal;

  console.log("searchResults", searchResults);
  return (
    <Fragment>
      <Box
        className="souvenirNFT_Box"
        id="Meal"
        style={{ height: searchResults === null && "150vh" }}
      >
        {/* {searchResults !== null &&  */}
        <>
          <div style={{ backgroundColor: "#130A1D" }}>
            <h1
              style={{ marginTop: "80px" }}
              className="text-gradient text-center pt-5"
            >
              Search Results
            </h1>
          </div>
          {searchResults === "notFound" || searchResults == null ? (
            <div>
              <Typography
                variant="h6"
                style={{
                  color: "#d0d7c2",
                  fontSize: "16px",
                  marginTop: "1rem",
                  textAlign: "center",
                }}
              >
                Your search does not return any results. Please see other NFTs
                here.
              </Typography>
            </div>
          ) : (
            <Container className="SouvenirNFT_card row pt-2 ">
              {searchResults?.map((data, idx) => (
                <div
                  key={{ idx }}
                  className="col-sm-12 col-md-4 col-lg-3 d-flex mb-3"
                  style={{ justifyContent: "center" }}
                >
                  <div class="card">
                    <CheckLogin register={"code"}>
                      <div
                        className=" like_card"
                        onClick={() =>
                          data.likes?.includes(user?.walletAddress)
                            ? unlikeNft(data?._id)
                            : likeNft(data?._id)
                        }
                      >
                        <i
                          className={`fa fa-heart ${
                            data?.likes?.includes(user?.walletAddress) &&
                            "heart-icon"
                          }`}
                        ></i>
                        <span className="">{data?.likes?.length}</span>
                      </div>
                    </CheckLogin>
                    <div
                      class="card-img"
                      style={{ backgroundImage: `url(${data.avatar.replace(removedUrl, "https://backend.playtoearnblockchain.com")})` }}
                    >
                      <div
                        class="overlay d-grid "
                        style={{
                          alignContent: "center",
                          justifyItems: "center",
                        }}
                      >
                        <div className="d-flex card_hover_icon">
                          <Link to={`/nft/${data?._id}/${data?.imageName}`}>
                            <button
                              className="card_hover_button mt-5"
                              href="#!"
                            >
                              BUY NOW
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div class="card-content">
                      <div className="row">
                        <Typography
                          className="mt-2 slider_nft_text mb-2"
                          variant="div"
                        >
                          <span className="text-primary">Category :</span>{" "}
                          {data?.type}
                        </Typography>
                        <Typography
                          className="mt-2 slider_nft_text mb-2"
                          variant="div"
                        >
                          <span className="text-primary">Name of NFT :</span>{" "}
                          {data?.name}
                        </Typography>

                        <Typography className="mt-2" variant="body2">
                          <span className="text-primary">
                            Price of NFT(USD):
                          </span>{" "}
                          {data.price}
                        </Typography>
                        <Typography className="mt-3" variant="body2">
                          <span className="text-primary">Total NFTs:</span>{" "}
                          {data?.totalNfts
                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Typography>

                        {/* <Typography className="mt-2" variant="body2">
                          <span className="text-primary">Date:</span> {`${data?.startDate.slice(8, 10)}/${data?.startDate.slice(5, 7)}/${data?.startDate.slice(0, 4)}`}
                        </Typography>
                        <Typography className="mt-2" variant="body2">
                          <span className="text-primary">Start Time:</span> {data?.startTime} SGT
                        </Typography>

                        <Typography className="mt-2" variant="body2">
                          <span className="text-primary">End Time:</span> {data?.endTime} SGT
                        </Typography>

                        <Typography className="mt-2 mb-1 slider_nft_text" variant="div">
                          <span className="text-primary">Venue:</span> {data?.venue}
                        </Typography> */}
                      </div>
                      <hr style={{ margin: "10px 0px 10px 0px" }} />
                      <div
                        className="d-flex card_bottom_btn_main"
                        style={{ margin: "15px 0 8px 0" }}
                      >
                        <div className="col-10 d-grid me-2">
                          <Link
                            to={`/nft/${data?._id}/${data?.imageName}`}
                            className="d-grid"
                          >
                            {" "}
                            <button
                              className={
                                ethereum
                                  ? "card_button2 bg-success glow_crypto_button fs-6"
                                  : "card_button2 bg-success fs-6"
                              }
                              href="#!"
                            >
                              Pay USD {data.price} by BNB
                            </button>{" "}
                          </Link>
                        </div>
                        {/* <div className="col-6 d-grid">
                          <Link to={`/paynow/${data?._id}/${data?.imageName}`} className="d-grid"> <button className="card_button2 bg-primary" href="#!">PAY BY PAYNOW </button> </Link>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Container>
          )}
        </>
        {/* } */}

        <div style={{ backgroundColor: "#130A1D" }}>
          <h1
            style={{ marginTop: "10px" }}
            className="text-gradient text-center pt-3"
          >
            Please Explore
          </h1>
        </div>
        <Container className="SouvenirNFT_card row pt-2 ">
          {allNft?.slice(0, 4).map((data, idx) => (
            <div
              key={{ idx }}
              className="col-sm-12 col-md-4 col-lg-3 d-flex mb-3"
              style={{ justifyContent: "center" }}
            >
              <div class="card">
                <CheckLogin register={"code"}>
                  <div
                    className=" like_card"
                    onClick={() =>
                      data.likes?.includes(user?.walletAddress)
                        ? unlikeNft(data?._id)
                        : likeNft(data?._id)
                    }
                  >
                    <i
                      className={`fa fa-heart ${
                        data?.likes?.includes(user?.walletAddress) &&
                        "heart-icon"
                      }`}
                    ></i>
                    <span className="">{data?.likes?.length}</span>
                  </div>
                </CheckLogin>
                <div
                  class="card-img"
                  style={{ backgroundImage: `url(${data.avatar.replace(removedUrl, "https://backend.playtoearnblockchain.com")})` }}
                >
                  <div
                    class="overlay d-grid "
                    style={{ alignContent: "center", justifyItems: "center" }}
                  >
                    <div className="d-flex card_hover_icon">
                      <Link to={`/nft/${data?._id}/${data?.imageName}`}>
                        <button className="card_hover_button mt-5" href="#!">
                          BUY NOW
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div class="card-content">
                  <div className="row">
                    <Typography
                      className="mt-2 slider_nft_text mb-2"
                      variant="div"
                    >
                      <span className="text-primary">Category :</span>{" "}
                      {data?.type}
                    </Typography>
                    <Typography
                      className="mt-2 slider_nft_text mb-2"
                      variant="div"
                    >
                      <span className="text-primary">Name of NFT :</span>{" "}
                      {data?.name}
                    </Typography>

                    <Typography className="mt-2" variant="body2">
                      <span className="text-primary">Price of NFT(USD):</span>{" "}
                      {data?.price}
                    </Typography>

                    <Typography className="mt-3" variant="body2">
                      <span className="text-primary">Total NFTs:</span>{" "}
                      {data?.totalNfts
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </Typography>

                    {/* <Typography className="mt-2" variant="body2">
                        <span className="text-primary">Date:</span> {`${data?.startDate.slice(8, 10)}/${data?.startDate.slice(5, 7)}/${data?.startDate.slice(0, 4)}`}
                      </Typography>
                      <Typography className="mt-2" variant="body2">
                        <span className="text-primary">Start Time:</span> {data?.startTime} SGT
                      </Typography>

                      <Typography className="mt-2" variant="body2">
                        <span className="text-primary">End Time:</span> {data?.endTime} SGT
                      </Typography>

                      <Typography className="mt-2 mb-1 slider_nft_text" variant="div">
                        <span className="text-primary">Venue:</span> {data?.venue}
                      </Typography> */}
                  </div>
                  <hr style={{ margin: "10px 0px 10px 0px" }} />
                  <div
                    className="d-flex card_bottom_btn_main"
                    style={{ margin: "15px 0 8px 0" }}
                  >
                    <div className="col-10 d-grid me-2">
                      <Link
                        to={`/nft/${data?._id}/${data?.imageName}`}
                        className="d-grid"
                      >
                        {" "}
                        <button
                          className={
                            ethereum
                              ? "card_button2 bg-success glow_crypto_button fs-6"
                              : "card_button2 bg-success fs-6"
                          }
                          href="#!"
                        >
                          Pay USD {data.price} by BNB
                        </button>{" "}
                      </Link>
                    </div>
                    {/* <div className="col-6 d-grid">
                        <Link to={`/paynow/${data?._id}/${data?.imageName}`} className="d-grid"> <button className="card_button2 bg-primary" href="#!">PAY BY PAYNOW </button> </Link>
                      </div> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Container>
        <div className="d-flex" style={{ justifyContent: "center" }}>
          {isMeal?.length > 0 ? (
            <Typography
              variant="h6"
              style={{ color: "#d0d7c2", fontSize: "16px", marginTop: "1rem" }}
            >
              {/* Pay by DSL and get 30% discount. */}
            </Typography>
          ) : (
            <Typography
              variant="h6"
              style={{ color: "#d0d7c2", fontSize: "16px", marginTop: "1rem" }}
            >
              Stay Tuned!
            </Typography>
          )}
        </div>
      </Box>
    </Fragment>
  );
};

export default MealSearchedNFT;
