import React, { useContext, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import CustomLink from "../../CustomLink";
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import { TiGroup } from "react-icons/ti";
import { HiCheckCircle } from "react-icons/hi";
import { MdMenuOpen, MdClose, MdPayment, MdCategory } from "react-icons/md";
import { AdminContext } from "../../../context/AdminContext";
import "./Dashboard.css";
import { AiFillLike } from "react-icons/ai";
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
} from "@mui/material";

const Dashboard = () => {
  const { logout } = useContext(AdminContext);
  const location = useLocation();
  const pathname = location.pathname;

  const today = new Date();
  const year = today.getFullYear();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleCloses = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <div
      className="dashboardFlex"
      style={{ height: "auto", backgroundColor: "#1A1A25" }}
    >
      <div
        className="handleNested text-center"
        style={{ backgroundColor: "#323246", color: "white" }}
      >
        <div className="positionHandleLogo">
          <Link to="/">
            {" "}
            {/* <img
              // src="/assets/images/logo-6.jpg"
              className="img-fluid w-25 h-auto mt-3"
              alt="logo"
            /> */}
          </Link>
          <hr />
        </div>
        <Navbar
          expand="sm"
          className="mb-3"
          style={{ marginTop: "100px", width: "220px" }}
          fixed="top"
        >
          <Container fluid style={{ color: "white" }}>
            <Navbar.Toggle aria-controls="offcanvasNavbar-expand-sm" />
            <Navbar.Offcanvas
              id={"offcanvasNavbar-expand-sm}"}
              aria-labelledby={"offcanvasNavbarLabel-expand-sm"}
              placement="start"
              style={{ backgroundColor: "#323246", color: "white" }}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={"offcanvasNavbarLabel-expand-sm"}>
                  DS Legends Pte. Ltd.
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="d-flex flex-column justify-content-center align-items-start  text-start">
                  <div className={pathname === "/dashboard" ? "activeBg" : ""}>
                    <Nav.Link
                      className="text-light mb-3 text-start"
                      as={CustomLink}
                      to="/dashboard"
                      style={{ width: "190px" }}
                    >
                      <span className="pe-3 ps-3 fs-4">
                        <TiGroup></TiGroup>
                      </span>{" "}
                      <span className="pe-3">DASHBOARD</span>
                    </Nav.Link>
                  </div>

                  <div
                    className={
                      pathname === "/dashboard/admin" ? "activeBg" : ""
                    }
                  >
                    <Nav.Link
                      className="text-light mb-3 text-start"
                      as={CustomLink}
                      to="/dashboard/admin"
                      style={{ width: "190px" }}
                    >
                      <span className="pe-3 ps-3 fs-4">
                        <TiGroup></TiGroup>
                      </span>{" "}
                      <span className="pe-3">ADMINS</span>
                    </Nav.Link>
                  </div>

                  <div
                    className={pathname === "/dashboard/nfts" ? "activeBg" : ""}
                  >
                    <Nav.Link
                      className="text-light mb-3 text-start"
                      as={CustomLink}
                      to="/dashboard/nfts"
                      style={{ width: "190px" }}
                    >
                      <span className="pe-3 ps-3 fs-4">
                        <HiCheckCircle></HiCheckCircle>
                      </span>{" "}
                      <span>NFTS</span>
                    </Nav.Link>
                  </div>
                  <div
                    className={
                      pathname === "/dashboard/likes" ? "activeBg" : ""
                    }
                  >
                    <Nav.Link
                      className="text-light mb-3 text-start"
                      as={CustomLink}
                      to="/dashboard/likes"
                      style={{ width: "190px" }}
                    >
                      <span className="pe-3 ps-3 fs-4">
                        <AiFillLike />
                      </span>{" "}
                      <span>LIKES</span>
                    </Nav.Link>
                  </div>

                  <div
                    className={
                      pathname === "/dashboard/categories" ? "activeBg" : ""
                    }
                  >
                    <Nav.Link
                      className="pe-4 text-light ps-4 mb-3 text-start"
                      as={CustomLink}
                      to="/dashboard/categories"
                      style={{ width: "190px" }}
                    >
                      <span className="pe-3 fs-4">
                        <MdCategory></MdCategory>
                      </span>{" "}
                      <span>CATEGORIES</span>
                    </Nav.Link>
                  </div>
                  <div
                    className={
                      pathname === "/dashboard/bannerapi" ? "activeBg" : ""
                    }
                  >
                    <Stack direction="row" spacing={2}>
                      <div>
                        <Nav.Link
                          ref={anchorRef}
                          id="composition-button"
                          aria-controls={open ? "composition-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={handleToggle}
                          className="pe-4 text-light ps-4 mb-3 text-start"
                          style={{ width: "190px" }}
                        >
                          <span className="pe-3 fs-4">
                            <i class="fas fa-file-alt"></i>
                          </span>{" "}
                          <span className="me-3">API</span>
                          {open ? (
                            <IoIosArrowDropdownCircle />
                          ) : (
                            <IoIosArrowDroprightCircle />
                          )}
                        </Nav.Link>
                        <Popper
                          open={open}
                          anchorEl={anchorRef.current}
                          role={undefined}
                          placement="bottom-start"
                          transition
                          disablePortal
                          className="left"
                          style={{ zIndex: "5" }}
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin:
                                  placement === "bottom-start"
                                    ? "left top"
                                    : "left bottom",
                              }}
                            >
                              <Paper>
                                <ClickAwayListener onClickAway={handleCloses}>
                                  <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}
                                  >
                                    <MenuItem
                                      onClick={handleCloses}
                                      className={
                                        pathname === "/dashboard/bannerapi"
                                          ? "activeBg text-white"
                                          : ""
                                      }
                                    >
                                      <Nav.Link
                                        onClick={() => {
                                          navigate("/dashboard/bannerapi");
                                        }}
                                        className="text-dark"
                                      >
                                        <i class="far fa-images me-1"></i>{" "}
                                        BANNER
                                      </Nav.Link>
                                    </MenuItem>
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      </div>
                    </Stack>
                  </div>
                  <div
                    className={
                      pathname === "/dashboard/payments" ? "activeBg" : ""
                    }
                  >
                    <Nav.Link
                      className="pe-4 text-light ps-4 mb-3 text-start"
                      as={CustomLink}
                      to="/dashboard/payments"
                      style={{ width: "190px" }}
                    >
                      <span className="pe-3 fs-4">
                        <MdPayment></MdPayment>
                      </span>{" "}
                      <span>PAYMENTS</span>
                    </Nav.Link>
                  </div>

                  <div style={{ width: "120px", marginLeft: "-1rem" }}>
                    <Button
                      style={{ zIndex: "4" }}
                      variant="danger"
                      className="text-uppercase w-100 pt-2 pb-2 mt-4"
                      size="sm"
                      onClick={() => handleLogout()}
                    >
                      Log Out
                    </Button>
                  </div>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </div>
      <div className="handleNavbarSecond">
        <Navbar
          expand="lg"
          variant="dark"
          className="navIssue"
          collapseOnSelect
        >
          <Container fluid>
            <Container className="d-flex justify-content-between align-items-center">
              {/* <Navbar.Brand href="#">Navbar</Navbar.Brand> */}
              <div className="hanleMenuBarForRespo">
                <MdMenuOpen
                  className="fs-3 text-light"
                  onClick={handleShow}
                ></MdMenuOpen>
                <Offcanvas
                  show={show}
                  onHide={handleClose}
                  style={{ backgroundColor: "#323246", color: "white" }}
                >
                  <Offcanvas.Header className="mb-4">
                    <Offcanvas.Title className="text-light">
                      <Nav className="d-flex flex-column justify-content-center align-items-center handleNav mx-auto text-center">
                        <Nav.Link className="text-light" as={CustomLink} to="/">
                          {/* <img
                            // src="/assets/images/logo-6.jpg"
                            style={{ width: "50px", marginLeft: "-95px" }}
                            // style={{ width: "50px", marginLeft: "-135px" }}
                            className="logoAdmins"
                            alt="logo"
                          /> */}
                        </Nav.Link>
                      </Nav>
                    </Offcanvas.Title>
                    <MdClose
                      onClick={handleClose}
                      color="#fff"
                      size={30}
                      style={{ cursor: "pointer" }}
                    />
                  </Offcanvas.Header>
                  <Offcanvas.Body
                    style={{ backgroundColor: "#323246", color: "white" }}
                  >
                    <Nav className="d-flex flex-column justify-content-center align-items-start handleNav nav_start ps-0 ms-0">
                      <div
                        className={
                          pathname === "/dashboard" ? "activeMobileBg" : ""
                        }
                      >
                        <Nav.Link
                          className="text-center ps-2 text-light text-start ms-3 mb-2"
                          as={CustomLink}
                          to="/dashboard"
                          href="#admin"
                          onClick={handleClose}
                          style={{
                            width: "100%",
                            backgroundColor: "transparent",
                          }}
                        >
                          <span className="me-2 fs-4">
                            <TiGroup></TiGroup>
                          </span>{" "}
                          <span>DASHBOARD</span>
                        </Nav.Link>
                      </div>

                      <div
                        className={
                          pathname === "/dashboard/admin"
                            ? "activeMobileBg"
                            : ""
                        }
                      >
                        <Nav.Link
                          className="text-center pe-4 text-light text-start ms-3 mb-2"
                          as={CustomLink}
                          to="/dashboard/admin"
                          href="#admin"
                          onClick={handleClose}
                          style={{
                            width: "100%",
                            backgroundColor: "transparent",
                          }}
                        >
                          <span className="me-2 fs-4">
                            <TiGroup></TiGroup>
                          </span>{" "}
                          <span>ADMINS</span>
                        </Nav.Link>
                      </div>

                      <div
                        className={
                          pathname === "/dashboard/nfts" ? "activeMobileBg" : ""
                        }
                      >
                        <Nav.Link
                          className="text-center pe-5 ps-3 text-light text-start ms-3 mb-2"
                          as={CustomLink}
                          to="/dashboard/nfts"
                          href="#nfts"
                          onClick={handleClose}
                          // style={{ width: '168px' }}
                          style={{
                            width: "100%",
                            backgroundColor: "transparent",
                          }}
                        >
                          <span className="me-2 fs-4">
                            <HiCheckCircle></HiCheckCircle>
                          </span>{" "}
                          <span>NFTS</span>
                        </Nav.Link>
                      </div>
                      <div
                        className={
                          pathname === "/dashboard/likes"
                            ? "activeMobileBg"
                            : ""
                        }
                      >
                        <Nav.Link
                          className="text-center pe-5 ps-3 text-light text-start ms-3 mb-2"
                          as={CustomLink}
                          to="/dashboard/likes"
                          href="#likes"
                          onClick={handleClose}
                          // style={{ width: '168px' }}
                          style={{
                            width: "100%",
                            backgroundColor: "transparent",
                          }}
                        >
                          <span className="me-2 fs-4">
                            <AiFillLike />
                          </span>{" "}
                          <span>LIKES</span>
                        </Nav.Link>
                      </div>

                      <div
                        className={
                          pathname === "/dashboard/categories"
                            ? "activeMobileBg"
                            : ""
                        }
                      >
                        <Nav.Link
                          className="text-center ps-1 text-light text-start ms-3 mb-2"
                          as={CustomLink}
                          to="/dashboard/categories"
                          href="#nfts"
                          onClick={handleClose}
                          // style={{ width: '188px' }}
                          style={{
                            width: "100%",
                            backgroundColor: "transparent",
                          }}
                        >
                          <span className="me-2 fs-4">
                            <MdCategory></MdCategory>
                          </span>{" "}
                          <span>CATEGORIES</span>
                        </Nav.Link>
                      </div>
                      <div
                        className={
                          pathname === "/dashboard/bannerapi"
                            ? "activeMobileBg"
                            : ""
                        }
                      >
                        <Stack direction="row" spacing={2}>
                          <div style={{ margin: "5px 0 0 25px" }}>
                            <Nav.Link
                              ref={anchorRef}
                              id="composition-button"
                              aria-controls={
                                open ? "composition-menu" : undefined
                              }
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={handleToggle}
                              className="pe-4 text-light ps-4 mb-3 text-start"
                              style={{ width: "190px" }}
                            >
                              <span className="pe-3 fs-4">
                                <i class="fas fa-file-alt"></i>
                              </span>{" "}
                              <span className="me-3">API</span>
                              {open ? (
                                <IoIosArrowDropdownCircle />
                              ) : (
                                <IoIosArrowDroprightCircle />
                              )}
                            </Nav.Link>
                            <Popper
                              open={open}
                              anchorEl={anchorRef.current}
                              role={undefined}
                              placement="bottom-start"
                              transition
                              disablePortal
                              className="left"
                              style={{ zIndex: "5" }}
                            >
                              {({ TransitionProps, placement }) => (
                                <Grow
                                  {...TransitionProps}
                                  style={{
                                    transformOrigin:
                                      placement === "bottom-start"
                                        ? "left top"
                                        : "left bottom",
                                  }}
                                >
                                  <Paper>
                                    <ClickAwayListener
                                      onClickAway={handleCloses}
                                    >
                                      <MenuList
                                        autoFocusItem={open}
                                        id="composition-menu"
                                        aria-labelledby="composition-button"
                                        onKeyDown={handleListKeyDown}
                                      >
                                        <MenuItem
                                          onClick={handleCloses}
                                          className={
                                            pathname === "/dashboard/bannerapi"
                                              ? "activeBg text-white"
                                              : ""
                                          }
                                        >
                                          <Nav.Link
                                            onClick={() => {
                                              navigate("/dashboard/bannerapi");
                                            }}
                                            className="text-dark"
                                          >
                                            <i class="far fa-images me-1"></i>{" "}
                                            BANNER
                                          </Nav.Link>
                                        </MenuItem>
                                      </MenuList>
                                    </ClickAwayListener>
                                  </Paper>
                                </Grow>
                              )}
                            </Popper>
                          </div>
                        </Stack>
                      </div>

                      <div
                        className={
                          pathname === "/dashboard/payments"
                            ? "activeMobileBg"
                            : ""
                        }
                      >
                        <Nav.Link
                          className="text-center ps-1 text-light text-start ms-3 mb-2"
                          as={CustomLink}
                          to="/dashboard/payments"
                          href="#nfts"
                          onClick={handleClose}
                          // style={{ width: '188px' }}
                          style={{
                            width: "100%",
                            backgroundColor: "transparent",
                          }}
                        >
                          <span className="me-2 fs-4">
                            <MdPayment></MdPayment>
                          </span>{" "}
                          <span>PAYMENTS</span>
                        </Nav.Link>
                      </div>
                      <div className="ms-4">
                        <Button
                          variant="danger"
                          className="text-uppercase w-100 me-5 pt-2 pb-2 mt-3"
                          size="sm"
                          onClick={() => handleLogout()}
                        >
                          Log Out
                        </Button>
                      </div>
                    </Nav>
                  </Offcanvas.Body>
                </Offcanvas>
              </div>
              <div className="profile">
                <div className="imgDashDiv p-3">
                  {/* <img
                    src="https://backend.grighund.net/assets/1658149611777.jpeg"
                    alt=""
                  /> */}
                </div>
              </div>
            </Container>
          </Container>
        </Navbar>

        <div className="handleOutlet">
          <Outlet />
        </div>
        <Navbar
          expand="lg"
          variant="dark"
          style={{
            backgroundColor: "#272D47",
            marginBottom: "50px",
            marginTop: "20px",
          }}
        >
          <Container fluid style={{ backgroundColor: "#272D47" }}>
            <Container>
              {/* <Navbar.Brand href="#">Navbar</Navbar.Brand> */}
              <p className="text-light text-center pt-3">
                Copyright &copy; {year} - DS Legends Pte. Ltd.
              </p>
            </Container>
          </Container>
        </Navbar>
      </div>
    </div>
  );
};

export default Dashboard;
