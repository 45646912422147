import React from 'react';
import { useNavigate } from "react-router";
import './NotFound.css';

const NotFound = () => {

    const navigate = useNavigate();

    const homePage = () => {
        navigate("/");
    };

    return (
    <div className="f-404-page-not-found-page">
      <div className="f-404-container">
        <h1 className="f-404-code">404</h1>
        <button onClick={homePage} className="f-404-call-to-action">
          HOME
        </button>
        <h3 className="f-404-info">Page not found</h3>
      </div>
    </div>
    );
};

export default NotFound;