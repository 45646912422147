import AnkrscanProvider from '@ankr.com/ankr.js';
import { Blockchain } from '@ankr.com/ankr.js/dist/types';
 
const provider = new AnkrscanProvider('');
 
export const getNfts = async (address) => {
  const assets  = await provider.getNFTsByOwner({
    walletAddress: address,
    blockchain: 'bsc',
  });
  return  assets;

};