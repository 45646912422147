import React, { useContext, useEffect, useState } from 'react';
import DashboardModalNewCategory from './DashboardModalNewCategory';
import Loading from '../../Loading/Loading';
import { Table } from 'react-bootstrap';
import swal from 'sweetalert';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import DashboardModalEditCategory from './DashboardModalEditCategory';
import { CelebrityContext } from '../../../context/CelebrityContext';

const Categories = () => {
    const { categoryEdited, setCategoryEdited } = useContext(CelebrityContext);
    const [modalShowNewCategory, setModalShowNewCategory] = useState(false);
    const [modalEditCategory, setModalEditCategory] = useState(false);
    const [allCategory, setAllCategory] = useState([]);
    const [isLoadingCategory, setIsLoadingCategory] = useState(false);
    const [refetch, setRefetch] = useState(false);
    const [selectedData, setSelectedData] = useState({});

    const editCategory = (id) => {
        const element = allCategory.find(el => el._id === id);
        setSelectedData(element);
        setModalEditCategory(true);
    }

    useEffect(() => {
        fetch("https://backend.playtoearnblockchain.com/api/v1/category")
            .then(res => res.json())
            .then(data => {
                setAllCategory(data.Categories)
                console.log(data.Categories)
                console.log(data.Categories[0].category)
                setCategoryEdited(false);
            })
    }, [refetch, categoryEdited])
    if (isLoadingCategory) {
        return <Loading />
    }


    const handleAdminDelete = (id) => {
        Swal.fire({
            text: "Are you sure, you want to delete this Category?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',

        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`https://backend.playtoearnblockchain.com/api/v1/category/${id}`, {
                    // headers: {
                    //     'authorization': `Bearer ${localStorage.getItem('adminCelebrity')}`
                    // }
                })
                    .then(res => {
                        if (res.status === 200) {
                            swal({
                                title: "Success",
                                text: `${res.data.message}`,
                                icon: "success",
                                button: "OK!",
                                className: "modal_class_success",
                            });
                            setAllCategory(allCategory.filter(admin => admin._id !== id))
                        }
                    })
                    .catch(error => {
                        swal({
                            title: "Attention",
                            text: `${error.response.data.message}`,
                            icon: "warning",
                            button: "OK!",
                            className: "modal_class_success",
                        });
                    })
            }
        })
    }



    return (
        <div className='overflow-hidden'
            // style={{ height: allCategory.length <= 5 ? "150vh" : "auto" }}
            style={{ height: "120vh" }}
        >
            <h5 className='text-white text-start ps-1'>Categories</h5>
            <div className='adminCard py-2'>
                <div className="text-center">
                    <button onClick={() => setModalShowNewCategory(true)} className='adminBtn text-uppercase' style={{display: 'inline-block', marginTop: "1rem"}}>Add Category</button>
                </div>
                <div className="tableNormal ">

                    <Table className='text-white adminDataTable' responsive>


                        <thead>
                            <tr>
                                <th className='text-start'>Categories</th>
                                <th className='text-end action' style={{ paddingRight: '56px' }}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                allCategory?.map(admin => <tr admin={admin} key={admin._id} className='tableRow'>
                                    <td className='text-start'>{admin?.category_name}</td>
                                    <td className='action'>
                                        <div className="actionDiv text-end pe-5">
                                            <button onClick={() => editCategory(admin?._id)} className="editBtn"><i className="fas fa-edit"></i></button>
                                            <button onClick={() => handleAdminDelete(admin?._id)} className="deleteBtn"><i className="fas fa-trash"></i></button>
                                        </div>
                                    </td>
                                </tr>)
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
            <DashboardModalNewCategory
                show={modalShowNewCategory}
                setIsLoadingCategory={setIsLoadingCategory}
                setModalShowNewCategory={setModalShowNewCategory}
                setRefetch={setRefetch}
                refetch={refetch}
                onHide={() => setModalShowNewCategory(false)}
            />
            <DashboardModalEditCategory
                show={modalEditCategory}
                setIsLoadingCategory={setIsLoadingCategory}
                setModalEditCategory={setModalEditCategory}
                setRefetch={setRefetch}
                refetch={refetch}
                data={selectedData}
                onHide={() => setModalEditCategory(false)}
            />
        </div>
    );
};

export default Categories;