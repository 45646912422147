import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Paper } from '@mui/material';
import styled from 'styled-components';
import { AiFillLike } from 'react-icons/ai';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StyledPaper = styled(Paper)`

  max-width:780px!important;
//   background:linear-gradient(to right bottom, #4e4c4c, #4e4c4c);
  background:#1e1e1e;
  color:white;

  @media screen and (max-width: 768px) {
text-align:center;
  }
`;

const LikeModal = ({ open, setOpen, handleClose, ids }) => {
    const [data, setData] = useState([])
    console.log(ids, "ids")
    useEffect(() => {
        const fetching = async () => {
            await axios.get(`https://backend.playtoearnblockchain.com/api/nft/${ids}`, {
                headers: { "authorization": `Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54` }
              })
                .then(res => {
                    setData(res.data.nft)
                    console.log(res.data, "datasse");
                })
        }
        fetching();

    }, [ids])
    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                PaperComponent={StyledPaper}
                aria-describedby="alert-dialog-slide-description"

            >
                <DialogTitle className='border-white border-bottom'><AiFillLike /> {data?.likes?.length}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" className='text-white pt-4 px-3'>


                        {data?.likes?.map(like => (<p style={{ wordBreak: "break-all" }} className='mb-1 mt-1 text-center py-2 border-bottom border-white'>
                            {like}
                        </p>))}

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} className='text-white bg-danger fw-bold border-danger border-1 border'>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default LikeModal;