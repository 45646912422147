import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { Button, Container, Form, InputGroup, Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { CelebrityContext } from "../../../context/CelebrityContext";
import "./MealNft.css";
import QRCode from "qrcode";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import EmailVerifyModal from "./EmailVerifyModal";
import Select from "react-select";
import { useTimer } from "react-timer-hook";
import { ethers } from "ethers";
import { v4 as uuidv4 } from "uuid";
import Tooltip from "@mui/material/Tooltip";

import ReleatedNft from "./ReleatedNft";
import LoginButton from "../../Shared/LoginButton";
import CheckLogin from "../../Shared/CheckLogin";
import { Link } from "react-router-dom";

const selectOptions = [
  {
    value: "bnb",
    label: "BNB",
    image: "/bnb.png",
  },
  // {
  //   value: "usdsc",
  //   label: "USDSC",
  //   image: "https://i.ibb.co/p1Vfjp0/usdsc.png",

  // },
  // {
  //   value: "dsl",
  //   label: "DSL",
  //   image: "/dsl.jpg",
  // },
];

function MealDetails({ expiryTimestamp }) {
  const [selectedOption, setSelectedOption] = useState({
    value: "bnb",
    label: "BNB",
    image: "/bnb.png",
  });

  const { mealnId } = useParams();
  const [disableAfterActivation, setDisableAfterActivation] = useState(false);
  const [allAvailable, setAllAvailable] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  const [isClickedMint, setIsClickedMint] = useState(false);
  const [dateCount, setDateCount] = useState("");
  const [targetCount, setTargetCount] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [email1, setEmail] = useState("");
  const [emailVerify, setEmailVerify] = useState(false);
  const [isError, setError] = useState(false);
  const [isDetails, setDetails] = useState({});
  const [otp, setOtp] = useState("");
  const [isSouvenir, setSouvenir] = useState([]);
  const [token, setToken] = useState("bnb");
  const [bnbToken, setBnbToken] = useState();
  const [dslToken, setDslToken] = useState();
  const [s39Token, setS39Token] = useState();
  const [nftData, setNftData] = useState();
  const [otpVerify, setOtpVerify] = useState();
  const [matchMint, setMatchMint] = useState("");
  const navigate = useNavigate();
  const [automint, setAutomint] = useState("");
  const [onsubDisable, setOnsubDisable] = useState(false);
  const [gotRefCode, setGotRefCode] = useState(false);
  const [gotFreeCode, setGotFreeCode] = useState(false);
  const [src, setSrc] = useState("");
  const [random, setRandom] = useState();
  const [sendMail, setSendMail] = useState("");
  const [latestNft, setLatestNft] = useState("");
  const [dataUrl, setDataUrl] = useState("");
  const [tokenId, setTokenId] = useState("");
  const [likes, setLikes] = useState(0);
  const [isClicked, setIsClicked] = useState(false);
  const [affiliateWalletAddress, setAffiliateWalletAddress] = useState("");
  const [strTimeToAmPm, setStrTimeToAmPm] = useState("");
  const [endTimeToAmPm, setEndTimeToAmPm] = useState("");
  const [showTooltip1, setShowTooltip1] = useState(false);
  const [showTooltip2, setShowTooltip2] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const addressMint = "0x23A227D3b78bE61bbC34d6FC36439A2e9CdE2D46"

  const {
    user,
    setRequestLoading,
    openWalletModal,
    getBalanceMainnet,
    mintTicketNFTTestnetBNB,
    mintTicketNFTTestnetUSDSC,
    mintTicketNFTTestnetDSL,
    mintAddressTestnet,
    signBuyFunction,
    USDSCtokenAddressMainnet,
    DSLtokenAddressMainnet,
    openWalletModalProfile,
    mintNft,
    MintContractAddress,
  } = useContext(CelebrityContext);

  // QR code functionality
  useEffect(() => {
    const val = Math.floor(10000 + Math.random() * 900000000000);
    const staticValRan = "4816" + val;
    setRandom(staticValRan);
    getBalanceMainnet();
  }, []);

  useEffect(() => {
    QRCode.toDataURL(random?.toString()).then(setSrc);
  }, [random]);

  useEffect(() => {
    axios
      .get("https://backend.playtoearnblockchain.com/api/v1/mint/mint-nft", {
        headers: {
          authorization: `Bearer x4abs2zDM01DMMEgt33CrKZt86atzL3CJdExpP4`,
        },
      })
      .then((res) => {
        setAllAvailable(res.data);
      });
  }, []);

  //get minted nft data
  useEffect(() => {
    axios
      .get("https://backend.playtoearnblockchain.com/api/v1/mint/mint-nft", {
        headers: {
          authorization: `Bearer x4abs2zDM01DMMEgt33CrKZt86atzL3CJdExpP4`,
        },
      })
      .then((res) => {
        setLatestNft(res.data[0].certificate);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`https://backend.playtoearnblockchain.com/api/nft/${mealnId}`, {
        headers: {
          authorization: `Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54`,
        },
      })
      .then((res) => {
        setDetails(res.data?.nft);
        console.log(res.data?.nft, "nnft");
        setDateCount(res.data?.nft?.startDate);
        setTargetCount(res.data?.nft?.purchaseDate);
        const convertTime24to12 = (time24h) => {
          let time = time24h
            .toString()
            .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time24h];

          if (time.length > 1) {
            time = time.slice(1, -1);
            time[5] = +time[0] < 12 ? " am" : " pm";
            time[0] = +time[0] % 12 || 12;
          }
          return time.join("");
        };
        const st = convertTime24to12(res.data?.nft?.startTime);
        const et = convertTime24to12(res.data?.nft?.endTime);
        setStrTimeToAmPm(st);
        setEndTimeToAmPm(et);
      });
  }, [mealnId]);

  // useEffect(() => {
  //   const todayDate = new Date();
  //   axios
  //     .get("https://backend.playtoearnblockchain.com/api/nft/all", {
  //       headers: {
  //         authorization: `Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54`,
  //       },
  //     })
  //     .then((res) => {
  //       setNftData(res?.data?.nft);
  //       const filtering = res?.data?.nft?.filter(
  //         (items) => items._id != mealnId
  //       );
  //       setSouvenir(filtering?.reverse());
  //     });
  // }, [nftData, mealnId]);

  useEffect(() => {
    axios
      .get("https://dslegends.org/api/get-asset-price.php?asset=BNB", {
        headers: {
          Tokenkey: `f02063004b60270f693bfefcbd8a37e91273a4290fdcc9e4ea7b0f531a9d9e64`,
        },
      })
      .then((res) => {
        setBnbToken(res.data.message);
        // setBnbToken(282.130);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://dslegends.org/api/get-asset-price.php?asset=DSL", {
        headers: {
          Tokenkey: `f02063004b60270f693bfefcbd8a37e91273a4290fdcc9e4ea7b0f531a9d9e64`,
        },
      })
      .then((res) => {
        setDslToken(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://dslegends.org/api/get-asset-price.php?asset=S39", {
        headers: {
          Tokenkey: `f02063004b60270f693bfefcbd8a37e91273a4290fdcc9e4ea7b0f531a9d9e64`,
        },
      })
      .then((res) => {
        setS39Token(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // useEffect(() => {
  const filtering = allAvailable.filter(
    (title) => title.name == isDetails.name
  );

  const availableNftOrigin =
    parseInt(isDetails?.availableNfts) - parseInt(filtering.length);

  // Re-send OTP functionality
  const { seconds, minutes, resume, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
  });

  const restarting = (sec) => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + sec);
    restart(time);
  };

  const handleVerifyEmail = async (e) => {
    // check if email is valid
    setDisableAfterActivation(true);
    if (email1.length > 0 && email1.includes("@" && ".")) {
      // setLoading(true);
      setEmailVerify(true);
      await axios
        .post(
          "https://backend.playtoearnblockchain.com/api/v1/verifymint/mail",
          {
            email: email1,
          },
          {
            headers: {
              authorization: `Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            // alert(res.data.message);
            setSendMail(res.data.email);
            restarting(180);
            swal({
              text: res.data.message,
              icon: "success",
              button: "OK!",
              className: "modal_class_success",
            });
            setOtpVerify(res.data.otp);

            setTimeout(() => {
              setDisableAfterActivation(false);
            }, 120000);
          }
          setOpenEmail(true);
        })
        .catch((err) => {
          // alert(err.response.data.message);
          setEmailVerify(false);
          swal({
            title: "Attention",
            text: err.response.data.message,
            icon: "warning",
            button: "OK!",
            className: "modal_class_success",
          });
        })
        .finally(() => {
          // setLoading(false);
        });
    } else {
      swal({
        title: "Attention",
        text: "Please enter a valid email address",
        icon: "warning",
        button: "OK!",
        className: "modal_class_success",
      });
    }
  };

  const onsubAutoMint = async (e) => {
    e.preventDefault();

    const automintcode = e.target.automintcode.value;
    const email = e.target.email.value;
    // const otp = e.target.verificationCode;

    if (otp == otpVerify) {
      await axios
        .get(
          `https://backend.dsl.sg/api/v1/paymentverifyemail/dsldata/${email}`
        )
        .then((res) => {
          if (res.status === 200) {
            setMatchMint(res.data[0]);
            const user = res.data.filter((userpro) => userpro.email == email);
            if (user) {
              if (res.data[0]?.otp === automintcode) {
                swal({
                  title: "Success",
                  text: "Auto mint successful",
                  icon: "success",
                  button: "OK!",
                  className: "modal_class_success",
                });
                e.target.reset();
                setOtp("");
                setAutomint("");
                setOnsubDisable(true);
              } else {
                swal({
                  title: "Attention",
                  text: "Auto mint unsuccessful",
                  icon: "warning",
                  button: "OK!",
                  className: "modal_class_success",
                });
              }
            } else {
              swal({
                title: "Attention",
                text: "User not found",
                icon: "warning",
                button: "OK!",
                className: "modal_class_success",
              });
            }
          }
        });
    } else {
      swal({
        title: "Attention",
        text: "Auto mint unsuccessful",
        icon: "warning",
        button: "OK!",
        className: "modal_class_success",
      });
    }
  };

  // Referal Code Discount
  const discountReferal = (10 / 100) * isDetails?.price;
  const disRefTwoDec = discountReferal.toFixed(2);

  // Calculation
  let totalUSD;

  if (!gotRefCode) {
    totalUSD = isDetails?.price;
  } else {
    totalUSD = isDetails?.price - disRefTwoDec;
  }

  const usdPerUSD = 0.72;
  const rsPerUSD = 80.97;
  const usd = totalUSD;
  const rs = totalUSD * rsPerUSD;

  // BNB Price
  const bnb = usd / (bnbToken ? bnbToken : 304.07);
  const bnbTwoDec = bnb.toFixed(4);

  // DSL Price
  // const dsl = usd / dslToken;
  const dsl = usd / (dslToken ? dslToken : 0.0108856926);
  const dslTwoDec = dsl.toFixed(4);

  // USDSC Price
  const usdsc = parseFloat(usd).toFixed(4);

  // Discount (30%)
  const discountUSD = (30 / 100) * totalUSD;
  const disUSDTwoDec = discountUSD.toFixed(2);

  // RS Discount
  const discountRs = (30 / 100) * rs;
  const disRsTwoDec = discountRs.toFixed(2);

  // USD Discount
  const discountUsd = (30 / 100) * usd;
  const disUsdTwoDec = discountUsd.toFixed(2);

  // Calculation without discounts
  const allUSDCost = isDetails?.price;

  const usdPerUSD01 = 0.72;
  const usd01 = isDetails?.price;

  // BNB Price
  const bnb01 = usd01 / (bnbToken ? bnbToken : 304.07);
  const bnbTwoDec01 = bnb01.toFixed(6);

  // DSL Price
  const dsl01 = usd01 / (dslToken ? dslToken : 0.0108856926);
  const dslTwoDec01 = dsl01.toFixed(4);

  // USDSC Price
  const usdsc01 = parseFloat(usd01).toFixed(4);

  // Saved prices calculation
  const savedBNB = bnbTwoDec01 - bnbTwoDec;
  const savedDSL = dslTwoDec01 - dslTwoDec;
  const savedUSDSC = usdsc01 - usdsc;
  const savedBNB4Digit = savedBNB.toFixed(6);
  const savedDSL4Digit = savedDSL.toFixed(4);
  const savedUSDSC4Digit = savedUSDSC.toFixed(4);

  let newDate = new Date();
  let dd = String(newDate.getDate()).padStart(2, "0");
  let mm = String(newDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = newDate.getFullYear();
  let hh = newDate.getHours();
  let min = newDate.getMinutes();
  let ss = newDate.getSeconds();
  newDate = dd + "/" + mm + "/" + yyyy + "  " + hh + ":" + min + ":" + ss;
  const nftId = random?.toString();

  const postDataAfterMint = async (e) => {
    const perkStatus = false;

    const data = {
      NFTID: nftId,
      NFTWebsite: "https://celebrity.sg",
      NFTType: isDetails.type,
      NFTDetails: isDetails.description,
      NFTPerks: isDetails.perkNft,
      NFTPerksStatus: perkStatus,
      NFTCreated: newDate,
    };

    await axios
      .post("https://backend.dsl.sg/api/v1/nftdetails", data, {})
      .then((res) => {
        if (res.status === 200) {
          console.log("Successfully data passed");
        }
      })
      .catch((err) => {
        // alert(err.response.data.message);
        swal({
          title: "Attention",
          text: err.response.data.message,
          icon: "warning",
          button: "OK!",
          className: "modal_class_success",
        });
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  let celebrityTemplate = useRef();

  useEffect(() => {
    const dataUrlNft = celebrityTemplate.current;
    // console.log(dataUrlNft)

    setDataUrl(dataUrlNft);
  }, [isDetails]);

  /// send full details to user

  const handleSubmit = (TokeNID, dataUrlCelebrity, mintHashs) => {
    const NFTID = TokeNID;
    const address = MintContractAddress;
    const type = isDetails.type;
    const name = isDetails.name;
    const details = isDetails.name;
    const price = isDetails.price;
    const available = availableNftOrigin
      ? availableNftOrigin
      : isDetails.availableNft;
    const startTime = isDetails.startTime;
    const perkNft = isDetails.perkNft;
    const briefDetails = isDetails.briefDetails;
    const endTime = isDetails.endTime;
    const venue = isDetails.venue;
    const image = isDetails.avatar.replace(
      removedUrl,
      "https://backend.playtoearnblockchain.com"
    );
    const experiencePoints = isDetails.experiencePoints;
    const date = isDetails.startDate;
    const endDate = isDetails.purchaseDate;
    const email = user?.email;
    const Qrcode = nftId;
    const QrcodeImg = dataUrlCelebrity;
    const walletAddress = user.walletAddress;
    const mintHash = mintHashs;

    var timeStamp = new Date();
    let dd = String(timeStamp.getDate()).padStart(2, "0");
    let mm = String(timeStamp.getMonth() + 1).padStart(2, "0");
    let yyyy = timeStamp.getFullYear();

    let hhFir = timeStamp.getHours();
    let hh;
    if (hhFir < 10) {
      hh = `0${timeStamp.getHours()}`;
    } else {
      hh = timeStamp.getHours();
    }

    let minFir = timeStamp.getMinutes();
    let min;
    if (minFir < 10) {
      min = `0${timeStamp.getMinutes()}`;
    } else {
      min = timeStamp.getMinutes();
    }

    let sfirst = timeStamp.getSeconds();
    let ss;
    if (sfirst < 10) {
      ss = `0${timeStamp.getSeconds()}`;
    } else {
      ss = timeStamp.getSeconds();
    }

    timeStamp = dd + "/" + mm + "/" + yyyy + "  " + hh + ":" + min + ":" + ss;

    console.log("44444");

    axios
      .post(
        "https://backend.playtoearnblockchain.com/api/v1/verifymint/send-user",
        {
          NFTID,
          perkNft,
          Qrcode,
          QrcodeImg,
          address,
          briefDetails,
          details,
          type,
          date,
          name,
          image,
          price,
          venue,
          email,
          available,
          startTime,
          endTime,
          endDate,
          walletAddress,
          timeStamp,
          mintHash,
          experiencePoints,
        },
        {
          headers: {
            authorization: `Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //===============//// MINTED NFT FUNCTION////===================//

  const errorRepresentationFunction = (Status) => {
    return swal({
      title: "Attention",
      text: Status.message,
      icon: Status.status,
      button: "OK",
      dangerMode: true,
      className: "modal_class_success",
    });
  };

  // ! this one
  const mintCelebrityNft = async (
    priceByToken,
    tokenAddress,
    affiliateWalletAddress,
    mealnId
  ) => {
    console.log(mintAddressTestnet, tokenAddress, "mintAddressTestnet")
    // if (!user.email) {
    //   return swal({
    //     text: "Before minting please update your profile. We will send the details to you.",
    //     icon: "warning",
    //     button: true,
    //     dangerMode: true,
    //     className: "modal_class_success",
    //   }).then((willDelete) => {
    //     if (willDelete) {
    //       navigate(`/profile`);
    //     } else {
    //       console.log("ok");
    //     }
    //   });
    // }

    setIsClickedMint(true);
    setRequestLoading(true);

    const data = new FormData();
    data.append("Id", isDetails._id);
    data.append("price", priceByToken);
    data.append("tokenAddress", tokenAddress);
    data.append("refAddress", affiliateWalletAddress);
    data.append("nonce", uuidv4());
    data.append("name", isDetails.name);
    data.append(
      "image",
      isDetails.avatar.replace(
        removedUrl,
        "https://backend.playtoearnblockchain.com"
      )
    );
    data.append("description", isDetails.description);
    data.append("type", isDetails.type);
    data.append("date", isDetails.date);
    data.append("venue", isDetails.venue);
    data.append("token", isDetails.token);

    await axios
      .post(
        "https://backend.playtoearnblockchain.com/api/v1/mint/uri-json-nft",
        data,
        {
          headers: {
            authorization: `Bearer x4abs2zDM01DMMEgt33CrKZt86atzL3CJdExpP4`,
          },
        }
      )
      .then(async (res) => {
        let mintObject = {};

        console.log("status", res.data);
        if (res.status === 200) {
          const data1 = await signBuyFunction(
            mealnId,
            ethers.utils.parseEther(priceByToken),
            tokenAddress,
            affiliateWalletAddress,
            res.data.uri
          );
          console.log("🚀:", data1);
          mintObject = await mintNft(data1);
          console.log("🚀 :", !mintObject);
          if (!mintObject.return) {
            setRequestLoading(false);
            errorRepresentationFunction(mintObject);
            return false;
          }

          // if (token === "bnb") {

          // } else if (token === "usdsc") {
          //   console.log("enter usdsc");
          //   mintObject = await mintTicketNFTTestnetUSDSC(data1);
          // } else if (token === "dsl") {
          //   mintObject = await mintTicketNFTTestnetDSL(data1);
          // }

          const data2 = {
            name: isDetails.name,
            price: isDetails.price,
            image: isDetails.avatar.replace(
              removedUrl,
              "https://backend.playtoearnblockchain.com"
            ),
          };

          setTokenId(mintObject.data.IdNumber);

          await axios
            .post(
              "https://backend.playtoearnblockchain.com/api/v1/mint/save-nft",
              data2,
              {
                headers: {
                  authorization: `Bearer x4abs2zDM01DMMEgt33CrKZt86atzL3CJdExpP4`,
                },
              }
            )
            .then((res) => {
              if (res.status === 200) {
                setRequestLoading(false);
                const wrapper = document.createElement("div");
                wrapper.innerHTML = `
                <a href=${mintObject.data.mint_hash} target="_any" className="link_hash">${mintObject.data.mint_hash}</a>
                <br/>
                <p style="color: yellow;">You have successfully minted.</p>
                <p>Use the following information to import the NFT to your wallet</p>
                <p className="address">Contract Address: <br/> <span style="color: yellow;">${MintContractAddress}</span></p>
                <p>Token ID: <br/> <span style="color: yellow;">${mintObject.data.IdNumber}</span></p>
                 `;
                swal({
                  title: "Minted",
                  content: wrapper,
                  icon: "success",
                  buttons: {
                    cancel: "Cancel",
                    Minted: "Minted",
                    confirm: "Ok",
                  },
                  className: "modal_class_success",
                }).then((willDelete) => {
                  if (willDelete == null) {
                    navigate(`/mintedDetails`);
                    swal({
                      title: "Success",
                      text: "Please check your email for minted NFT details.",
                      icon: "success",
                      button: "OK!",
                      className: "modal_class_success",
                    });
                  } else if (willDelete == "Minted") {
                    navigate(`/mintedDetails`);
                    swal({
                      title: "Success",
                      text: "Please check your email for minted NFT details.",
                      icon: "success",
                      button: "OK!",
                      className: "modal_class_success",
                    });
                  } else if (willDelete == true) {
                    navigate(`/mintedDetails`);
                    swal({
                      title: "Success",
                      text: "Please check your email for minted NFT details.",
                      icon: "success",
                      button: "OK!",
                      className: "modal_class_success",
                    });
                  } else {
                    swal({
                      title: "Success",
                      text: "Please check your email for minted NFT details.",
                      icon: "success",
                      button: "OK!",
                      className: "modal_class_success",
                    });
                  }
                });
                postDataAfterMint();
                handleSubmit(
                  mintObject.data.IdNumber,
                  res.data.ImgCelebrity,
                  mintObject.data.mint_hash
                );
              }
            })
            .catch((err) => {
              console.log(err);
              setRequestLoading(false);
              const wrapper = document.createElement("div");
              wrapper.innerHTML = `<a href=${mintObject.data.mint_hash} target="_any" className="link_hash">${mintObject.data.mint_hash}</a> <br/> <p>You have successfully minted but error in while saving data.</p>`;
              swal({
                title: "Warning",
                content: wrapper,
                icon: "warning",
                button: "OK",
                className: "modal_class_success",
              });
            });
        }
      })
      .catch((err) => {
        console.log("whats the error", err);
        setRequestLoading(false);
        if (err.code === -32603) {
          return swal({
            title: "Failed",
            text: "Insufficient Funds",
            icon: "warning",
            button: "OK",
            dangerMode: true,
            className: "modal_class_success",
          });
        } else if (err.code === 4001) {
          return swal({
            title: "Failed",
            text: "Minting Failed!",
            icon: "warning",
            button: "OK",
            dangerMode: true,
            className: "modal_class_success",
          });
          return swal({
            title: "Failed",
            text: "Minting Failed!",
            icon: "warning",
            button: "OK",
            dangerMode: true,
            className: "modal_class_success",
          });
        } else if (err.code === -32603) {
          return swal({
            title: "Failed",
            text: "Insufficient Funds",
            icon: "warning",
            button: "OK",
            dangerMode: true,
            className: "modal_class_success",
          });
        }

        return swal({
          title: "Attention",
          text: "You have cancelled!",
          icon: "warning",
          button: "OK",
          dangerMode: true,
          className: "modal_class_success",
        });
      });
  };
  const removedUrl = "https://backend.celebritygames.net";

  //===============//// MINTED Free NFT FUNCTION////===================//

  const mintCelebrityNftFree = async (
    priceByToken,
    tokenAddress,
    affiliateWalletAddress,
    mealnId
  ) => {
    if (!user.email) {
      return swal({
        text: "Before minting please update your profile. We will send the details to you.",
        icon: "warning",
        button: true,
        dangerMode: true,
        className: "modal_class_success",
      }).then((willDelete) => {
        if (willDelete) {
          navigate(`/profile`);
        } else {
          console.log("ok");
        }
      });
    }

    setIsClickedMint(true);
    setRequestLoading(true);

    const data = new FormData();
    data.append("Id", isDetails._id);
    data.append("price", 0);
    data.append("tokenAddress", tokenAddress);
    data.append("refAddress", affiliateWalletAddress);
    data.append("nonce", uuidv4());
    data.append("name", isDetails.name);
    data.append(
      "image",
      isDetails.avatar.replace(
        removedUrl,
        "https://backend.playtoearnblockchain.com"
      )
    );
    data.append("description", isDetails.description);
    data.append("type", isDetails.type);
    data.append("date", isDetails.date);
    data.append("venue", isDetails.venue);
    data.append("token", isDetails.token);

    await axios
      .post(
        "https://backend.playtoearnblockchain.com/api/v1/mint/uri-json-nft",
        data,
        {
          headers: {
            authorization: `Bearer x4abs2zDM01DMMEgt33CrKZt86atzL3CJdExpP4`,
          },
        }
      )
      .then(async (res) => {
        let Obj = {};

        if (res.status === 200) {
          // console.log("status", res.data);
          const data1 = await signBuyFunction(
            mealnId,
            ethers.utils.parseEther("0"),
            tokenAddress,
            affiliateWalletAddress,
            res.data.uri
          );

          if (token === "bnb") {
            Obj = await mintTicketNFTTestnetBNB(data1);
          }

          const data2 = {
            name: isDetails.name,
            price: 0,
            image: isDetails.avatar.replace(
              removedUrl,
              "https://backend.playtoearnblockchain.com"
            ),
          };

          setTokenId(Obj.ID);

          await axios
            .post(
              "https://backend.playtoearnblockchain.com/api/v1/mint/save-nft",
              data2,
              {
                headers: {
                  authorization: `Bearer x4abs2zDM01DMMEgt33CrKZt86atzL3CJdExpP4`,
                },
              }
            )
            .then((res) => {
              if (res.status === 200) {
                const IsCodeFormData = new FormData();
                IsCodeFormData.append("isUsedCode", true);
                axios
                  .put(
                    `https://backend.playtoearnblockchain.com/api/v1/user/update/${user?._id}`,
                    IsCodeFormData,
                    {
                      headers: {
                        authorization: `Bearer ${localStorage.getItem(
                          "TestGptnftToken"
                        )}`,
                      },
                    }
                  )
                  .then((res) => {
                    console.log("update");
                    if (res.status === 200) {
                      console.log(res.data);
                    }
                  });

                setRequestLoading(false);
                const wrapper = document.createElement("div");
                wrapper.innerHTML = `
                <a href=${Obj.mint_hash} target="_any" className="link_hash">${Obj.mint_hash}</a>
                <br/>
                <p style="color: yellow;">You have successfully minted.</p>
                <p>Use the following information to import the NFT to your wallet</p>
                <p className="address">Contract Address: <br/> <span style="color: yellow;">${mintAddressTestnet}</span></p>
                <p>Token ID: <br/> <span style="color: yellow;">${Obj.ID}</span></p>
                 `;
                swal({
                  title: "Minted",
                  content: wrapper,
                  icon: "success",
                  buttons: {
                    cancel: "Cancel",
                    Minted: "Minted",
                    confirm: "Ok",
                  },
                  className: "modal_class_success",
                }).then((willDelete) => {
                  if (willDelete == null) {
                    navigate(`/mintednft/${Obj.ID}/${mintAddressTestnet}`);
                    swal({
                      title: "Success",
                      text: "Please check your email for minted NFT details.",
                      icon: "success",
                      button: "OK!",
                      className: "modal_class_success",
                    });
                  } else if (willDelete == "Minted") {
                    navigate(`/mintedDetails`);
                    swal({
                      title: "Success",
                      text: "Please check your email for minted NFT details.",
                      icon: "success",
                      button: "OK!",
                      className: "modal_class_success",
                    });
                  } else if (willDelete == true) {
                    navigate(`/mintedDetails`);
                    swal({
                      title: "Success",
                      text: "Please check your email for minted NFT details.",
                      icon: "success",
                      button: "OK!",
                      className: "modal_class_success",
                    });
                  } else {
                    swal({
                      title: "Success",
                      text: "Please check your email for minted NFT details.",
                      icon: "success",
                      button: "OK!",
                      className: "modal_class_success",
                    });
                  }
                });
                postDataAfterMint();
                handleSubmit(Obj.ID, res.data.ImgCelebrity, Obj.mint_hash);
              }
            })
            .catch((err) => {
              console.log(err);
              setRequestLoading(false);
              const wrapper = document.createElement("div");
              wrapper.innerHTML = `<a href=${Obj.mint_hash} target="_any" className="link_hash">${Obj.mint_hash}</a> <br/> <p>You have successfully minted but error in while saving data.</p>`;
              swal({
                title: "Warning",
                content: wrapper,
                icon: "warning",
                button: "OK",
                className: "modal_class_success",
              });
            });
        }
      })
      .catch((err) => {
        console.log("whats the error", err);
        setRequestLoading(false);
        if (err.code === -32603) {
          return swal({
            title: "Failed",
            text: "Insufficient Funds",
            icon: "warning",
            button: "OK",
            dangerMode: true,
            className: "modal_class_success",
          });
        } else if (err.code === 4001) {
          return swal({
            title: "Failed",
            text: "Minting Failed!",
            icon: "warning",
            button: "OK",
            dangerMode: true,
            className: "modal_class_success",
          });
          return swal({
            title: "Failed",
            text: "Minting Failed!",
            icon: "warning",
            button: "OK",
            dangerMode: true,
            className: "modal_class_success",
          });
        } else if (err.code === -32603) {
          return swal({
            title: "Failed",
            text: "Insufficient Funds",
            icon: "warning",
            button: "OK",
            dangerMode: true,
            className: "modal_class_success",
          });
        }

        return swal({
          title: "Attention",
          text: "You have cancelled!",
          icon: "warning",
          button: "OK",
          dangerMode: true,
          className: "modal_class_success",
        });
      });
  };

  // console.log(isDetails)

  // Referal code discount
  useEffect(() => {
    axios
      .get("https://backend.playtoearnblockchain.com/api/v1/user/all", {
        headers: {
          authorization: `Bearer bIq7Olx4abs2zDM01DMMEgt33fbEe54fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4`,
        },
      })
      .then((res) => {
        setAllUsers(res.data);
      });
  }, []);

  const othersRefCodes = allUsers.filter(
    (i) => i?.myReferralCode !== user?.myReferralCode
  );

  const handleAffiliateCode = (e) => {
    const refCode = othersRefCodes.find(
      (i) => i?.myReferralCode === e.target.value
    );
    setAffiliateWalletAddress(refCode?.walletAddress);
    if (refCode?.myReferralCode === e.target.value) {
      setGotRefCode(true);
    } else if (e.target.value === user?.myReferralCode) {
      ownRefcode();
    } else {
      setGotRefCode(false);
    }
  };

  const handleFreeCode = (e) => {
    const refCode = e.target.value;
    if (user?.oneTimeCode === refCode && user?.isUsedCode === false) {
      setGotFreeCode(true);
    } else {
      setGotFreeCode(false);
    }
  };

  const ownRefcode = () => {
    return swal({
      title: "Attention",
      text: "You can’t use your own code.",
      icon: "warning",
      button: "OK",
      dangerMode: true,
      className: "modal_class_success",
    });
  };

  // Select options functionality
  const handleChoosePayment = (e) => {
    setSelectedOption(e);
    setToken(e.value);
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      color: "#000",
      backgroundColor: "#fff",
    }),

    singleValue: (provided, state) => {
      return { ...provided };
    },
  };

  const handleClick = () => {
    if (!user?.walletAddress || user?.walletAddress === "undefined") {
      openWalletModal();
    } else {
      if (isClicked) {
        // setLikes(likes - 1);
        localStorage.setItem("like", likes - 1);
      } else {
        // setLikes(likes + 1);
        localStorage.setItem("like", likes + 1);
      }
      setIsClicked(!isClicked);
      // setLikes(likess);
      // console.log(likess);
    }
  };

  // like functionality
  const likeNft = (id) => {
    if (!user?.walletAddress || user?.walletAddress === "undefined") {
      openWalletModal();
    } else {
      fetch("https://backend.playtoearnblockchain.com/api/nft/like", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54",
        },
        body: JSON.stringify({
          nftId: id,
          walletAddress: user?.walletAddress,
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          if (isDetails._id == result._id) {
            setDetails(result);
          } else {
            setDetails(isDetails);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const unlikeNft = (id) => {
    if (!user?.walletAddress || user?.walletAddress === "undefined") {
      openWalletModal();
    } else {
      fetch("https://backend.playtoearnblockchain.com/api/nft/unlike", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54",
        },
        body: JSON.stringify({
          nftId: id,
          walletAddress: user?.walletAddress,
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          if (isDetails._id == result._id) {
            setDetails(result);
          } else {
            setDetails(isDetails);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div>
      <div className="d-grid justify_items_center">
        <Container
          className="row"
          style={{ marginTop: "100px", alignItems: "flex-start" }}
        >
          <Typography
            className="meal_details_type_title text-gradient"
            variant="subtitle2"
            gutterBottom
            component="div"
          >
            <h1 style={{ fontSize: "22px" }}>CelebrityGames NFTs</h1>
          </Typography>
          <div className="col-sm-12 col-md-6 col-lg-6 d-grid justify_items_center pt-2">
            <Box className=" col-12 card_top_icon mb-2">
              <CheckLogin register={"code"}>
                <Box
                  className="icon_love_Dtl_box icon_love_Dtl_box_none pt-1"
                  onClick={() =>
                    isDetails.likes?.includes(user?.walletAddress)
                      ? unlikeNft(isDetails?._id)
                      : likeNft(isDetails?._id)
                  }
                >
                  <i
                    className={`fa fa-heart ${isDetails?.likes?.includes(user?.walletAddress) &&
                      "heart-icon"
                      }`}
                  ></i>
                  <span className="ps-1">{isDetails?.likes?.length}</span>
                </Box>
              </CheckLogin>
            </Box>

            {isDetails?.avatar && (
              <div className="certificateCelebrity">
                <img
                  alt="This is celebrity NFT"
                  src={isDetails?.avatar.replace(
                    removedUrl,
                    "https://backend.playtoearnblockchain.com"
                  )}
                  className="deteilsPageImage"
                />
                {/* <img
                  src="https://i.ibb.co/Pwt1fRw/9ee03415-e591-4320-bf25-af881b8c27a6.jpg"
                  alt=""
                  className={`img-fluid nft-watermark ${isClickedMint ? "d-none" : ""
                    }`}
                /> */}
                <img
                  src={src}
                  alt="barcode"
                  className="img-fluid handleBarcode"
                  ref={celebrityTemplate}
                />
              </div>
            )}

            {/* <img
              src="https://i.ibb.co/Pwt1fRw/9ee03415-e591-4320-bf25-af881b8c27a6.jpg"
              alt=""
              className={`img-fluid nft-watermark3 ${isClickedMint ? "d-none" : ""
                }`}
            /> */}
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 d-grid marginPhone">
            <Box className="pt-0 fontArial" style={{ color: "white" }}>
              <Typography
                className="mb-2"
                variant="subtitle2"
                gutterBottom
                component="div"
              >
                <span className="text-primary fontArial  fontExtand">
                  Category :<br />
                </span>{" "}
                <span className="fw-normal fontArial  fontExtand">
                  {isDetails?.type}
                </span>
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                <span className="text-primary fontArial  fontExtand">
                  Name of NFT :<br />
                </span>{" "}
                <span className="fw-normal fontArial  fontExtand">
                  {isDetails?.name}
                </span>
              </Typography>

              <Typography
                className="pt-1 fontArial  fontExtand"
                variant="subtitle2"
                component="div"
              >
                <span className="text-primary fontArial  fontExtand">
                  Price of NFT (USD):
                  <Tooltip
                    title="Price of NFT is the price you purchase the NFT."
                    open={showTooltip1}
                    onOpen={() => setShowTooltip1(true)}
                    onClose={() => setShowTooltip1(false)}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setShowTooltip1(!showTooltip1)}
                    >
                      <i class="fas fa-info-circle text-white"></i>
                    </Button>
                  </Tooltip>
                  <br />{" "}
                </span>
                <span className="fw-normal fontArial  fontExtand">
                  {isDetails?.price}
                </span>
              </Typography>
              <Typography
                className="pt-1 fontArial  fontExtand"
                variant="subtitle2"
                component="div"
              >
                <span className="text-primary fontArial  fontExtand">
                  Value of NFT (USD):
                  <Tooltip
                    title="Value of NFT is the value the NFT has. You can withdraw the eligible value as BUSD."
                    open={showTooltip2}
                    onOpen={() => setShowTooltip2(true)}
                    onClose={() => setShowTooltip2(false)}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setShowTooltip2(!showTooltip2)}
                    >
                      <i class="fas fa-info-circle text-white"></i>
                    </Button>
                  </Tooltip>
                  <br />{" "}
                </span>
                <span className="fw-normal fontArial  fontExtand">
                  {isDetails?.price}
                </span>
              </Typography>
              <Typography
                className="pt-1 fontArial  fontExtand"
                variant="subtitle2"
                gutterBottom
                component="div"
              >
                <span className="text-primary fontArial  fontExtand">
                  Experience Points:
                  <Tooltip
                    title="Experience Points are accumulated when you play our blockchain games."
                    open={showTooltip}
                    onOpen={() => setShowTooltip(true)}
                    onClose={() => setShowTooltip(false)}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setShowTooltip(!showTooltip)}
                    >
                      <i class="fas fa-info-circle text-white"></i>
                    </Button>
                  </Tooltip>
                  <br />
                  <span className="text-light fw-normal fontArial  fontExtand">
                    {isDetails?.price}
                  </span>
                </span>
              </Typography>
              <Typography
                className="pt-1 fontArial  fontExtand"
                variant="subtitle2"
                gutterBottom
                component="div"
              >
                <span className="text-primary fontArial  fontExtand">
                  Total NFTs:
                  <br />
                  <span className="text-light fw-normal fontArial  fontExtand">
                    {isDetails?.totalNfts
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                </span>
              </Typography>
              <Typography
                className="pt-1 fontArial  fontExtand"
                variant="subtitle2"
                gutterBottom
                component="div"
              >
                <span className="text-primary fontArial  fontExtand">
                  Available NFTs:
                  <br />
                  <span className="text-light fw-normal fontArial  fontExtand">
                    {availableNftOrigin
                      ? availableNftOrigin
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : isDetails?.availableNft
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                </span>
              </Typography>

              <Typography
                className="pt-1 fontArial  fontExtand"
                variant="subtitle2"
                gutterBottom
                component="div"
              >
                <span className="text-primary fontArial fontExtand">
                  Brief Details:
                </span>
              </Typography>
              <div
                className="spaceIssue fontArial"
                style={{ marginBottom: "0.35em" }}
                dangerouslySetInnerHTML={{ __html: isDetails?.briefDetails }}
              ></div>

              <span className="text-warning fontArial fontExtand mb-1 mt-2">
                Option 01:
              </span>
              <div className="diffrentBack1">
                <span className=" pt-1 text-primary mb-2 fontArial fontExtand">
                  Choose how you want to pay: <br />
                  <span style={{ color: "cyan", fontSize: "13px" }}>
                    (You can purchase BNB from https://www.binance.com/en)
                  </span>
                </span>
                <div className="widthDetailsInput mt-1">
                  <Select
                    isSearchable={false}
                    value={selectedOption}
                    onChange={handleChoosePayment}
                    options={selectOptions}
                    styles={customStyles}
                    formatOptionLabel={(option) => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <img
                          src={option.image}
                          style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "100px",
                          }}
                          alt=""
                        />
                        <span>{option.label}</span>
                      </div>
                    )}
                  />
                </div>

                <Typography
                  className="pt-1 pb-1  text-gradient"
                  variant="subtitle2"
                  gutterBottom
                  component="div"
                >
                  <span className="spanDiscount " style={{ fontSize: "13px" }}>
                    Enjoy 10% discount if you have affiliate code.
                  </span>
                </Typography>

                <span className="text-primary fontArial fontExtand mb-1">
                  Affiliate Code:
                </span>
                <div class="input-group mb-2 widthDetailsInput">
                  <input
                    type="text"
                    name="affiliateCode"
                    onChange={handleAffiliateCode}
                    class="form-control"
                    placeholder="Enter Affiliate Code"
                    aria-label="Enter Affiliate Code"
                    aria-describedby="button-addon2"
                  />
                  <button
                    className={
                      !gotRefCode ? "btn btn-danger" : "btn btn-success"
                    }
                    type="button"
                    id="button-addon2"
                    style={{ zIndex: "0" }}
                  >
                    {!gotRefCode ? <AiOutlineClose /> : <AiOutlineCheck />}
                  </button>
                  <h6
                    className="text-light mb-0 pb-0 font-weight-normal"
                    style={{ fontWeight: "400", fontSize: "13px" }}
                  >
                    Register and get your affiliate code. You can share with
                    your friends to earn.{" "}
                    <span
                      style={{ cursor: "pointer" }}
                      className="text-primary d-inline"
                      onClick={() =>
                        user?.walletAddress
                          ? navigate("/profile")
                          : openWalletModalProfile()
                      }
                    >
                      Share now!
                    </span>
                  </h6>
                </div>

                {/* Saved bucks */}
                {gotRefCode && (
                  <div style={{ textAlign: "start" }}>
                    {token === "bnb" && (
                      <Typography
                        className="pt-1 pb-1  text-gradient"
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                      >
                        <span className="spanDiscount ">
                          You saved {savedBNB4Digit} BNB
                        </span>
                      </Typography>
                    )}
                    {token === "usdsc" && (
                      <Typography
                        className="pt-1 pb-1  text-gradient"
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                      >
                        <span className="spanDiscount ">
                          You saved {savedUSDSC4Digit} USDSC
                        </span>
                      </Typography>
                    )}
                    {token === "dsl" && (
                      <Typography
                        className="pt-1 pb-1  text-gradient"
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                      >
                        <span className="spanDiscount ">
                          You saved {savedDSL4Digit} DSL
                        </span>
                      </Typography>
                    )}
                  </div>
                )}
              </div>
            </Box>
            <span className="text-warning fontArial fontExtand mb-1 mt-2">
              Option 02:
            </span>
            <div className="diffBack2">
              <span className="text-primary fontArial fontExtand mb-1 mt-2">
                Free NFT Code:
              </span>
              <div class="input-group widthDetailsInput">
                <input
                  type="text"
                  style={{
                    background: "rgb(104 81 93)",
                    border: "1.5px solid rgb(104 81 93)",
                    color: "white",
                  }}
                  name="freeCode"
                  onChange={handleFreeCode}
                  class="form-control free-nft-code-input"
                  placeholder="Enter one time Free NFT Code here"
                  aria-label="Enter one time Free NFT Code here"
                  aria-describedby="button-addon2"
                />
                <button
                  className={"btn btn-success"}
                  type="button"
                  id="button-addon2"
                  style={{ zIndex: "0" }}
                >
                  Paste
                  {
                    // <i class="fa-regular fa-paste"></i>
                  }
                </button>
              </div>
              <span
                className="text-warning fontArial "
                style={{ fontSize: "13px" }}
              >
                <Link
                  to="/profile"
                  className="text-decoration-none text-warning"
                  rel="profile"
                >
                  You can find the code in your profile.
                </Link>
              </span>
            </div>

            <div className="dslDiscountForPayment">
              {token === "dsl" && (
                <p style={{ margin: "0" }}>
                  YOU GET DISCOUNT OF : USD {disUSDTwoDec}
                </p>
              )}
            </div>

            <div
              className="d-flex rpv_center details-page"
              style={{ alignItems: "flex-end", justifyContent: "start" }}
            >
              {!user?.walletAddress || user?.walletAddress === "undefined" ? (
                <LoginButton Id={mealnId} register={"pay"}></LoginButton>
              ) : (
                <>
                  {token === "bnb" && gotFreeCode === false && (
                    <button
                      disabled={
                        (availableNftOrigin
                          ? availableNftOrigin
                          : isDetails.availableNft) < 1
                      }
                      className="card_button button_dtl mt-3"
                      onClick={() =>
                        mintCelebrityNft(
                          bnbTwoDec,
                          MintContractAddress,
                          affiliateWalletAddress,
                          mealnId
                        )
                      }
                      href="#!"
                    >
                      {(availableNftOrigin
                        ? availableNftOrigin
                        : isDetails.availableNft) < 1
                        ? "No Nft available"
                        : `BUY THIS NFT FOR ${bnbTwoDec} BNB`}
                    </button>
                  )}
                  {token === "usdsc" && (
                    <button
                      disabled={
                        (availableNftOrigin
                          ? availableNftOrigin
                          : isDetails.availableNft) < 1
                      }
                      className="card_button button_dtl mt-3"
                      onClick={() =>
                        mintCelebrityNft(
                          usdsc,
                          MintContractAddress,
                          affiliateWalletAddress,
                          mealnId
                        )
                      }
                      href="#!"
                    >
                      {(availableNftOrigin
                        ? availableNftOrigin
                        : isDetails.availableNft) < 1
                        ? "No Nft available"
                        : `BUY THIS NFT FOR ${usdsc} USDSC`}
                    </button>
                  )}
                  {token === "dsl" && (
                    <button
                      disabled={
                        (availableNftOrigin
                          ? availableNftOrigin
                          : isDetails.availableNft) < 1
                      }
                      className="card_button button_dtl mt-3"
                      onClick={() =>
                        mintCelebrityNft(
                          dslTwoDec,
                          MintContractAddress,
                          affiliateWalletAddress,
                          mealnId
                        )
                      }
                      href="#!"
                    >
                      {(availableNftOrigin
                        ? availableNftOrigin
                        : isDetails.availableNft) < 1
                        ? "No Nft available"
                        : `BUY THIS NFT FOR ${dslTwoDec} DSl`}
                    </button>
                  )}
                  {gotFreeCode === true && (
                    <button
                      disabled={
                        (availableNftOrigin
                          ? availableNftOrigin
                          : isDetails.availableNft) < 1
                      }
                      className="card_button button_dtl mt-3"
                      onClick={() =>
                        mintCelebrityNftFree(
                          dslTwoDec,
                          MintContractAddress,
                          affiliateWalletAddress,
                          mealnId
                        )
                      }
                      href="#!"
                    >
                      {(availableNftOrigin
                        ? availableNftOrigin
                        : isDetails.availableNft) < 1
                        ? "No Nft available"
                        : `BUY THIS NFT FREE`}
                    </button>
                  )}
                </>
              )}
              <br />
            </div>
            <div style={{ color: "#ffffff", textAlign: "start" }}>
              {token === "bnb" && gotFreeCode === false && (
                <div style={{ display: "flex", gap: "10px" }}>
                  {" "}
                  <p style={{ margin: "0" }}>
                    You need to pay {bnbTwoDec} BNB and gas fees.
                  </p>
                </div>
              )}
              {token === "usdsc" && (
                <p style={{ margin: "0" }}>You need to pay {usdsc} USDSC</p>
              )}
              {gotFreeCode === true && <p>You need to pay gas fees only.</p>}
            </div>
            <div className="my-3">
              <Button
                variant="danger"
                className="px-3"
                onClick={() => navigate(-1)}
              >
                <span className="">Back</span>
              </Button>
            </div>
          </div>
        </Container>
        <Container>
          <h3
            className="text-white text-start mb-0 mt-5 mb-3 d-grid justify_items_center"
            style={{ fontFamily: "system-ui" }}
          >
            Related NFTs
          </h3>
          <div className="small-border bg-color-2"></div>
          {isSouvenir?.length <= 0 ? (
            <div
              style={{ marginTop: "-40px", marginBottom: "32px" }}
              className="text-gradient text-center fs-4 pt-4"
            >
              No related NFTs for now!
            </div>
          ) : (
            <ReleatedNft
              isSouvenir={isSouvenir}
              setIsClicked={setIsClicked}
              isClicked={isClicked}
              isDetails={isDetails}
              setDetails={setDetails}
              likes={likes}
              setLikes={setLikes}
            ></ReleatedNft>
          )}
          {isSouvenir?.length >= 1 && (
            <div className="d-flex mt-1" style={{ justifyContent: "center" }}>
              {isSouvenir?.length > 0 ? (
                <Typography
                  variant="h6"
                  style={{
                    color: "#d0d7c2",
                    fontSize: "16px",
                    marginTop: "1rem",
                  }}
                >
                  Pay by DSL and get 30% discount.
                </Typography>
              ) : (
                <Typography
                  variant="h6"
                  style={{
                    color: "#d0d7c2",
                    fontSize: "16px",
                    marginTop: "1rem",
                  }}
                >
                  Stay Tuned!
                </Typography>
              )}
            </div>
          )}
        </Container>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        className="overflow-hidden text-light"
        style={{ overflowY: "hidden", overflowX: "hidden" }}
      >
        <Modal.Header
          className="text-light"
          closeButton
          style={{ backgroundColor: "#242435", color: "white" }}
        >
          <Modal.Title className="text-light">Auto Mint</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#242435", color: "white" }}>
          <form onSubmit={onsubAutoMint}>
            <Form.Control
              min="1"
              type="number"
              value={automint}
              onChange={(e) => setAutomint(e.target.value)}
              name="automintcode"
              placeholder="Auto Mint Code"
              required
            />
            <br />
            <InputGroup>
              <Form.Control
                style={{ textTransform: "lowercase" }}
                type="email"
                name="email"
                placeholder="Email"
                required
              />
              <button
                onClick={() => handleVerifyEmail()}
                disabled={
                  email1.length === 0 || disableAfterActivation ? true : false
                }
                type="button"
                className="btn btn-danger"
                id="button-addon2"
              >
                Verify Email
              </button>
            </InputGroup>
            <label className="fs-6 pb-3">
              We will send your NFT to this email
            </label>
            <br />
            <Form.Control
              min="1"
              type="number"
              name="verificationCode"
              placeholder="Verification Code"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
            />
            <Modal.Footer
              style={{ backgroundColor: "#242435", color: "white" }}
            >
              <Button variant="secondary" onClick={handleClose}>
                CLOSE
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={
                  email1.length === 0 ||
                    otp.length === 0 ||
                    automint.length === 0 ||
                    onsubDisable ||
                    otp != otpVerify
                    ? true
                    : false
                }
              >
                SUBMIT
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
      <EmailVerifyModal
        handleVerifyEmail={handleVerifyEmail}
        minutes={minutes}
        seconds={seconds}
        open={openEmail}
        setOpenEmail={setOpenEmail}
        otpVerify={otpVerify}
        setError={setError}
      />
    </div>
  );
}

export default MealDetails;
