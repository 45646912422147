import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const BusdPayment = () => {
    const [data, setData] = useState([])
    useEffect(() => {
        const fetching = async () => {
            await axios.get("https://backend.playtoearnblockchain.com/api/v1/admin-payment-busd-record")
                .then(res => {
                    setData(res.data.result.reverse())
                    console.log(res.data, "datass");
                })
        }
        fetching();

    }, [])


    // Get the data to paginate

    // Set the number of items per page
    const itemsPerPage = 10;

    // Use the useState hook to store the current page and an array of data for each page
    const [currentPage, setCurrentPage] = useState(1);
    const [pagedData, setPagedData] = useState([]);

    // Use the useEffect hook to paginate the data when the component mounts or the data changes
    React.useEffect(() => {
        // Calculate the number of pages needed
        const numPages = Math.ceil(data.length / itemsPerPage);

        // Create an array of arrays, with each inner array containing the items for a single page
        const pages = Array.from({ length: numPages }, (_, i) =>
            data.slice(i * itemsPerPage, (i + 1) * itemsPerPage)
        );

        // Set the paged data to the appropriate page
        setPagedData(pages[currentPage - 1]);
    }, [data, currentPage, itemsPerPage]);

    // Create a function to handle changing the page
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };




    const navigate = useNavigate()
    return (
        <div style={{
            height: data.length > 10 ? "auto" : "100vh",
        }}>
            <div class="table-responsive">
                <table class="table text-white">
                    <thead>
                        <tr>
                            <th scope="col">S/No</th>
                            <th scope="col">Wallet Address
                            </th>
                            <th scope="col">Email Address
                            </th>
                            <th scope="col">Tokens</th>
                            {/* <th scope="col">Status</th> */}
                            <th scope="col">Paid by</th>
                            <th scope="col">BSC Url</th>
                            <th scope="col">Pay</th>
                        </tr>
                    </thead>
                    <tbody style={{ verticalAlign: "revert" }}>
                        {
                            pagedData?.map((items, index) =>
                                <tr>
                                    <th scope="row">{index + 1}</th>
                                    <td>{items.walletAddress}</td>
                                    <td>{items.email}</td>
                                    <td>{items.busdToken} BUSD</td>
                                    {/* <td>{items.claim ? <span className='text-success'>Yes</span> : <Link className='text-decoration-none text-danger' to={`/dashboard/makepayment/${"busd"}/${items?._id}`}>No</Link>}</td> */}
                                    <td>{items?.payBy}</td>
                                    <td>{items?.bsc && items?.bsc.slice(0, 36)}<br />{items?.bsc && items.bsc.slice(37, 69)}<br />{items?.bsc && items.bsc.slice(70, items.bsc.length)}</td>
                                    <td><button style={{ minWidth: "60px" }} className={`${items.claim ? "btn btn-success" : "btn-danger btn"}`} disabled={items.claim} onClick={() => navigate(`/dashboard/makepayment/${"busd"}/${items?._id}`)}>{items.claim ? "Paid" : "Pay"}</button></td>
                                </tr>

                            )
                        }

                    </tbody>
                </table>


            </div>

            <div className='text-center pt-4'>
                {/* Only show the "prev" button if we're not on the first page */}
                {currentPage > 1 && (
                    <button
                        className='btn btn-primary me-1'
                        onClick={() => handlePageChange(currentPage - 1)}>
                        Prev
                    </button>
                )}

                {/* Show the page numbers */}
                {Array.from(
                    { length: Math.ceil(data.length / itemsPerPage) },
                    (_, i) => (
                        <button
                            className='btn btn-primary me-1'
                            key={i + 1}
                            onClick={() => handlePageChange(i + 1)}
                            disabled={i + 1 === currentPage}
                        >
                            {i + 1}
                        </button>
                    )
                )}

                {/* Only show the "next" button if we're not on the last page */}
                {currentPage < Math.ceil(data.length / itemsPerPage) && (
                    <button
                        className='btn btn-primary me-1'
                        onClick={() => handlePageChange(currentPage + 1)}>
                        Next
                    </button>
                )}
            </div>
        </div>
    );
};

export default BusdPayment;