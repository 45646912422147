import { Button } from "@mui/material";
import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { CelebrityContext } from "../../../context/CelebrityContext";
import BusdLoginModal from "./BusdLoginModal";

function SelectWin() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const { openWalletModal, user } = useContext(CelebrityContext);
  useEffect(() => {
    if (!user?.email && user?.walletAddress) {
      navigate("/profile");
    }
  }, [user]);
  return (
    <div className="" style={{ backgroundColor: "#1A1A25", height: "70vh" }}>
      <div>
        <h1
          className="text-gradient text-center pt-5 text-uppercase"
          style={{ marginTop: "80px", fontSize: "1.75rem" }}
        >
          Select what you <br /> want to win
        </h1>
        <div className="small-border bg-color-2"></div>
      </div>
      <div>
        <div className="d-flex justify-content-center">
          <div className="text-white pt-4 win-btns">
            <Button
              className="money-button1"
              // onClick={()=>{setMoney("DSL");setModalShow(true);handleClose()}}
              onClick={() => navigate("/horsechampsdsl")}
            >
              <img className="money-img win-dsl-logo" src="/dsl.jpg"></img> Win
              DSL (No Staking)
            </Button>
            <Button
              className="money-button2"
              onClick={() => {
                // navigate("/horsechampsbusd/gamenft")
                // swal({
                //     title: `You can win BUSD in this game.`,
                //     text: "Please Login with your wallet to stake your NFTs to win BUSD.",
                //     // icon: "warning",
                //     dangerMode: true,
                // })
                user?.walletAddress
                  ? navigate("/horsechampsbusd/gamenft")
                  : setOpen(true);
              }}
            >
              <img className="money-img" src="/busd.jpeg"></img>Win BUSD (NFT
              Staking)
            </Button>
          </div>
        </div>
        <div className="text-center mt-4">
          <button
            className="btn btn-danger px-4"
            onClick={() => navigate("/playtoearn")}
          >
            <i class="fas fa-arrow-left me-1"></i> Back
          </button>
        </div>
        <div className="text-center mt-4">
          <button
            className="btn px-4 text-white"
            onClick={() => navigate("/allrace")}
            style={{ backgroundColor: "#36698c" }}
          >
            All Races Replay
          </button>
        </div>
        <BusdLoginModal handleClose={handleClose} open={open}></BusdLoginModal>
      </div>
    </div>
  );
}

export default SelectWin;
