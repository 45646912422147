import { CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { CelebrityContext } from "../../../context/CelebrityContext";
import Swal from "sweetalert2";
import swal from "sweetalert";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import CheckLogin from "../../Shared/CheckLogin";

const { ethereum } = window;

const MealSlider = ({ pull_meal }) => {
  const navigate = useNavigate();
  const { openWalletModal, user } = useContext(CelebrityContext);
  const [isMeal, setIsMeal] = useState([]);
  const [nftsPro, setNftsPro] = useState([]);
  const [usersWalletAdd, setUsersWalletAdd] = useState("");
  const [isLiked, setIsLiked] = useState({});
  const [updated, setUpdated] = useState(null);
  const [postIdDetails, setPostIdDetails] = useState([]);
  const [allAvailable, setAllAvailable] = useState([]);
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(true);
  let [makeLive, setMakeLive] = useState(new Array(100).fill(0));
  const [totalNftValue, setTotalNftValue] = useState();
  const [nftSold, setnftSold] = useState();
  const [refresh,setRefresh]=useState(false)

  const nftValues = totalNftValue?.toLocaleString();
  const nftSoldValues = nftSold?.toLocaleString();
  // const sliderRef = useRef(null);

  // useEffect(() => {
  //   sliderRef.current.slickGoTo(0);
  // }, []);



  function onImageLoaded(url) {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      setTimeout(() => {
        setLoading(false)
      }, 1500);
    };   //Image has loaded or failed
    return url;
  }

  useEffect(() => {
    const dataNftSold = async () => {
      await axios
        .get("https://backend.playtoearnblockchain.com/api/v1/mint/sum")
        .then((res) => {
          setnftSold(res?.data?.TotalAmount);
        });
    };
    dataNftSold();
  }, []);

  useEffect(() => {
    const nftValue = async () => {
      await axios
        .get("https://backend.playtoearnblockchain.com/api/v1/total-nft")
        .then((res) => {
          setTotalNftValue(res?.data?.TotalAvailabaleNft);
        });
    };
    nftValue();
  }, []);

  useEffect(() => {
    const dataNft = async () => {
      await axios
        .get("https://backend.playtoearnblockchain.com/api/v1/mint/mint-nft", {
          headers: {
            authorization: `Bearer x4abs2zDM01DMMEgt33CrKZt86atzL3CJdExpP4`,
          },
        })
        .then((res) => {
          setAllAvailable(res.data);
        });
    };
    dataNft();
  }, []);
  const [dataArray, setDataArray] = useState([]);
  const [shuffledArrays, setShuffledArray] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const dataIsMeal = async () => {
      await axios
        .get("https://backend.playtoearnblockchain.com/api/nft/all", {
          headers: {
            authorization: `Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54`,
          },
        })
        .then((res) => {
          // const filtering = res.data.nft.filter(items => items.isDraft === false && new Date(`${items?.purchaseDate.slice(5, 7)}/${items?.purchaseDate.slice(8, 10)}/${items?.purchaseDate.slice(0, 4)}`) > todayDate);
          // setIsMeal(filtering);
          setIsMeal(res?.data?.nft);
          setDataArray(res?.data?.nft)
          // setFilterData(res.data.slice(0, 5))
        });
    };
    dataIsMeal();
  }, []);

  // const index = isMeal?.findIndex(obj => obj._id === "6434b1aaa6cf75357933ab1e");



  // console.log("index",index); // Output: 2



  // Shuffle the array when data is fetched
  useEffect(() => {
    if (dataArray.length > 0) {
      const shuffled = [...dataArray].sort(() => Math.random() - 0.5);

      setShuffledArray(shuffled);
    }
  }, [dataArray]);

  // Show 8 values every 10 seconds
  useEffect(() => {
    // const interval = setInterval(() => {
    const  Index=Math.floor(Math.random() * (shuffledArrays.length + 1))
      if (Index + 8 <= shuffledArrays.length) {
        setMakeLive(new Array(100).fill(0));
        setCurrentIndex(Index + 8);
      } else {
        setMakeLive(new Array(100).fill(0));
        setCurrentIndex(0); // Start from the beginning if we reach the end
      }
    // }, 12000);

    return () => {
      // clearInterval(interval); // Clean up the interval when component unmounts
    };
  // }, [currentIndex, shuffledArrays]);
  }, [refresh]);




  let arr = [];

  const allNft = arr?.concat(isMeal).reverse();

  const shuffledArray = allNft
    ?.slice()
    ?.sort(() => Math.random() - 0.5)
    ?.slice(0, 8);

  // const [shuffledArray, setShuffledArray] = useState([]);

  // useEffect(() => {
  //   // Define the function to shuffle the array and update state
  //   const shuffleArray = () => {
  //     const shuffled = allNft
  //       ?.slice()
  //       ?.sort(() => Math.random() - 0.5)
  //       ?.slice(0, 8);

  //     setShuffledArray(shuffled);
  //   };

  //   // Initially shuffle the array
  //   shuffleArray();

  //   // Set up the interval to shuffle every 15 seconds
  //   const intervalId = setInterval(shuffleArray, 15000);

  //   // Clean up the interval when the component unmounts
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);

  // useEffect(() => {
  //   setLoading(true)
  // }, [shuffledArray])

  useEffect(() => {
    const liking = async () => {
      await axios
        .get("https://backend.playtoearnblockchain.com/api/like/getLikes", {
          headers: {
            authorization: `Bearer Egt33CrKZt86atzL3CJdExpP4x4abs2zDM01DMMEgt33`,
          },
        })
        .then((res) => {
          setNftsPro(res.data.likes);
        });
    };
    liking();
  }, [id]);

  useEffect(() => {
    const isLike = async () => {
      await axios
        .get(`https://backend.playtoearnblockchain.com/api/nft/${id}`, {
          headers: {
            authorization: `Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54`,
          },
        })
        .then((res) => {
          setIsLiked(res.data.nft);
        });
    };

    isLike();
  }, [id]);
  const removedUrl = "https://backend.celebritygames.net";



  // like functionality
  const likeNft = (id) => {
    console.log("inside like");
    if (!user.walletAddress || user.walletAddress === "undefined") {
      // openWalletModal();
    } else {
      console.log(id);
      fetch("https://backend.playtoearnblockchain.com/api/nft/like", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54",
        },
        body: JSON.stringify({
          nftId: id,
          walletAddress: user?.walletAddress,
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          // console.log(result)
          const newNft = isMeal?.map((data) => {
            if (data._id == result._id) {
              return result;
            } else {
              return data;
            }
          });
          setIsMeal(newNft);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const unlikeNft = (id) => {
    console.log("inside unlike");
    if (!user.walletAddress || user.walletAddress === "undefined") {
      // openWalletModal();
    } else {
      console.log(id);
      fetch("https://backend.playtoearnblockchain.com/api/nft/unlike", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54",
        },
        body: JSON.stringify({
          nftId: id,
          walletAddress: user?.walletAddress,
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          // console.log(result)
          const newNft = isMeal?.map((data) => {
            if (data._id == result._id) {
              return result;
            } else {
              return data;
            }
          });
          setIsMeal(newNft);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // let settings = {
  //   dots: false,
  //   infinite: false,
  //   slidesToShow: 2,
  //   slidesToScroll: 1,
  //   initialSlide: 0,
  //   // autoplay: true,
  //   speed: 1500,
  //   autoplaySpeed: 5000,
  //   cssEase: "linear",
  //   // arrows: true,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //         infinite: false,
  //         dots: false,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //         initialSlide: 2,
  //         arrows: false,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         // slidesToShow: 1,
  //         // slidesToScroll: 1,
  //         // initialSlide: 2,
  //         slidesToShow: 1,
  //         slidesToScroll: 2,
  //         initialSlide: 2,
  //         arrows: false,
  //       },
  //     },
  //   ],
  // };

  const updateValueAtIndex = (index, newValue) => {
    const updatedArray = [...makeLive]; // Create a copy of the original array
    updatedArray[index] = newValue; // Update the specific index
    setMakeLive(updatedArray); // Set the state with the updated array
    console.log(makeLive, "makeLive", updatedArray, "updatedArray", index, newValue)
  };

  const refreshNft = () => {
    setRefresh(refresh=>!refresh)
  }
  return (
    <div
    // className="d-none d-sm-block"
    >
      {/* <p className="text-center">
        <Link className="viewall" to="/nfts">
          View All
        </Link>
      </p>

      <Swiper
        slidesPerView={2}
        spaceBetween={5}
        // centeredSlides={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        // modules={[Autoplay]}
        className="mySwiper sliderWidth"
        loop={false}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          // 1024: {
          //   slidesPerView: 2,
          //   spaceBetween: 20,
          // }
        }}
      >
        {twoElements?.map((aNft) => (
          <SwiperSlide>
            <div key={aNft?._id} className="d-item1">
              <div class="card">
                <CheckLogin register={"code"}>
                  <div
                    onClick={() =>
                      aNft.likes?.includes(user?.walletAddress)
                        ? unlikeNft(aNft?._id)
                        : likeNft(aNft?._id)
                    }
                    className="nft_item_like like_card "
                  >
                    <i
                      className={`fa fa-heart pe-1 ${
                        aNft?.likes?.includes(user?.walletAddress) &&
                        "heart-icon"
                      }`}
                    ></i>
                    <span style={{ marginBottom: "2.2px" }}>
                      {aNft?.likes?.length}
                    </span>
                  </div>
                </CheckLogin>
                <div
                  class="card-img"
                  style={{ backgroundImage: `url(${aNft?.avatar})` }}
                >
                  <img
                    src="https://i.ibb.co/Pwt1fRw/9ee03415-e591-4320-bf25-af881b8c27a6.jpg"
                    alt=""
                    className="img-fluid nft-watermark2"
                  />
                  <div
                    class="overlay d-grid "
                    style={{ alignContent: "center", justifyItems: "center" }}
                  >
                    <Link to={`/nft/${aNft?._id}/${aNft?.imageName}`}>
                      <button className="card_hover_button mt-5" href="#!">
                        BUY THIS NFT FOR USD {aNft?.price}
                      </button>
                    </Link>
                  </div>
                </div>
                <div class="card-content">
                  <div className="row">
                    <Typography className="mt-3 text-white" variant="div">
                      <span className="text-primary">Name of NFT :</span>{" "}
                      {aNft?.name}
                    </Typography>

                    <Typography
                      className="mt-3"
                      style={{ bottom: "8px" }}
                      variant="body2"
                    >
                      <span className="text-primary ">Price of NFT (USD):</span>{" "}
                      {aNft?.price}
                    </Typography>
                    <Typography
                      className="mt-3"
                      style={{ bottom: "8px" }}
                      variant="body2"
                    >
                      <span className="text-primary ">Total NFTs:</span>{" "}
                      {aNft?.totalNfts
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </Typography>
                  </div>
                  <hr style={{ margin: "10px 0px 10px 0px" }} />
                  <div
                    className="d-flex card_bottom_btn_main "
                    style={{ margin: "15px 0 8px 0" }}
                  >
                    <div className="col-10 d-grid">
                      <Link
                        to={`/nft/${aNft?._id}/${aNft?.imageName}`}
                        className="d-grid"
                      >
                        {" "}
                        <button
                          className={
                            ethereum
                              ? "card_button2 bg-success glow_crypto_button fs-6"
                              : "card_button2 bg-success fs-6"
                          }
                          href="#!"
                        >
                          Pay USD {aNft?.price} by Crypto
                        </button>{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div>
        <Typography
          variant="h6"
          className="phn_btns"
          style={{
            color: "#d0d7c2",
            textAlign: "center",
            fontSize: "15px",
            marginTop: "1rem",
            marginBottom: "2rem",
          }}
        >
          Please scroll sideways to view all.
        </Typography>
        {allNft?.length > 0 ? (
          <>
            <Typography
              variant="h6"
              style={{
                color: "#d0d7c2",
                textAlign: "center",
                fontSize: "16px",
                marginTop: "1rem",
              }}
            >
              Pay by DSL token and get 30% discount.
            </Typography>
            <p
              className="text-gradient text-center fs-6 pt-4"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/nfts")}
            >
              NFTs available: {allNft?.length}
            </p>

            <p
              style={{ fontSize: "0.9rem" }}
              className="text-white text-center pt-1 mb-0"
            >
              Total value of NFTs: {nftValues} USD
            </p>
            <p
              style={{ fontSize: "0.9rem" }}
              className="text-white text-center pt-"
            >
              Total value of NFTs sold: {nftSoldValues} USD
            </p>
          </>
        ) : (
          <Typography
            variant="h6"
            style={{
              color: "#d0d7c2",
              textAlign: "center",
              fontSize: "16px",
              marginTop: "1rem",
            }}
          >
            Stay Tuned!
          </Typography>
        )}
      </div> */}
      <div class=" nfts-landing-margin">
        <h4 className="text-end text-white"><i onClick={()=>refreshNft()} style={{cursor:"pointer"}} class="fa-solid fa-arrows-rotate"></i></h4>
        <div class="row">
          {shuffledArrays.slice(currentIndex, currentIndex + 8).map((aNft, index) => (
            <div
              key={aNft?._id}
              className="d-item1 col-lg-3 col-md-6 col-sm-6 col-6"
            >
              <div
                class="card nft-home-card"
                style={{ borderRadius: "10px", backgroundColor: "#241d2b" }}
              >
                <CheckLogin register={"code"}>
                  <div
                    onClick={() => {
                      const liked = aNft.likes?.includes(user?.walletAddress) ? 0 : 1
                      updateValueAtIndex(index, liked)
                      aNft.likes?.includes(user?.walletAddress)
                        ? unlikeNft(aNft?._id)
                        : likeNft(aNft?._id)
                    }
                    }
                    className="nft_item_like like_card "
                  >
                    <i
                      className={`fa fa-heart pe-1 ${(makeLive[index]) && "heart-icon"} ${aNft?.likes?.includes(user?.walletAddress) &&
                        "heart-icon"
                        }`}
                    ></i>
                    <span style={{ marginBottom: "2.2px" }}>
                      {aNft?.likes?.length + (makeLive[index] ? 1 : 0)}
                    </span>
                  </div>
                </CheckLogin>
                <div
                  class="card-img"
                  style={{ backgroundImage: `url(${onImageLoaded(aNft?.avatar.replace(removedUrl, "https://backend.playtoearnblockchain.com"))})`, backgroundColor: "rgba(25, 29, 38, 0.85)" }}
                >
                  {loading && <div className="h-100 d-flex align-items-center justify-content-center"><CircularProgress /></div>}
                  {/* <img
                    src="https://i.ibb.co/Pwt1fRw/9ee03415-e591-4320-bf25-af881b8c27a6.jpg"
                    alt=""
                    className="img-fluid nft-watermark2"
                  /> */}
                  <div
                    class="overlay d-grid "
                    style={{ alignContent: "center", justifyItems: "center" }}
                  >
                    <Link to={`/nft/${aNft?._id}/${aNft?.imageName}`}>
                      <button className="card_hover_button mt-5" href="#!">
                        BUY THIS NFT
                      </button>
                    </Link>
                  </div>
                </div>
                <p className="text-light pt-1" style={{ fontWeight: "600" }}>
                  {aNft?.name}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MealSlider;
