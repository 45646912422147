import React, { useContext, useEffect, useState } from "react";
import { Contract, ethers, BigNumber } from "ethers";
import Web3 from "web3";
import swal from "sweetalert";
import RewardsContract from "./RewardsContract";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { CelebrityContext } from "../../../../context/CelebrityContext";
import axios from "axios";

const web3 = new Web3(Web3.givenProvider || "http://localhost:8545");

function ClaimRewards() {
  const { openWalletModal, user } = useContext(CelebrityContext);
  const [rewardAmount, setRewardAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();
  const locataion = useLocation();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const myValue = query.get("price");
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert("Copied!");
  };

  const [checkDevice, setCheckDevice] = useState("");
  const [texts, setText] = useState("");
  useEffect(() => {
    const detecting = async () => {
      if (window.innerWidth < 1000 && typeof window.ethereum === "undefined") {
        console.log("mobile");
        setCheckDevice(() => "mobileWithoutApp");
      } else if (
        window.innerWidth < 1000 &&
        typeof window.ethereum !== "undefined"
      ) {
        setCheckDevice(() => "mobileWithApp");
      } else if (
        window.innerWidth > 1000 &&
        typeof window.ethereum !== "undefined"
      ) {
        console.log("pc");
        setCheckDevice(() => "pcWithExtention");
      } else if (
        window.innerWidth > 1000 &&
        typeof window.ethereum === "undefined"
      ) {
        setCheckDevice(() => "pcWithoutExtention");
      }
    };

    detecting();
  }, []);

  const [AllData, setAllData] = React.useState([]);

  useEffect(() => {
    fetch("https://backend.playtoearnblockchain.com/api/v1/latestraceDSL", {
      headers: {
        authorization: `Bearer bs2zDM01DMMEgt33CrKZt8x4abs2zDM01DMMEgt33Cr6atzL3CJdExpP4x4abs2zDM01DMMEgt33Cr`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setAllData(data?.data[0]);
      });
    console.log(locataion?.state, "claim state", query, myValue);
    myValue
      ? setRewardAmount(myValue)
      : setRewardAmount(
        locataion?.state?.name ? parseInt(locataion?.state?.name) : 0
      );
  }, [AllData]);

  async function getAccount() {
    const provider = new ethers.providers.Web3Provider(web3.currentProvider);
    const accounts = await provider.listAccounts();
    return accounts[0];
  }

  const account = getAccount();

  async function handleClaim() {
    setLoading(true);
    // await axios
    //   .post(
    //     `https://backend.playtoearnblockchain.com/api/v1/payment/${user?.walletAddress}`,
    //     { prizeAmount: rewardAmount }
    //   )
    //   .then((res) => {
    //     if (res.status == 200) {
          try {
            axios
              .post(
                "https://backend.playtoearnblockchain.com/api/v1/admin-payment-dsl-record",
                {
                  walletAddress: user?.walletAddress,
                  dslToken: rewardAmount,
                  email: user?.email,
                  claim: false,
                }
              )
              .then((res) => {
                axios
                  .put(
                    `https://backend.playtoearnblockchain.com/api/v1/user/updatetoken/${user?.walletAddress}`,
                    { dslWon: rewardAmount }
                  )
                  .then(() => {
                    setSuccess(
                      `You have successfully claimed ${rewardAmount} DSL Tokens.`
                    );

                    const wrapper = document.createElement("div");
                    wrapper.innerHTML = `
            <p style="color: white;">You have successfully claimed ${rewardAmount} DSL Tokens.</p>
           
            <p style="color: white;">You will receive it within 48 hours once our admin checks</p>
        `;
                    swal({
                      content: wrapper,
                      icon: "success",
                      button: "OK!",
                      className: "modal_class_success",
                    }).then((willDelete) => {
                      if (willDelete) {
                        navigate("/playtoearn");
                      }
                    });
                  });

              });
          } catch (err) {
            setError(err.message);
            setLoading(false);
          }
      //   }
      // })
      // .catch((error) => {
      //   swal({
      //     title: "Attention",
      //     text: error.response.data.message,
      //     icon: "warning",
      //     button: "OK!",
      //     className: "modal_class_success",
      //   });
      // });
  }

  return (
    <div
      style={{
        height: "100vh",
        paddingTop: "96px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div>
        <div>
          <h1
            className="text-gradient text-center text-uppercase mt-3"
            style={{ fontSize: "1.75rem" }}
          >
            Claim Your Winnings
          </h1>
          <div className="small-border bg-color-2"></div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            marginTop: "36px",
          }}
        >
          <p
            style={{
              margin: "0",
              fontWeight: "600",
              color: "white",
              fontSize: "20px",
            }}
          >
            You have won {rewardAmount} DSL Tokens
          </p>

          {!user?.walletAddress || user?.walletAddress === "undefined" ? (
            <div className="landing-button text-center mt-4 mb-4 claimdsl">
              {checkDevice === "mobileWithoutApp" && (
                <a
                  href={`https://metamask.app.link/dapp/http://playtoearnblockchain.com/claimdsl?price=${rewardAmount}`}
                  // href={href}
                  target={"_blank"}
                  className="text-decoration-none"
                >
                  <button className="text-uppercase button-metamask  px-5">
                    {/* <img
                      className="me-2"
                      width="20px"
                      src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                      alt=""
                    />{" "} */}
                    CONNECT WITH WALLET
                  </button>
                </a>
              )}
              {checkDevice === "mobileWithApp" && (
                <>
                  {!user?.walletAddress ||
                    user?.walletAddress === "undefined" ? (
                    <button
                      className="text-uppercase button-metamask px-5"
                      onClick={() => openWalletModal()}
                    >
                      {" "}
                      {/* <img
                      className="me-2"
                      width="20px"
                      src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                      alt=""
                    />{" "} */}
                      CONNECT WITH WALLET
                    </button>
                  ) : (
                    <button
                      className="text-uppercase button-metamask px-5"
                      onClick={() => navigate("/profile")}
                    >
                      {" "}
                      {/* <img
                      className="me-2"
                      width="20px"
                      src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                      alt=""
                    />{" "} */}
                      profile
                    </button>
                  )}
                </>
              )}
              {checkDevice === "pcWithExtention" && (
                <>
                  {!user?.walletAddress ||
                    user?.walletAddress === "undefined" ? (
                    <button
                      className="text-uppercase button-metamask px-4"
                      onClick={() => openWalletModal()}
                      style={{ width: "auto" }}
                    >
                      {" "}
                      <img
                        className="me-2"
                        width="20px"
                        src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                        alt=""
                      />
                      CONNECT WITH WALLET TO EARN YOUR {rewardAmount} DSL TOKENS
                    </button>
                  ) : (
                    <button
                      className="text-uppercase button-metamask px-5"
                      onClick={() => navigate("/profile")}
                    >
                      {" "}
                      {/* <img
                      className="me-2"
                      width="20px"
                      src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                      alt=""
                    />{" "} */}
                      profile
                    </button>
                  )}
                </>
              )}
              {checkDevice === "pcWithoutExtention" && (
                <a
                  href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
                  target={"_blank"}
                  className="text-decoration-none"
                >
                  <button className="text-uppercase button-metamask px-5">
                    <img
                      className="me-2"
                      width="20px"
                      src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                      alt=""
                    />{" "}
                    CONNECT WITH WALLET
                  </button>
                </a>
              )}
            </div>
          ) : (
            <button
              className="button-18"
              disabled={loading || rewardAmount == 0}
              onClick={handleClaim}
            >
              Claim Your {rewardAmount} DSL Tokens
            </button>
          )}

          <p style={{ color: "white", textAlign: "center" }}>
            For more details about DSL Tokens,
            <br />
            Please{" "}
            <a
              href="https://backend.dsl.sg/public/dsl-whitepaper.pdf"
              target="_blank"
            >
              click here.
            </a>
          </p>
          <div className="text-center mt-0">
            <button
              onClick={() => navigate(-1)}
              style={{
                backgroundColor: "#dc3545",
                fontSize: "13px",
                padding: "10px 35px",
              }}
              class="button-18 mt-2 m-0"
              role="button"
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClaimRewards;
