import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { RiAdminFill } from 'react-icons/ri';
import './DashboardModalNewAdmin.css';
import 'react-phone-number-input/style.css';
import axios from 'axios';
import swal from 'sweetalert';
import { MdClose } from 'react-icons/md';

const DashboardModalNewCategory = (props) => {
    const {
        setIsLoadingCategory,
        setModalShowNewCategory,
        refetch,
        setRefetch } = props;

    const subNewAdmin = async event => {
        event.preventDefault();

        setIsLoadingCategory(true);
        let data = {
            category_name: event.target.category_name.value,
            name: "name",
            price: 34
        }

        setIsLoadingCategory(false);




        await axios.post("https://backend.playtoearnblockchain.com/api/v1/category", data, {

        })
            .then(res => {
                if (res.status === 200) {
                    console.log("success")
                    setIsLoadingCategory(false);
                    setModalShowNewCategory(false);
                    setRefetch(!refetch);
                    event.target.reset();
                    swal({
                        title: "Success",
                        text: `Category added successfully`,
                        icon: "success",
                        button: "OK!",
                        className: "modal_class_success",
                    });
                    // alert(res.data.message);
                }
            })
            .catch(error => {

                setIsLoadingCategory(false);
                swal({
                    title: "Attention",
                    text: `${error.response.data.message}`,
                    icon: "warning",
                    button: "OK!",
                    className: "modal_class_success",
                });
            })
        // setIsLoadingCategory(false);
    }

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header style={{ backgroundColor: "#272d47", color: 'white' }}>
                <Modal.Title id="contained-modal-title-vcenter" className='fs-5 text-light'>
                    Add Category
                </Modal.Title>
                <MdClose onClick={props.onHide} color='#fff' size={30} style={{ cursor: 'pointer' }} />
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#272d47", color: 'white' }}>
                <div>
                    <div>
                        <form onSubmit={subNewAdmin}>
                            <div className="row addAdminDiv">
                                <div className="col-lg-12">

                                    <p className='mb-1'>Category Name</p>
                                    <input
                                        className='form-control'
                                        placeholder='Enter category name'
                                        type="text"
                                        name="category_name"
                                        required
                                    />

                                </div>
                                <Modal.Footer className='mt-5'>
                                    <button type="button" className='adminBtnAdd11 text-uppercase' onClick={props.onHide}>Cancel</button>
                                    <button type="submit" className='adminBtnAdd text-uppercase'>Add</button>
                                </Modal.Footer>

                            </div>
                        </form>
                    </div>
                    <div>

                    </div>
                </div>
            </Modal.Body>

        </Modal>
    );
};

export default DashboardModalNewCategory;