import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CelebrityContext } from "../../context/CelebrityContext";

const LoginButton = ({ register, Id }) => {
  const { openWalletModal, openWalletModalProfile, user, searchNftTitle } =
    useContext(CelebrityContext);
  const [checkDevice, setCheckDevice] = useState("");
  const [texts, setText] = useState("");
  const [url, setUrl] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const detecting = async () => {
      if (window.innerWidth < 1000 && typeof window.ethereum === "undefined") {
        console.log("mobile");
        setCheckDevice(() => "mobileWithoutApp");
      } else if (
        window.innerWidth < 1000 &&
        typeof window.ethereum !== "undefined"
      ) {
        setCheckDevice(() => "mobileWithApp");
      } else if (
        window.innerWidth > 1000 &&
        typeof window.ethereum !== "undefined"
      ) {
        console.log("pc");
        setCheckDevice(() => "pcWithExtention");
      } else if (
        window.innerWidth > 1000 &&
        typeof window.ethereum === "undefined"
      ) {
        setCheckDevice(() => "pcWithoutExtention");
      }
    };

    detecting();
    console.log(register, "register");
    if (register == "login") {
      setText("CONNECT WITH WALLET");
      setUrl(
        "https://metamask.app.link/dapp/http://playtoearnblockchain.com/checknft"
      );
    } else if (register == "code") {
      setText("CONNECT WITH WALLET");
      setUrl("https://metamask.app.link/dapp/http://playtoearnblockchain.com");
    } else if (register == "pay") {
      setText("CONNECT WITH WALLET to Buy");
      setUrl(
        `https://metamask.app.link/dapp/http://playtoearnblockchain.com/nft/${Id}/nfts`
      );
    } else if (register == "busdgame") {
      setText("CONNECT WITH WALLET");
      setUrl(
        `https://metamask.app.link/dapp/http://playtoearnblockchain.com/selectwinhorsechamp`
      );
    }
  }, []);

  return (
    <div className="landing-button text-center mt-4 mb-4">
      {checkDevice === "mobileWithoutApp" && (
        <a href={url} target={"_blank"} className="text-decoration-none">
          <button className="text-uppercase button-metamask  px-5">
            {/* <img
              className="me-2"
              width="20px"
              src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
              alt=""
            />{" "}  */}
            {register == "pay"
              ? "CONNECT WITH WALLET TO BUY"
              : "CONNECT WITH WALLET"}
          </button>
        </a>
      )}
      {checkDevice === "mobileWithApp" && (
        <>
          {!user?.walletAddress || user?.walletAddress === "undefined" ? (
            <button
              className="text-uppercase button-metamask px-5"
              onClick={() => openWalletModal()}
            >
              {" "}
              {/* <img
                className="me-2"
                width="20px"
                src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                alt=""
              /> */}
              {register == "pay"
                ? "CONNECT WITH WALLET TO BUY"
                : "CONNECT WITH WALLET"}
            </button>
          ) : (
            <button
              className="text-uppercase button-metamask px-5"
              onClick={() => navigate("/profile")}
            >
              {" "}
              {/* <img
                className="me-2"
                width="20px"
                src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                alt=""
              />{" "} */}
              profile
            </button>
          )}
        </>
      )}
      {checkDevice === "pcWithExtention" && (
        <>
          {!user?.walletAddress || user?.walletAddress === "undefined" ? (
            <button
              className="text-uppercase button-metamask px-5"
              onClick={() => openWalletModal()}
            >
              {" "}
              {/* <img
                className="me-2"
                width="20px"
                src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                alt=""
              /> */}
              {texts}
            </button>
          ) : (
            <button
              className="text-uppercase button-metamask px-5"
              onClick={() => navigate("/profile")}
            >
              {" "}
              {/* <img
                className="me-2"
                width="20px"
                src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                alt=""
              />{" "} */}
              profile
            </button>
          )}
        </>
      )}
      {checkDevice === "pcWithoutExtention" && (
        <a
          href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
          target={"_blank"}
          className="text-decoration-none"
        >
          <button className="text-uppercase button-metamask px-5">
            {/* <img
              className="me-2"
              width="20px"
              src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
              alt=""
            />{" "} */}{" "}
            CONNECT WITH WALLET
          </button>
        </a>
      )}
    </div>
  );
};

export default LoginButton;
